/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./popup-more-images.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../modules/language/language-translate.pipe";
import * as i3 from "../../modules/language/language.service";
import * as i4 from "@angular/common";
import * as i5 from "../../pages/investments-nft-popup/investments-nft-popup.component.ngfactory";
import * as i6 from "../../pages/investments-nft-popup/investments-nft-popup.component";
import * as i7 from "../../service/loader.service";
import * as i8 from "../../modules/currency/currency.service";
import * as i9 from "../../service/cashapp-pay.service";
import * as i10 from "../popup/popup.component";
import * as i11 from "../popup-big-image/popup-big-image.component.ngfactory";
import * as i12 from "../popup-big-image/popup-big-image.component";
import * as i13 from "./popup-more-images.component";
import * as i14 from "../../service/perks.service";
import * as i15 from "../../service/account.service";
import * as i16 from "../../service/pageScroll/paginationScroll.service";
var styles_PopupMoreImagesComponent = [i0.styles];
var RenderType_PopupMoreImagesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PopupMoreImagesComponent, data: {} });
export { RenderType_PopupMoreImagesComponent as RenderType_PopupMoreImagesComponent };
function View_PopupMoreImagesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["class", "slide library__slide js-images-item js-images-item-list"]], [[1, "data-id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", ""], ["class", "slide library__slide-img"], ["width", "100%"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "p", [["class", "library__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", " ", "%"])), i1.ɵpid(0, i2.LanguageTranslatePipe, [i3.LanguageService]), i1.ɵppd(5, 2)], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.uuid; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.image_link, ""); _ck(_v, 1, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("ownership")); var currVal_3 = i1.ɵunv(_v, 3, 1, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent.parent, 0), _v.context.$implicit.ownership, "1.0-2")); _ck(_v, 3, 0, currVal_2, currVal_3); }); }
function View_PopupMoreImagesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "library library--grid"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupMoreImagesComponent_3)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imagesData.items; _ck(_v, 2, 0, currVal_0); }, null); }
function View_PopupMoreImagesComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "slide library__slide js-library-slide js-images-item-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", ""], ["class", "slide library__slide-img"], ["style", "pointer-events: auto"], ["width", "100%"]], [[8, "src", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onShowLibraryBigImagePopup(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "nft-terms-agreement"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "input", [["class", "nft-terms-agreement__input checkbox-primary"], ["type", "checkbox"]], [[8, "id", 0], [8, "checked", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onLibraryImageCheckboxChange($event, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "label", [["class", "nft-terms-agreement__label"], ["style", "cursor: pointer"]], [[8, "htmlFor", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", (_v.context.$implicit.image_link ? _v.context.$implicit.image_link : _v.context.$implicit.image_full), ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = ("mintBrushPopup" + _v.context.index); var currVal_2 = !(_v.context.$implicit.privacy_status - 0); _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_3 = ("mintBrushPopup" + _v.context.index); _ck(_v, 4, 0, currVal_3); }); }
function View_PopupMoreImagesComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "library library--grid"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupMoreImagesComponent_5)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imagesData.items; _ck(_v, 2, 0, currVal_0); }, null); }
function View_PopupMoreImagesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "investments-popup__inner  js-images-parent"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "button", [["class", "investments-popup__close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closePopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "dropdown"], ["style", "padding-bottom: 0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, [["scrolledEl", 1]], null, 4, "div", [["class", "dropdown__body dropdown__body--prizes"]], null, [[null, "scroll"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("scroll" === en)) {
        var pd_0 = (_co.scroll($event, _co.imagesData) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupMoreImagesComponent_2)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupMoreImagesComponent_4)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.type === _co.POPUP_MORE_IMAGES_TYPE.nft); _ck(_v, 5, 0, currVal_0); var currVal_1 = (_co.type === _co.POPUP_MORE_IMAGES_TYPE.image); _ck(_v, 7, 0, currVal_1); }, null); }
function View_PopupMoreImagesComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-investments-nft-popup", [], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.onPopupNFTClose($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_InvestmentsNftPopupComponent_0, i5.RenderType_InvestmentsNftPopupComponent)), i1.ɵdid(1, 9027584, null, 0, i6.InvestmentsNftPopupComponent, [i7.LoaderService, i3.LanguageService, i1.ChangeDetectorRef, i8.CurrencyService, i9.CashappPayService, i10.PopupComponent], { popupData: [0, "popupData"], popupType: [1, "popupType"] }, { close: "close" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.NFTPopupData.data; var currVal_1 = _co.NFTPopupData.type; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_PopupMoreImagesComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-popup-big-image", [], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.onPopupLibraryBigImageClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_PopupBigImageComponent_0, i11.RenderType_PopupBigImageComponent)), i1.ɵdid(1, 9027584, null, 0, i12.PopupBigImageComponent, [i7.LoaderService, i3.LanguageService, i1.ChangeDetectorRef], { popupData: [0, "popupData"] }, { close: "close" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.libraryBigImagePopupData.data; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PopupMoreImagesComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.DecimalPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "investments-popup investments-popup--pay"]], [[2, "is-blurred", null], [2, "is-active", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupMoreImagesComponent_1)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupMoreImagesComponent_6)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupMoreImagesComponent_7)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.popupInfo.show; _ck(_v, 3, 0, currVal_2); var currVal_3 = (_co.showNFTPopup && _co.NFTPopupData); _ck(_v, 5, 0, currVal_3); var currVal_4 = (_co.showLibraryBigImagePopup && _co.libraryBigImagePopupData); _ck(_v, 7, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.showNFTPopup || _co.showLibraryBigImagePopup); var currVal_1 = _co.popupInfo.show; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_PopupMoreImagesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-popup-more-images", [], null, null, null, View_PopupMoreImagesComponent_0, RenderType_PopupMoreImagesComponent)), i1.ɵdid(1, 638976, null, 0, i13.PopupMoreImagesComponent, [i7.LoaderService, i14.PerksService, i15.AccountService, i16.PaginationScrollService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PopupMoreImagesComponentNgFactory = i1.ɵccf("app-popup-more-images", i13.PopupMoreImagesComponent, View_PopupMoreImagesComponent_Host_0, { popupInfo: "popupInfo", type: "type" }, { close: "close", libraryImageCheckboxChange: "libraryImageCheckboxChange" }, []);
export { PopupMoreImagesComponentNgFactory as PopupMoreImagesComponentNgFactory };

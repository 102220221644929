import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {LoaderService} from "./loader.service";
import {CHANNELS_CONFIG, PERKS_CONFIG} from "../config/api";


@Injectable({
    providedIn: 'root'
})
export class ChannelsService {
    private config = CHANNELS_CONFIG;

    constructor(private http: HttpClient,
                public loaderService: LoaderService) {}

    getPageInfo() {
        this.loaderService.show();
        return this.http.post<any>(this.config.getChannelsList, {});
    }

    onCallMe() {
        this.loaderService.show();
        return this.http.post<any>(this.config.onCallMe, {});
    }
}

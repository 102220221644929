import { STATES_CONFIG } from "../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./loader.service";
var StatesService = /** @class */ (function () {
    function StatesService(http, loaderService) {
        this.http = http;
        this.loaderService = loaderService;
        this.config = STATES_CONFIG;
    }
    StatesService.prototype.getStates = function () {
        this.loaderService.show();
        var post = this.http.post(this.config.getStates, {});
        return post;
    };
    StatesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StatesService_Factory() { return new StatesService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: StatesService, providedIn: "root" });
    return StatesService;
}());
export { StatesService };

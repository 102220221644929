import { OnInit } from '@angular/core';
import { Validators } from "@angular/forms";
import { RouterPaths } from "../../config/router-paths.model";
import { CRYPTO_AMOUNT_MASK, PROJECT_MESSAGES } from "../../config/project";
import { formatNumber } from "@angular/common";
import { POPUP_CHANNELS_TYPE } from "../../shared/popup-channels/popup-channels.config";
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "../../modules/language/language.service";
import * as i3 from "../../service/perks.service";
import * as i4 from "../../service/loader.service";
import * as i5 from "../../modules/currency/currency.service";
import * as i6 from "../../service/pos-settings.service";
import * as i7 from "../../service/content-displaying.service";
import * as i8 from "../../shared/popup/popup.component";
var PerksComponent = /** @class */ (function () {
    function PerksComponent(formBuilder, languageService, perksService, loaderService, currencyService, posSettingsService, contentDisplayingService, popupComponent) {
        this.formBuilder = formBuilder;
        this.languageService = languageService;
        this.perksService = perksService;
        this.loaderService = loaderService;
        this.currencyService = currencyService;
        this.posSettingsService = posSettingsService;
        this.contentDisplayingService = contentDisplayingService;
        this.popupComponent = popupComponent;
        this.entriesVal = 0;
        this.redeemedVal = 0;
        this.availableBalance = 0;
        this.providers = [];
        this.RouterPaths = RouterPaths;
        this.POPUP_CHANNELS_TYPE = POPUP_CHANNELS_TYPE;
        this.moreThenAvailablePayout = false;
        this.showMoreTransactionsBtn = false;
        this.transactionsList = [];
        this.accountHistoryProcessing = [];
        this.popupChannels = { show: false };
        this.popupTransactions = { show: false };
        this.popupConfirmInfo = { show: false, totalDebit: 0 };
        this.showSellPoints = true;
    }
    PerksComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.languageChangeSubscription = this.languageService.languageIsUpdated.subscribe(function (val) {
            if (val) {
                setTimeout(function () {
                    _this.getPageInfo();
                });
            }
        });
        this.pageForm = this.formBuilder.group({
            method: [null, Validators.required],
            amount: [null, Validators.required],
        });
        this.getPageInfo();
    };
    PerksComponent.prototype.ngOnDestroy = function () {
        this.languageChangeSubscription.unsubscribe();
    };
    PerksComponent.prototype.getPageInfo = function (only_balance) {
        var _this = this;
        if (only_balance === void 0) { only_balance = false; }
        this.perksService.getPageInfo(1, false).subscribe(function (data) {
            _this.setPageInfo(data, only_balance);
        }, function () {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
        });
    };
    PerksComponent.prototype.setPageInfo = function (data, only_balance) {
        try {
            this.entriesVal = data.entries;
            this.redeemedVal = data.spend;
            this.posSettingsService.setSupportVisible(data.support);
            if (only_balance) {
                this.updateGameAvailableBalance(this.selectedProvider.id);
                return;
            }
            else {
                this.posSettingsService.setRedeemsVisibility(data.redeems);
                this.showSellPoints = this.posSettingsService.getRedeemsVisibility();
                this.providers = [];
                if (data.games && data.games.length) {
                    this.setGamesProviders(data.games);
                }
                this.setTransactionsList(data.accountHistory, data.limit, data.total);
                this.accountHistoryProcessing = data.accountHistoryProcessing;
            }
        }
        catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    PerksComponent.prototype.setGamesProviders = function (providers) {
        this.providers = providers;
        this.selectedProvider = this.providers.find(function (provider) { return provider.default; });
        this.pageForm.controls.method.setValue(this.selectedProvider);
        this.updateGameAvailableBalance(this.selectedProvider.id);
    };
    PerksComponent.prototype.updateGameAvailableBalance = function (id) {
        var _this = this;
        this.perksService.getGameBalance(id).subscribe(function (data) {
            _this.setGameAvailableBalance(data);
        }, function () {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
        });
    };
    PerksComponent.prototype.setGameAvailableBalance = function (data) {
        var _this = this;
        try {
            if (data.status) {
                this.availableBalance = data.availableBalance;
                setTimeout(function () {
                    _this.onInputAmount();
                });
            }
            else {
                this.popupComponent.showPopupError({ text: data.message });
            }
        }
        catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    PerksComponent.prototype.onProviderChange = function (item) {
        this.updateGameAvailableBalance(item.id);
    };
    PerksComponent.prototype.onInputAmount = function () {
        var avaliable = typeof this.availableBalance === 'number' ? this.availableBalance : Number.parseFloat(this.availableBalance);
        var entered = Number.parseFloat(this.pageForm.controls.amount.value);
        if (avaliable < entered) {
            this.pageForm.controls.amount.setValue(String(avaliable));
            this.moreThenAvailablePayout = true;
        }
        else {
            this.moreThenAvailablePayout = false;
        }
    };
    PerksComponent.prototype.setTransactionsList = function (history, limit, total) {
        this.showMoreTransactionsBtn = total > limit;
        this.transactionsList = history;
    };
    PerksComponent.prototype.showTransactionsPopup = function () {
        document.body.classList.add('hide-menu');
        this.popupTransactions = {
            show: true
        };
    };
    PerksComponent.prototype.closeTransactionsPopup = function () {
        document.body.classList.remove('hide-menu');
        this.popupTransactions = {
            show: false
        };
    };
    PerksComponent.prototype.showChannelsPopup = function (type) {
        document.body.classList.add('hide-menu');
        this.popupChannels = {
            show: true,
            type: type,
            availableBalance: this.redeemedVal
        };
    };
    PerksComponent.prototype.closeChannelsPopup = function (needUpdateBalance) {
        document.body.classList.remove('hide-menu');
        this.popupChannels = {
            show: false
        };
        if (needUpdateBalance) {
            this.getPageInfo();
        }
    };
    PerksComponent.prototype.onSubmitRedeem = function (amount) {
        var methodName = this.pageForm.controls.method.value.name;
        var providerId = this.pageForm.controls.method.value.id;
        if (!amount || !methodName) {
            this.popupComponent.showPopupError({ text: PROJECT_MESSAGES.notAllFieldsAreFilled });
        }
        else {
            var text = this.languageService.getStringFirstCharCapital('redeem') + " \n                          $" + formatNumber(amount, 'en', CRYPTO_AMOUNT_MASK) + ".00 \n                          " + this.languageService.getString('from') + " \n                          " + methodName + "?";
            this.showPopupConfirmCancel(text, this.sendRedeem.bind(this, providerId, amount));
        }
    };
    PerksComponent.prototype.sendRedeem = function (providerId, amount) {
        var _this = this;
        this.perksService.redeem(providerId, amount).subscribe(function (data) {
            _this.displaySubmitPayoutResult(data);
        }, function () { _this.loaderService.hideAndShowTryAgain(); }, function () { _this.loaderService.hide(); });
    };
    PerksComponent.prototype.displaySubmitPayoutResult = function (data) {
        try {
            if (data.status) {
                this.popupComponent.showPopupSuccess({ text: data.message });
                this.getPageInfo();
                this.closePopupConfirm();
                this.pageForm.controls.amount.reset();
                this.moreThenAvailablePayout = false;
            }
            else {
                this.popupComponent.showPopupError({ text: data.message });
            }
        }
        catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    PerksComponent.prototype.showPopupConfirmCancel = function (text, successCallback) {
        this.popupComponent.showPopupConfirmCancel({ text: text }, function (status) {
            if (status) {
                successCallback();
            }
        });
    };
    PerksComponent.prototype.closePopupConfirm = function () {
        document.body.classList.remove('hide-menu');
        this.popupConfirmInfo = {
            show: false, totalDebit: 0
        };
    };
    PerksComponent.prototype.formatCurrency = function (amount) {
        return this.currencyService.formatCurrency(amount);
    };
    PerksComponent.prototype.showAdditionalContent = function () {
        return this.contentDisplayingService.showAdditionContent();
    };
    PerksComponent.prototype.isSupportShown = function () {
        return this.posSettingsService.getSupportVisibility();
    };
    PerksComponent.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PerksComponent_Factory() { return new PerksComponent(i0.ɵɵinject(i1.FormBuilder), i0.ɵɵinject(i2.LanguageService), i0.ɵɵinject(i3.PerksService), i0.ɵɵinject(i4.LoaderService), i0.ɵɵinject(i5.CurrencyService), i0.ɵɵinject(i6.PosSettingsService), i0.ɵɵinject(i7.ContentDisplayingService), i0.ɵɵinject(i8.PopupComponent)); }, token: PerksComponent, providedIn: "root" });
    return PerksComponent;
}());
export { PerksComponent };

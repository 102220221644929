import {
    Component, ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChildren
} from '@angular/core';
import {PopupComponent} from "../../../shared/popup/popup.component";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ContactInfoService} from "../../../service/contactInfo.service";
import {LoaderService} from "../../../service/loader.service";
import {Router} from "@angular/router";
import {AppPromptService} from "../../../service/app/appPrompt.service";
import {AuthenticationService} from "../../../modules/auth/_services/authentication.service";
import {LanguageService} from "../../../modules/language/language.service";

@Component({
    selector: 'app-login-contacts-form',
    templateUrl: './login-contacts-form.component.html',
    styleUrls: ['./login-contacts-form.component.scss'],
})
export class LoginContactsFormComponent implements OnInit {
    @Input() style;
    @Input() email;
    @Input() isUserInfoUpdate = false;
    @Input() userInfoUpdateData: any;
    @Output() registerUser = new EventEmitter();
    @Output() backToLogin = new EventEmitter();

    isMascot: boolean;
    contactInfo: FormGroup;
    info;

    numOfDigits = 4;


    @HostBinding('class.is-mascot') mascotClass: boolean;
    @HostBinding('@.disabled') public animationsDisabled: boolean;
    @ViewChildren('inputs') inputs: QueryList<ElementRef>;

    constructor(private popupComponent: PopupComponent,
                private contactInfoService: ContactInfoService,
                private router: Router,
                private loaderService: LoaderService,
                private appPromptService: AppPromptService,
                private languageService: LanguageService,
                private auth: AuthenticationService,
                private formBuilder: FormBuilder) {
    }


    ngOnInit() {
        this.contactInfo = this.formBuilder.group({
            first_name: [null, Validators.required],
            last_name: [null, Validators.required],
            email: [null, [Validators.required, Validators.pattern('^[0-9A-Za-z._%+-]+@[0-9A-Za-z.-]+\\.[a-zA-Z]{2,4}$')]],
            promo: [null],
            password: [null, Validators.required],
            passwordRepeat: [null, Validators.required],
            digits: this.formBuilder.array([])
        });
        for (let i = 0; i < this.numOfDigits; i++) {
            (this.contactInfo.get('digits') as FormArray).push(this.formBuilder.control(null, Validators.required));
        }


        setTimeout(() => {
            if (this.email) {
                this.contactInfo.controls.email.setValue(this.email);
                this.contactInfo.controls.email.disable();
            }
        });

        window.addEventListener('appinstalled', (evt) => {
            this.popupComponent.closePopup();
            const text = this.languageService.getString('messagesData', 'launchAppFromDevice');
            this.popupComponent.showPopupSuccess({text: text});
        });

        if (this.isUserInfoUpdate) {
            this.contactInfo.controls.first_name.setValue(this.userInfoUpdateData.firstName ? this.userInfoUpdateData.firstName : null);
            this.contactInfo.controls.last_name.setValue(this.userInfoUpdateData.lastName ? this.userInfoUpdateData.lastName : null);
            this.contactInfo.controls.email.setValue(this.userInfoUpdateData.email ? this.userInfoUpdateData.email : null);
        }
    }

    onSubmit() {
        if (this.contactInfo.controls.digits.invalid) {
            return;
        }

        let pin = this.contactInfo.controls.digits.value;
        pin = pin.join('');

        if (pin.length !== 4) {
            const errorMessage = this.languageService.getString('messagesData', 'errorIncorrectPin');
            this.popupComponent.showPopupError({text: errorMessage});
            return
        }

        const firstName = this.contactInfo.controls.first_name.value;
        const lastName = this.contactInfo.controls.last_name.value;
        const email = this.contactInfo.controls.email.value;
        const promo = this.contactInfo.controls.promo.value;
        const password = this.contactInfo.controls.password.value;
        const passwordRepeat = this.contactInfo.controls.passwordRepeat.value;

        if (password !== passwordRepeat) {
            const text = this.languageService.getString('messagesData', 'passwordNotMatch');
            this.popupComponent.showPopupError({text: text});
            return;
        }

        this.registerUser.emit({firstName, lastName, email, promo, password, pin});
    }

    onInput(control, $event) {
        this.contactInfo.get(control).patchValue( $event.target.value.trim(), {emitEvent: false} );
    }

    resetPinInput() {
        (this.contactInfo.get('digits') as FormArray).clear();
        for (let i = 0; i < this.numOfDigits; i++) {
            (this.contactInfo.get('digits') as FormArray).push(this.formBuilder.control(null, Validators.required));
        }
    }

    check(index, field, event) {
        if (isNaN(parseInt(event.key, 10)) && event.key !== 'Backspace') {
            event.preventDefault();
        }

        if (field.value && event.key !== 'Backspace') {
            if (index < this.inputs.toArray().length - 1) {
                this.inputs.toArray()[index + 1].nativeElement.focus();
            }
        } else if (event.key === 'Backspace') {
            if (index > 0) {
                field.setValue(null)
                this.inputs.toArray()[index - 1].nativeElement.focus();
            } else {
                console.log('first field');
            }
        }
    }

    goBack() {
        this.contactInfo.reset();
        this.backToLogin.emit();
    }
}

import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {timer} from "rxjs";
import {mergeMap} from "rxjs/operators";
import {LoaderService} from "./loader.service";
import {SPINNER_GAME_CONFIG} from "../config/api";

@Injectable({
    providedIn: 'root'
})
export class SpinnerService {
    private config = SPINNER_GAME_CONFIG;

    constructor(private http: HttpClient) {
    }

    init() {
        // this.showLoader();
        let post = this.http.post<any>(this.config.init, {});
        return post;

        // return timer(1500).pipe(
        //   mergeMap(() => this.http.post<any>(this.config.init, {}))
        // );
    }
}

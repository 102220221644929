import {Injectable} from '@angular/core';
import {PopupComponent} from "../shared/popup/popup.component";
import {LanguageService} from "../modules/language/language.service";


@Injectable({
  providedIn: 'root'
})
export class CopyToBufferService {

  constructor(private popupComponent: PopupComponent,
              private languageService: LanguageService) {
  }

  copyToBuffer(value, needMessage?, messageText?) {
    let tempInput = document.createElement('input');
    tempInput.value = value;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    if (needMessage) {
      const popupMessage = messageText ? messageText : this.languageService.getString('messagesData', 'codeCopied');
      this.popupComponent.showPopupSuccess({text: popupMessage});
    }
  }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {LoaderService} from "../../service/loader.service";
import {PopupComponent} from "../popup/popup.component";
import {CardsService} from "../../service/cards.service";
import {formatCurrency} from "@angular/common";
import {LanguageService} from "../../modules/language/language.service";
import {PhoneVerifyService} from "../../service/phoneVerify.service";
import {CurrencyService} from "../../modules/currency/currency.service";
import {ContentDisplayingService} from "../../service/content-displaying.service";
import {PartnerService} from "../../service/partner.service";

@Component({
    selector: 'app-popup-withdraw-check',
    templateUrl: './popup-withdraw-check.component.html',
    styleUrls: ['./popup-withdraw-check.component.scss']
})
export class PopupWithdrawCheckComponent implements OnInit {
    @Input() popupInfo: any = {show: false};
    @Output('onClose') emitClose: EventEmitter<any> = new EventEmitter();
    @Output('onSuccess') onSuccess: EventEmitter<any> = new EventEmitter();

    form: FormGroup;

    popupSteps = {
        amount: 1,
        address: 2,
        total: 3
    }
    currentStep = 1;
    enteredAmount = null;
    total = null;
    fee = null;
    firstName;
    lastName = null;
    address = null;
    subtotal = null;
    date = new Date();
    moreThenAvailablePayout = false;
    constructor(private formBuilder: FormBuilder,
                private loaderService: LoaderService,
                private languageService: LanguageService,
                private phoneVerifyService: PhoneVerifyService,
                private partnerService: PartnerService,
                private popupComponent: PopupComponent,
                private contentDisplayingService: ContentDisplayingService,
                private currencyService: CurrencyService,
                private cardsService: CardsService) {}

    ngOnInit() {
        this.date = new Date();
        this.form = this.formBuilder.group({
            amount: [null, Validators.required],
            address: [null, Validators.required]
        });
    }

    ngOnChanges(changes) {
        this.resetPopup();
    }

    sendForm() {
        const amount = this.form.controls.amount.value;

        this.onGeneratePassword(amount);
    }

    ngOnDestroy() {
        this.resetPopup();
    }

    onGeneratePassword(amount) {
        this.phoneVerifyService.repeatFunction = this.verifyCardRequest.bind(this, amount);
        this.verifyCardRequest(amount);
    }

    verifyCardRequest(amount) {
        const id = this.popupInfo.id;
        this.cardsService.verifyCard(amount, id).subscribe(data => {
            this.setRequestResult(data);
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        })
    }

    setRequestResult(data) {
        try {
            if (data.status) {
                this.popupComponent.showPopupSuccess({text: data.message});
                this.closePopup();
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    closePopup() {
        this.resetPopup();
        this.emitClose.emit();
    }

    onInputAmount() {
        const avaliable = typeof this.popupInfo.totalDebit === 'number' ? this.popupInfo.totalDebit : Number.parseFloat(this.popupInfo.totalDebit);
        const entered = Number.parseFloat(this.form.controls.amount.value);

        if (avaliable < entered) {
            this.form.controls.amount.setValue(String(avaliable));
            this.moreThenAvailablePayout = true;
        } else {
            this.moreThenAvailablePayout = false;
        }
    }

    onContinue() {
        const amount = this.form.controls.amount.value;
        const address = this.form.controls.address.value;
        if (this.currentStep === this.popupSteps.amount) {
            this.phoneVerifyService.repeatFunction = this.getCheckAddressRequest.bind(this, amount);
            this.getCheckAddressRequest(amount);
        } else if (this.currentStep === this.popupSteps.address) {
            this.phoneVerifyService.repeatFunction = this.getCheckTotalRequest.bind(this, amount);
            this.getCheckTotalRequest(amount)
        } else if (this.currentStep === this.popupSteps.total) {
            const text = `${this.languageService.getString('transfersData', 'youAreAboutToRedeem')} 
                          ${this.currencyService.formatCurrency(this.total)} 
                          ${this.languageService.getStringWithVariables([{firstName: this.firstName}, {lastName: this.lastName}, {address: address}], 'transfersData', 'toPhysicalCheck')}.
                          ${this.languageService.getString('pleaseConfirm')}.`;
            this.popupComponent.showPopupConfirmCancel({text}, (status) => {
                if (status) {
                    this.phoneVerifyService.repeatFunction = this.redeemViaCheckRequest.bind(this, amount, address);
                    this.redeemViaCheckRequest(amount, address)
                }
            });
        }
    }

    getCheckAddressRequest(amount) {
        this.cardsService.getCheckAddress(amount).subscribe(data => {
            this.setAddressStep(data);
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    getCheckTotalRequest(amount) {
        this.cardsService.getCheckTotal(amount).subscribe(data => {
            this.setTotalStep(data);
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    redeemViaCheckRequest(amount, address) {
        this.cardsService.redeemViaCheck(amount, address).subscribe(data => {
            this.onRedeemResult(data);
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    onBack() {
        if (this.currentStep === this.popupSteps.amount) {
            this.closePopup();
        } else if (this.currentStep === this.popupSteps.address) {
            this.currentStep = this.popupSteps.amount;
        } else if (this.currentStep === this.popupSteps.total) {
            this.currentStep = this.popupSteps.address;
        }
    }

    setAddressStep(data) {
        try {
            if (data.status) {
                if (data.result && (typeof data.result === 'string') && data.result.trim()) {
                    this.form.controls.address.setValue(data.result);
                }
                this.enteredAmount = this.form.controls.amount.value;
                this.currentStep = this.popupSteps.address;
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    setTotalStep(data) {
        try {
            if (data.status) {
                this.subtotal = data.subtotal;
                this.total = data.total;
                this.fee = data.fee;
                this.firstName = data.firstName;
                this.lastName = data.lastName;
                this.currentStep = this.popupSteps.total;
                this.address = this.form.controls.address.value;
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    onRedeemResult(data) {
        try {
            if (data.status) {
                this.popupComponent.showPopupSuccess({text: data.message});
                this.onSuccess.emit();
                this.closePopup();
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    resetPopup() {
        if (this.form) {
            this.form.reset();
        }
        this.currentStep = this.popupSteps.amount;
        this.enteredAmount = null;
        this.total = null;
        this.fee = null;
        this.firstName = null;
        this.lastName = null;
        this.address = null;
        this.date = new Date();
        this.subtotal = null;
        this.moreThenAvailablePayout = false;
    }

    formatCurrency(amount) {
        return this.currencyService.formatCurrency(amount);
    }

    getPartnerLogo() {
        return this.partnerService.getPartnerLogo() ? this.partnerService.getPartnerLogo() : '../assets/images/logo.png';
    }
}

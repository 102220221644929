import * as tslib_1 from "tslib";
import { ChangeDetectorRef, EventEmitter, OnInit } from '@angular/core';
var PopupBigImageComponent = /** @class */ (function () {
    function PopupBigImageComponent(loaderService, languageService, cdr) {
        this.loaderService = loaderService;
        this.languageService = languageService;
        this.cdr = cdr;
        this.close = new EventEmitter();
        this.selectedImage = false;
    }
    PopupBigImageComponent.prototype.ngAfterViewChecked = function () {
        this.cdr.detectChanges();
    };
    PopupBigImageComponent.prototype.ngOnInit = function () { };
    PopupBigImageComponent.prototype.closePopup = function (reload) {
        if (reload === void 0) { reload = false; }
        this.close.emit(reload);
        document.body.classList.remove('hide-menu');
        this.selectedImage = false;
    };
    PopupBigImageComponent.prototype.ngOnChanges = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (this.popupData) {
                    document.body.classList.add('hide-menu');
                }
                return [2 /*return*/];
            });
        });
    };
    return PopupBigImageComponent;
}());
export { PopupBigImageComponent };

import {Injectable, NgZone} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {LoaderService} from "./loader.service";
import {HttpClient} from "@angular/common/http";
import {PARTNERS_CONFIG} from "../config/api";
import {BehaviorSubject} from "rxjs";
import {FrontendTestSettingsService} from "./testing/frontendTestSettings.service";
import {AuthenticationService} from "../modules/auth/_services/authentication.service";
import {LanguageService} from "../modules/language/language.service";

@Injectable({
    providedIn: 'root'
})
export class PartnerService {
    private config = PARTNERS_CONFIG;
    currentPartner = '';
    private storage = sessionStorage;
    currentPartnerTemplate = new BehaviorSubject('');

    constructor(private ngZone: NgZone,
                private route: ActivatedRoute,
                private loaderService: LoaderService,
                private auth: AuthenticationService,
                private http: HttpClient,
                private router: Router,
                private frontendTestSettingsService: FrontendTestSettingsService,) {
    }

    getPartner(){
        const domain = window.location.hostname;
        return this.http.post<any>(this.config.partner, {domain:domain});
    }

    detectPartner(callback?) {
        if(!this.auth.isUserLogged()){
            this.auth.clearStorage();

            this.getPartner().subscribe(data => {
                if (data.status) {
                    this.savePartner(data);
                    if (callback) {
                        callback();
                    } else {
                        //this.router.navigate([RouterPaths.login]);
                    }
                }else{
                    this.router.navigate(['/']);
                }
            }, (data) => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.route.queryParams.subscribe(params => {
                    if (!params.phone && !params.email) {
                        this.loaderService.hide();
                    }
                });
            });
        }
    }

    savePartner(data){
        if (this.frontendTestSettingsService.isPartnerTypeDefault()) {
            data.result.type = 0;
            console.log('d')
        }
        if (this.frontendTestSettingsService.isPartnerTypeSingle()) {
            data.result.type = 1;
            // console.log('d2')
        }

        if (this.frontendTestSettingsService.isPartnerAdditionInfo()) {
            data.result.businessName = 'Oksana Test';
            data.result.domain = 'http://google.com';
            data.result.domainHome = 'http://google.com';
            data.result.logo_background = './assets/partners/snekapek/icons/icon-144.png';
        }

        if(data.result){
            this.storageSetData(data.result);

            this.storageSetShortName('');
            if(data.result.short_name){
                this.storageSetShortName(data.result.short_name);
            }

            this.storageSetTemplate('');
            if(data.result.template){
                this.storageSetTemplate(data.result.template);
            }

            this.storageSetTerminalShortName('');
            if(data.result.terminalShortName){
                this.storageSetTerminalShortName(data.result.terminalShortName);
            }

            this.storageSetCurrentPartnerBusinessName('');
            if(data.result.businessName){
                this.storageSetCurrentPartnerBusinessName(data.result.businessName);
                document.querySelector('title').innerHTML = data.result.businessName;
            }

            this.storageSetCurrentPartnerDomain('');
            if(data.result.domain){
                this.storageSetCurrentPartnerDomain(data.result.domain);
            }

            this.storageSetCurrentPartnerDomainHome('');
            if(data.result.domainHome){
                this.storageSetCurrentPartnerDomainHome(data.result.domainHome);
            }

            this.storageSetCurrentPartnerLogo('');
            if(data.result.logo){
                this.storageSetCurrentPartnerLogo(data.result.logo);
            }

            this.storageSetCurrentPartnerLogoBackground('');
            if(data.result.logo_background){
                this.storageSetCurrentPartnerLogoBackground(data.result.logo_background);
            }

            this.storageSetCashapTag('');
            if(data.result.cashapp_tag){
                this.storageSetCashapTag(data.result.cashapp_tag);
            }

            this.storageSetPartnerType('');
            if(data.result.type || data.result.type === 0){
                this.storageSetPartnerType(data.result.type);
            }

            this.storageSetContentType(0);
            if(data.result.showSweeps || data.result.showSweeps === 0){
                this.storageSetContentType(data.result.showSweeps);
            }

            // if (this.getShortName() === PARTNERS.senet.short_name || this.getShortName() === PARTNERS.senetLabel.short_name) {
            //     this.changeThumbnailIcons(PARTNERS.senet.short_name);
            //     document.querySelector('title').innerHTML = this.getBusinessName();
            // }
        }
    }

    storageSetCurrentPartnerBusinessName(data){
        this.storage.setItem('currentPartnerBusinessName', data);
    }

    storageSetCurrentPartnerDomain(data){
        this.storage.setItem('currentPartnerDomain', data);
    }

    storageSetCurrentPartnerDomainHome(data){
        this.storage.setItem('currentPartnerDomainHome', data);
    }

    storageSetCurrentPartnerLogoBackground(data){
        this.storage.setItem('currentPartnerLogoBackground', data);
    }

    storageSetCurrentPartnerLogo(data){
        this.storage.setItem('currentPartnerLogo', data);
    }

    storageSetTerminalShortName(data){
        this.storage.setItem('currentPartnerTerminalShortName', data);
    }

    storageSetData(data){
        this.storage.setItem('currentPartner', JSON.stringify(data));
    }

    storageSetTemplate(data){
        this.storage.setItem('currentPartnerTemplate', data);
    }

    storageSetShortName(data){
        this.storage.setItem('currentPartnerShortName', data);
    }

    storageSetCashapTag(data){
        this.storage.setItem('currentPartnerCashappTag', data);
    }

    storageSetPartnerType(data){
        this.storage.setItem('currentPartnerType', data);
    }

    storageSetContentType(data){
        this.storage.setItem('currentContentType', data);
    }

    getContentType(){
        if (this.storage.getItem('currentContentType')) {
            return +this.storage.getItem('currentContentType');
        }
        return 0;
    }

    getShortName(){
        if (this.storage.getItem('currentPartnerShortName')) {
            return this.storage.getItem('currentPartnerShortName');
        }

        return 'default';
    }

    getDomainHome(){
        if (this.storage.getItem('currentPartnerDomainHome')) {
            return this.storage.getItem('currentPartnerDomainHome');
        }
        return '';
    }

    getBusinessName(){
        if (this.storage.getItem('currentPartnerBusinessName')) {
            return this.storage.getItem('currentPartnerBusinessName');
        }
        return '';
    }

    getPartnerLogo(){
        if (this.storage.getItem('currentPartnerLogo')) {
            return this.storage.getItem('currentPartnerLogo');
        }
        return '';
    }

    changeThumbnailIcon(selector, icon, partner) {
        const defaultIcon = document.querySelector(selector).getAttribute('href');
        const newIcon = defaultIcon.split('/icons')[0] + `/partners/${partner}/icons/${icon}`;
        document.querySelector(selector).setAttribute('href', newIcon);
    }

    changeThumbnailIcons(partner) {
        this.changeThumbnailIcon('link[rel="icon"]', 'icon-72.png', partner);
        this.changeThumbnailIcon('link[rel="apple-touch-icon"][sizes="180x180"]', 'icon-180.png', partner);
        this.changeThumbnailIcon('link[rel="apple-touch-icon"][sizes="120x120"]', 'icon-120.png', partner);
    }
}

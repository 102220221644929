import { PAYMENTS_TYPES } from "../config/payment-types.config";
import * as i0 from "@angular/core";
var PosSettingsService = /** @class */ (function () {
    function PosSettingsService() {
        this.PAYMENTS_TYPES = PAYMENTS_TYPES;
        this.storage = sessionStorage;
        this.checkOutPageVisibilityKey = 'isCheckOutVisible';
        this.redeemsVisibilityKey = 'isRedeemsVisible';
        this.supportVisibilityKey = 'isSupportVisible';
    }
    PosSettingsService.prototype.setRedeemsVisibility = function (redeems) {
        var state;
        if (!redeems) {
            state = 1;
        }
        if (redeems) {
            this.redeems = redeems;
            if (redeems.find(function (item) { return +item.status === 1; })) {
                state = 1;
            }
            else {
                state = 0;
            }
        }
        this.storage.setItem(this.redeemsVisibilityKey, state);
    };
    PosSettingsService.prototype.getRedeemsVisibility = function () {
        return !!+this.storage.getItem(this.redeemsVisibilityKey);
    };
    PosSettingsService.prototype.removeRedeemsVisibility = function () {
        this.storage.removeItem(this.redeemsVisibilityKey);
    };
    PosSettingsService.prototype.setCheckOutPageVisibility = function (payments) {
        this.payments = payments;
        var state;
        if (!payments) {
            state = 1;
        }
        if (payments) {
            if (payments.find(function (item) { return +item.status === 1; })) {
                state = 1;
            }
            else {
                state = 0;
            }
        }
        this.storage.setItem(this.checkOutPageVisibilityKey, state);
    };
    PosSettingsService.prototype.getCheckOutPageVisibility = function () {
        return !!+this.storage.getItem(this.checkOutPageVisibilityKey);
    };
    PosSettingsService.prototype.removeCheckOutPageVisibility = function () {
        this.storage.removeItem(this.checkOutPageVisibilityKey);
    };
    PosSettingsService.prototype.isCardsVisible = function () {
        var _this = this;
        if (this.payments) {
            if (this.payments.find(function (el) { return el.id === _this.PAYMENTS_TYPES.card; })) {
                return !!+this.payments.find(function (el) { return el.id === _this.PAYMENTS_TYPES.card; }).status;
            }
            return false;
        }
        return true;
    };
    PosSettingsService.prototype.isCashAppVisible = function () {
        var _this = this;
        if (this.payments) {
            if (this.payments.find(function (el) { return el.id === _this.PAYMENTS_TYPES.cashApp; })) {
                return !!+this.payments.find(function (el) { return el.id === _this.PAYMENTS_TYPES.cashApp; }).status;
            }
            return false;
        }
        return true;
    };
    PosSettingsService.prototype.setSupportVisible = function (val) {
        if ((+val === 1) || (+val === 0)) {
            this.storage.setItem(this.supportVisibilityKey, val);
        }
        else {
            this.storage.setItem(this.supportVisibilityKey, '0');
        }
    };
    PosSettingsService.prototype.getSupportVisibility = function () {
        return !!+this.storage.getItem(this.supportVisibilityKey);
    };
    PosSettingsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PosSettingsService_Factory() { return new PosSettingsService(); }, token: PosSettingsService, providedIn: "root" });
    return PosSettingsService;
}());
export { PosSettingsService };

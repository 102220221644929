import { PERKS_CONFIG } from "../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./loader.service";
var PerksService = /** @class */ (function () {
    function PerksService(http, loaderService) {
        this.http = http;
        this.loaderService = loaderService;
        this.config = PERKS_CONFIG;
    }
    PerksService.prototype.getPageInfo = function (page, singleHistoryPage) {
        this.loaderService.show();
        return this.http.post(this.config.getPageInfo, { page: page, singleHistoryPage: singleHistoryPage });
    };
    PerksService.prototype.getGameBalance = function (id) {
        this.loaderService.show();
        return this.http.post(this.config.getGameBalance, { id: id });
    };
    PerksService.prototype.redeem = function (gameProviderId, amount) {
        this.loaderService.show();
        return this.http.post(this.config.redeem, { gameProviderId: gameProviderId, amount: amount });
    };
    PerksService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PerksService_Factory() { return new PerksService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: PerksService, providedIn: "root" });
    return PerksService;
}());
export { PerksService };

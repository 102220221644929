<div class="investments-popup investments-popup--pay"
     [class.is-active]="popupInfo.show">
    <div class="investments-popup__inner">
        <button class="investments-popup__close"
                (click)="closePopup()"></button>
        <div class="investments-popup__title">
            <ng-container *ngIf="isPopupTypeEmail()">
                {{'change' | languageTranslate}} {{'formData' | languageTranslate: [{$innerVariable: 'email'}]}}
            </ng-container>

            <ng-container *ngIf="isPopupTypeEmailVerify()">
                {{'verify' | languageTranslate}} {{'formData' | languageTranslate: [{$innerVariable: 'email'}]}}
            </ng-container>

            <ng-container *ngIf="isPopupTypePhone()">
                {{'change' | languageTranslate}} {{'formData' | languageTranslate: [{$innerVariable: 'phone'}]}}
            </ng-container>

            <ng-container *ngIf="isPopupTypePhoneVerify()">
                {{'verify' | languageTranslate}} {{'formData' | languageTranslate: [{$innerVariable: 'phone'}]}}
            </ng-container>

            <ng-container *ngIf="isPopupTypePassword()">
                {{'change' | languageTranslate}} {{'formData' | languageTranslate: [{$innerVariable: 'password'}]}}
            </ng-container>

            <ng-container *ngIf="isPopupTypePin()">
                {{'change' | languageTranslate}} {{'formData' | languageTranslate: [{$innerVariable: 'pin'}]}}
            </ng-container>
        </div>
        <div class="investments-popup__scrolled">
<!--            <div class="investments-popup__title-pay">-->
<!--                <p>-->
<!--                    {{'formData' | languageTranslate: [{$innerVariable: 'phone'}]}} {{popupInfo.handle | mask: phoneMask}} {{'withdrawData' | languageTranslate: [{$innerVariable: 'willAssociatedWithVenmo'}]}}-->
<!--                </p>-->
<!--            </div>-->

            <form [formGroup]="form"
                  (ngSubmit)="onSubmitForm()"
                  class="form-simple form-simple--shrink form-simple--primary">

                <div class="form-simple__item"
                     *ngIf="isPopupTypeEmail()">
                    <input formControlName="email"
                           placeholder="{{'formData' | languageTranslate: [{$innerVariable: 'email'}]}}"
                           [email]="true"
                           [type]="'text'"
                           class="form-simple__input"
                           autocomplete="off">
                </div>

                <div class="form-simple__item"
                     *ngIf="isPopupTypePhone()">
                    <input formControlName="phone"
                           placeholder="{{'phoneNumber' | languageTranslate}}"
                           [mask]="phoneMask"
                           [type]="'tel'"
                           class="form-simple__input"
                           autocomplete="off">
                </div>

                <div class="form-simple__item"
                     *ngIf="!isPopupTypeEmailVerify() && !isPopupTypePhoneVerify() && !isPopupTypePassword()">
                    <input formControlName="password"
                           placeholder="{{'formData' | languageTranslate: [{$innerVariable: 'password'}]}}"
                           [type]="'text'"
                           class="form-simple__input"
                           autocomplete="off">
                </div>

                <div class="form-simple__item"
                     *ngIf="isPopupTypePassword()">
                    <input formControlName="password"
                           placeholder="{{'old' | languageTranslate}} {{'formData' | languageTranslate: [{$innerVariable: 'password'}]}}"
                           [type]="'text'"
                           class="form-simple__input"
                           autocomplete="off">
                </div>

                <div class="form-simple__item"
                     *ngIf="isPopupTypePassword()">
                    <input formControlName="newPassword"
                           placeholder="{{'new' | languageTranslate}} {{'formData' | languageTranslate: [{$innerVariable: 'password'}]}}"
                           [type]="'text'"
                           class="form-simple__input"
                           autocomplete="off">
                </div>

                <div class="form-simple__item"
                     *ngIf="isPopupTypePassword()">
                    <input formControlName="retypePassword"
                           placeholder="{{'retype' | languageTranslate}} {{'formData' | languageTranslate: [{$innerVariable: 'password'}]}}"
                           [type]="'text'"
                           class="form-simple__input"
                           autocomplete="off">
                </div>

                <div class="pin-block"
                     *ngIf="isPopupTypePin() || isPopupTypePhoneVerify() || isPopupTypeEmailVerify()">
                    <div class="pin-block__title" *ngIf="isPopupTypePin()">
                        {{'authPinTitle' | languageTranslate}}
                    </div>
                    <div class="pin-block__text" *ngIf="isPopupTypePhoneVerify() && popupInfo.phoneLastFour">
                        {{'loginSecondStepInfo' | languageTranslate: [{phoneLastFour: popupInfo.phoneLastFour}]}}
                    </div>
<!--                    <div class="pin-block__text" *ngIf="isPopupTypeEmailVerify()">-->
<!--                        {{'loginSecondStepInfo' | languageTranslate: [{phoneLastFour: popupInfo.phoneLastFour}]}}-->
<!--                    </div>-->
                    <div class="login__items" formArrayName="digits">
                        <input (keydown.enter)="onSubmitForm()"
                               *ngFor="let field of form.get('digits')['controls']; let i = index"
                               #inputs [maxLength]="1" [formControlName]="i"
                               (keydown)="check(i, field, $event)"
                               mask="0"
                               class="login-input-small" type="tel">
                    </div>
                </div>


                <div class="form-simple__btns">
                    <button class="button snake-btn"
                            *ngIf="!isPopupTypePhoneVerify() && !isPopupTypeEmailVerify()"
                            [disabled]="((isPopupTypeEmail() && form.controls.email.invalid) ||
                                         (isPopupTypePhone() && form.controls.phone.invalid) ||
                                         (isPopupTypePhoneVerify() && form.controls.digits.invalid) ||
                                         (isPopupTypePassword() && (form.controls.newPassword.invalid || form.controls.retypePassword.invalid || (form.controls.newPassword.value !== form.controls.retypePassword.value))) ||
                                         (isPopupTypePin() && form.controls.digits.invalid))
                                         || form.controls.password.invalid">
                        {{'save' | languageTranslate}}
                        <svg><rect></rect></svg>
                    </button>

                    <button class="button snake-btn"
                            *ngIf="isPopupTypePhoneVerify() || isPopupTypeEmailVerify()"
                            [disabled]="form.controls.digits.invalid">
                        {{'verify' | languageTranslate}}
                        <svg><rect></rect></svg>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

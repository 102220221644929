<div class="investments-popup investments-popup--pay" [class.is-active]="popupInfo.show">
    <div class="investments-popup__inner  js-prizes-parent" *ngIf="popupInfo.show">
        <button class="investments-popup__close"
                (click)="closePopup()"></button>
        <div class="investments-popup__scrolled" id="scrolled-wrapper">
<!--            <div class="investments-popup__header investments-popup__header&#45;&#45;centered">Please enter your password to generate crypto wallet. Crypto wallet is 100% Free.</div>-->

            <form [formGroup]="form" (ngSubmit)="sendForm()">
                <div class="form-simple__item">
                    <input formControlName="name"
                           placeholder="{{'formData' | languageTranslate: [{$innerVariable: 'name'}]}}"
                           [type]="'text'"
                           class="form-simple__input"
                           autocomplete="off">
                </div>

                <div class="form-simple__item">
                    <input [mask]="phoneMask"
                           formControlName="phone"
                           placeholder="{{'formData' | languageTranslate: [{$innerVariable: 'phone'}]}}"
                           [type]="'tel'"
                           class="form-simple__input"
                           autocomplete="off">
                </div>

                <div class="form-simple__item">
                    <input formControlName="email"
                           placeholder="{{'formData' | languageTranslate: [{$innerVariable: 'email'}]}}"
                           [type]="'text'"
                           email="true"
                           class="form-simple__input"
                           autocomplete="off">
                </div>

                <div class="form-simple__item">

                    <textarea formControlName="message"
                              placeholder="{{'formData' | languageTranslate: [{$innerVariable: 'message'}]}}"
                              class="form-simple__input"
                              name="" id="" cols="30" rows="5"></textarea>
                </div>

                <div class="form-simple__btns">
                    <button class="button button--reverse"
                            [disabled]="form.invalid">
                        {{'formData' | languageTranslate: [{$innerVariable: 'send'}]}}
                    </button>
                </div>
            </form>

        </div>
    </div>
</div>

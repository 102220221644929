import {
    Component,
    OnInit, Injectable
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PopupComponent} from "../../shared/popup/popup.component";
import {RouterPaths} from "../../config/router-paths.model";
import {LoaderService} from "../../service/loader.service";
import {PerksService} from "../../service/perks.service";
import {SenetproTransactionsModel} from "../../shared/senetpro-transactions/senetpro-transactions.model";
import {CRYPTO_AMOUNT_MASK, PROJECT_MESSAGES} from "../../config/project";
import {formatNumber} from "@angular/common";
import {POPUP_CHANNELS_TYPE} from "../../shared/popup-channels/popup-channels.config";
import {PosSettingsService} from "../../service/pos-settings.service";
import {LanguageService} from "../../modules/language/language.service";
import {CurrencyService} from "../../modules/currency/currency.service";
import {ContentDisplayingService} from "../../service/content-displaying.service";

@Component({
    selector: 'app-perks',
    templateUrl: './perks.component.html',
    styleUrls: ['./perks.component.scss'],
})
@Injectable({providedIn: 'root'})
export class PerksComponent implements OnInit {
    pageForm: FormGroup;

    entriesVal = 0;
    redeemedVal = 0;

    availableBalance = 0;
    providers = [];
    selectedProvider;

    RouterPaths = RouterPaths;
    POPUP_CHANNELS_TYPE = POPUP_CHANNELS_TYPE;

    moreThenAvailablePayout = false;

    showMoreTransactionsBtn = false;
    transactionsList: SenetproTransactionsModel[] = [];
    accountHistoryProcessing: SenetproTransactionsModel[] = [];

    popupChannels: any = {show: false};
    popupTransactions = {show: false};
    popupConfirmInfo: any = {show: false, totalDebit: 0};

    showSellPoints = true;
    languageChangeSubscription;
    constructor(
        private formBuilder: FormBuilder,
        private languageService: LanguageService,
        private perksService: PerksService,
        private loaderService: LoaderService,
        private currencyService: CurrencyService,
        private posSettingsService: PosSettingsService,
        private contentDisplayingService: ContentDisplayingService,
        private popupComponent: PopupComponent) {
    }

    ngOnInit() {
        this.languageChangeSubscription = this.languageService.languageIsUpdated.subscribe(val => {
            if (val) {
                setTimeout(() => {
                    this.getPageInfo();
                });
            }
        });
        this.pageForm = this.formBuilder.group({
            method: [null, Validators.required],
            amount: [null, Validators.required],
        });
        this.getPageInfo();
    }

    ngOnDestroy() {
        this.languageChangeSubscription.unsubscribe();
    }

    getPageInfo(only_balance = false) {
        this.perksService.getPageInfo(1, false).subscribe(data => {
            this.setPageInfo(data, only_balance);
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        })
    }

    setPageInfo(data, only_balance) {
        try {
            this.entriesVal = data.entries;
            this.redeemedVal = data.spend;
            this.posSettingsService.setSupportVisible(data.support);

            if (only_balance) {
                this.updateGameAvailableBalance(this.selectedProvider.id);
                return;
            } else {
                this.posSettingsService.setRedeemsVisibility(data.redeems);
                this.showSellPoints = this.posSettingsService.getRedeemsVisibility();

                this.providers = [];
                if (data.games && data.games.length) {
                    this.setGamesProviders(data.games);
                }

                this.setTransactionsList(data.accountHistory, data.limit, data.total);
                this.accountHistoryProcessing = data.accountHistoryProcessing;
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    setGamesProviders(providers) {
        this.providers = providers;
        this.selectedProvider = this.providers.find(provider => provider.default);
        this.pageForm.controls.method.setValue(this.selectedProvider);
        this.updateGameAvailableBalance(this.selectedProvider.id);
    }

    updateGameAvailableBalance(id) {
        this.perksService.getGameBalance(id).subscribe((data) => {
           this.setGameAvailableBalance(data);
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        })
    }

    setGameAvailableBalance(data) {
        try {
            if (data.status) {
                this.availableBalance = data.availableBalance;
                setTimeout(() => {
                    this.onInputAmount();
                });
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    onProviderChange(item) {
        this.updateGameAvailableBalance(item.id);
    }

    onInputAmount() {
        const avaliable = typeof this.availableBalance === 'number' ? this.availableBalance : Number.parseFloat(this.availableBalance);
        const entered = Number.parseFloat(this.pageForm.controls.amount.value);

        if (avaliable < entered) {
            this.pageForm.controls.amount.setValue(String(avaliable));
            this.moreThenAvailablePayout = true;
        } else {
            this.moreThenAvailablePayout = false;
        }
    }

    setTransactionsList(history: SenetproTransactionsModel[], limit: number, total: number): void {
        this.showMoreTransactionsBtn = total > limit;
        this.transactionsList = history;
    }

    showTransactionsPopup(): void {
        document.body.classList.add('hide-menu');
        this.popupTransactions = {
            show: true
        }
    }

    closeTransactionsPopup(): void {
        document.body.classList.remove('hide-menu');
        this.popupTransactions = {
            show: false
        }
    }

    showChannelsPopup(type): void {
        document.body.classList.add('hide-menu');
        this.popupChannels = {
            show: true,
            type,
            availableBalance: this.redeemedVal
        }
    }

    closeChannelsPopup(needUpdateBalance): void {
        document.body.classList.remove('hide-menu');
        this.popupChannels = {
            show: false
        };
        if (needUpdateBalance) {
            this.getPageInfo();
        }
    }

    onSubmitRedeem(amount) {
        const methodName = this.pageForm.controls.method.value.name;
        const providerId = this.pageForm.controls.method.value.id;

        if (!amount || !methodName) {
            this.popupComponent.showPopupError({text: PROJECT_MESSAGES.notAllFieldsAreFilled});
        } else {
            const text = `${this.languageService.getStringFirstCharCapital('redeem')} 
                          $${formatNumber(amount, 'en', CRYPTO_AMOUNT_MASK)}.00 
                          ${this.languageService.getString('from')} 
                          ${methodName}?`;
            this.showPopupConfirmCancel(text, this.sendRedeem.bind(this, providerId, amount));
        }
    }

    sendRedeem(providerId, amount){
        this.perksService.redeem(providerId, amount).subscribe(data => {
              this.displaySubmitPayoutResult(data);
          },
          () => { this.loaderService.hideAndShowTryAgain();},
          () => {this.loaderService.hide()}
        );
    }

    displaySubmitPayoutResult(data) {
        try {
            if (data.status) {
                this.popupComponent.showPopupSuccess({text: data.message});
                this.getPageInfo();
                this.closePopupConfirm();
                this.pageForm.controls.amount.reset();
                this.moreThenAvailablePayout = false;
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    showPopupConfirmCancel(text: string, successCallback: () => void): void {
        this.popupComponent.showPopupConfirmCancel({text}, (status) => {
            if (status) { successCallback(); }
        });
    }

    closePopupConfirm() {
        document.body.classList.remove('hide-menu');
        this.popupConfirmInfo = {
            show: false, totalDebit: 0
        };
    }

    formatCurrency(amount) {
        return this.currencyService.formatCurrency(amount);
    }

    showAdditionalContent() {
        return this.contentDisplayingService.showAdditionContent();
    }

    isSupportShown() {
        return this.posSettingsService.getSupportVisibility();
    }
}


import { EventEmitter, OnInit } from '@angular/core';
import { Validators } from "@angular/forms";
var VerifyCashappTagComponent = /** @class */ (function () {
    function VerifyCashappTagComponent(formBuilder, loaderService, popupComponent, languageService, phoneMaskService, cardsService) {
        this.formBuilder = formBuilder;
        this.loaderService = loaderService;
        this.popupComponent = popupComponent;
        this.languageService = languageService;
        this.phoneMaskService = phoneMaskService;
        this.cardsService = cardsService;
        this.isPaypal = false;
        this.isVenmo = false;
        this.isVenmoPhone = false;
        this.onVerify = new EventEmitter();
        this.cashappVerified = false;
    }
    Object.defineProperty(VerifyCashappTagComponent.prototype, "verifyStatus", {
        set: function (val) {
            this.cashappVerified = val;
        },
        enumerable: true,
        configurable: true
    });
    ;
    VerifyCashappTagComponent.prototype.ngOnInit = function () {
        this.cashappForm = this.formBuilder.group({
            address: [null, Validators.required],
            retype: [null, Validators.required],
        });
        this.paypalForm = this.formBuilder.group({
            email: [null, Validators.required],
            retype: [null, Validators.required],
        });
        this.venmoForm = this.formBuilder.group({
            address: [null, Validators.required],
            retype: [null, Validators.required],
        });
        this.phoneMask = this.phoneMaskService.setPhoneMask();
    };
    VerifyCashappTagComponent.prototype.onSubmitCashappForm = function () {
        // const address = this.cashappForm.controls.address.value;
        // const retype = this.cashappForm.controls.retype.value;
        //
        // this.popupComponent.showPopupConfirmCancel({text: `The CashApp address ${address} will be now associated with your account and will be used to send out redeems as well as to process deposits. If you would like to change it in the future, please contact support.`}, (status) => {
        //     if (status) {
        //         this.settingsService.verifyCashapp(address, retype).subscribe(data => {
        //             this.setSubmitFormResult(data);
        //         }, () => {
        //             this.loaderService.hideAndShowTryAgain();
        //         }, () => {
        //             this.loaderService.hide();
        //         })
        //     }
        // });
    };
    VerifyCashappTagComponent.prototype.onSubmitVenmoForm = function () {
        var _this = this;
        var address = this.venmoForm.controls.address.value;
        var retype = this.venmoForm.controls.retype.value;
        var text = this.languageService.getStringWithVariables([{ address: address }], 'venmoIsVerifiedText');
        this.popupComponent.showPopupConfirmCancel({ text: text }, function (status) {
            if (status) {
                _this.cardsService.verifyVenmo(address, retype).subscribe(function (data) {
                    _this.setSubmitFormResult(data);
                }, function () {
                    _this.loaderService.hideAndShowTryAgain();
                }, function () {
                    _this.loaderService.hide();
                });
            }
        });
    };
    VerifyCashappTagComponent.prototype.onSubmitPaypalForm = function () {
        var _this = this;
        var email = this.paypalForm.controls.email.value;
        var retype = this.paypalForm.controls.retype.value;
        var text = this.languageService.getStringWithVariables([{ email: email }], 'emailIsVerifiedText');
        this.popupComponent.showPopupConfirmCancel({ text: text }, function (status) {
            if (status) {
                _this.cardsService.verifyPaypal(email, retype).subscribe(function (data) {
                    _this.setSubmitFormResult(data);
                }, function () {
                    _this.loaderService.hideAndShowTryAgain();
                }, function () {
                    _this.loaderService.hide();
                });
            }
        });
    };
    VerifyCashappTagComponent.prototype.setSubmitFormResult = function (data) {
        try {
            if (data.status) {
                this.popupComponent.showPopupSuccess({ text: data.message });
                this.setCashappVerified();
            }
            else {
                this.popupComponent.showPopupError({ text: data.message, confirmButtonText: this.languageService.getString('ok') });
            }
        }
        catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    VerifyCashappTagComponent.prototype.setCashappVerified = function () {
        this.cashappVerified = true;
        if (this.isPaypal) {
            this.onVerify.emit(this.paypalForm.controls.email.value);
        }
        else if (this.isVenmo) {
            this.onVerify.emit(this.venmoForm.controls.address.value);
        }
        else {
            this.onVerify.emit(this.cashappForm.controls.address.value);
        }
    };
    return VerifyCashappTagComponent;
}());
export { VerifyCashappTagComponent };

import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {LoaderService} from "./loader.service";
import {STATES_CONFIG} from "../config/api";

@Injectable({
    providedIn: 'root'
})
export class StatesService {
    private config = STATES_CONFIG;

    constructor(private http: HttpClient,
                private loaderService: LoaderService) {
    }

    getStates() {
        this.loaderService.show();
        let post = this.http.post<any>(this.config.getStates, {});
        return post;
    }
}

import { NgZone } from '@angular/core';
import { PARTNERS_CONFIG } from "../config/api";
import { BehaviorSubject } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./loader.service";
import * as i3 from "../modules/auth/_services/authentication.service";
import * as i4 from "@angular/common/http";
import * as i5 from "./testing/frontendTestSettings.service";
var PartnerService = /** @class */ (function () {
    function PartnerService(ngZone, route, loaderService, auth, http, router, frontendTestSettingsService) {
        this.ngZone = ngZone;
        this.route = route;
        this.loaderService = loaderService;
        this.auth = auth;
        this.http = http;
        this.router = router;
        this.frontendTestSettingsService = frontendTestSettingsService;
        this.config = PARTNERS_CONFIG;
        this.currentPartner = '';
        this.storage = sessionStorage;
        this.currentPartnerTemplate = new BehaviorSubject('');
    }
    PartnerService.prototype.getPartner = function () {
        var domain = window.location.hostname;
        return this.http.post(this.config.partner, { domain: domain });
    };
    PartnerService.prototype.detectPartner = function (callback) {
        var _this = this;
        if (!this.auth.isUserLogged()) {
            this.auth.clearStorage();
            this.getPartner().subscribe(function (data) {
                if (data.status) {
                    _this.savePartner(data);
                    if (callback) {
                        callback();
                    }
                    else {
                        //this.router.navigate([RouterPaths.login]);
                    }
                }
                else {
                    _this.router.navigate(['/']);
                }
            }, function (data) {
                _this.loaderService.hideAndShowTryAgain();
            }, function () {
                _this.route.queryParams.subscribe(function (params) {
                    if (!params.phone && !params.email) {
                        _this.loaderService.hide();
                    }
                });
            });
        }
    };
    PartnerService.prototype.savePartner = function (data) {
        if (this.frontendTestSettingsService.isPartnerTypeDefault()) {
            data.result.type = 0;
            console.log('d');
        }
        if (this.frontendTestSettingsService.isPartnerTypeSingle()) {
            data.result.type = 1;
            // console.log('d2')
        }
        if (this.frontendTestSettingsService.isPartnerAdditionInfo()) {
            data.result.businessName = 'Oksana Test';
            data.result.domain = 'http://google.com';
            data.result.domainHome = 'http://google.com';
            data.result.logo_background = './assets/partners/snekapek/icons/icon-144.png';
        }
        if (data.result) {
            this.storageSetData(data.result);
            this.storageSetShortName('');
            if (data.result.short_name) {
                this.storageSetShortName(data.result.short_name);
            }
            this.storageSetTemplate('');
            if (data.result.template) {
                this.storageSetTemplate(data.result.template);
            }
            this.storageSetTerminalShortName('');
            if (data.result.terminalShortName) {
                this.storageSetTerminalShortName(data.result.terminalShortName);
            }
            this.storageSetCurrentPartnerBusinessName('');
            if (data.result.businessName) {
                this.storageSetCurrentPartnerBusinessName(data.result.businessName);
                document.querySelector('title').innerHTML = data.result.businessName;
            }
            this.storageSetCurrentPartnerDomain('');
            if (data.result.domain) {
                this.storageSetCurrentPartnerDomain(data.result.domain);
            }
            this.storageSetCurrentPartnerDomainHome('');
            if (data.result.domainHome) {
                this.storageSetCurrentPartnerDomainHome(data.result.domainHome);
            }
            this.storageSetCurrentPartnerLogo('');
            if (data.result.logo) {
                this.storageSetCurrentPartnerLogo(data.result.logo);
            }
            this.storageSetCurrentPartnerLogoBackground('');
            if (data.result.logo_background) {
                this.storageSetCurrentPartnerLogoBackground(data.result.logo_background);
            }
            this.storageSetCashapTag('');
            if (data.result.cashapp_tag) {
                this.storageSetCashapTag(data.result.cashapp_tag);
            }
            this.storageSetPartnerType('');
            if (data.result.type || data.result.type === 0) {
                this.storageSetPartnerType(data.result.type);
            }
            this.storageSetContentType(0);
            if (data.result.showSweeps || data.result.showSweeps === 0) {
                this.storageSetContentType(data.result.showSweeps);
            }
            // if (this.getShortName() === PARTNERS.senet.short_name || this.getShortName() === PARTNERS.senetLabel.short_name) {
            //     this.changeThumbnailIcons(PARTNERS.senet.short_name);
            //     document.querySelector('title').innerHTML = this.getBusinessName();
            // }
        }
    };
    PartnerService.prototype.storageSetCurrentPartnerBusinessName = function (data) {
        this.storage.setItem('currentPartnerBusinessName', data);
    };
    PartnerService.prototype.storageSetCurrentPartnerDomain = function (data) {
        this.storage.setItem('currentPartnerDomain', data);
    };
    PartnerService.prototype.storageSetCurrentPartnerDomainHome = function (data) {
        this.storage.setItem('currentPartnerDomainHome', data);
    };
    PartnerService.prototype.storageSetCurrentPartnerLogoBackground = function (data) {
        this.storage.setItem('currentPartnerLogoBackground', data);
    };
    PartnerService.prototype.storageSetCurrentPartnerLogo = function (data) {
        this.storage.setItem('currentPartnerLogo', data);
    };
    PartnerService.prototype.storageSetTerminalShortName = function (data) {
        this.storage.setItem('currentPartnerTerminalShortName', data);
    };
    PartnerService.prototype.storageSetData = function (data) {
        this.storage.setItem('currentPartner', JSON.stringify(data));
    };
    PartnerService.prototype.storageSetTemplate = function (data) {
        this.storage.setItem('currentPartnerTemplate', data);
    };
    PartnerService.prototype.storageSetShortName = function (data) {
        this.storage.setItem('currentPartnerShortName', data);
    };
    PartnerService.prototype.storageSetCashapTag = function (data) {
        this.storage.setItem('currentPartnerCashappTag', data);
    };
    PartnerService.prototype.storageSetPartnerType = function (data) {
        this.storage.setItem('currentPartnerType', data);
    };
    PartnerService.prototype.storageSetContentType = function (data) {
        this.storage.setItem('currentContentType', data);
    };
    PartnerService.prototype.getContentType = function () {
        if (this.storage.getItem('currentContentType')) {
            return +this.storage.getItem('currentContentType');
        }
        return 0;
    };
    PartnerService.prototype.getShortName = function () {
        if (this.storage.getItem('currentPartnerShortName')) {
            return this.storage.getItem('currentPartnerShortName');
        }
        return 'default';
    };
    PartnerService.prototype.getDomainHome = function () {
        if (this.storage.getItem('currentPartnerDomainHome')) {
            return this.storage.getItem('currentPartnerDomainHome');
        }
        return '';
    };
    PartnerService.prototype.getBusinessName = function () {
        if (this.storage.getItem('currentPartnerBusinessName')) {
            return this.storage.getItem('currentPartnerBusinessName');
        }
        return '';
    };
    PartnerService.prototype.getPartnerLogo = function () {
        if (this.storage.getItem('currentPartnerLogo')) {
            return this.storage.getItem('currentPartnerLogo');
        }
        return '';
    };
    PartnerService.prototype.changeThumbnailIcon = function (selector, icon, partner) {
        var defaultIcon = document.querySelector(selector).getAttribute('href');
        var newIcon = defaultIcon.split('/icons')[0] + ("/partners/" + partner + "/icons/" + icon);
        document.querySelector(selector).setAttribute('href', newIcon);
    };
    PartnerService.prototype.changeThumbnailIcons = function (partner) {
        this.changeThumbnailIcon('link[rel="icon"]', 'icon-72.png', partner);
        this.changeThumbnailIcon('link[rel="apple-touch-icon"][sizes="180x180"]', 'icon-180.png', partner);
        this.changeThumbnailIcon('link[rel="apple-touch-icon"][sizes="120x120"]', 'icon-120.png', partner);
    };
    PartnerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PartnerService_Factory() { return new PartnerService(i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i1.ActivatedRoute), i0.ɵɵinject(i2.LoaderService), i0.ɵɵinject(i3.AuthenticationService), i0.ɵɵinject(i4.HttpClient), i0.ɵɵinject(i1.Router), i0.ɵɵinject(i5.FrontendTestSettingsService)); }, token: PartnerService, providedIn: "root" });
    return PartnerService;
}());
export { PartnerService };

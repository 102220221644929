import { SHOP_CONFIG } from "../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./loader.service";
var ShopService = /** @class */ (function () {
    function ShopService(http, loaderService) {
        this.http = http;
        this.loaderService = loaderService;
        this.config = SHOP_CONFIG;
    }
    ShopService.prototype.getPageInfo = function (game_short_name, only_balance) {
        if (game_short_name === void 0) { game_short_name = null; }
        if (only_balance === void 0) { only_balance = false; }
        this.loaderService.show();
        return this.http.post(this.config.getPageInfo, { game_short_name: game_short_name, only_balance: only_balance });
    };
    ShopService.prototype.onActivate = function (code) {
        this.loaderService.show();
        return this.http.post(this.config.onActivate, { code: code });
    };
    ShopService.prototype.showSellCryptoPopup = function () {
        this.loaderService.show();
        return this.http.post(this.config.showSellCryptoPopup, {});
    };
    ShopService.prototype.sellCryptoEstimate = function (amount) {
        this.loaderService.show();
        return this.http.post(this.config.sellCryptoEstimate, { amount: amount });
    };
    ShopService.prototype.onSellCryptoRequest = function (amount, password) {
        this.loaderService.show();
        return this.http.post(this.config.onSellCryptoRequest, { amount: amount, password: password });
    };
    ShopService.prototype.onSellGiftCards = function (id, code, pin, amount) {
        this.loaderService.show();
        return this.http.post(this.config.onSellGiftCards, { id: id, code: code, pin: pin, amount: amount });
    };
    ShopService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ShopService_Factory() { return new ShopService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: ShopService, providedIn: "root" });
    return ShopService;
}());
export { ShopService };

import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ReferralBonusService {
    storageReferralBonusStatusKey = 'referralBonusStatus';

    constructor() {}

    setReferralBonusStatus(status) {
        sessionStorage.setItem(this.storageReferralBonusStatusKey, status);
    }

    getReferralBonusStatus() {
        return JSON.parse(sessionStorage.getItem(this.storageReferralBonusStatusKey));
    }

    resetReferralBonusStatus() {
        sessionStorage.removeItem(this.storageReferralBonusStatusKey);
    }

    showReferralPage() {
        return !!+this.getReferralBonusStatus();
    }
}

import { OnInit } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "../../service/loader.service";
import * as i2 from "../../service/partner.service";
var PartnerComponent = /** @class */ (function () {
    function PartnerComponent(loaderService, partnerService) {
        this.loaderService = loaderService;
        this.partnerService = partnerService;
    }
    PartnerComponent.prototype.ngOnInit = function () {
        this.loaderService.show();
        this.partnerService.detectPartner();
    };
    PartnerComponent.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PartnerComponent_Factory() { return new PartnerComponent(i0.ɵɵinject(i1.LoaderService), i0.ɵɵinject(i2.PartnerService)); }, token: PartnerComponent, providedIn: "root" });
    return PartnerComponent;
}());
export { PartnerComponent };

import { OnInit } from '@angular/core';
import { Validators } from "@angular/forms";
import { RouterPaths } from "../../config/router-paths.model";
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "../../service/loader.service";
import * as i3 from "@angular/router";
import * as i4 from "../../modules/language/language.service";
import * as i5 from "../../service/content-displaying.service";
import * as i6 from "../../modules/auth/_services/authentication.service";
import * as i7 from "../../service/partner.service";
import * as i8 from "../../shared/popup/popup.component";
var ResetPasswordComponent = /** @class */ (function () {
    function ResetPasswordComponent(formBuilder, loaderService, router, languageService, activatedRoute, contentDisplayingService, authenticationService, partnerService, popupComponent) {
        this.formBuilder = formBuilder;
        this.loaderService = loaderService;
        this.router = router;
        this.languageService = languageService;
        this.activatedRoute = activatedRoute;
        this.contentDisplayingService = contentDisplayingService;
        this.authenticationService = authenticationService;
        this.partnerService = partnerService;
        this.popupComponent = popupComponent;
        this.RouterPaths = RouterPaths;
        this.passwordFormInit();
        this.popupComponent.closePopup();
        document.body.classList.remove('hide-menu');
    }
    ResetPasswordComponent.prototype.ngOnInit = function () {
        this.loaderService.hide();
        this.focusFirstInput();
    };
    ResetPasswordComponent.prototype.passwordFormInit = function () {
        this.passwordForm = this.formBuilder.group({
            password: [null, Validators.required],
            passwordRepeat: [null, Validators.required]
        });
    };
    ResetPasswordComponent.prototype.onSubmit = function () {
        var _this = this;
        var code = this.activatedRoute.snapshot.queryParams.code;
        var email = this.activatedRoute.snapshot.queryParams.email;
        var password = this.passwordForm.controls.password.value;
        var passwordRepeat = this.passwordForm.controls.passwordRepeat.value;
        if (password !== passwordRepeat) {
            this.popupComponent.showPopupError({ text: this.languageService.getString('messagesData', 'passwordNotMatch') });
            return;
        }
        if (code && email && password) {
            this.authenticationService.restorePassword(code, email, password).subscribe(function (data) {
                try {
                    if (data.status) {
                        _this.popupComponent.showPopupSuccess({ text: data.message });
                        _this.popupComponent.showPopupSuccessReturnEvent({ text: data.message }, function () {
                            _this.goBackToLogin();
                        });
                    }
                    else {
                        _this.popupComponent.showPopupError({ text: data.message });
                    }
                }
                catch (e) {
                    _this.loaderService.hideAndShowTryAgain();
                }
            }, function () {
                _this.loaderService.hideAndShowTryAgain();
            }, function () {
                _this.loaderService.hide();
            });
        }
        else {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    ResetPasswordComponent.prototype.getPartnerLogo = function () {
        return this.partnerService.getPartnerLogo() ? this.partnerService.getPartnerLogo() : '../assets/images/logo.png';
    };
    ResetPasswordComponent.prototype.focusFirstInput = function () {
        setTimeout(function () {
            document.querySelector('input').focus();
        });
    };
    ResetPasswordComponent.prototype.goBackToLogin = function () {
        this.router.navigate(['./']);
    };
    ResetPasswordComponent.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ResetPasswordComponent_Factory() { return new ResetPasswordComponent(i0.ɵɵinject(i1.FormBuilder), i0.ɵɵinject(i2.LoaderService), i0.ɵɵinject(i3.Router), i0.ɵɵinject(i4.LanguageService), i0.ɵɵinject(i3.ActivatedRoute), i0.ɵɵinject(i5.ContentDisplayingService), i0.ɵɵinject(i6.AuthenticationService), i0.ɵɵinject(i7.PartnerService), i0.ɵɵinject(i8.PopupComponent)); }, token: ResetPasswordComponent, providedIn: "root" });
    return ResetPasswordComponent;
}());
export { ResetPasswordComponent };

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {LoaderService} from "../../../service/loader.service";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {SHOP_POPUP_TYPE} from "../shop.config";
import {ShopService} from "../../../service/shop.service";
import {CopyToBufferService} from "../../../service/copyToBuffer.service";
import {CRYPTO_AMOUNT_MASK} from "../../../config/project";
import {LanguageService} from "../../../modules/language/language.service";
import {CurrencyService} from "../../../modules/currency/currency.service";

@Component({
    selector: 'app-popup-shop',
    templateUrl: './popup-shop.component.html',
    styleUrls: ['./popup-shop.component.scss']
})
export class PopupShopComponent implements OnInit {
    @Input() popupInfo;
    @Output() close: EventEmitter<boolean> = new EventEmitter();
    @Output() send: EventEmitter<any> = new EventEmitter();

    SHOP_POPUP_TYPE = SHOP_POPUP_TYPE;
    CRYPTO_AMOUNT_MASK = CRYPTO_AMOUNT_MASK;
    SELL_CRYPTO_STEPS  = {
      amount: 1,
      estimate: 2,
      password: 3
    };

    formActivateBuy: FormGroup;
    formSellCrypto: FormGroup;
    formSellGiftCards: FormGroup;
    formSendCrypto: FormGroup;
    formRevealKeyPassword: FormGroup;

    moreThenAvailablePayout = false;

    sellCryptoStep = this.SELL_CRYPTO_STEPS.amount;

    sellEstimateData = {
        sweeps: 0,
        btc: 0,
        amount: 0
    };

    constructor(private loaderService: LoaderService,
                private languageService: LanguageService,
                private shopService: ShopService,
                private popupComponent: PopupComponent,
                private currencyService: CurrencyService,
                private copyToBufferService: CopyToBufferService,
                private formBuilder: FormBuilder) {}

    ngOnInit() {
        this.formActivateBuy = this.formBuilder.group({
            code: [null, Validators.required],
        });

        this.formSellCrypto = this.formBuilder.group({
            amount: [null, Validators.required],
            password: [null, Validators.required],
        });

        this.formSellGiftCards = this.formBuilder.group({
            code: [null, Validators.required],
            pin: [null],
            amount: [null, Validators.required],
        });

        this.formSendCrypto = this.formBuilder.group({
            amount: [null, Validators.required],
            address: [null, Validators.required],
            password: [null, Validators.required],
        });

        this.formRevealKeyPassword = this.formBuilder.group({
            password: [null, Validators.required],
        });
    }

    ngOnChanges(changes) {
        if (changes.popupInfo.currentValue.show) {

        } else {
            if (this.formActivateBuy) {
                this.formActivateBuy.reset();
            }

            if (this.formSellGiftCards) {
                this.formSellGiftCards.reset();
            }

            if (this.formSellCrypto) {
                this.formSellCrypto.reset();
            }

            if (this.formSendCrypto) {
                this.formSendCrypto.reset();
            }

            if (this.formRevealKeyPassword) {
                this.formRevealKeyPassword.reset();
            }

            this.sellCryptoStep = this.SELL_CRYPTO_STEPS.amount;
            this.moreThenAvailablePayout = false;

            this.sellEstimateData = {
                sweeps: 0,
                btc: 0,
                amount: 0
            };
        }
    }

    closePopup() {
        this.close.emit(true);
        this.formActivateBuy.reset();
        this.formSellGiftCards.reset();
        this.formSellCrypto.reset();
        this.formSendCrypto.reset();
    }

    scrollToTopOfPopup(smooth = true) {
        setTimeout(() => {
            document.querySelector('#scrolled-wrapper').scroll({
                top: 0, left: 0, behavior: smooth ? 'smooth' : 'auto'
            });
        }, smooth ? 500 : 0);
    }

    sendForm() {
        const type = this.popupInfo.type;
        if (type === SHOP_POPUP_TYPE.activateBuy) {
            this.send.emit({code: this.formActivateBuy.controls.code.value});
        } else if (type === SHOP_POPUP_TYPE.sellGiftCard) {
            this.send.emit({
                code: this.formSellGiftCards.controls.code.value,
                pin: this.formSellGiftCards.controls.pin.value,
                amount: this.formSellGiftCards.controls.amount.value,
            });
        } else if (type === SHOP_POPUP_TYPE.sellCrypto) {
            if (this.isSellCryptoAmountStep()) {
                const amount = this.formSellCrypto.controls.amount.value;
                this.shopService.sellCryptoEstimate(amount).subscribe(data => {
                    this.setEstimateResult(data);
                }, () => {
                    this.loaderService.hideAndShowTryAgain();
                }, () => {
                    this.loaderService.hide();
                });
            } else if (this.isSellCryptoEstimateStep()) {
                this.sellCryptoStep = this.SELL_CRYPTO_STEPS.password;
            } else if (this.isSellCryptoPasswordStep()) {
                this.send.emit({
                    amount: this.formSellCrypto.controls.amount.value,
                    password: this.formSellCrypto.controls.password.value
                });
            }
        } else if (type === SHOP_POPUP_TYPE.revealKey) {
            this.send.emit();
        } else if (type === SHOP_POPUP_TYPE.revealKeyPassword) {
            this.send.emit({
                password: this.formRevealKeyPassword.controls.password.value
            });
        } else if (type === SHOP_POPUP_TYPE.sendCrypto) {
            this.send.emit({
                address: this.formSendCrypto.controls.address.value,
                amount: this.formSendCrypto.controls.amount.value,
                password: this.formSendCrypto.controls.password.value
            });
        }
    }

    setEstimateResult(data) {
        try {
            if (data.status) {
                this.sellEstimateData = {
                    sweeps: data.sweeps,
                    btc: data.btc,
                    amount: this.formSellCrypto.controls.amount.value
                };
                this.sellCryptoStep = this.SELL_CRYPTO_STEPS.estimate;
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    onInputAmount() {
        const activeForm = this.popupInfo.type === SHOP_POPUP_TYPE.sellCrypto ? this.formSellCrypto : this.popupInfo.type === SHOP_POPUP_TYPE.sendCrypto ? this.formSendCrypto : this.formSellGiftCards;
        const avaliable = typeof this.popupInfo.availableBalance === 'number' ? this.popupInfo.availableBalance : Number.parseFloat(this.popupInfo.availableBalance);
        const entered = Number.parseFloat(activeForm.controls.amount.value);

        if (avaliable < entered) {
            activeForm.controls.amount.setValue(String(avaliable));
            this.moreThenAvailablePayout = true;
        } else {
            this.moreThenAvailablePayout = false;
        }
    }

    isSellCryptoAmountStep() {
        return this.sellCryptoStep === this.SELL_CRYPTO_STEPS.amount;
    }

    isSellCryptoEstimateStep() {
        return this.sellCryptoStep === this.SELL_CRYPTO_STEPS.estimate;
    }

    isSellCryptoPasswordStep() {
        return this.sellCryptoStep === this.SELL_CRYPTO_STEPS.password;
    }

    copyToBuffer(value) {
        // const message = 'Text has been successfully copied!';
        const message = this.languageService.getString('messagesData', 'textCopied');

        this.copyToBufferService.copyToBuffer(value, true, message);
    }

    formatCurrency(amount) {
        return this.currencyService.formatCurrency(amount);
    }
}

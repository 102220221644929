import { COMMISSION_CONFIG } from "../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../loader.service";
var CommissionService = /** @class */ (function () {
    function CommissionService(http, loaderService) {
        this.http = http;
        this.loaderService = loaderService;
        this.config = COMMISSION_CONFIG;
    }
    CommissionService.prototype.getCommissionData = function (type, page, pageDisputs) {
        // this.showLoader();
        return this.http.post(this.config.data, { type: type, page: page, pageDisputs: pageDisputs });
    };
    CommissionService.prototype.sendRefer = function (firstLastName, phone) {
        this.loaderService.show();
        return this.http.post(this.config.refer, { firstLastName: firstLastName, phone: phone });
    };
    CommissionService.prototype.sendPayout = function (amount, type) {
        this.loaderService.show();
        return this.http.post(this.config.payout, { amount: amount, type: type });
    };
    CommissionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CommissionService_Factory() { return new CommissionService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: CommissionService, providedIn: "root" });
    return CommissionService;
}());
export { CommissionService };

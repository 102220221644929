import {Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import {LoaderService} from "../../service/loader.service";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PhoneMaskService} from "../../service/phoneMask.service";
import {PopupComponent} from "../popup/popup.component";
import {CHANGE_USER_INFO_TYPE} from "./popup-change-user-info.config";
import {AccountService} from "../../service/account.service";
import {LanguageService} from "../../modules/language/language.service";

@Component({
    selector: 'app-popup-change-user-info',
    templateUrl: './popup-change-user-info.component.html',
    styleUrls: ['./popup-change-user-info.component.scss']
})
export class PopupChangeUserInfoComponent implements OnInit {
    @Input() popupInfo;
    @Output() close: EventEmitter<boolean> = new EventEmitter();
    @Output() onVerified: EventEmitter<any> = new EventEmitter();
    @Output() onConfirm: EventEmitter<any> = new EventEmitter();

    form: FormGroup;

    phoneMask;
    numOfDigits = 4;

    @ViewChildren('inputs') inputs: QueryList<ElementRef>;
    constructor(private loaderService: LoaderService,
                private accountService: AccountService,
                private phoneMaskService: PhoneMaskService,
                private popupComponent: PopupComponent,
                private languageService: LanguageService,
                private formBuilder: FormBuilder) {}

    ngOnInit() {
        this.form = this.formBuilder.group({
            email: [null, Validators.required],
            phone: [null, Validators.required],
            password: [null, Validators.required],
            newPassword: [null, Validators.required],
            retypePassword: [null, Validators.required],
            digits: this.formBuilder.array([])
        });
        for (let i = 0; i < this.numOfDigits; i++) {
            (this.form.get('digits') as FormArray).push(this.formBuilder.control(null, Validators.required));
        }
        this.phoneMask = this.phoneMaskService.setPhoneMask();
    }

    ngOnChanges(changes) {
        if (changes.popupInfo.currentValue.show) {
            if (this.popupInfo.phone) {
                this.form.controls.phone.setValue(this.popupInfo.phone);
            }
            if (this.popupInfo.email) {
                this.form.controls.email.setValue(this.popupInfo.email);
            }
        } else {
            if (this.form) {
                this.form.reset();
            }
        }
    }

    setPaypalVerified($event) {
        // this.popupInfo.paypalAddress = true;
        // this.updateHandle(this.popupInfo.type, $event);
    }

    closePopup(full = false) {
        this.close.emit(full);
    }


    onSubmitForm() {
        if (this.isPopupTypeEmail()) {
            const email = this.form.controls.email.value;
            const password = this.form.controls.password.value;
            this.accountService.onChangeEmail(email, password).subscribe(data => {
                this.handleSendFormResult(data);
            }, () => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        } else if (this.isPopupTypePhone()) {
            const phone = this.form.controls.phone.value;
            const password = this.form.controls.password.value;
            this.accountService.onChangePhone(phone, password).subscribe(data => {
                this.handleSendFormResult(data);
            }, () => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        } else if (this.isPopupTypePassword()) {
            const password = this.form.controls.password.value;
            const passwordNew = this.form.controls.newPassword.value;
            const passwordRetype = this.form.controls.retypePassword.value;
            if (passwordRetype !== passwordNew) {
                this.popupComponent.showPopupError({text: this.languageService.getString('messagesData', 'passwordNotMatch')});
                return;
            }
            this.accountService.onChangePassword(password, passwordNew).subscribe(data => {
                this.handleSendFormResult(data);
            }, () => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        } else if (this.isPopupTypePin()) {
            const password = this.form.controls.password.value;
            let pin = this.form.controls.digits.value;
            pin = pin.join('');

            this.accountService.onChangePin(pin, password).subscribe(data => {
                this.handleSendFormResult(data);
            }, () => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        } else if (this.isPopupTypePhoneVerify()) {
            let pin = this.form.controls.digits.value;
            pin = pin.join('');

            this.accountService.onVerifyPhone(pin).subscribe(data => {
                this.handleSendFormResult(data);
            }, () => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        }
    }

    handleSendFormResult(data) {
        try {
            if (data.status) {
                this.popupComponent.showPopupSuccess({text: data.message});

                if (this.isPopupTypePhone() || this.isPopupTypeEmail()) {
                    const value = this.isPopupTypePhone() ? this.form.controls.phone.value : this.form.controls.email.value;
                    this.onConfirm.emit({type: this.popupInfo.type, value: value});
                }
                if (this.isPopupTypePhoneVerify()) {
                    this.onVerified.emit('phone');
                }
                if (this.isPopupTypeEmailVerify()) {
                    this.onVerified.emit('email');
                }
                this.close.emit();
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    check(index, field, event) {
        if (isNaN(parseInt(event.key, 10)) && event.key !== 'Backspace') {
            event.preventDefault();
        }

        if (field.value && event.key !== 'Backspace') {
            if (index < this.inputs.toArray().length - 1) {
                this.inputs.toArray()[index + 1].nativeElement.focus();
            }
        } else if (event.key === 'Backspace') {
            if (index > 0) {
                field.setValue(null)
                this.inputs.toArray()[index - 1].nativeElement.focus();
            } else {
                console.log('first field');
            }
        }
    }


    resetPinInput() {
        (this.form.get('digits') as FormArray).clear();
        for (let i = 0; i < this.numOfDigits; i++) {
            (this.form.get('digits') as FormArray).push(this.formBuilder.control(null, Validators.required));
        }
    }

    isPopupTypeEmail() {
        return this.popupInfo.type === CHANGE_USER_INFO_TYPE.email;
    }

    isPopupTypeEmailVerify() {
        return this.popupInfo.type === CHANGE_USER_INFO_TYPE.emailVerify;
    }

    isPopupTypePhone() {
        return this.popupInfo.type === CHANGE_USER_INFO_TYPE.phone;
    }

    isPopupTypePhoneVerify() {
        return this.popupInfo.type === CHANGE_USER_INFO_TYPE.phoneVerify;
    }

    isPopupTypePassword() {
        return this.popupInfo.type === CHANGE_USER_INFO_TYPE.password;
    }

    isPopupTypePin() {
        return this.popupInfo.type === CHANGE_USER_INFO_TYPE.pin;
    }

}

import {Component, OnInit} from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {LoaderService} from "../../../service/loader.service";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {AuthenticationService} from "../../../modules/auth/_services/authentication.service";
import {RouterPaths} from "../../../config/router-paths.model";
import {PartnerService} from "../../../service/partner.service";

@Component({
  selector: 'app-senetpro',
  templateUrl: './senetpro.component.html'
})
export class SenetproComponent implements OnInit {
  userInfo;
  token;
  scrollToGames = false;
  constructor(private formBuilder: FormBuilder,
              private loaderService: LoaderService,
              private popupComponent: PopupComponent,
              private auth: AuthenticationService,
              private partnerService: PartnerService,
              private route: Router,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
      this.auth.clearStorage();

      this.activatedRoute.queryParams.subscribe((params: Params) => {
        this.token = params.token;
        this.scrollToGames = !!params.scrollToGames;
        this.onPlayNow(this.token);

        // this.partnerService.detectPartner(this.onPlayNow.bind(this, this.token));
      });
  }

  onPlayNow(token) {
    this.auth.playNow(token).subscribe(data => {
      this.checkPlayNowSubmit(data);
    }, (error) => {
      this.loaderService.hideAndShowTryAgain();
    }, () => {
      this.loaderService.hide();
    });
  }

  checkPlayNowSubmit(data) {
    try {
      if (data.status) {
          // this.popupComponent.showPopupErrorReturnEvent({text: data.message}, () => {
          // this.userInfo = data;
          // this.partnerComponent.detectPartner(this.loginUser.bind(this));
        // });

        const showContactsFormFirst = data.result.first_name == '' && data.result.last_name == '' && data.result.email == '';
        if (showContactsFormFirst) {
          this.route.navigate(['./home/contacts']);
        } else {
          if (this.scrollToGames) {
            this.route.navigate([RouterPaths.shop], {queryParams: {scrollToGames: 1}});
          } else {
            this.route.navigate([RouterPaths.shop]);
          }
        }
      } else {
        this.popupComponent.showPopupError({text: data.message});
      }
    } catch (e) {
      this.loaderService.hideAndShowTryAgain();
    }
  }
}

import * as i0 from "@angular/core";
import * as i1 from "../modules/language/language.service";
var DateService = /** @class */ (function () {
    function DateService(languageService) {
        this.languageService = languageService;
        this.days = [
            this.languageService.getString('weekData', 'sunday'),
            this.languageService.getString('weekData', 'monday'),
            this.languageService.getString('weekData', 'tuesday'),
            this.languageService.getString('weekData', 'wednesday'),
            this.languageService.getString('weekData', 'thursday'),
            this.languageService.getString('weekData', 'friday'),
            this.languageService.getString('weekData', 'saturday')
        ];
    }
    DateService.prototype.getWeekDayName = function (date) {
        var d;
        if (date) {
            d = new Date(date);
        }
        else {
            d = new Date();
        }
        return this.days[d.getDay()];
    };
    DateService.prototype.getDateSafariSupport = function (date) {
        if (date) {
            return date.replace(/\s/g, 'T');
        }
        else {
            return '';
        }
    };
    DateService.prototype.msToTime = function (duration) {
        var seconds = parseInt(String((duration / 1000) % 60));
        var minutes = parseInt(String((duration / (1000 * 60)) % 60));
        var hours = parseInt(String((duration / (1000 * 60 * 60)) % 24));
        var days = parseInt(String(duration / (1000 * 60 * 60 * 24)));
        var daysTitle = days === 1 ? this.languageService.getString('weekData', 'day') : this.languageService.getString('weekData', 'days');
        hours = (hours < 10) ? "0" + String(hours) : hours;
        minutes = (minutes < 10) ? "0" + String(minutes) : minutes;
        seconds = (seconds < 10) ? "0" + String(seconds) : seconds;
        return days ? (days + " " + daysTitle + " " + hours + ":" + minutes + ":" + seconds) : (hours + ":" + minutes + ":" + seconds);
    };
    DateService.prototype.getDisableTimeDist = function (disableTime) {
        var now = new Date();
        var utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
        var disableDate = new Date(disableTime);
        var sub = disableDate.getTime() - utc.getTime();
        return sub;
    };
    DateService.prototype.isDisableTimeActual = function (disableTime) {
        var dist = this.getDisableTimeDist(disableTime);
        return dist > 0;
    };
    DateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DateService_Factory() { return new DateService(i0.ɵɵinject(i1.LanguageService)); }, token: DateService, providedIn: "root" });
    return DateService;
}());
export { DateService };

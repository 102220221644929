import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";

import {LoaderService} from "./loader.service";
import {ACCOUNT_CONFIG} from "../config/api";


@Injectable({
    providedIn: 'root'
})
export class AccountService {
    private config = ACCOUNT_CONFIG;

    constructor(private http: HttpClient, public loaderService: LoaderService) {
    }

    getPageInfo(page, pageAi, pageNft, singleHistoryPage, singleAiHistoryPage, singleNftHistoryPage) {
        return this.http.post<any>(this.config.getPageInfo, {page, pageAi, pageNft, singleHistoryPage, singleAiHistoryPage, singleNftHistoryPage});
    }

    onRevealPrivateKey(password) {
        this.loaderService.show();
        return this.http.post<any>(this.config.onRevealPrivateKey, {password});
    }

    onReceiveCrypto() {
        this.loaderService.show();
        return this.http.post<any>(this.config.onReceiveCrypto, {});
    }

    onSendCrypto(amount, address, password) {
        this.loaderService.show();
        return this.http.post<any>(this.config.onSendCrypto, {amount, address, password});
    }

    onSaveContacts(firstName, lastName, address, notifyText, notifyEmail) {
        this.loaderService.show();
        return this.http.post<any>(this.config.onSaveContacts, {firstName, lastName, address, notifyText, notifyEmail});
    }

    onRequestVerifyEmail() {
        this.loaderService.show();
        return this.http.post<any>(this.config.requestVerifyEmail, {});
    }

    onChangeEmail(email, password) {
        this.loaderService.show();
        return this.http.post<any>(this.config.onChangeEmail, {email, password});
    }

    onRequestVerifyPhone() {
        this.loaderService.show();
        return this.http.get<any>(this.config.requestVerifyPhone, {});
    }

    onChangePhone(phone, password) {
        this.loaderService.show();
        return this.http.post<any>(this.config.onChangePhone, {phone, password});
    }

    onVerifyPhone(pin) {
        this.loaderService.show();
        return this.http.post<any>(this.config.onVerifyPhone, {pin});
    }

    onVerifyEmail(code, email) {
        this.loaderService.show();
        return this.http.post<any>(this.config.onVerifyEmail, {code, email});
    }

    onChangePassword(passwordOld, password) {
        this.loaderService.show();
        return this.http.post<any>(this.config.onChangePassword, {passwordOld, password});
    }

    onChangePin(pin, password) {
        this.loaderService.show();
        return this.http.post<any>(this.config.onChangePin, {pin, password});
    }

    onLibraryImageStatusChange(uuid, status) {
       // this.loaderService.show();
        return this.http.post<any>(this.config.onLibraryImageStatusChange, {uuid, status});
    }
}

import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {LoaderService} from "../../service/loader.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {StatesModel} from "../../models/states/states.model";
import {PopupComponent} from "../popup/popup.component";
import {PROJECT_MESSAGES} from "../../config/project";
import {CardsService} from "../../service/cards.service";
import {POPUP_DEBIT_CARD_TYPE} from "./popup-debit-card.config";
import {DateAdapter, MatDatepicker} from "@angular/material";
import {LanguageService} from "../../modules/language/language.service";
import {CountryService} from "../../modules/country/country.service";
import {COUNTRIES_SHORT} from "../../config/countries.config";

@Component({
    selector: 'app-popup-debit-card',
    templateUrl: './popup-debit-card.component.html',
    styleUrls: ['./popup-debit-card.component.scss']
})
export class PopupDebitCardComponent implements OnInit {
    @Input() popupInfo;
    @Input() isPopup = true;
    @Input() statesList: Array<StatesModel> = [];
    @Output() close: EventEmitter<boolean> = new EventEmitter();
    @Output() back: EventEmitter<boolean> = new EventEmitter();

    formCheckCard: FormGroup;
    formCheckBankAccount: FormGroup;
    formNew: FormGroup;
    formNewBankAccount: FormGroup;
    formAdditionInfo: FormGroup;

    selectedState;
    states: Array<StatesModel> = [];

    birthdayDate;

    activeMethod;
    activeCard;
    cards = [];
    accounts = [];

    POPUP_DEBIT_CARD_TYPE = POPUP_DEBIT_CARD_TYPE;

    showAdditionTransition = false;
    showAdditionTransitionTypeOld = null;
    isUSACountry = true;

    @ViewChild('picker', {static:false}) datePicker: MatDatepicker<Date>;
    constructor(private loaderService: LoaderService,
                private languageService: LanguageService,
                private countryService: CountryService,
                private cardsService: CardsService,
                private dateAdapter: DateAdapter<any>,
                private popupComponent: PopupComponent,
                private formBuilder: FormBuilder) {}

    ngOnInit() {
        this.isUSACountry = this.countryService.detectCountry() === COUNTRIES_SHORT.usa;
        this.formCheckCard = this.formBuilder.group({
            name: [null],
        });

        this.formCheckBankAccount = this.formBuilder.group({
            name: [null],
        });

        this.formNew = this.formBuilder.group({
            first_name: [null, Validators.required],
            last_name: [null, Validators.required],
            card_number: [null],
            exp: [null, Validators.required],
            cvv: [null, Validators.required],
            city: [null, Validators.required],
            state: [null, Validators.required],
            billing_address: [null, Validators.required],
            billing_zip: [null, Validators.required],
            card_nickname: [null, Validators.required]
        });

        this.formNewBankAccount = this.formBuilder.group({
            routingNumber: [null, Validators.required],
            accountNumber: [null, Validators.required],
        });

        this.formAdditionInfo = this.formBuilder.group({
            legal_first_name: [null, Validators.required],
            legal_last_name: [null, Validators.required],
            address: [null, Validators.required],
            address_2: [null],
            city: [null, Validators.required],
            zip: [null, Validators.required],
            state: [null, Validators.required],
            birthday: [null, Validators.required],
            last_four: [null, Validators.required],
        });

        if (!this.isUSACountry) {
            this.formNew.controls.state.setValidators(Validators.nullValidator);
            this.formAdditionInfo.controls.state.setValidators(Validators.nullValidator);
        }
    }

    getCards() {
        this.cardsService.getDebitCards().subscribe(data => {
            this.setCardsList(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    setCardsList(data) {
        try {
            if (data.status) {
                if (data.result && data.result.length) {
                    this.cards = data.result;
                    this.cards.forEach(card => {
                        if (card.default == 1) {
                            this.activeCard = card;
                            this.setCheckedCard(card);
                        }
                    });
                    this.accounts = [];
                } else {
                    this.cards = [];
                }
                if (data.states && data.states.length && this.isUSACountry) {
                    this.states = data.states;
                    this.selectedState = this.states[0];
                    this.setStateSelect(this.selectedState);
                }
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    getBankAccounts() {
        if (this.isBankAccount()) {
            this.cardsService.getBankAccounts().subscribe(data => {
                this.setBankAccounts(data);
            }, (error) => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        } else {
            this.cardsService.getCashAppAccounts().subscribe(data => {
                this.setBankAccounts(data);
            }, (error) => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        }

    }

    setBankAccounts(data) {
        try {
            if (data.status) {
                if (data.result && data.result.length) {
                    this.accounts = data.result;
                    this.accounts.forEach(account => {
                        if (account.default == 1) {
                            this.activeCard = account;
                            this.setCheckedCard(account);
                        }
                    });
                    this.cards = [];
                } else {
                    this.accounts = [];
                }
                if (data.states && data.states.length && this.isUSACountry) {
                    this.states = data.states;
                    this.selectedState = this.states[0];
                    this.setStateSelect(this.selectedState);
                }
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    getStates() {
        // this.statesService.getStatesList().subscribe(data => {
        //     this.setStatesList(data);
        // }, (error) => {
        //     this.loaderService.hideAndShowTryAgain();
        // }, () => {
        //     this.loaderService.hide();
        // });
    }

    // setStatesList(data) {
    //     try {
    //         if (data.status) {
    //             this.states = [];
    //             if (data.result && data.result.length) {
    //                 this.states = data.result;
    //                 this.selectedState = this.states[0];
    //                 this.setStateSelect(this.selectedState);
    //             }
    //         } else {
    //             this.popupComponent.showPopupError({text: data.message});
    //         }
    //     } catch (e) {
    //         this.loaderService.hideAndShowTryAgain();
    //     }
    // }

    setStateSelect(val) {
        if (this.isUSACountry) {
            if (this.formNew) {
                this.formNew.controls.state.setValue(val);
            }
            if (this.formAdditionInfo) {
                this.formAdditionInfo.controls.state.setValue(val);
            }
        }
    }

    ngOnChanges(changes) {
        if (changes && changes.popupInfo && changes.popupInfo.currentValue && changes.popupInfo.currentValue.show) {
            this.dateAdapter.setLocale(this.languageService.getActiveLanguage());
            if (this.isUSACountry) {
                if (this.states.length) {
                    this.setStateSelect(this.states[0]);
                } else {
                    if (this.statesList.length) {
                        this.states = this.statesList;
                        setTimeout(() => {
                            this.selectedState = this.states[0];
                            this.setStateSelect(this.states[0]);
                        });
                    } else {
                        // this.getStates();
                    }
                }
            }
            if (changes.popupInfo.currentValue.type === POPUP_DEBIT_CARD_TYPE.advancedCard) {
                this.getCards();
            }
            if ((changes.popupInfo.currentValue.type === POPUP_DEBIT_CARD_TYPE.bankAccount) || (changes.popupInfo.currentValue.type === POPUP_DEBIT_CARD_TYPE.cashApp)) {
                this.getBankAccounts();
            }
            if (changes.popupInfo.currentValue.type === 2) {
                // this.getCards();
                this.getDebitCardAdditionInfo();
            }
        } else {
            // if (changes.popupInfo && changes.popupInfo.previous && changes.popupInfo.previous.type === POPUP_DEBIT_CARD_TYPE.simpleCard) {
            //     document.body.classList.remove('hide-menu');
            // }
            this.showAdditionTransition = false;
            this.showAdditionTransitionTypeOld = null;
        }
    }

    getDebitCardAdditionInfo() {
        this.cardsService.getDebitCardAdditionInfo().subscribe(data => {
            this.setDebitCardAdditionInfo(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    setDebitCardAdditionInfo(data) {
        try {
            if (data.status) {
                this.formAdditionInfo.reset();
                const info = data.result ? data.result : {};

                if (!this.states.length && data.states.length && this.isUSACountry) {
                    this.states = data.states;
                    setTimeout(() => {
                        this.selectedState = this.states[0];
                        this.setStateSelect(this.states[0]);
                    });
                }

                if (info.firstName) {
                    this.formAdditionInfo.controls.legal_first_name.setValue(info.firstName);
                }
                if (info.lastName) {
                    this.formAdditionInfo.controls.legal_last_name.setValue(info.lastName);
                }
                if (info.address) {
                    this.formAdditionInfo.controls.address.setValue(info.address);
                }
                if (info.addressTwo) {
                    this.formAdditionInfo.controls.address_2.setValue(info.addressTwo);
                }
                if (info.city) {
                    this.formAdditionInfo.controls.city.setValue(info.city);
                }
                if (info.zip) {
                    this.formAdditionInfo.controls.zip.setValue(info.zip);
                }
                if (info.dob) {
                    this.birthdayDate = new Date(info.dob);
                }
                if (this.isUSACountry) {
                    if (info.stateId) {
                        this.states.forEach(state => {
                            if (state.id === info.stateId) {
                                this.selectedState = state;
                            }
                        });
                        this.setStateSelect(this.selectedState);
                        if (this.selectedState) {
                            this.setStateSelect(this.selectedState);
                        } else {
                            this.selectedState = this.states[0];
                            this.setStateSelect(this.states[0]);
                        }
                    } else {
                        this.selectedState = this.states[0];
                        this.setStateSelect(this.states[0]);
                    }
                }

            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    closePopup(update = false) {
        this.close.emit(update);
        this.formNew.reset();
        this.formNewBankAccount.reset();
        this.formAdditionInfo.reset();
        this.accounts = [];
        this.cards = [];
    }

    goBack(update = false) {
        if (this.showAdditionTransition) {
            this.popupInfo.type = this.showAdditionTransitionTypeOld;
            this.scrollToTopOfPopup(false);
            if (this.popupInfo.type === POPUP_DEBIT_CARD_TYPE.advancedCard) {
                this.getCards();
            }
            if ((this.popupInfo.type === POPUP_DEBIT_CARD_TYPE.bankAccount) || (this.popupInfo.type === POPUP_DEBIT_CARD_TYPE.cashApp)) {
                this.getBankAccounts();
            }
            this.formAdditionInfo.reset();
            this.showAdditionTransitionTypeOld = null;
            this.showAdditionTransition = false;
        } else {
            this.back.emit(this.activeCard);
            this.formNew.reset();
            this.formNewBankAccount.reset();
            this.formAdditionInfo.reset();
        }
    }

    onSubmitNewCardForm() {
        const number = this.getInputValue(this.formNew,'card_number');
        const exp = this.getInputValue(this.formNew,'exp');
        const cvv = this.getInputValue(this.formNew,'cvv');
        const nickname = this.getInputValue(this.formNew,'card_nickname');
        const firstName = this.getInputValue(this.formNew,'first_name');
        const lastName = this.getInputValue(this.formNew,'last_name');
        const billingAddress = this.getInputValue(this.formNew,'billing_address');
        const billingZip = this.getInputValue(this.formNew,'billing_zip');
        const city = this.getInputValue(this.formNew,'city');
        const stateId = this.isUSACountry ? this.formNew.controls.state.value.id : null;

        if (number && exp && cvv && nickname) {

            let expMonth = exp.substr(0, 2);
            let expYear = exp.substr(2, 3);

            if(expMonth != '' && expYear != '' && +expMonth && +expYear && billingAddress != '' && billingZip != '' && firstName != '' && lastName != ''){

                if (this.isSimpleDebitCard()) {
                    this.cardsService.addDebitCard(number, expMonth, expYear, cvv, nickname, firstName, lastName, billingAddress, billingZip, city, stateId).subscribe(data => {
                        this.checkSubmitNewCardResult(data);
                    }, (error) => {
                        this.loaderService.hideAndShowTryAgain();
                    }, () => {
                        this.loaderService.hide();
                    });
                } else {
                    this.cardsService.addAdvancedDebitCard(number, expMonth, expYear, cvv, nickname, firstName, lastName, billingAddress, billingZip, city, stateId).subscribe(data => {
                        this.checkSubmitNewCardResult(data);
                    }, (error) => {
                        this.loaderService.hideAndShowTryAgain();
                    }, () => {
                        this.loaderService.hide();
                    });
                }

            } else {
                this.popupComponent.showPopupError({text: PROJECT_MESSAGES.incorrectDataFormat});
            }
        } else {
            this.popupComponent.showPopupError({text: PROJECT_MESSAGES.notAllFieldsAreFilled});
        }
    }

    checkSubmitNewCardResult(data) {
        try {
            if (data.status === true) {
                if (data.statusLegalInfo || this.isSimpleDebitCard()) {
                    this.popupComponent.showPopupSuccess({text: data.message});
                    // this.getStates();

                    this.scrollToTopOfPopup();
                    if (this.isAdvancedDebitCard()) {
                        this.formNew.reset();
                        this.getCards();
                    }
                    if (this.isBankAccount() || this.isCashApp()) {
                        this.formNewBankAccount.reset();
                        this.getBankAccounts();
                    }
                    if (this.isSimpleDebitCard()) {
                        this.closePopup(true);
                    }
                } else {
                    this.showAdditionTransitionTypeOld = this.popupInfo.type;
                    this.popupInfo.type = 2;
                    this.showAdditionTransition = true;
                    this.scrollToTopOfPopup(false);
                    this.getDebitCardAdditionInfo();
                }
            } else if (data.status === false) {
                this.popupComponent.showPopupError({text: data.message});
            } else {
                this.loaderService.hideAndShowTryAgain();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    onSubmitNewBankAccount() {
        const routingNumber = this.getInputValue(this.formNewBankAccount,'routingNumber');
        const accountNumber = this.getInputValue(this.formNewBankAccount,'accountNumber');

        if (routingNumber && accountNumber) {
            if (this.isBankAccount()) {
                this.cardsService.addBankAccount(routingNumber, accountNumber).subscribe(data => {
                    this.checkSubmitNewCardResult(data);
                }, (error) => {
                    this.loaderService.hideAndShowTryAgain();
                }, () => {
                    this.loaderService.hide();
                });
            } else {
                this.cardsService.addCashAppAccount(routingNumber, accountNumber).subscribe(data => {
                    this.checkSubmitNewCardResult(data);
                }, (error) => {
                    this.loaderService.hideAndShowTryAgain();
                }, () => {
                    this.loaderService.hide();
                });
            }

        } else {
            this.popupComponent.showPopupError({text: PROJECT_MESSAGES.notAllFieldsAreFilled});
        }
    }

    getInputValue(form, field) {
        if (form.controls[field]) {
            return form.controls[field].value
        }
        return null;
    }

    scrollToTopOfPopup(smooth = true) {
        setTimeout(() => {
            document.querySelector('#scrolled-wrapper').scroll({
                top: 0, left: 0, behavior: smooth ? 'smooth' : 'auto'
            });
        }, smooth ? 500 : 0);
    }

    onSubmitAdditionInfoForm() {
        const legalFirstName = this.getInputValue(this.formAdditionInfo,'legal_first_name');
        const legalLastName = this.getInputValue(this.formAdditionInfo,'legal_last_name');
        const address = this.getInputValue(this.formAdditionInfo,'address');
        const address_2 = this.getInputValue(this.formAdditionInfo,'address_2');
        const city = this.getInputValue(this.formAdditionInfo,'city');
        const zip = this.getInputValue(this.formAdditionInfo,'zip');
        const stateId = this.isUSACountry ? this.formAdditionInfo.controls.state.value.id : null;
        const birthday = this.getFilterBirhday();
        const last_four = this.getInputValue(this.formAdditionInfo,'last_four');
        const shortName = this.popupInfo.shortName;

        if(legalFirstName != '' && legalLastName != '' && address != '' && city != '' && zip != '' && birthday != '' && last_four != ''){
            this.cardsService.addDebitCardAdditionInfo(legalFirstName, legalLastName, address, address_2, city, zip, stateId, birthday, last_four, shortName).subscribe(data => {
                this.checkSubmitAdditionInfoResult(data);
            }, (error) => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        } else {
            this.popupComponent.showPopupError({text: PROJECT_MESSAGES.notAllFieldsAreFilled});
        }
    }

    getFilterBirhday(){
      if (this.isUSACountry) {
        return (<HTMLInputElement>document.querySelector('[formcontrolname=birthday]')).value;
      } else {
        const date = (<HTMLInputElement>document.querySelector('[formcontrolname=birthday]')).value;
        const dateArray = date.split('/');
        return `${dateArray[1]}/${dateArray[0]}/${dateArray[2]}`;
      }
    }

    checkSubmitAdditionInfoResult(data) {
        try {
            if (data.status === true) {
                this.popupComponent.showPopupSuccess({text: data.message});
                this.closePopup();

                // this.popupInfo.type = 1;
                // this.formNew.reset();
                // this.formAdditionInfo.reset();
                // this.getCards();
                // this.scrollToTopOfPopup();
            } else if (data.status === false) {
                this.popupComponent.showPopupError({text: data.message});
            } else {
                this.loaderService.hideAndShowTryAgain();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    askOfDeletingCard(card) {
        // const message = '<b class="js-popup-title">Please confirm deleting card</b>';
        const message = !card.last_four ? `<b class="js-popup-title">${this.languageService.getString('messagesData', 'confirmDeletingCard')}</b>` : card.cardholder ?
          `<b class="js-popup-title">${this.languageService.getString('messagesData', 'confirmDeleting')}</b> <br><br> <span class="js-popup-subtitle">${card.cardholder} - ${card.last_four}</span>` :
          `<b class="js-popup-title">${this.languageService.getString('messagesData', 'confirmDeletingCard')}</b> <br><br> <span class="js-popup-subtitle">${this.languageService.getString('messagesData', 'endingIn')} ${card.last_four}</span>`;
        this.popupComponent.showPopupAgreement({text: message}, (status) => {
            if (status) {
                this.removeDebitCard(card.uuid);
            }
        });
    }

    removeDebitCard(uuid) {
        this.cardsService.removeAdvancedDebitCard(uuid).subscribe(data => {
            this.checkSubmitRemoveDebitCardResult(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    checkSubmitRemoveDebitCardResult(data) {
        try {
            if (data.status === true) {
                if (this.isBankAccount() || this.isCashApp()) {
                    this.getBankAccounts();
                } else {
                    this.getCards();
                }
            } else if (data.status === false) {
                this.popupComponent.showPopupError({text: data.message});
            } else {
                this.loaderService.hideAndShowTryAgain();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    askOfDeletingBankAccount(account) {
        // const message = '<b class="js-popup-title">Please confirm deleting card</b>';
        const bankAccountMessage = `<b class="js-popup-title">${this.languageService.getString('messagesData', 'confirmDeletingBankAccount')}</b> <br><br> <span class="js-popup-subtitle">${this.languageService.getString('messagesData', 'endingIn')} ${account.account_number_last4}</span>`;
        const cashAppAccountMessage = `<b class="js-popup-title">${this.languageService.getString('messagesData', 'confirmDeletingCashAppAccount')}</b> <br><br> <span class="js-popup-subtitle">${this.languageService.getString('messagesData', 'endingIn')} ${account.account_number_last4}</span>`;
        const message = this.isBankAccount() ? bankAccountMessage : cashAppAccountMessage;
        this.popupComponent.showPopupAgreement({text: message}, (status) => {
            if (status) {
                if (this.isBankAccount()) {
                    this.removeBankAccount(account.uuid);
                } else {
                    this.removeCashAppAccount(account.uuid);
                }
            }
        });
    }

    removeBankAccount(uuid) {
        this.cardsService.removeBankAccount(uuid).subscribe(data => {
            this.checkSubmitRemoveDebitCardResult(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    removeCashAppAccount(uuid) {
        this.cardsService.removeCashAppAccount(uuid).subscribe(data => {
            this.checkSubmitRemoveDebitCardResult(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    changeMethod(method){
        if (method) {
            this.activeMethod = method;
        }
    }

    onSelectCard() {
        if (this.isBankAccount()) {
            const cardId = this.formCheckCard.controls.name.value;
            this.cardsService.changeActiveBankAccount(cardId).subscribe(data => {
                this.selectCardResult(data);
            }, (error) => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        } else if (this.isCashApp()) {
            const cardId = this.formCheckCard.controls.name.value;
            this.cardsService.changeActiveCashAppAccount(cardId).subscribe(data => {
                this.selectCardResult(data);
            }, (error) => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        } else {
            const cardId = this.formCheckCard.controls.name.value;
            this.cardsService.changeActiveCard(cardId).subscribe(data => {
                this.selectCardResult(data);
            }, (error) => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        }

    }

    selectCardResult(data) {
        try {
            if (data.status) {
                this.popupComponent.showPopupSuccess({text: data.message});
                this.activeCard = this.activeMethod;
            } else {
                this.popupComponent.showPopupError({text: data.message});
                this.setCheckedCard(this.activeCard);
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    setCheckedCard(card) {
        this.activeMethod = card;
        if (card) {
            this.formCheckCard.controls.name.setValue(this.activeMethod.uuid);
        } else {
            this.formCheckCard.controls.name.setValue(null);
        }
    }

    isSimpleDebitCard() {
        return this.popupInfo.type === POPUP_DEBIT_CARD_TYPE.simpleCard;
    }

    isAdvancedDebitCard() {
        return this.popupInfo.type === POPUP_DEBIT_CARD_TYPE.advancedCard;
    }

    isBankAccount() {
        return this.popupInfo.type === POPUP_DEBIT_CARD_TYPE.bankAccount;
    }

    isCashApp() {
        return this.popupInfo.type === POPUP_DEBIT_CARD_TYPE.cashApp;
    }

    isAdditionInfoPopup() {
        return this.popupInfo.type === POPUP_DEBIT_CARD_TYPE.additionInfo;
    }

    openCalendar() {
        this.datePicker.open();
    }

    isAddCardButtonDisabled() {
        if (this.formNew) {
            return this.formNew.invalid || !this.formNew.controls.card_number.value || (this.formNew.controls.card_number.value && (this.formNew.controls.card_number.value.length < 15));
        }
    }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {LoaderService} from "../../service/loader.service";
import {PopupComponent} from "../popup/popup.component";
import {CardsService} from "../../service/cards.service";

@Component({
    selector: 'app-popup-verify-card',
    templateUrl: './popup-verify-card.component.html',
    styleUrls: ['./popup-verify-card.component.scss']
})
export class PopupVerifyCardComponent implements OnInit {
    @Input() popupInfo: any = {show: false};
    @Output('onClose') emitClose: EventEmitter<any> = new EventEmitter();

    form: FormGroup;
    constructor(private formBuilder: FormBuilder,
                private loaderService: LoaderService,
                private popupComponent: PopupComponent,
                private cardsService: CardsService) {}

    ngOnInit() {
        this.form = this.formBuilder.group({
            amount: [null, Validators.required]
        });
    }

    sendForm() {
        const amount = this.form.controls.amount.value;

        this.onGeneratePassword(amount);
    }

    ngOnDestroy() {
        this.form.reset();
    }

    onGeneratePassword(amount) {
        const id = this.popupInfo.id;
        this.cardsService.verifyCard(amount, id).subscribe(data => {
            this.setRequestResult(data);
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        })
    }

    setRequestResult(data) {
        try {
            if (data.status) {
                this.popupComponent.showPopupSuccess({text: data.message});
                this.closePopup(true);
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    closePopup(result) {
        this.form.reset();
        this.emitClose.emit(result);
    }

}

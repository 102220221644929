import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";

import {LoaderService} from "./loader.service";
import {CHECKOUT_CONFIG} from "../config/api";


@Injectable({
    providedIn: 'root'
})
export class CheckoutService {
    private config = CHECKOUT_CONFIG;

    constructor(private http: HttpClient, public loaderService: LoaderService) {
    }

    getPageInfo(only_balance = false) {
        this.loaderService.show();
        return this.http.post<any>(this.config.getPageInfo, {only_balance});
    }

    // getCartInfo(shortName, amount) {
    //     this.loaderService.show();
    //     return this.http.post<any>(this.config.getCartInfo, {shortName, amount});
    // }

    buyWithCard(aiStyleId, amount, aiTypeImageGeneration, keywords, id) {
        this.loaderService.show();
        return this.http.post<any>(this.config.buyWithCard, {aiStyleId, amount, aiTypeImageGeneration, keywords, id});
    }

    buyWithCashApp(aiStyleId, amount, aiTypeImageGeneration, keywords, token) {
        this.loaderService.show();
        return this.http.post<any>(this.config.buyWithCashApp, {aiStyleId, amount, aiTypeImageGeneration, keywords, token});
    }

    getGenerateInfo() {
        this.loaderService.show();
        return this.http.post<any>(this.config.getGenerateInfo, {});
    }

    generateOne(keywords) {
        this.loaderService.show();
        return this.http.post<any>(this.config.generateOne, {keywords});
    }

    generateAll(keywords) {
        this.loaderService.show();
        return this.http.post<any>(this.config.generateAll, {keywords});
    }

    generateText() {
        //this.loaderService.show();
        return this.http.post<any>(this.config.generateText, {});
    }

    getMintInfo(page) {
        this.loaderService.show();
        return this.http.post<any>(this.config.getMintInfo, {page});
    }

    onMintImages(images) {
        this.loaderService.show();
        return this.http.post<any>(this.config.onMintImages, {images});
    }
}

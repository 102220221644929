import { TERMS_AND_CONDITIONS_CONFIG } from '../../config/api';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../loader.service";
var TermsAndConditionsService = /** @class */ (function () {
    function TermsAndConditionsService(http, loaderService) {
        this.http = http;
        this.loaderService = loaderService;
        this.config = TERMS_AND_CONDITIONS_CONFIG;
    }
    TermsAndConditionsService.prototype.getPageInfo = function () {
        this.loaderService.show();
        return this.http.post(this.config.getPageInfo, {});
    };
    TermsAndConditionsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TermsAndConditionsService_Factory() { return new TermsAndConditionsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: TermsAndConditionsService, providedIn: "root" });
    return TermsAndConditionsService;
}());
export { TermsAndConditionsService };

import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {CHANGE_USER_INFO_TYPE} from "../popup-change-user-info/popup-change-user-info.config";

@Component({
    selector: 'app-popup-verify-phone',
    templateUrl: './popup-verify-phone.component.html',
    styleUrls: ['./popup-verify-phone.component.scss']
})
export class PopupVerifyPhoneComponent implements OnInit {
    @Input() popupInfo: any = {show: false, code: null, canBeClosed: true};

    form: FormGroup;
    popupChangeUserInfo = {
        show: true,
        type: CHANGE_USER_INFO_TYPE.phoneVerify
    }
    constructor() {}

    ngOnInit() {
        document.body.classList.add('hide-menu');
        document.body.querySelector('.login__forms').classList.add('is-blurred');
        document.body.querySelector('.app-wrapper').classList.add('is-blurred');
    }

    ngOnDestroy() {
        document.body.querySelector('.app-wrapper').classList.remove('is-blurred');
        if (document.querySelectorAll('.investments-popup.is-active').length <= 1) {
            document.body.classList.remove('hide-menu');
            document.body.querySelector('.login__forms').classList.remove('is-blurred');
        }
    }

    closePopupSuccess() {}

    closePopup() {}

    onVerify() {
        this.closePopupSuccess();
    }
}

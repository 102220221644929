import { USER_CONTACT_CONFIG } from "../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./loader.service";
var ContactInfoService = /** @class */ (function () {
    function ContactInfoService(http, loaderService) {
        this.http = http;
        this.loaderService = loaderService;
        this.config = USER_CONTACT_CONFIG;
    }
    ContactInfoService.prototype.createUser = function (first_name, last_name, email, promo_code, password) {
        this.loaderService.show();
        return this.http.post(this.config.create, { first_name: first_name, last_name: last_name, email: email, promo_code: promo_code, password: password });
    };
    ContactInfoService.prototype.saveUserContacts = function (first_name, last_name, email) {
        this.loaderService.show();
        return this.http.post(this.config.save, { first_name: first_name, last_name: last_name, email: email });
    };
    ContactInfoService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ContactInfoService_Factory() { return new ContactInfoService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: ContactInfoService, providedIn: "root" });
    return ContactInfoService;
}());
export { ContactInfoService };

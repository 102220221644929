<div class="login__forms"  [class.is-blurred]="popupTransactions.show || popupConfirmInfo.show || popupChannels.show || popupShop.show || popupChangeUserInfo.show || popupImages.show || showLibraryBigImagePopup">
    <div class="login__tab">
        <div class="is-popup-shown" *ngIf="showAdditionalContent()">
            <app-balance-heading [entries]="entriesVal" [send]="redeemedVal"></app-balance-heading>
        </div>

        <div class="page-block is-popup-shown" [class.page-block--no-mt]="!showAdditionalContent()">
            <div class="gl-page-title gl-page-title--icon c-accent" style="margin-bottom: 1rem">
                <svg>
                    <use xlink:href="../../../../assets/images/sprite.svg#coin-icon"></use>
                </svg>
<!--                Settings-->
                {{'settings' | languageTranslate}}
            </div>
<!--            <div class="gl-subtitle gl-subtitle&#45;&#45;opacity">{{bitcoinDescription}}</div>-->

            <form [formGroup]="contactsForm" class="login__form" (ngSubmit)="onSaveContcts()">
                <div class="form-simple__item">
                    <input formControlName="firstName"
                           placeholder="{{'formData' | languageTranslate: [{$innerVariable: 'firstName'}]}}"
                           [type]="'text'"
                           class="form-simple__input"
                           autocomplete="off">
                </div>

                <div class="form-simple__item">
                    <input formControlName="lastName"
                           placeholder="{{'formData' | languageTranslate: [{$innerVariable: 'lastName'}]}}"
                           [type]="'text'"
                           class="form-simple__input"
                           autocomplete="off">
                </div>

                <div class="form-simple__item">
                    <input formControlName="address"
                           placeholder="{{'formData' | languageTranslate: [{$innerVariable: 'address'}]}}"
                           [type]="'text'"
                           class="form-simple__input"
                           autocomplete="off">
                </div>

                <div class="form-simple__item">
                    <input formControlName="email"
                           placeholder="{{'formData' | languageTranslate: [{$innerVariable: 'email'}]}}"
                           [email]="true"
                           [type]="'text'"
                           disabled="disabled"
                           class="form-simple__input"
                           autocomplete="off">
                </div>

                <div class="form-simple__item">
                    <input [mask]="phoneMask"
                           formControlName="phone"
                           placeholder="{{'phoneNumber' | languageTranslate}}"
                           [type]="'tel'"
                           disabled="disabled"
                           class="form-simple__input"
                           autocomplete="off">
                </div>

                <div class="saved saved--owner">
                    <div class="form-simple__item card-selection__row"
                         *ngIf="isPhoneVerified">
                        <input [id]="'notifyText'"
                               [type]="'checkbox'"
                               [checked]="notifyText"
                               class="form-simple__radio"
                               (change)="onCheckboxChange($event, 'text')">
                        <label [for]="'notifyText'">
                            {{'notifyViaText' | languageTranslate}}
                        </label>
                    </div>
                    <div class="form-simple__item card-selection__row"
                         *ngIf="isEmailVerified">
                        <input [id]="'notifyEmail'"
                               [type]="'checkbox'"
                               [checked]="notifyEmail"
                               class="form-simple__radio"
                               (change)="onCheckboxChange($event, 'email')">
                        <label [for]="'notifyEmail'">
                            {{'notifyViaEmail' | languageTranslate}}
                        </label>
                    </div>
                </div>

                <div class="checkout-tabs__item">
                    <div class="checkout-btns checkout-btns--bordered">
                        <button class="button"
                                [disabled]="!this.contactsForm.controls.firstName.value && !this.contactsForm.controls.lastName.value && !this.contactsForm.controls.address.value">
                            {{'save' | languageTranslate}}
                        </button>
                    </div>
                    <div class="checkout-btns">
                        <div class="checkout-btns__row">
                            <button class="button"
                                    type="button"
                                    style="margin-bottom: 1rem"
                                    (click)="changeUserInfo(CHANGE_USER_INFO_TYPE.email)">
                                {{'change' | languageTranslate}} {{'formData' | languageTranslate: [{$innerVariable: 'email'}]}}
                            </button>
                            <button class="button"
                                    type="button"
                                    *ngIf="!isEmailVerified && contactsForm.controls.email.value"
                                    (click)="changeUserInfo(CHANGE_USER_INFO_TYPE.emailVerify)"
                                    style="width: 5rem; min-width: 5rem; margin-bottom: 1rem; background-color: transparent;">
                                {{'verify' | languageTranslate}}
                            </button>
                        </div>
                        <div class="checkout-btns__row">
                            <button class="button"
                                    type="button"
                                    style="margin-bottom: 1rem"
                                    (click)="changeUserInfo(CHANGE_USER_INFO_TYPE.phone)">
                                {{'change' | languageTranslate}} {{'formData' | languageTranslate: [{$innerVariable: 'phone'}]}}
                            </button>
                            <button class="button"
                                    type="button"
                                    *ngIf="!isPhoneVerified && contactsForm.controls.phone.value"
                                    (click)="changeUserInfo(CHANGE_USER_INFO_TYPE.phoneVerify)"
                                    style="width: 5rem; min-width: 5rem; margin-bottom: 1rem; background-color: transparent;">
                                {{'verify' | languageTranslate}}
                            </button>
                        </div>

                        <button class="button"
                                type="button"
                                (click)="changeUserInfo(CHANGE_USER_INFO_TYPE.password)">
                            {{'changePassword' | languageTranslate}}
                        </button>
                        <button class="button"
                                type="button"
                                (click)="changeUserInfo(CHANGE_USER_INFO_TYPE.pin)">
                            {{'change' | languageTranslate}} {{'formData' | languageTranslate: [{$innerVariable: 'pin'}]}}
                        </button>
                    </div>
                </div>
            </form>
        </div>

<!--        <div class="shop-block is-popup-shown">-->
<!--            <div class="gl-page-title gl-page-title&#45;&#45;icon c-accent">-->
<!--                <svg>-->
<!--                    <use xlink:href="../../../../assets/images/sprite.svg#coin-icon"></use>-->
<!--                </svg>-->
<!--&lt;!&ndash;                BITCOIN BALANCE AND TRANSACTIONS&ndash;&gt;-->
<!--                {{'bitcoinBalanceAndTransactions' | languageTranslate}}-->
<!--            </div>-->
<!--            <div class="gl-subtitle gl-subtitle&#45;&#45;opacity">{{bitcoinDescription}}</div>-->

<!--            <div class="small-action-row">-->
<!--                <a [href]="btcTransactionsLink"-->
<!--                   target="_blank"-->
<!--                   class="small-action-row__btn">-->
<!--&lt;!&ndash;                    View Transaction blockchain&ndash;&gt;-->
<!--                    {{'viewTransactionBlockchain' | languageTranslate}}-->
<!--                </a>-->
<!--                <div class="small-action-row__text">{{bitcoinAmount}} BTC</div>-->
<!--            </div>-->

<!--            <div class="small-action-row">-->
<!--                <button class="small-action-row__btn"-->
<!--                        (click)="showRevealKeyPopup()">-->
<!--&lt;!&ndash;                    Reveal Private key&ndash;&gt;-->
<!--                    {{'revealPrivateKey' | languageTranslate}}-->
<!--                </button>-->
<!--            </div>-->

<!--            <div class="show-btns-list show-btns-list&#45;&#45;max-height">-->
<!--                <div class="show-btns-list__row">-->
<!--                    <button class="button"-->
<!--                            (click)="onSellCrypto()"-->
<!--                            type="button">-->
<!--                        {{'formData' | languageTranslate: [{$innerVariable: 'sell'}]}}-->
<!--                    </button>-->
<!--                    <div class="show-btns-list__descr" [innerHTML]="sellDescription"></div>-->
<!--                </div>-->
<!--                <div class="show-btns-list__row"-->
<!--                     *ngIf="isCheckOutVisible">-->
<!--                    <a class="button"-->
<!--                       [routerLink]="RouterPaths.checkout"-->
<!--                        type="button">-->
<!--&lt;!&ndash;                        Buy&ndash;&gt;-->
<!--                        {{'buy' | languageTranslate}}-->
<!--                    </a>-->
<!--                    <div class="show-btns-list__descr" [innerHTML]="buyDescription"></div>-->
<!--                </div>-->
<!--                <div class="show-btns-list__row">-->
<!--                    <button class="button"-->
<!--                            (click)="onSendCrypto()"-->
<!--                            type="button">-->
<!--&lt;!&ndash;                        Send&ndash;&gt;-->
<!--                        {{'formData' | languageTranslate: [{$innerVariable: 'send'}]}}-->
<!--                    </button>-->
<!--                    <div class="show-btns-list__descr" [innerHTML]="sendDescription"></div>-->
<!--                </div>-->
<!--                <div class="show-btns-list__row">-->
<!--                    <button class="button"-->
<!--                            (click)="onReceiveCrypto()"-->
<!--                            type="button">-->
<!--&lt;!&ndash;                        Receive&ndash;&gt;-->
<!--                        {{'receive' | languageTranslate}}-->
<!--                    </button>-->
<!--                    <div class="show-btns-list__descr" [innerHTML]="receiveDescription"></div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

        <div class="shop-block">
            <div class="gl-page-title gl-page-title--icon c-accent is-popup-shown">
                <svg>
                    <use xlink:href="../../../../assets/images/sprite.svg#coin-icon"></use>
                </svg>
<!--                NFT LIBRARY-->
                {{'nftLibrary' | languageTranslate}}
            </div>
            <div class="gl-subtitle gl-subtitle--opacity is-popup-shown">{{nftDescription}}</div>

            <app-nft-library [nftData]="nftData"></app-nft-library>

            <button style="display: block; margin-left: auto; margin-right: auto;"
                    *ngIf="showMoreLibraryNFTBtn"
                    (click)="showNFTLibraryPopup()"
                    class="button btn-show-transactions">
                <!--                    Show more-->
                {{'showMore' | languageTranslate}}
            </button>
        </div>

        <div class="shop-block">
            <div class="gl-page-title gl-page-title--icon c-accent is-popup-shown">
                <svg>
                    <use xlink:href="../../../../assets/images/sprite.svg#coin-icon"></use>
                </svg>
                <!--                NFT LIBRARY-->
                {{'imagesLibrary' | languageTranslate}}
            </div>
            <div class="gl-subtitle gl-subtitle--opacity is-popup-shown">{{'imagesLibraryDescription' | languageTranslate}}</div>

            <div class="library library--grid">
                <button *ngFor="let slide of imagesList; let i = index"
                        class="slide library__slide js-library-slide">
                    <img (click)="onShowLibraryBigImagePopup(slide)"
                         class="slide library__slide-img"
                         style="pointer-events: auto"
                         src="{{ slide.image_link ? slide.image_link : slide.image_full }}" alt="" width="100%">
                    <div class="nft-terms-agreement">
                        <input [id]="'mintBrush'+i"
                               (change)="onLibraryImageCheckboxChange($event, slide)"
                               [checked]="!+slide.privacy_status"
                               class="nft-terms-agreement__input checkbox-primary"
                               type="checkbox">
                        <label class="nft-terms-agreement__label" [for]="'mintBrush'+i" style="cursor: pointer">
                        </label>
                    </div>
                </button>
            </div>

            <button style="display: block; margin-left: auto; margin-right: auto;"
                    *ngIf="showMoreLibraryImagesBtn"
                    (click)="showImagesLibraryPopup()"
                    class="button btn-show-transactions">
                <!--                    Show more-->
                {{'showMore' | languageTranslate}}
            </button>
        </div>

        <div class="shop-block is-popup-shown">
            <div class="gl-page-title gl-page-title--icon c-accent">
                <svg>
                    <use xlink:href="../../../../assets/images/sprite.svg#coin-icon"></use>
                </svg>
<!--                PRODUCT HISTORY-->
                {{'purchaseHistory' | languageTranslate}}
            </div>
            <div class="gl-subtitle gl-subtitle--opacity">{{'purchaseHistoryDescription' | languageTranslate}}</div>

            <div class="dropdown" style="padding-bottom: 0; text-align: center">
                <app-senetpro-transactions [items]="transactionsList"
                                           [type]="TRANSACTIONS_LIST_TYPE.history"
                                           [isStickyHeader]="false"></app-senetpro-transactions>

                <button (click)="showTransactionsPopup()"
                        class="button btn-show-transactions"
                        *ngIf="showMoreTransactionsBtn">
<!--                    Show more-->
                    {{'showMore' | languageTranslate}}
                </button>
            </div>
        </div>
    </div>
</div>

<app-popup-senetpro-transactions [popupInfo]="popupTransactions"
                                 [type]="TRANSACTIONS_LIST_TYPE.history"
                                 (close)="closeTransactionsPopup()">
</app-popup-senetpro-transactions>

<app-popup-more-images [popupInfo]="popupImages"
                       (libraryImageCheckboxChange)="onLibraryImageCheckboxPopupChange($event)"
                       [type]="popupImages.type"
                       (close)="closeImagesPopup()">
</app-popup-more-images>

<app-popup-shop (close)="onPopupShopClose()"
                (send)="onSendPopupForm($event)"
                [popupInfo]="popupShop"></app-popup-shop>

<app-popup-change-user-info [popupInfo]="popupChangeUserInfo"
                            (onVerified)="onVerified($event)"
                            (onConfirm)="setChangedUserInfo($event)"
                            (close)="closeChangeUserInfoPopup()"></app-popup-change-user-info>

<app-popup-big-image  *ngIf="showLibraryBigImagePopup && libraryBigImagePopupData"
                            [popupData]="libraryBigImagePopupData.data"
                            (close)="onPopupLibraryBigImageClose()"></app-popup-big-image>

<template #popupGenerate></template>

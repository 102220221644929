import {ComponentFactory, ComponentFactoryResolver, Injectable, ViewChild, ViewContainerRef} from '@angular/core';
import {HttpClient} from "@angular/common/http";

import {LoaderService} from "./loader.service";
import {WALLET_CONFIG} from "../config/api";
import {PopupGenerateComponent} from "../shared/popup-generate/popup-generate.component";
import {PopupComponent} from "../shared/popup/popup.component";
import {BehaviorSubject, Observable} from "rxjs";


@Injectable({
    providedIn: 'root'
})
export class WalletService {
    private config = WALLET_CONFIG;
    showModal = new BehaviorSubject({show: false, code: null});
    repeatFunction = () => {};

    constructor(private http: HttpClient,
                private popupComponent: PopupComponent,
                private resolver: ComponentFactoryResolver,
                public loaderService: LoaderService) {}

    resetRepeatFunction() {
        this.repeatFunction = () => {};
    }
    generate(password) {
        this.loaderService.show();
        return this.http.post<any>(this.config.generate, {password});
    }

    cryptoSendPassword(password) {
        this.loaderService.show();
        return this.http.post<any>(this.config.crypto, {password});
    }

    showGeneratePopup(container, code, canBeClosed = true) {
        container.clear();
        const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(PopupGenerateComponent);
        const popup = container.createComponent(factory);
        popup.instance.popupInfo.show = true;
        popup.instance.popupInfo.code = code;
        popup.instance.popupInfo.canBeClosed = canBeClosed;
        popup.instance.closePopupSuccess = () => {
            container.clear();
            this.repeatFunction();
            this.resetPopup();
        };
        popup.instance.closePopup = () => {
            container.clear();
            this.resetPopup();
        };
    }

    showPopup(code) {
        this.showModal.next({show: true, code: code});
    }

    resetPopup() {
        this.showModal.next({show: false, code: null});
    }
}

import { SUPPORT_CONFIG } from "../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../loader.service";
var SupportService = /** @class */ (function () {
    function SupportService(http, loaderService) {
        this.http = http;
        this.loaderService = loaderService;
        this.config = SUPPORT_CONFIG;
    }
    SupportService.prototype.getSession = function () {
        this.loaderService.show();
        return this.http.post(this.config.session, {});
    };
    SupportService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SupportService_Factory() { return new SupportService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: SupportService, providedIn: "root" });
    return SupportService;
}());
export { SupportService };

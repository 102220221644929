import * as i0 from "@angular/core";
var SessionStorageService = /** @class */ (function () {
    function SessionStorageService() {
        this.storage = sessionStorage;
    }
    SessionStorageService.prototype.cleanStorage = function () {
        // this.removePromo();
        // this.removeCashAppTag();
        // this.removeLoginStep();
        // this.removeAppLink();
        // this.removeCashappFrom();
        // this.removeCashappLink();
    };
    SessionStorageService.prototype.getUserToken = function () {
        return JSON.parse(this.storage.getItem('currentUser')).token;
    };
    SessionStorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SessionStorageService_Factory() { return new SessionStorageService(); }, token: SessionStorageService, providedIn: "root" });
    return SessionStorageService;
}());
export { SessionStorageService };

import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {PopupComponent} from "../shared/popup/popup.component";
import {LanguageService} from "../modules/language/language.service";

@Injectable({
    providedIn: 'root'
})
export class LoaderService {
    public visible = new BehaviorSubject(false);

    constructor(private popupComponent: PopupComponent,
                private languageService: LanguageService) {

    }

    show() {
        this.visible.next(true);
        document.body.classList.add('is-hidden');

    }

    hide() {
        this.visible.next(false);
        document.body.classList.remove('is-hidden');
    }

    hideAndShowTryAgain(){
        this.hide();
        const errorMessage = this.languageService.getString('messagesData', 'tryAgain');
        this.popupComponent.showPopupError({text: errorMessage});
    }

}

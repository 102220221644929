import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RouterPaths} from "../../config/router-paths.model";

@Component({
    selector: 'app-get-rewards',
    templateUrl: './get-rewards.component.html',
    styleUrls: ['./get-rewards.component.scss']
})
export class GetRewardsComponent implements OnInit {
    RouterPaths = RouterPaths;
    @Output('onClick')
    emitClick: EventEmitter<any> = new EventEmitter();


    constructor() {
    }

    ngOnInit() {

    }

}

import { OnInit } from '@angular/core';
import Swal from "sweetalert2";
import * as i0 from "@angular/core";
import * as i1 from "../../modules/language/language.service";
var PopupComponent = /** @class */ (function () {
    function PopupComponent(languageService) {
        this.languageService = languageService;
        this.swalStyled = Swal.mixin({
            customClass: {
                container: 'popup',
                popup: 'popup__inner',
                image: 'popup__image',
                title: 'popup__title',
                content: 'popup__content',
                confirmButton: 'popup__btn popup__btn_confirm button',
                cancelButton: 'popup__btn popup__btn_cancel'
            },
        });
    }
    PopupComponent.prototype.ngOnInit = function () {
    };
    PopupComponent.prototype.showPopupInfo = function (message) {
        this.swalStyled.fire({
            showClass: {
                popup: 'swal-agreement'
            },
            html: message && message.text ? message.text : this.languageService.getString('messagesData', 'successMessage'),
            showConfirmButton: false,
            cancelButtonText: '<svg class="close" width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                '<path d="M25.5 24.1719L18.3281 17L25.5 9.82812C25.8668 9.46136 25.8668 8.86676 25.5 8.5C25.1332 8.13324 24.5386 8.13324 24.1719 8.5L17 15.6719L9.82813 8.5C9.46136 8.13324 8.86676 8.13324 8.5 8.5C8.13324 8.86676 8.13324 9.46136 8.5 9.82812L15.6719 17L8.5 24.1719C8.13324 24.5386 8.13324 25.1332 8.5 25.5C8.86676 25.8668 9.46136 25.8668 9.82813 25.5L17 18.3281L24.1719 25.5C24.5386 25.8668 25.1332 25.8668 25.5 25.5C25.8668 25.1332 25.8668 24.5386 25.5 24.1719Z"/>\n' +
                '</svg>',
            allowOutsideClick: true,
            showCancelButton: true,
        });
    };
    PopupComponent.prototype.showPopupSuccess = function (message) {
        this.swalStyled.fire({
            showClass: {
                popup: 'swal-success'
            },
            text: message && message.text ? message.text : this.languageService.getString('messagesData', 'successMessage'),
            confirmButtonText: this.languageService.getString('okThanks'),
        });
    };
    PopupComponent.prototype.showPopupSuccessReturnEvent = function (message, handler) {
        var text = message.text ? message.text : this.languageService.getString('messagesData', 'errorMessage');
        var confirmButtonText = message.confirmButtonText ? message.confirmButtonText : this.languageService.getString('ok');
        var closeBtn = message.closeBtn === false ? message.closeBtn : true;
        this.swalStyled.fire({
            showClass: {
                popup: 'swal-success'
            },
            text: text,
            showConfirmButton: closeBtn,
            allowOutsideClick: closeBtn,
            confirmButtonText: confirmButtonText,
        }).then(function (result) {
            if (result.isConfirmed) {
                return handler(true);
            }
            else {
                return handler(false);
            }
        });
    };
    PopupComponent.prototype.showPopupConfirmCancel = function (options, handler, timer) {
        if (timer === void 0) { timer = null; }
        var text = options.text ? options.text : this.languageService.getString('messagesData', 'errorMessage');
        var style = options.style ? options.style : 'swal-confirm-cancel';
        var closeBtn = options.closeBtn === false ? options.closeBtn : true;
        var confirmButtonText = options.confirmButtonText ? options.confirmButtonText : this.languageService.getString('confirm');
        var cancelButtonText = options.cancelButtonText ? options.cancelButtonText : this.languageService.getString('cancel');
        this.swalStyled.fire({
            showClass: {
                popup: style,
            },
            html: text,
            allowOutsideClick: closeBtn,
            showCancelButton: true,
            cancelButtonText: cancelButtonText,
            showConfirmButton: true,
            confirmButtonText: confirmButtonText,
            timer: timer,
            customClass: {
                container: 'popup',
                popup: 'popup__inner',
                image: 'popup__image',
                title: 'popup__title',
                content: 'popup__content',
                confirmButton: 'popup__btn popup__btn_confirm button',
                input: 'login-input',
                cancelButton: 'popup__btn popup__btn_cancel button'
            }
        }).then(function (result) {
            var dismissReason = result.dismiss && result.dismiss.toString();
            if (dismissReason === 'timer') {
                return handler('timerOut');
            }
            if (result.isConfirmed) {
                return handler(true);
            }
            else {
                return handler(false);
            }
        });
    };
    PopupComponent.prototype.showPopupErrorReturnEvent = function (message, handler) {
        var text = message.text ? message.text : this.languageService.getString('messagesData', 'errorMessage');
        var confirmButtonText = message.confirmButtonText ? message.confirmButtonText : this.languageService.getString('ok');
        var closeBtn = message.closeBtn === false ? message.closeBtn : true;
        this.swalStyled.fire({
            text: text,
            showConfirmButton: closeBtn,
            allowOutsideClick: closeBtn,
            confirmButtonText: confirmButtonText,
        }).then(function (result) {
            if (result.isConfirmed) {
                return handler(true);
            }
            else {
                return handler(false);
            }
        });
    };
    PopupComponent.prototype.showPopupError = function (message) {
        var text = message && message.text ? message.text : this.languageService.getString('messagesData', 'errorMessage');
        var confirmButtonText = message && message.confirmButtonText ? message.confirmButtonText : this.languageService.getString('ok');
        var closeBtn = message && (message.closeBtn === false) ? message.closeBtn : true;
        this.swalStyled.fire({
            // title: 'Error',
            // imageUrl: '../assets/images/popup/error-logo.png',
            //className: "swal-back",
            showClass: {
                popup: 'swal-error'
            },
            text: text,
            showConfirmButton: closeBtn,
            allowOutsideClick: closeBtn,
            confirmButtonText: confirmButtonText
        });
    };
    PopupComponent.prototype.showPopupTryAgain = function () {
        this.swalStyled.fire({
            showClass: {
                popup: 'swal-error'
            },
            text: this.languageService.getString('messagesData', 'errorMessage'),
            confirmButtonText: this.languageService.getString('ok')
        });
    };
    PopupComponent.prototype.showPopupAgreement = function (options, handler, timer) {
        if (timer === void 0) { timer = null; }
        var text = options.text ? options.text : this.languageService.getString('messagesData', 'errorMessage');
        var style = options.style ? options.style : 'swal-agreement';
        var closeBtn = options.closeBtn === false ? options.closeBtn : true;
        var allowOutsideClick = options.allowOutsideClick;
        var showConfirmButton = options.showConfirmButton === false ? options.showConfirmButton : true;
        var confirmButtonText = options.confirmButtonText ? options.confirmButtonText : this.languageService.getString('confirm');
        var cancelButtonText = options.cancelButtonText ? options.cancelButtonText : '<svg class="close" width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<path d="M25.5 24.1719L18.3281 17L25.5 9.82812C25.8668 9.46136 25.8668 8.86676 25.5 8.5C25.1332 8.13324 24.5386 8.13324 24.1719 8.5L17 15.6719L9.82813 8.5C9.46136 8.13324 8.86676 8.13324 8.5 8.5C8.13324 8.86676 8.13324 9.46136 8.5 9.82812L15.6719 17L8.5 24.1719C8.13324 24.5386 8.13324 25.1332 8.5 25.5C8.86676 25.8668 9.46136 25.8668 9.82813 25.5L17 18.3281L24.1719 25.5C24.5386 25.8668 25.1332 25.8668 25.5 25.5C25.8668 25.1332 25.8668 24.5386 25.5 24.1719Z"/>\n' +
            '</svg>';
        this.swalStyled.fire({
            showClass: {
                popup: style,
            },
            html: text,
            confirmButtonText: confirmButtonText,
            cancelButtonText: closeBtn ? cancelButtonText : false,
            allowOutsideClick: allowOutsideClick !== undefined ? closeBtn && allowOutsideClick : closeBtn,
            showCancelButton: true,
            showConfirmButton: showConfirmButton,
            timer: timer
        }).then(function (result) {
            var dismissReason = result.dismiss && result.dismiss.toString();
            if (dismissReason === 'timer') {
                return handler('timerOut');
            }
            if (result.isConfirmed) {
                return handler(true);
            }
            else {
                return handler(false);
            }
        });
    };
    PopupComponent.prototype.closePopup = function () {
        if (this) {
            this.swalStyled.close();
        }
    };
    PopupComponent.prototype.showPopupVariants = function (options, handler) {
        var _this = this;
        var text = options.text ? options.text : this.languageService.getString('messagesData', 'askToSelectAccount');
        var style = options.style ? options.style : 'swal-agreement';
        var confirmButtonText = options.confirmButtonText ? options.confirmButtonText : this.languageService.getString('confirm');
        var accounts = options.accounts;
        var cancelButtonText = options.cancelButtonText ? options.cancelButtonText : '<svg class="close" width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<path d="M25.5 24.1719L18.3281 17L25.5 9.82812C25.8668 9.46136 25.8668 8.86676 25.5 8.5C25.1332 8.13324 24.5386 8.13324 24.1719 8.5L17 15.6719L9.82813 8.5C9.46136 8.13324 8.86676 8.13324 8.5 8.5C8.13324 8.86676 8.13324 9.46136 8.5 9.82812L15.6719 17L8.5 24.1719C8.13324 24.5386 8.13324 25.1332 8.5 25.5C8.86676 25.8668 9.46136 25.8668 9.82813 25.5L17 18.3281L24.1719 25.5C24.5386 25.8668 25.1332 25.8668 25.5 25.5C25.8668 25.1332 25.8668 24.5386 25.5 24.1719Z"/>\n' +
            '</svg>';
        var $this = this;
        this.swalStyled.fire({
            showClass: {
                popup: style,
            },
            input: 'radio',
            inputOptions: accounts,
            html: text,
            confirmButtonText: confirmButtonText,
            cancelButtonText: cancelButtonText,
            showCancelButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            inputValidator: function (value) {
                if (!value) {
                    return _this.languageService.getString('messagesData', 'askToChooseSomething');
                }
                else {
                    //return handler(value);
                    //return value;
                }
            }
        }).then(function (result) {
            if (result.isConfirmed) {
                return handler(result.value);
            }
            else {
                return handler(false);
            }
        });
    };
    PopupComponent.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PopupComponent_Factory() { return new PopupComponent(i0.ɵɵinject(i1.LanguageService)); }, token: PopupComponent, providedIn: "root" });
    return PopupComponent;
}());
export { PopupComponent };

export class AuthConfig {
    checkPhoneAndSendPin: string;
    login: string;
    register: string;
    forgotPassword: string;
    restorePassword: string;
    refresh: string;
    logout: string;
    addPassword: string;
}

<div class="get-rewards">
    <div class="get-rewards__row">
        <a class="get-rewards__img"
                   [routerLink]="RouterPaths.commission">
            <img style="max-width: 100%" src="../../../assets/images/get-rewards.png" alt="">
        </a>
        <div class="get-rewards__col">
            <div class="get-rewards__text">{{'getRewardsInviteText' | languageTranslate}}</div>
            <a class="button get-rewards__btn"
               [routerLink]="RouterPaths.commission"
               type="button">
                {{'getRewardsStartBtn' | languageTranslate}}
            </a>
        </div>
    </div>
</div>

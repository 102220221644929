import * as i0 from "@angular/core";
var ScrollToService = /** @class */ (function () {
    function ScrollToService() {
    }
    ScrollToService.prototype.scrollToEnd = function (wrapper) {
        var list = document.querySelector(wrapper);
        setTimeout(function () {
            if (list) {
                var scrollTo_1 = list.scrollHeight;
                list.scroll({
                    top: scrollTo_1,
                    left: 0,
                    behavior: 'smooth'
                });
            }
        }, 500);
    };
    ScrollToService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ScrollToService_Factory() { return new ScrollToService(); }, token: ScrollToService, providedIn: "root" });
    return ScrollToService;
}());
export { ScrollToService };

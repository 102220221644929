import {Injectable} from '@angular/core';
import {DOMAINS_LESS_CONTENT_LIST, LOGO_LESS_CONTENT_REPLACEMENT} from "../config/content-displaying.config";
import {PartnerService} from "./partner.service";

@Injectable({
  providedIn: 'root'
})
export class ContentDisplayingService {
  domainsLessContent = DOMAINS_LESS_CONTENT_LIST;
  logoTextReplacement = LOGO_LESS_CONTENT_REPLACEMENT;

  constructor(private partnerService: PartnerService) {}

  detectDomain() {
    const host = window.location.hostname;
    // const host = 'app.ra-techapp.com';

    const parts = host.split('.');
    const sndleveldomain = parts.slice(-2).join('.');

    return sndleveldomain;
  }

  showAdditionContent() {
    return !!this.partnerService.getContentType();
    // return !this.domainsLessContent.includes(this.detectDomain());
  }

  raTechappSenetReplacementText() {
    return this.logoTextReplacement[this.detectDomain()] ? this.logoTextReplacement[this.detectDomain()] : this.logoTextReplacement[this.domainsLessContent[0]];
  }

}

export const SPINNER_SOUND_TYPE = {
  win: {
    name: 'win',
    src: './assets/audio/games/win.wav'
  },
  wheel: {
    name: 'wheel',
    src: './assets/audio/games/wheel.mp3'
  }
}

import { ElementRef, EventEmitter, OnInit, QueryList } from '@angular/core';
import { SPINNER_SOUND_TYPE } from "./spinner.config";
import { AUTH_CODES } from "../../config/auth_codes";
var SpinnerComponent = /** @class */ (function () {
    function SpinnerComponent(spinnerService, languageService, popupComponent, loaderService) {
        this.spinnerService = spinnerService;
        this.languageService = languageService;
        this.popupComponent = popupComponent;
        this.loaderService = loaderService;
        this.onSpinResult = new EventEmitter();
        this.onShowDebitCardPopup = new EventEmitter();
        this.spinning = false;
        this.spinningFinish = false;
        this.tommorow = {
            id: 18,
            initialText: 'noLuck',
            resultText: 'tomorrow'
        };
        this.transformValue = -8;
    }
    Object.defineProperty(SpinnerComponent.prototype, "data", {
        get: function () {
            return this._data;
        },
        set: function (value) {
            var _this = this;
            this._data = value;
            if (value) {
                setTimeout(function () {
                    _this.setSpinnerData(value);
                }, 500);
            }
        },
        enumerable: true,
        configurable: true
    });
    SpinnerComponent.prototype.ngOnInit = function () {
        // document.addEventListener('mousemove', this.onMouseUpdate, false);
        // document.addEventListener('mouseenter', this.onMouseUpdate, false);
        this.loadImages();
    };
    SpinnerComponent.prototype.onMouseUpdate = function (e) {
        var x = e.pageX;
        var y = e.pageY;
        console.log(x, y);
    };
    SpinnerComponent.prototype.ngOnDestroy = function () {
    };
    SpinnerComponent.prototype.getSpinResult = function () {
        var _this = this;
        this.spinning = true;
        this.transformValue += 12 * 30;
        this.spinnerInterval = setInterval(function () {
            _this.transformValue += 12 * 30;
        }, 500);
        this.spinnerService.init().subscribe(function (data) {
            _this.setSpinnerResult(data);
        }, function () {
            _this.onSpin();
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
        });
    };
    SpinnerComponent.prototype.setSpinnerResult = function (responce) {
        var _this = this;
        try {
            if (responce.status === true) {
                this.onSpin(responce.result, responce.message, responce.type);
            }
            else if (responce.status === false) {
                if (responce.code && (responce.code === AUTH_CODES.addCreditCard)) {
                    this.onSpin();
                    this.popupComponent.showPopupConfirmCancel({
                        text: responce.message,
                        closeBtn: true,
                        confirmButtonText: this.languageService.getString('formData', 'add') + " " + this.languageService.getString('formData', 'card'),
                        cancelButtonText: 'CANCEL'
                    }, function (result) {
                        if (result) {
                            _this.onShowDebitCardPopup.emit();
                        }
                    });
                }
                else {
                    this.onSpin();
                    this.popupComponent.showPopupError({ text: responce.message });
                }
            }
            else {
                this.onSpin();
                this.loaderService.hideAndShowTryAgain();
                // window.location.reload();
            }
        }
        catch (e) {
            this.onSpin();
            this.loaderService.hideAndShowTryAgain();
            // window.location.reload();
        }
    };
    SpinnerComponent.prototype.onSpin = function (id, message, type) {
        var _this = this;
        if (id) {
            this.playSound(SPINNER_SOUND_TYPE.wheel.name);
        }
        clearInterval(this.spinnerInterval);
        this.spinningFinish = true;
        var activeSegmentPosition = this.slice.find(function (el) { return el.nativeElement.classList.contains('is-winning'); }).nativeElement.getAttribute('data-segment-position');
        if (id) {
            var newSegment = this.slice.find(function (el) { return +el.nativeElement.getAttribute('data-segment-id') === +id; });
            var defaultSegment = this.slice.find(function (el) { return +el.nativeElement.getAttribute('data-segment-id') === +_this.data[0].id; });
            var newSegmentPosition = newSegment ? newSegment.nativeElement.getAttribute('data-segment-position') : defaultSegment.nativeElement.getAttribute('data-segment-position');
            var distToEl = activeSegmentPosition - newSegmentPosition;
            this.transformValue += (12 * this.getRndInteger(1, 2) + distToEl) * 30;
        }
        else {
            // this.transformValue += (12 * this.getRndInteger(1, 2) + 6) * 30;
            this.transformValue += 0;
        }
        // setTimeout(() => {
        //     this.transformValue += 150;
        // }, 1750);
        this.slice.forEach(function (el) {
            el.nativeElement.classList.remove('is-winning');
            // el.nativeElement.querySelector('.segment__middle').innerHTML = '';
        });
        setTimeout(function () {
            console.log("test_test: 26");
            var distMin = 0;
            var distMinEl;
            var pointArrow = document.querySelector('.spinner__arrow .spinner__arrow-inner').getBoundingClientRect();
            var pointArrowTop = pointArrow.top + window.pageYOffset;
            var pointArrowLeft = pointArrow.left + window.pageXOffset;
            _this.slice.forEach(function (el) {
                var start = el.nativeElement.querySelector('.segment__inner').getBoundingClientRect();
                var startX = start.x;
                var startY = start.y;
                var end = el.nativeElement.querySelector('.segment__end').getBoundingClientRect();
                var endX = end.x + window.pageXOffset;
                var endY = end.y + window.pageYOffset;
                var elemDistToArrow = _this.dist(endX, endY, pointArrowLeft, pointArrowTop);
                if (!distMin || distMin > elemDistToArrow) {
                    distMin = elemDistToArrow;
                    distMinEl = el;
                }
            });
            distMinEl.nativeElement.classList.add('is-winning');
            if (id && id === _this.tommorow.id) {
                distMinEl.nativeElement.querySelector('.segment__middle').innerHTML = _this.languageService.getString(_this.tommorow.resultText);
            }
            _this.spinning = false;
            _this.spinningFinish = false;
            if (message && (message != '')) {
                // if (type) {
                //this.popupComponent.showPopupSuccess({text: message});
                // } else {
                _this.popupComponent.showPopupError({ text: message });
                // }
            }
            _this.stopSound();
            if (type) {
                _this.playSound(SPINNER_SOUND_TYPE.win.name);
                // this.mascotService.updateBalance();
            }
            _this.onSpinResult.emit(!!type);
        }, 5000);
    };
    SpinnerComponent.prototype.getRndInteger = function (min, max) {
        return Math.floor(Math.random() * (max - min)) + min;
    };
    SpinnerComponent.prototype.dist = function (x1, y1, x2, y2) {
        var deltaX = this.diff(x1, x2);
        var deltaY = this.diff(y1, y2);
        var dist = Math.sqrt(Math.pow(deltaX, 2) + Math.pow(deltaY, 2));
        return (dist);
    };
    SpinnerComponent.prototype.diff = function (num1, num2) {
        if (num1 > num2) {
            return (num1 - num2);
        }
        else {
            return (num2 - num1);
        }
    };
    SpinnerComponent.prototype.setSpinnerData = function (value) {
        var _this = this;
        this.slice.forEach(function (el, index) {
            var elDOM = el.nativeElement;
            var elMiddleDOM = el.nativeElement.querySelector('.segment__middle');
            if (_this.data[index]) {
                elDOM.setAttribute('data-segment-id', _this.data[index].id);
                if (_this.data[index].id === _this.tommorow.id) {
                    elMiddleDOM.innerHTML = _this.languageService.getString(_this.tommorow.initialText);
                }
                else {
                    elMiddleDOM.innerHTML = _this.data[index].name;
                }
            }
        });
    };
    SpinnerComponent.prototype.playSound = function (type) {
        var audio = this.audio.nativeElement;
        if (type === SPINNER_SOUND_TYPE.wheel.name) {
            audio.src = SPINNER_SOUND_TYPE.wheel.src;
            audio.loop = true;
            audio.play();
        }
        else if (type === SPINNER_SOUND_TYPE.win.name) {
            var audioWin = new Audio(SPINNER_SOUND_TYPE.win.src);
            audioWin.play();
        }
    };
    SpinnerComponent.prototype.stopSound = function () {
        this.audio.nativeElement.pause();
    };
    SpinnerComponent.prototype.showInfo = function () {
        this.popupComponent.showPopupInfo({
            text: "<div><div>" + this.languageService.getString('spinnerInfoPopup', 'firstTitle') + "</div><div style=\"font-size: .9em\">" + this.languageService.getString('spinnerInfoPopup', 'firstText') + "</div><br><div>" + this.languageService.getString('spinnerInfoPopup', 'secondTitle') + "</div><div style=\"font-size: .9em\">" + this.languageService.getString('spinnerInfoPopup', 'secondText') + "</div></div>"
        });
    };
    SpinnerComponent.prototype.loadImages = function () {
        var sinnerImagesLoadedClass = 'spinner-images-loaded';
        var spinnerWrapper = document.querySelector('.spinner-wrapper');
        var images = [
            '/assets/images/spinner/cents.png',
            '/assets/images/spinner/game-arrow.png',
            '/assets/images/spinner/wheel_4.png'
        ];
        var imagesCounter = images.length;
        images.forEach(function (src, index) {
            var image = new Image();
            image.src = src;
            image.addEventListener('load', function () {
                imagesCounter -= 1;
                if (!imagesCounter) {
                    spinnerWrapper.classList.add(sinnerImagesLoadedClass);
                }
            });
        });
    };
    return SpinnerComponent;
}());
export { SpinnerComponent };

import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ActivatedRoute, Router} from "@angular/router";
import {LoaderService} from "./loader.service";

@Injectable({
    providedIn: 'root'
})
export class LoginService {
    public loginChanged = new BehaviorSubject(null);

    constructor(private ngZone: NgZone,
                private route: ActivatedRoute,
                private loaderService: LoaderService,
                private router: Router) {

    }

    // show() {
    //     this.loginChanged.next(true);
    //     document.body.classList.add('is-hidden');
    // }

}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./shared/loader/loader.component.ngfactory";
import * as i4 from "./shared/loader/loader.component";
import * as i5 from "./service/loader.service";
import * as i6 from "./app.component";
import * as i7 from "./modules/auth/_services/authentication.service";
import * as i8 from "./modules/language/language.service";
import * as i9 from "./modules/country/country.service";
import * as i10 from "./service/wallet.service";
import * as i11 from "./service/phoneVerify.service";
import * as i12 from "./service/content-displaying.service";
import * as i13 from "./shared/popup/popup.component";
import * as i14 from "./service/app-version/appVersion.service";
import * as i15 from "./service/login.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { popupGenerateContainer: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "app-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(3, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-loader", [], null, null, null, i3.View_LoaderComponent_0, i3.RenderType_LoaderComponent)), i1.ɵdid(5, 114688, null, 0, i4.LoaderComponent, [i5.LoaderService], null, null), (_l()(), i1.ɵeld(6, 16777216, [[1, 3], ["popupGenerate", 1]], null, 0, "template", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "senet-iframe"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "iframe", [["class", "senet-iframe__frame"], ["frameborder", "0"]], null, null, null, null, null))], function (_ck, _v) { _ck(_v, 3, 0); _ck(_v, 5, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).clickInside($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 245760, null, 0, i6.AppComponent, [i2.Router, i7.AuthenticationService, i8.LanguageService, i9.CountryService, i10.WalletService, i11.PhoneVerifyService, i12.ContentDisplayingService, i13.PopupComponent, i14.AppVersionService, i15.LoginService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i6.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };

<div class="app-wrapper">
    <router-outlet></router-outlet>
</div>

<app-loader></app-loader>

<template #popupGenerate></template>

<div class="senet-iframe">
    <iframe frameborder="0" class="senet-iframe__frame"></iframe>
</div>

import { APP_VERSION_CONFIG } from "../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
import * as i3 from "../../modules/language/language.service";
import * as i4 from "../../shared/popup/popup.component";
var AppVersionService = /** @class */ (function () {
    function AppVersionService(http, router, languageService, activatedRoute, popupComponent) {
        this.http = http;
        this.router = router;
        this.languageService = languageService;
        this.activatedRoute = activatedRoute;
        this.popupComponent = popupComponent;
        this.config = APP_VERSION_CONFIG;
        this.versionPopupShow = false;
    }
    AppVersionService.prototype.setVersionCount = function (count) {
        localStorage.setItem('versionCount', count);
    };
    AppVersionService.prototype.getVersionCount = function () {
        if (localStorage.getItem('versionCount')) {
            return parseInt(localStorage.getItem('versionCount'));
        }
        return 0;
    };
    AppVersionService.prototype.checkVersion = function () {
        var _this = this;
        console.log("checkVersion");
        this.getVersion().subscribe(function (data) {
            var version = _this.getMeta('app-version');
            if (version != data.result) {
                var versionCount = _this.getVersionCount();
                ++versionCount;
                _this.setVersionCount(versionCount);
                _this.showUpgradePopup();
            }
            else {
                _this.setVersionCount(0);
            }
        });
        var $this = this;
        setTimeout(function () {
            $this.checkVersion();
        }, 5 * 60 * 1000);
    };
    AppVersionService.prototype.showUpgradePopup = function () {
        var _this = this;
        if (!this.versionPopupShow) {
            this.versionPopupShow = true;
            if (false) {
                var text = this.languageService.getString('messagesData', 'askToRestartApp');
                this.popupComponent.showPopupAgreement({
                    text: text,
                    closeBtn: false,
                    showConfirmButton: false
                }, function () {
                });
            }
            else {
                var text = this.languageService.getString('messagesData', 'appVersionIsOld');
                this.popupComponent.showPopupAgreement({
                    text: text,
                    closeBtn: false,
                    confirmButtonText: this.languageService.getString('upgrade')
                }, function () {
                    _this.upgradeApp();
                });
            }
        }
    };
    AppVersionService.prototype.getVersion = function () {
        return this.http.post(this.config.version, {});
    };
    AppVersionService.prototype.getMeta = function (metaName) {
        var metas = document.getElementsByTagName('meta');
        for (var i = 0; i < metas.length; i++) {
            if (metas[i].getAttribute('name') === metaName) {
                return metas[i].getAttribute('content');
            }
        }
        return '';
    };
    AppVersionService.prototype.upgradeApp = function () {
        var time = +new Date();
        window.location.href = window.location.protocol + '//' + this.getDomain(window.location.href, true) + '?hash=' + time;
    };
    AppVersionService.prototype.getDomain = function (url, subdomain) {
        subdomain = subdomain || false;
        url = url.replace(/(https?:\/\/)?(www.)?/i, '');
        if (!subdomain) {
            url = url.split('.');
            url = url.slice(url.length - 2).join('.');
        }
        if (url.indexOf('/') !== -1) {
            return url.split('/')[0];
        }
        return url;
    };
    AppVersionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppVersionService_Factory() { return new AppVersionService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.LanguageService), i0.ɵɵinject(i2.ActivatedRoute), i0.ɵɵinject(i4.PopupComponent)); }, token: AppVersionService, providedIn: "root" });
    return AppVersionService;
}());
export { AppVersionService };

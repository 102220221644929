import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {LoaderService} from "./loader.service";

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  constructor(public http: HttpClient, public loaderService: LoaderService) {}

  request(url:string, data:any, loader = true) {
    if (loader) {
      this.loaderService.show();
    }
    return this.http.post<any>(url, data);
  }

}

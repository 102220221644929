<table class="transactions"
       *ngIf="type === TRANSACTIONS_LIST_TYPE.transactions"
       [class.is-left-align]="isPerks"
       [class.is-sticky]="isStickyHeader">
    <tr class="transactions__head js-prizes-item">
        <th class="transactions__date">
<!--            DATE-->
            {{'tableData' | languageTranslate: [{$innerVariable: 'date'}]}}
        </th>
        <th style="text-align: center" class="transactions__amount">
<!--            CREDIT-->
            {{'tableData' | languageTranslate: [{$innerVariable: 'credit'}]}}
        </th>
        <th style="text-align: center" class="transactions__amount">
<!--            DEBIT-->
            {{'tableData' | languageTranslate: [{$innerVariable: 'debit'}]}}
        </th>
        <th style="text-align: center" class="transactions__amount">
<!--            BALANCE-->
            {{'tableData' | languageTranslate: [{$innerVariable: 'balance'}]}}
        </th>
        <th class="transactions__type">
<!--            TYPE-->
            {{'tableData' | languageTranslate: [{$innerVariable: 'type'}]}}
        </th>
    </tr>

    <ng-container *ngIf="processingItems && processingItems.length && isPerks">
        <p class="transactions__subtitle">
            {{'processing' | languageTranslate}}
        </p>
        <tr *ngFor="let transaction of processingItems" class="transactions__item  js-prizes-item" [class.c-accent]="transaction.type === WITHDRAW_TRANSACTION_TYPES.bonuses">
            <td class="transactions__date">{{getDate(transaction.created) | date: 'MM/dd/yy'}}</td>

            <td style="text-align: center" class="transactions__amount" *ngIf="isTransaction(WITHDRAW_TRANSACTION_TYPES.increase, transaction.type)">{{formatCurrency(transaction.amount)}}</td>
            <td style="text-align: center" class="transactions__amount" *ngIf="!isTransaction(WITHDRAW_TRANSACTION_TYPES.increase, transaction.type)">-</td>

            <td style="text-align: center" class="transactions__amount" *ngIf="isTransaction(WITHDRAW_TRANSACTION_TYPES.decrease, transaction.type)">{{formatCurrency(transaction.amount)}}</td>
            <td style="text-align: center" class="transactions__amount" *ngIf="!isTransaction(WITHDRAW_TRANSACTION_TYPES.decrease, transaction.type)">-</td>

            <td style="text-align: center" class="transactions__amount">{{formatCurrency(transaction.balance)}}</td>

            <td class="transactions__type">{{transaction.method_name}}</td>
        </tr>
    </ng-container>

    <ng-container *ngIf="isPerks">
        <p class="transactions__subtitle" [ngStyle]="{marginTop: processingItems && processingItems.length ? '0.5rem' : '0'}">
<!--            Posted-->
            {{'posted' | languageTranslate}}
        </p>
    </ng-container>
    <tr *ngFor="let transaction of items" class="transactions__item  js-prizes-item" [class.c-accent]="transaction.type === WITHDRAW_TRANSACTION_TYPES.bonuses">
        <td class="transactions__date">{{getDate(transaction.created) | date: 'MM/dd/yy'}}</td>

        <td style="text-align: center" class="transactions__amount" *ngIf="isTransaction(WITHDRAW_TRANSACTION_TYPES.increase, transaction.type)">{{formatCurrency(transaction.amount)}}</td>
        <td style="text-align: center" class="transactions__amount" *ngIf="!isTransaction(WITHDRAW_TRANSACTION_TYPES.increase, transaction.type)">-</td>

        <td style="text-align: center" class="transactions__amount" *ngIf="isTransaction(WITHDRAW_TRANSACTION_TYPES.decrease, transaction.type)">{{formatCurrency(transaction.amount)}}</td>
        <td style="text-align: center" class="transactions__amount" *ngIf="!isTransaction(WITHDRAW_TRANSACTION_TYPES.decrease, transaction.type)">-</td>

        <td style="text-align: center" class="transactions__amount">{{formatCurrency(transaction.balance)}}</td>

        <td class="transactions__type">{{transaction.method_name}}</td>
    </tr>
</table>

<table class="transactions transactions--product"
       *ngIf="type === TRANSACTIONS_LIST_TYPE.history"
       [class.is-sticky]="isStickyHeader">
    <tr class="transactions__head js-prizes-item">
        <th class="transactions__date">
<!--            DATE-->
            {{'tableData' | languageTranslate: [{$innerVariable: 'date'}]}}
        </th>
        <th class="transactions__description">
<!--            TYPE-->
            {{'tableData' | languageTranslate: [{$innerVariable: 'type'}]}}
        </th>
    </tr>
    <tr *ngFor="let transaction of items" class="transactions__item  js-prizes-item" [class.c-accent]="transaction.type === WITHDRAW_TRANSACTION_TYPES.bonuses">
        <td class="transactions__date">{{getDate(transaction.created) | date: 'MM/dd/yy'}}</td>
        <td class="transactions__description">{{transaction.description}}</td>
    </tr>
</table>

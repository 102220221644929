import { ComponentFactory, ComponentFactoryResolver } from '@angular/core';
import { WALLET_CONFIG } from "../config/api";
import { PopupGenerateComponent } from "../shared/popup-generate/popup-generate.component";
import { BehaviorSubject } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../shared/popup/popup.component";
import * as i3 from "./loader.service";
var WalletService = /** @class */ (function () {
    function WalletService(http, popupComponent, resolver, loaderService) {
        this.http = http;
        this.popupComponent = popupComponent;
        this.resolver = resolver;
        this.loaderService = loaderService;
        this.config = WALLET_CONFIG;
        this.showModal = new BehaviorSubject({ show: false, code: null });
        this.repeatFunction = function () { };
    }
    WalletService.prototype.resetRepeatFunction = function () {
        this.repeatFunction = function () { };
    };
    WalletService.prototype.generate = function (password) {
        this.loaderService.show();
        return this.http.post(this.config.generate, { password: password });
    };
    WalletService.prototype.cryptoSendPassword = function (password) {
        this.loaderService.show();
        return this.http.post(this.config.crypto, { password: password });
    };
    WalletService.prototype.showGeneratePopup = function (container, code, canBeClosed) {
        var _this = this;
        if (canBeClosed === void 0) { canBeClosed = true; }
        container.clear();
        var factory = this.resolver.resolveComponentFactory(PopupGenerateComponent);
        var popup = container.createComponent(factory);
        popup.instance.popupInfo.show = true;
        popup.instance.popupInfo.code = code;
        popup.instance.popupInfo.canBeClosed = canBeClosed;
        popup.instance.closePopupSuccess = function () {
            container.clear();
            _this.repeatFunction();
            _this.resetPopup();
        };
        popup.instance.closePopup = function () {
            container.clear();
            _this.resetPopup();
        };
    };
    WalletService.prototype.showPopup = function (code) {
        this.showModal.next({ show: true, code: code });
    };
    WalletService.prototype.resetPopup = function () {
        this.showModal.next({ show: false, code: null });
    };
    WalletService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WalletService_Factory() { return new WalletService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.PopupComponent), i0.ɵɵinject(i0.ComponentFactoryResolver), i0.ɵɵinject(i3.LoaderService)); }, token: WalletService, providedIn: "root" });
    return WalletService;
}());
export { WalletService };

import * as i0 from "@angular/core";
import * as i1 from "./loader.service";
var PointService = /** @class */ (function () {
    function PointService(loaderService) {
        this.loaderService = loaderService;
        this.storagePointKey = 'point';
        this.storagePointAccountsKey = 'pointAccounts';
    }
    PointService.prototype.savePointAccounts = function (accounts) {
        sessionStorage.setItem(this.storagePointAccountsKey, JSON.stringify(accounts));
    };
    PointService.prototype.getPointAccounts = function () {
        return JSON.parse(sessionStorage.getItem(this.storagePointAccountsKey));
    };
    PointService.prototype.savePointInfo = function (info) {
        sessionStorage.setItem(this.storagePointKey, JSON.stringify(info));
    };
    PointService.prototype.getPointInfo = function (key) {
        if (key) {
            var pointInfo = JSON.parse(sessionStorage.getItem(this.storagePointKey));
            return pointInfo[key];
        }
        return sessionStorage.getItem(this.storagePointKey);
    };
    PointService.prototype.resetPointsInfo = function () {
        sessionStorage.removeItem(this.storagePointKey);
        sessionStorage.removeItem(this.storagePointAccountsKey);
    };
    PointService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PointService_Factory() { return new PointService(i0.ɵɵinject(i1.LoaderService)); }, token: PointService, providedIn: "root" });
    return PointService;
}());
export { PointService };

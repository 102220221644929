import {Component, EventEmitter, HostBinding, HostListener, Input, OnInit, Output} from '@angular/core';
import {slideToRight} from "../../animations/router.animations";
import {PopupComponent} from "../../shared/popup/popup.component";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ContactInfoService} from "../../service/contactInfo.service";
import {LoaderService} from "../../service/loader.service";
import {Router} from "@angular/router";
// import {UsersPointService} from "../../service/users/users-point.service";
import {AppPromptService} from "../../service/app/appPrompt.service";
// import {StylingService} from "../../service/styling.service";
import {AuthenticationService} from "../../modules/auth/_services/authentication.service";
import {LanguageService} from "../../modules/language/language.service";

@Component({
    selector: 'app-contacts-form',
    templateUrl: './contacts-form.component.html',
    styleUrls: ['./contacts-form.component.scss'],
    // animations: [slideToRight],
    // host: {'[@slideToRight]': ''}
})
export class ContactsFormComponent implements OnInit {
    @Input() style;
    @Output() userCreated = new EventEmitter();

    isMascot: boolean;
    contactInfo: FormGroup;
    info;

    appInstalled = false;
    // isBrowser;
    IOSInstallBtn = false;
    ios_popup_text;
    androidInstallBtn = false;
    deferredPrompt: any;

    @HostBinding('class.is-mascot') mascotClass: boolean;
    @HostBinding('@.disabled')
    public animationsDisabled: boolean;

    constructor(private popupComponent: PopupComponent,
                private contactInfoService: ContactInfoService,
                private router: Router,
                private loaderService: LoaderService,
                // private usersPointService: UsersPointService,
                private appPromptService: AppPromptService,
                private languageService: LanguageService,
                // private stylingService: StylingService,
                private auth:AuthenticationService,
                private formBuilder: FormBuilder) {
        this.deferredPrompt = this.appPromptService.deferredPrompt;
        //this.auth.userLoggedStatusChanged.next(false);
    }


    ngOnInit() {
        this.contactInfo = this.formBuilder.group({
            first_name: [null, Validators.required],
            last_name: [null, Validators.required],
            email: [null, [Validators.required, Validators.pattern('^[0-9A-Za-z._%+-]+@[0-9A-Za-z.-]+\\.[a-zA-Z]{2,4}$')]],
            promo: [null],
            password: [null, Validators.required],
            passwordRepeat: [null, Validators.required],
        });

        window.addEventListener('appinstalled', (evt) => {
            this.popupComponent.closePopup();
            const text = this.languageService.getString('messagesData', 'launchAppFromDevice');
            this.popupComponent.showPopupSuccess({text: text});
        });
    }

    onSubmit() {
        const firstName = this.contactInfo.controls.first_name.value;
        const lastName = this.contactInfo.controls.last_name.value;
        const email = this.contactInfo.controls.email.value;
        const promo = this.contactInfo.controls.promo.value;
        const password = this.contactInfo.controls.password.value;
        const passwordRepeat = this.contactInfo.controls.passwordRepeat.value;

        if (password !== passwordRepeat) {
            const text = this.languageService.getString('messagesData', 'passwordNotMatch');
            this.popupComponent.showPopupError({text: text});
            return;
        }

        this.loaderService.show();
        this.contactInfoService.createUser(firstName, lastName, email, promo, password).subscribe(data => {
            this.loaderService.hide();

            try {
                if(data.status){
                    if (this.isMascot) {
                        this.userCreated.emit();
                    } else {
                        this.router.navigate(['/home/shop']);
                        // this.showInstallPopup();
                    }

                    this.auth.userLoggedStatusChanged.next(true);
                }else{
                    this.popupComponent.showPopupError({text: data.message});
                }
            } catch (e) {
                this.loaderService.hide();
                this.popupComponent.showPopupError();
            }


        }, (error) => {
            this.loaderService.hide();
            this.popupComponent.showPopupError();
        }, () => {
            this.loaderService.hide();
        });
    }

    onInput(control, $event) {
        this.contactInfo.get(control).patchValue( $event.target.value.trim(), {emitEvent: false} );
    }

    // showInstallPopup() {
    //     const pointHash = this.usersPointService.getHash();
    //     if (pointHash !== '' && (window.matchMedia('(display-mode: standalone)').matches || window.innerWidth < 1025)) {
    //     //if ((window.matchMedia('(display-mode: standalone)').matches || window.innerWidth < 1025)) {
    //         const $this = this;
    //         this.popupComponent.showPopupAgreement({text: '<button style="margin-bottom: 1rem; margin-top: 1rem" id="browserBtn" class="button snake-btn is-notouched">Browser<svg><rect></rect></svg></button>' +
    //               '<button id="installBtn" class="button snake-btn is-notouched">Install<svg><rect></rect></svg></button>' +
    //               '  <div id="iosInstall" style="display: none" class="info">\n' +
    //               '                <div class="info__pre">\n' +
    //               '                    <img src="../../../../assets/images/add-icon.svg" alt="Add to Home Screen icon">\n' +
    //               '                </div>\n' +
    //               '                <div class="info__body">\n' +
    //               `                    <p  id="infoText">Install this webapp on your device</p>\n` +
    //               '                    <p> tap (shared icon) <img src="../../../../assets/images/share-icon.svg" alt="Share icon"></p>\n' +
    //               '                    <p>and then Add to Home Screen.</p>\n' +
    //               '                </div>\n' +
    //               '            </div>',
    //                                                         closeBtn: false,
    //                                                         showConfirmButton: false,
    //                                                         confirmButtonText: 'Install<svg><rect></rect></svg>'},
    //                                                 (status) => {});
    //         document.getElementById('browserBtn').onclick = () => {
    //             this.useBrowser();
    //         }
    //         document.getElementById('installBtn').onclick = () => {
    //             this.install();
    //         }
    //     }
    // }
    //
    // install() {
    //     if (this.isIos() && !this.isInStandaloneMode()) {
    //         let userAgent = window.navigator.userAgent.toLowerCase();
    //         this.IOSInstallBtn = true;
    //         let device = 'device';
    //
    //         if  (/iphone/.test( userAgent )) {
    //             device = 'IPhone';
    //         } else if (/ipad/.test( userAgent )) {
    //             device = 'IPad';
    //         }
    //         this.ios_popup_text = `Install this webapp on your ${device}: `;
    //
    //         document.getElementById('iosInstall').style.display = 'flex';
    //         document.getElementById('installBtn').style.display = 'none';
    //
    //     } else {
    //         if (this.deferredPrompt) {
    //             this.deferredPrompt.prompt();
    //             this.deferredPrompt.userChoice.then(choiceResult => {
    //                 if (choiceResult.outcome === 'accepted') {
    //                     this.popupComponent.closePopup();
    //
    //                     this.popupComponent.showPopupSuccess({text: 'Launch the app from your device!'});
    //                 } else {
    //                     console.log('User dismissed the prompt');
    //                 }
    //                 //this.deferredPrompt = null;
    //             });
    //         }
    //
    //     }
    // }
    //
    // // Detects if device is in standalone mode
    // isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator['standalone']);
    // isIos(){
    //     let userAgent = window.navigator.userAgent.toLowerCase();
    //     return /iphone|ipad|ipod/.test( userAgent );
    // }
    //
    // isMobile(){
    //     if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
    //         return true
    //     }
    //     else{
    //         return false
    //     }
    // }
    //
    // useBrowser() {
    //     localStorage.useBrowser = true;
    //     this.popupComponent.closePopup();
    // }

    getActiveColor() {
        return "#FDFD88";
    }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nft-library.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../modules/language/language-translate.pipe";
import * as i4 from "../../modules/language/language.service";
import * as i5 from "../../pages/investments-nft-popup/investments-nft-popup.component.ngfactory";
import * as i6 from "../../pages/investments-nft-popup/investments-nft-popup.component";
import * as i7 from "../../service/loader.service";
import * as i8 from "../../modules/currency/currency.service";
import * as i9 from "../../service/cashapp-pay.service";
import * as i10 from "../popup/popup.component";
import * as i11 from "./nft-library.component";
import * as i12 from "../../service/copyToBuffer.service";
var styles_NftLibraryComponent = [i0.styles];
var RenderType_NftLibraryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NftLibraryComponent, data: {} });
export { RenderType_NftLibraryComponent as RenderType_NftLibraryComponent };
function View_NftLibraryComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", ""], ["class", "wallet__qr-code"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.nftData.qrCode; _ck(_v, 0, 0, currVal_0); }); }
function View_NftLibraryComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "wallet__info"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NftLibraryComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "wallet__content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "wallet__address"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "wallet__row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "button", [["class", "button button--default button--small"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.copyToBuffer() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "])), i1.ɵpid(0, i3.LanguageTranslatePipe, [i4.LanguageService])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.nftData.qrCode; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.nftData.wallet; _ck(_v, 5, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("copy")); _ck(_v, 8, 0, currVal_2); }); }
function View_NftLibraryComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["class", "slide library__slide js-library-slide"]], [[1, "data-id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", ""], ["class", "slide library__slide-img"], ["width", "100%"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "p", [["class", "library__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", " ", "%"])), i1.ɵpid(0, i3.LanguageTranslatePipe, [i4.LanguageService]), i1.ɵppd(5, 2)], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.uuid; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.image_link, ""); _ck(_v, 1, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("ownership")); var currVal_3 = i1.ɵunv(_v, 3, 1, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent.parent, 0), _v.context.$implicit.ownership, "1.0-2")); _ck(_v, 3, 0, currVal_2, currVal_3); }); }
function View_NftLibraryComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "dropdown__body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "library library--grid"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NftLibraryComponent_5)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.nftData.library; _ck(_v, 3, 0, currVal_0); }, null); }
function View_NftLibraryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "nft-library"]], [[2, "is-blurred", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "wallet"], ["style", "margin-bottom: 1rem;"]], [[2, "wallet--flex", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NftLibraryComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NftLibraryComponent_4)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.nftData.wallet; _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.nftData.library.length; _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showNFTPopup; _ck(_v, 0, 0, currVal_0); var currVal_1 = !_co.nftData.wallet; _ck(_v, 1, 0, currVal_1); }); }
function View_NftLibraryComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-investments-nft-popup", [], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.onPopupNFTClose($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_InvestmentsNftPopupComponent_0, i5.RenderType_InvestmentsNftPopupComponent)), i1.ɵdid(1, 9027584, null, 0, i6.InvestmentsNftPopupComponent, [i7.LoaderService, i4.LanguageService, i1.ChangeDetectorRef, i8.CurrencyService, i9.CashappPayService, i10.PopupComponent], { popupData: [0, "popupData"], popupType: [1, "popupType"] }, { close: "close" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.NFTPopupData.data; var currVal_1 = _co.NFTPopupData.type; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_NftLibraryComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DecimalPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NftLibraryComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NftLibraryComponent_6)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.nftData; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.showNFTPopup && _co.NFTPopupData); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_NftLibraryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-nft-library", [], null, null, null, View_NftLibraryComponent_0, RenderType_NftLibraryComponent)), i1.ɵdid(1, 114688, null, 0, i11.NftLibraryComponent, [i12.CopyToBufferService, i4.LanguageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NftLibraryComponentNgFactory = i1.ɵccf("app-nft-library", i11.NftLibraryComponent, View_NftLibraryComponent_Host_0, { nftData: "nftData", filter: "filter" }, { emitClick: "onClick" }, []);
export { NftLibraryComponentNgFactory as NftLibraryComponentNgFactory };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./partner.component";
import * as i3 from "../../service/loader.service";
import * as i4 from "../../service/partner.service";
var styles_PartnerComponent = [];
var RenderType_PartnerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PartnerComponent, data: {} });
export { RenderType_PartnerComponent as RenderType_PartnerComponent };
export function View_PartnerComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(1, 212992, null, 0, i1.RouterOutlet, [i1.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_PartnerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-partner", [], null, null, null, View_PartnerComponent_0, RenderType_PartnerComponent)), i0.ɵdid(1, 114688, null, 0, i2.PartnerComponent, [i3.LoaderService, i4.PartnerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PartnerComponentNgFactory = i0.ɵccf("app-partner", i2.PartnerComponent, View_PartnerComponent_Host_0, {}, {}, []);
export { PartnerComponentNgFactory as PartnerComponentNgFactory };

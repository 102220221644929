<div class="investments-popup investments-popup--balance" [class.is-active]="popupInfo.show">
    <div class="investments-popup__inner">
        <button class="investments-popup__close" (click)="closePopup()"></button>
        <div class="balance-popup">
            <div class="balance-popup__content">
                <h5 style="text-transform: uppercase;">{{popupInfo.gameVendorName}} {{'sweeps' | languageTranslate}}</h5>

                <div class="balance-popup__text" *ngIf="popupInfo.gameVendor === GAME_VENDORS_GOLDENDRAGON">
<!--                    Your mobile ID has been copied! Please paste it on the next screen to continue.-->
                    {{'messagesData' | languageTranslate: [{$innerVariable: 'mobileIdIsCopied'}]}}
                </div>
                <div class="balance-popup__text" *ngIf="popupInfo.gameVendor === GAME_VENDORS_RIVER">
<!--                    Your account number is copied! Paste it during River login. Download the River app at-->
                    {{'messagesData' | languageTranslate: [{$innerVariable: 'accountNumberIdIsCopied'}]}}
                    <a class="balance-popup__link" href="https://river777.com">https://river777.com</a>
                </div>
                <div class="balance-popup__balance">
                    {{('availableEntries' | languageTranslate).toUpperCase()}} {{formatCurrency(popupInfo.availableBalance)}}
                </div>
                <div class="balance-popup__form">
                    <form [formGroup]="balanceForm"
                          (ngSubmit)="onSubmitBalanceForm()"
                          class="form-simple form-simple--shrink form-simple--primary">

                        <div class="form-simple__item form-simple__item--amount" *ngIf="popupInfo.availableBalance">
                            <input mask="separator.0"
                                   thousandSeparator=","
                                   (input)="onInputAmount()"
                                   formControlName="amount"
                                   placeholder="{{'formData' | languageTranslate: [{$innerVariable: 'amount'}]}}"
                                   [type]="'tel'"
                                   class="form-simple__input"
                                   autocomplete="off">
                            <span>.00</span>
                            <div class="form-simple__available" [class.is-active]="moreThenAvailablePayout">
                                {{'available' | languageTranslate}} {{formatCurrency(popupInfo.availableBalance)}}
                            </div>
                        </div>

                        <div class="form-simple__btns" *ngIf="popupInfo.availableBalance">
                            <button class="button"
                                    [disabled]="+balanceForm.controls.amount.value <= 0">
                                {{'formData' | languageTranslate: [{$innerVariable: 'add'}]}}
                            </button>
                        </div>
                        <div class="form-simple__btns" *ngIf="!popupInfo.availableBalance">
                            <a [routerLink]="ROUTER_PATHS.checkout" class="button">
                                {{'formData' | languageTranslate: [{$innerVariable: 'addFunds'}]}}
                            </a>
                        </div>
                        <div class="form-simple__btns" *ngIf="!popupInfo.availableBalance">
                            <a [routerLink]="ROUTER_PATHS.checkout" [queryParams]="{part: 'addCard'}" class="button">
                                {{'addCard' | languageTranslate}}
                            </a>
                        </div>
                    </form>
                </div>
                <div class="balance-popup__text">
                    {{'askAddDesiredSweepsEntries' | languageTranslate}}
                </div>

                <div *ngIf="popupInfo.userPin && popupInfo.gameVendor === GAME_VENDORS_RIVER"
                     id="rewards_code"
                     class="shop__game-info">
                    {{popupInfo.userPin}}
                </div>

                <div *ngIf="popupInfo.gameVendor === GAME_VENDORS_GOLDENDRAGON" class="shop__game-info">
                    <div class="shop__game-row">
                        <div class="shop__game-col">{{'mobileId' | languageTranslate}}:
                        </div>
                        <div class="shop__game-col">
                            {{popupInfo.gd_mobile_id}}
                        </div>
                    </div>

                    <div class="shop__game-row">
                        <div class="shop__game-col">
                            {{'formData' | languageTranslate: [{$innerVariable: 'password'}]}}:
                        </div>
                        <div class="shop__game-col">
                            {{popupInfo.gd_password}}
                        </div>
                    </div>
                </div>

                <div *ngIf="isGameWithAccountAndPassword()" class="shop__game-info">
                    <div class="shop__game-row">
                        <div class="shop__game-col">{{'account' | languageTranslate}}:
                        </div>
                        <div class="shop__game-col">
                            {{popupInfo.game_account}}
                        </div>
                    </div>

                    <div class="shop__game-row">
                        <div class="shop__game-col">
                            {{'formData' | languageTranslate: [{$innerVariable: 'password'}]}}:
                        </div>
                        <div class="shop__game-col">
                            {{popupInfo.game_password}}
                        </div>
                    </div>
                </div>

                <div class="balance-popup__text" *ngIf="showChangingPasswordText()">
                    {{'ifChangePasswordResult' | languageTranslate}}
                </div>

                <div class="balance-popup__footer">
                    <button class="button balance-popup__sweep-btn" type="button" (click)="sweepGame()">
                        {{'sweepsRewards' | languageTranslate}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

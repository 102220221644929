import { ComponentFactory, ComponentFactoryResolver } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { PopupVerifyPhoneComponent } from "../shared/popup-verify-phone/popup-verify-phone.component";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../shared/popup/popup.component";
import * as i3 from "./account.service";
import * as i4 from "./loader.service";
var PhoneVerifyService = /** @class */ (function () {
    function PhoneVerifyService(http, popupComponent, accountService, resolver, loaderService) {
        this.http = http;
        this.popupComponent = popupComponent;
        this.accountService = accountService;
        this.resolver = resolver;
        this.loaderService = loaderService;
        this.showModal = new BehaviorSubject({ show: false });
        this.repeatFunction = function () { };
    }
    PhoneVerifyService.prototype.resetRepeatFunction = function () {
        this.repeatFunction = function () { };
    };
    PhoneVerifyService.prototype.showGeneratePopup = function (container) {
        var _this = this;
        this.accountService.onRequestVerifyPhone().subscribe(function (data) {
            try {
                if (data.status) {
                    container.clear();
                    var factory = _this.resolver.resolveComponentFactory(PopupVerifyPhoneComponent);
                    var popup = container.createComponent(factory);
                    popup.instance.popupInfo.show = true;
                    popup.instance.closePopupSuccess = function () {
                        container.clear();
                        _this.repeatFunction();
                        _this.resetPopup();
                    };
                    popup.instance.closePopup = function () {
                        container.clear();
                        _this.resetPopup();
                    };
                }
                else {
                    _this.popupComponent.showPopupError({ text: data.message });
                }
            }
            catch (e) {
                _this.loaderService.hideAndShowTryAgain();
            }
        }, function () {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
        });
    };
    PhoneVerifyService.prototype.showPopup = function () {
        this.showModal.next({ show: true });
    };
    PhoneVerifyService.prototype.resetPopup = function () {
        this.showModal.next({ show: false });
    };
    PhoneVerifyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PhoneVerifyService_Factory() { return new PhoneVerifyService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.PopupComponent), i0.ɵɵinject(i3.AccountService), i0.ɵɵinject(i0.ComponentFactoryResolver), i0.ɵɵinject(i4.LoaderService)); }, token: PhoneVerifyService, providedIn: "root" });
    return PhoneVerifyService;
}());
export { PhoneVerifyService };

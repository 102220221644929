import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";

import {LoaderService} from "./loader.service";
import {SHOP_CONFIG} from "../config/api";


@Injectable({
    providedIn: 'root'
})
export class ShopService {
    private config = SHOP_CONFIG;

    constructor(private http: HttpClient, public loaderService: LoaderService) {
    }

    getPageInfo(game_short_name = null, only_balance = false) {
        this.loaderService.show();
        return this.http.post<any>(this.config.getPageInfo, {game_short_name, only_balance});
    }

    onActivate(code) {
        this.loaderService.show();
        return this.http.post<any>(this.config.onActivate, {code});
    }

    showSellCryptoPopup() {
        this.loaderService.show();
        return this.http.post<any>(this.config.showSellCryptoPopup, {});
    }

    sellCryptoEstimate(amount) {
        this.loaderService.show();
        return this.http.post<any>(this.config.sellCryptoEstimate, {amount});
    }

    onSellCryptoRequest(amount, password) {
        this.loaderService.show();
        return this.http.post<any>(this.config.onSellCryptoRequest, {amount, password});
    }

    onSellGiftCards(id, code, pin, amount) {
        this.loaderService.show();
        return this.http.post<any>(this.config.onSellGiftCards, {id, code, pin, amount});
    }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./senetpro.component";
import * as i2 from "@angular/forms";
import * as i3 from "../../../service/loader.service";
import * as i4 from "../../../shared/popup/popup.component";
import * as i5 from "../../../modules/auth/_services/authentication.service";
import * as i6 from "../../../service/partner.service";
import * as i7 from "@angular/router";
var styles_SenetproComponent = [];
var RenderType_SenetproComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SenetproComponent, data: {} });
export { RenderType_SenetproComponent as RenderType_SenetproComponent };
export function View_SenetproComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_SenetproComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-senetpro", [], null, null, null, View_SenetproComponent_0, RenderType_SenetproComponent)), i0.ɵdid(1, 114688, null, 0, i1.SenetproComponent, [i2.FormBuilder, i3.LoaderService, i4.PopupComponent, i5.AuthenticationService, i6.PartnerService, i7.Router, i7.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SenetproComponentNgFactory = i0.ɵccf("app-senetpro", i1.SenetproComponent, View_SenetproComponent_Host_0, {}, {}, []);
export { SenetproComponentNgFactory as SenetproComponentNgFactory };

import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {TERMS_AND_CONDITIONS_CONFIG} from '../../config/api';
import {LoaderService} from '../loader.service';


@Injectable({
    providedIn: 'root'
})
export class TermsAndConditionsService {
    private config = TERMS_AND_CONDITIONS_CONFIG;

    constructor(private http: HttpClient, public loaderService: LoaderService) {
    }

    getPageInfo() {
        this.loaderService.show();
        return this.http.post<any>(this.config.getPageInfo, {});
    }
}

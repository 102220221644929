import {Inject, Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {EMPTY, Observable, of, throwError} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {AuthConfig} from '../_services/auth-config';
import {AuthenticationService} from "../_services/authentication.service";
import {LoaderService} from "../../../service/loader.service";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {WalletService} from "../../../service/wallet.service";
import {PointService} from "../../../service/point.service";
import {environment} from "../../../../environments/environment";
import {ReferralBonusService} from "../../../service/referralBonus.service";
import {LanguageService} from "../../language/language.service";
import {AUTH_CODES} from "../../../config/auth_codes";
import {PhoneVerifyService} from "../../../service/phoneVerify.service";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  hasError = false;
  refreshingToken = false;

  constructor(@Inject(AuthConfig) private authConfig: AuthConfig,
              private authenticationService: AuthenticationService,
              private loaderService: LoaderService,
              private languageService: LanguageService,
              private walletService: WalletService,
              private phoneVerifyService: PhoneVerifyService,
              private referralBonusService: ReferralBonusService,
              private pointService: PointService,
              private popupComponent: PopupComponent,
              private router: Router) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // ////console.log(request);
    // ////console.log(next);
    if (this.authConfig.login === request.url) {
      this.hasError = false;
      this.refreshingToken = false;
    }

    while (this.refreshingToken) {

    }
    if (this.hasError && this.authConfig.login !== request.url) {
      return null;
    }
    let storage = sessionStorage;
    // add authorization header with jwt token if available
    const currentUser = JSON.parse(storage.getItem('currentUser'));
    // ////console.log(request.url);

      request = request.clone({
          setHeaders: {
              Partner: `${window.location.hostname}`
          }
      });

    if (currentUser && currentUser.token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.token}`
        }
      });
    }

    if (!environment.frontEndTest) {
      if (this.pointService.getPointInfo()) {
        request = request.clone({
          setHeaders: {
            Pointid: this.pointService.getPointInfo('point_id')
          }
        });
      }
      request = request.clone({
        setHeaders: {
          Language: this.languageService.getActiveLanguage()
        }
      });
    } else {
      console.log('setHeadersLanguage', this.languageService.getActiveLanguage());
      // if (this.pointService.getPointInfo()) {
      //   console.log(this.pointService.getPointInfo());
      //   console.log(this.pointService.getPointInfo('point_id'))
      // }
    }


    // if (this.authConfig.refresh === request.url) {
    //   this.refreshingToken = true;
    // }

    return next.handle(request).pipe(
      map((data:any) => {
          if (data.body && data.body.codeCryptoWallet) {
            this.walletService.showPopup(data.body.codeCryptoWallet);
            return EMPTY;
          }
          if (data.body && data.body.code && (data.body.code == AUTH_CODES.phoneNoVerified)) {
            this.phoneVerifyService.showPopup();
            return EMPTY;
          }
          return data;
        }
      ),
      tap( // Log the result or error
          (data:any) => {
            try{
                if(data != undefined) {
                    if (data.body) {
                        if (data.body.referralBonusStatus || data.body.referralBonusStatus === 0) {
                          this.referralBonusService.setReferralBonusStatus(data.body.referralBonusStatus);
                        }

                        if (data.body.code) {
                            if (data.body.code == 401) {

                                this.authenticationService.logout();
                                this.router.navigate(['/'], {queryParams: {notLogged: true}});
                                //this.router.navigate([this.authConfig.login], {queryParams: {returnUrl: ""}});
                            }
                        }
                    }
                }
            }catch (e) {

            }

          // if (this.authConfig.refresh === request.url) {
          //   this.refreshingToken = false;
          // }
        },
        error => {
            this.loaderService.hide();
            this.authenticationService.logout();
            this.router.navigate(['/'], {queryParams: {notLogged: true}});
        }
      )
    );
  }
}

import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./loader.service";
var RequestService = /** @class */ (function () {
    function RequestService(http, loaderService) {
        this.http = http;
        this.loaderService = loaderService;
    }
    RequestService.prototype.request = function (url, data, loader) {
        if (loader === void 0) { loader = true; }
        if (loader) {
            this.loaderService.show();
        }
        return this.http.post(url, data);
    };
    RequestService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RequestService_Factory() { return new RequestService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: RequestService, providedIn: "root" });
    return RequestService;
}());
export { RequestService };

import {
    Component,
    OnInit, Injectable
} from '@angular/core';
import {PopupComponent} from "../../shared/popup/popup.component";
import {LoaderService} from "../../service/loader.service";
import {ActivatedRoute, Router} from "@angular/router";
import {LanguageService} from "../../modules/language/language.service";
import {AccountService} from "../../service/account.service";
import {ContentDisplayingService} from "../../service/content-displaying.service";
import {PartnerService} from "../../service/partner.service";

@Component({
    selector: 'app-verify-email',
    templateUrl: './verify-email.component.html',
    styleUrls: ['./verify-email.component.scss'],
})
@Injectable({providedIn: 'root'})
export class VerifyEmailComponent implements OnInit {
    verifyResultMessage = '';

    constructor(
      private loaderService: LoaderService,
      private router: Router,
      private languageService: LanguageService,
      private activatedRoute: ActivatedRoute,
      private contentDisplayingService: ContentDisplayingService,
      private partnerService: PartnerService,
      private accountService: AccountService,
      private popupComponent: PopupComponent) {

        this.popupComponent.closePopup();
        document.body.classList.remove('hide-menu');
    }

    ngOnInit() {
        this.onVerify();
    }

    onVerify() {
        const code = this.activatedRoute.snapshot.queryParams.code;
        const email = this.activatedRoute.snapshot.queryParams.email;

        if (code && email) {
            this.accountService.onVerifyEmail(code, email).subscribe(data => {
                try {
                    this.verifyResultMessage = data.message;
                } catch (e) {
                    this.loaderService.hideAndShowTryAgain();
                }
            }, () => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    getPartnerLogo() {
        return this.partnerService.getPartnerLogo() ? this.partnerService.getPartnerLogo() : '../assets/images/logo.png';    }

    goBackToLogin() {
        this.router.navigate(['./']);
    }
}

export const GAME_VENDORS = {
  river: 'river',
  mascot: 'mascot',
  mascotCard: 'baccarat',
  mascotKeno: 'keno',
  mascotCasino: 'mascot_casino',
  goldenDragon: 'gd',
  ebox: 'ebox',
  senet: 'senet',
  firekirin: 'firekirin',
  puma: 'panda',
  juwa: 'juwa',
  vblink: 'vblink',
  ultrapanda: 'ultrapanda'
};

export const GAME_VENDOR_LINK = {
  river: 'https://river777.net',
  goldenDragon: 'https://web.goldendragoncity.com',
  firekirin: 'http://play.firekirin.xyz',
  puma: 'http://play.pandamaster.vip',
  juwa: 'https://juwa777.com/play',
  vblink: 'https://www.vblink777.club',
  ultrapanda: 'https://www.ultrapanda.mobi'
};

export const GAME_VENDORS_RIVER = GAME_VENDORS.river;
export const GAME_VENDORS_MASCOT = GAME_VENDORS.mascot;
export const GAME_VENDORS_MASCOT_CARD = GAME_VENDORS.mascotCard;
export const GAME_VENDORS_MASCOT_KENO = GAME_VENDORS.mascotKeno;
export const GAME_VENDORS_MASCOTCASINO = GAME_VENDORS.mascotCasino;
export const GAME_VENDORS_GOLDENDRAGON = GAME_VENDORS.goldenDragon;
export const GAME_VENDORS_FIREKIRIN = GAME_VENDORS.firekirin;
export const GAME_VENDORS_PUMA = GAME_VENDORS.puma;
export const GAME_VENDORS_JUWA = GAME_VENDORS.juwa;
export const GAME_VENDORS_EBOX = GAME_VENDORS.ebox;
export const GAME_VENDORS_SENET = GAME_VENDORS.senet;
export const GAME_VENDORS_VBLINK = GAME_VENDORS.vblink;
export const GAME_VENDORS_ULTRAPANDA = GAME_VENDORS.ultrapanda;

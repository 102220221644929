import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CopyToBufferService} from "../../service/copyToBuffer.service";
import {NFT_POPUP_TYPE} from "../../pages/investments-nft-popup/investments-nft-popup.config";
import {LanguageService} from "../../modules/language/language.service";

@Component({
    selector: 'app-nft-library',
    templateUrl: './nft-library.component.html',
    styleUrls: ['./nft-library.component.scss']
})
export class NftLibraryComponent implements OnInit {

    @Input() nftData;

    @Input() filter: boolean;
    @Output('onClick')
    emitClick: EventEmitter<any> = new EventEmitter();
    showNFTPopup = false;
    NFTPopupData;

    constructor(private copyToBufferService: CopyToBufferService,
                private languageService: LanguageService) {
    }

    ngOnInit() {
        document.body.addEventListener( 'click', ( event ) => {
            if ((event.target as HTMLElement).classList.contains('js-library-slide')) {
                const id = (<HTMLElement>event.target).getAttribute('data-id');
                const slideInfo = this.nftData.library.find(el => el.uuid === id);

                if (slideInfo) {
                    this.onShowNFTPopup(slideInfo);
                }
            }
        } );
    }

    handleClick() {
        this.emitClick.emit();
    }

    copyToBuffer() {
        const value = this.nftData.wallet;
        const message = this.languageService.getString('messagesData', 'walletAddressCopied');

        this.copyToBufferService.copyToBuffer(value, true, message);
    }

    onShowNFTPopup(slide) {
        console.log('gggg');
        console.log(slide);
        document.querySelector('.login__forms').classList.add('blur');
        this.showNFTPopup = true;
        this.NFTPopupData = {
            type: NFT_POPUP_TYPE.info,
            data: {
                element: slide
            }
        }
    }

    onPopupNFTClose(needReload) {
        this.showNFTPopup = false;
        document.querySelector('.login__forms').classList.remove('blur');
        if (needReload) {
            //this.getData();
        }
    }

    generateWallet() {

    }

}

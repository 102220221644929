<div *ngIf="popupData"
     class="investments-popup investments-popup--nft-add-info"
     [class.is-active]="popupData">
    <div class="investments-popup__inner">
        <button class="investments-popup__close" (click)="closePopup()">
        </button>

        <div class="nft-info">
            <img class="nft-info__img"
                 [src]="popupData.image_full ? popupData.image_full : popupData.image_link"
                 alt=""
                 >
        </div>
    </div>
</div>

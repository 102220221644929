<form *ngIf="!cashappVerified && !isPaypal && !isVenmo" [formGroup]="cashappForm" (ngSubmit)="onSubmitCashappForm()" class="form-simple form-simple--primary">
    <div class="form-simple__item">
        <input formControlName="address"
               placeholder="{{'enter' | languageTranslate}} {{'cashApp' | languageTranslate}} {{'formData' | languageTranslate: [{$innerVariable: 'address'}]}}"
               [type]="'text'"
               class="form-simple__input"
               autocomplete="off">
    </div>

    <div class="form-simple__item">
        <input formControlName="retype"
               placeholder="{{'retype' | languageTranslate}} {{'cashApp' | languageTranslate}} {{'formData' | languageTranslate: [{$innerVariable: 'address'}]}}"
               [type]="'text'"
               class="form-simple__input"
               autocomplete="off">
    </div>

    <!--<h4 class="form-simple__subtitle">For your security only one cashapp address is accepted per active account</h4>-->

    <div class="form-simple__btns">
        <button
                class="navigation__button button"
                [disabled]="cashappForm.invalid || cashappForm.controls.address.value !== cashappForm.controls.retype.value">
            {{'verify' | languageTranslate}} {{'formData' | languageTranslate: [{$innerVariable: 'address'}]}}
        </button>
    </div>
</form>

<form *ngIf="!cashappVerified && isPaypal && !isVenmo" [formGroup]="paypalForm" (ngSubmit)="onSubmitPaypalForm()" class="form-simple form-simple--primary">
    <div class="form-simple__item">
        <input formControlName="email"
               placeholder="{{'formData' | languageTranslate: [{$innerVariable: 'email'}]}}"
               [type]="'text'"
               email="true"
               class="form-simple__input"
               autocomplete="off">
    </div>

    <div class="form-simple__item">
        <input formControlName="retype"
               placeholder="{{'retype' | languageTranslate}} {{'formData' | languageTranslate: [{$innerVariable: 'email'}]}}"
               [type]="'text'"
               email="true"
               class="form-simple__input"
               autocomplete="off">
    </div>

    <!--<h4 class="form-simple__subtitle">For your security only one email address is accepted per active account</h4>-->

    <div class="form-simple__btns">
        <button
                class="navigation__button button"
                [disabled]="paypalForm.invalid || paypalForm.controls.email.value !== paypalForm.controls.retype.value">
            {{'verify' | languageTranslate}} {{'formData' | languageTranslate: [{$innerVariable: 'email'}]}}
        </button>
    </div>
</form>

<form *ngIf="!cashappVerified && !isPaypal && isVenmo" [formGroup]="venmoForm" (ngSubmit)="onSubmitVenmoForm()" class="form-simple form-simple--primary">
    <ng-container *ngIf="!isVenmoPhone">
        <div class="form-simple__item">
            <input formControlName="address"
                   placeholder="{{'enter' | languageTranslate}} Venmo {{'formData' | languageTranslate: [{$innerVariable: 'address'}]}}"
                   [type]="'text'"
                   class="form-simple__input"
                   autocomplete="off">
        </div>

        <div class="form-simple__item">
            <input formControlName="retype"
                   placeholder="{{'retype' | languageTranslate}} Venmo {{'formData' | languageTranslate: [{$innerVariable: 'address'}]}}"
                   [type]="'text'"
                   class="form-simple__input"
                   autocomplete="off">
        </div>
    </ng-container>

    <ng-container *ngIf="isVenmoPhone">
        <div class="form-simple__item">
            <input formControlName="address"
                   placeholder="{{'phoneNumber' | languageTranslate}}"
                   [mask]="phoneMask"
                   [type]="'tel'"
                   class="form-simple__input"
                   autocomplete="off">
        </div>

        <div class="form-simple__item">
            <input formControlName="retype"
                   placeholder="{{'retype' | languageTranslate}} {{'phoneNumber' | languageTranslate}}"
                   [mask]="phoneMask"
                   [type]="'tel'"
                   class="form-simple__input"
                   autocomplete="off">
        </div>
    </ng-container>


    <!--<h4 class="form-simple__subtitle">For your security only one email address is accepted per active account</h4>-->

    <div class="form-simple__btns">
        <button
                class="navigation__button button"
                [disabled]="venmoForm.invalid || venmoForm.controls.address.value !== venmoForm.controls.retype.value">
            {{'verify' | languageTranslate}} {{'formData' | languageTranslate: [{$innerVariable: 'address'}]}}
        </button>
    </div>
</form>

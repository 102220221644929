import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";

import {LoaderService} from "./loader.service";
import {PERKS_CONFIG} from "../config/api";


@Injectable({
    providedIn: 'root'
})
export class PerksService {
    private config = PERKS_CONFIG;

    constructor(private http: HttpClient, public loaderService: LoaderService) {
    }

    getPageInfo(page, singleHistoryPage) {
        this.loaderService.show();
        return this.http.post<any>(this.config.getPageInfo, {page, singleHistoryPage});
    }

    getGameBalance(id) {
        this.loaderService.show();
        return this.http.post<any>(this.config.getGameBalance, {id});
    }

    redeem(gameProviderId, amount) {
        this.loaderService.show();
        return this.http.post<any>(this.config.redeem, {gameProviderId, amount});
    }
}

import { PHONE_MASK } from "../config/country_phone_mask";
import { COUNTRIES_SHORT } from "../config/countries.config";
import * as i0 from "@angular/core";
var PhoneMaskService = /** @class */ (function () {
    function PhoneMaskService() {
        this.PHONE_MASK_CONFIG = PHONE_MASK;
        this.country = localStorage.country ? localStorage.country : 'us';
    }
    PhoneMaskService.prototype.setPhoneMask = function () {
        if (this.country && this.PHONE_MASK_CONFIG[this.country]) {
            return this.PHONE_MASK_CONFIG[this.country];
        }
        return this.PHONE_MASK_CONFIG[COUNTRIES_SHORT.usa];
    };
    PhoneMaskService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PhoneMaskService_Factory() { return new PhoneMaskService(); }, token: PhoneMaskService, providedIn: "root" });
    return PhoneMaskService;
}());
export { PhoneMaskService };

<div class="iframe-popup">
    <div class="iframe-popup__inner">
        <div class="iframe-popup__wrapper" #iframeParent>
            <button class="iframe-popup__close" (click)="closePopup()"></button>
            <div class="transaction-processing">
                <div class="transaction-processing__text">
                    {{'messagesData' | languageTranslate: [{$innerVariable: 'transactionInProcessing'}]}}
                </div>
                <div class="transaction-processing__loader"><app-ellipsis-loader></app-ellipsis-loader></div>
            </div>
        </div>
    </div>
</div>

import { ACCOUNT_CONFIG } from "../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./loader.service";
var AccountService = /** @class */ (function () {
    function AccountService(http, loaderService) {
        this.http = http;
        this.loaderService = loaderService;
        this.config = ACCOUNT_CONFIG;
    }
    AccountService.prototype.getPageInfo = function (page, pageAi, pageNft, singleHistoryPage, singleAiHistoryPage, singleNftHistoryPage) {
        return this.http.post(this.config.getPageInfo, { page: page, pageAi: pageAi, pageNft: pageNft, singleHistoryPage: singleHistoryPage, singleAiHistoryPage: singleAiHistoryPage, singleNftHistoryPage: singleNftHistoryPage });
    };
    AccountService.prototype.onRevealPrivateKey = function (password) {
        this.loaderService.show();
        return this.http.post(this.config.onRevealPrivateKey, { password: password });
    };
    AccountService.prototype.onReceiveCrypto = function () {
        this.loaderService.show();
        return this.http.post(this.config.onReceiveCrypto, {});
    };
    AccountService.prototype.onSendCrypto = function (amount, address, password) {
        this.loaderService.show();
        return this.http.post(this.config.onSendCrypto, { amount: amount, address: address, password: password });
    };
    AccountService.prototype.onSaveContacts = function (firstName, lastName, address, notifyText, notifyEmail) {
        this.loaderService.show();
        return this.http.post(this.config.onSaveContacts, { firstName: firstName, lastName: lastName, address: address, notifyText: notifyText, notifyEmail: notifyEmail });
    };
    AccountService.prototype.onRequestVerifyEmail = function () {
        this.loaderService.show();
        return this.http.post(this.config.requestVerifyEmail, {});
    };
    AccountService.prototype.onChangeEmail = function (email, password) {
        this.loaderService.show();
        return this.http.post(this.config.onChangeEmail, { email: email, password: password });
    };
    AccountService.prototype.onRequestVerifyPhone = function () {
        this.loaderService.show();
        return this.http.get(this.config.requestVerifyPhone, {});
    };
    AccountService.prototype.onChangePhone = function (phone, password) {
        this.loaderService.show();
        return this.http.post(this.config.onChangePhone, { phone: phone, password: password });
    };
    AccountService.prototype.onVerifyPhone = function (pin) {
        this.loaderService.show();
        return this.http.post(this.config.onVerifyPhone, { pin: pin });
    };
    AccountService.prototype.onVerifyEmail = function (code, email) {
        this.loaderService.show();
        return this.http.post(this.config.onVerifyEmail, { code: code, email: email });
    };
    AccountService.prototype.onChangePassword = function (passwordOld, password) {
        this.loaderService.show();
        return this.http.post(this.config.onChangePassword, { passwordOld: passwordOld, password: password });
    };
    AccountService.prototype.onChangePin = function (pin, password) {
        this.loaderService.show();
        return this.http.post(this.config.onChangePin, { pin: pin, password: password });
    };
    AccountService.prototype.onLibraryImageStatusChange = function (uuid, status) {
        // this.loaderService.show();
        return this.http.post(this.config.onLibraryImageStatusChange, { uuid: uuid, status: status });
    };
    AccountService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AccountService_Factory() { return new AccountService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: AccountService, providedIn: "root" });
    return AccountService;
}());
export { AccountService };

import {Component, HostListener, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {LoginService} from "./service/login.service";
import {NavigationEnd, NavigationStart, Router} from "@angular/router";
import {AuthenticationService} from "./modules/auth/_services/authentication.service";
import {PopupComponent} from "./shared/popup/popup.component";
import {fromEvent} from "rxjs";
import {WalletService} from "./service/wallet.service";
import {AppVersionService} from './service/app-version/appVersion.service';
import NoSleep from 'nosleep.js';
import {LanguageService} from "./modules/language/language.service";
import {CountryService} from "./modules/country/country.service";
import {COUNTRIES_LANG} from "./config/countries-lang.config";
import {PhoneVerifyService} from "./service/phoneVerify.service";
import {ContentDisplayingService} from "./service/content-displaying.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    login = false;
    isConnected = true;
    noInternetConnection = false;
    canListen = false;

    @HostListener('click', ['$event'])
    clickInside(event) {
        if  (this.canListen) {
            const snakeBtns = document.querySelectorAll('.is-notouched');
            snakeBtns.forEach(el => {
                el.classList.remove('is-notouched');
            });
        };
    }

    @ViewChild("popupGenerate", { read: ViewContainerRef, static: false }) popupGenerateContainer: ViewContainerRef;
    noSleep;
    constructor(private router: Router,
                private authenticationService: AuthenticationService,
                private languageService: LanguageService,
                private countryService: CountryService,
                private walletService: WalletService,
                private phoneVerifyService: PhoneVerifyService,
                private contentDisplayingService: ContentDisplayingService,
                private popupComponent: PopupComponent,
                private appVersionService: AppVersionService,
                private loginService: LoginService) {
        fromEvent(window, 'online').subscribe((resp: any) => {
            this.isConnected = true;
            this.noInternetConnection = false;
            this.popupComponent.closePopup();
        });

        fromEvent(window, 'offline').subscribe((resp: any) => {
            this.isConnected = false;
            this.noInternetConnection = true;
            const text = this.languageService.getString('messagesData', 'noInternetConnection');
            this.popupComponent.showPopupError({text: text, closeBtn: false})
        });

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                //console.log('Navigation Start')
                this.canListen = false;
            }

            if (event instanceof NavigationEnd) {
                //console.log('Navigation End')

                const snakeBtns = document.querySelectorAll('.snake-btn');
                snakeBtns.forEach(el => {
                    (<HTMLElement>el).classList.add('is-notouched');
                });

                if (document.querySelector('.login__inner')) {
                    document.querySelector('.login__inner').scrollTop = 0;
                }
                if (document.querySelector('.login__content')) {
                    document.querySelector('.login__content').scrollTop = 0;
                }

                // window.scrollTo(0,0);

                setTimeout(()  => {
                    this.canListen = true;
                }, 0);

                this.appHeight();
            }
        });

        this.walletService.showModal.subscribe(val => {
            if (val.show) {
                const code = val.code;
                this.walletService.showGeneratePopup(this.popupGenerateContainer, code);
            }
        });

        this.phoneVerifyService.showModal.subscribe(val => {
            if (val.show) {
                this.phoneVerifyService.showGeneratePopup(this.popupGenerateContainer);
            }
        });

        this.languageService.requestNewLanguage.subscribe(val => {
            if (val) {
                this.changeLanguage(val);
            }
        })
    }

    ngOnInit() {
        // this.loginService.loginChanged.subscribe((value: any) => this.loginChanged(value));
        if (this.isIos()) {
            document.body.classList.add('is-ios');
        }
        window.addEventListener('resize', this.appHeight);
        this.appHeight();

        this.refreshToken();
        this.appVersion();

        this.setNoScreenLock();
        this.detectLanguage();
    }

    detectLanguage() {
        const country = this.countryService.detectCountry();
        let defaultLang = '';
        let countryLang = '';

        Object.keys(COUNTRIES_LANG).forEach(lang => {
            COUNTRIES_LANG[lang].forEach(contr => {
                if (contr === 'default') {
                    defaultLang = lang;
                }
                if (contr === country) {
                    countryLang = lang;
                }
            });
        });

        const lang = countryLang ? countryLang : defaultLang;
        localStorage.setItem('country', country);

        this.changeLanguage(lang);
    }

    changeLanguage(lang) {
        this.languageService.setActiveLanguage(lang);
        localStorage.setItem('activeLanguage', lang);
    }

    ngOnDestroy() {}

    loginChanged(status){
        this.login = status;
    }

    appVersion(){
        this.appVersionService.checkVersion();
    }

    refreshToken() {
        this.authenticationService.refreshToken();
    }

    isIos(){
        let userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test( userAgent );
    }

    appHeight() {
        const doc = document.documentElement;
        doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }

    // Detects if device is in standalone mode
    isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator['standalone']);

    setNoScreenLock() {
        this.noSleep = new NoSleep();
        const $this = this;
        document.addEventListener('click', function enableNoSleep() {
            document.removeEventListener('click', enableNoSleep, false);
            $this.noSleep.enable(); // on
            // $this.noSleep.disable(); off
        }, false);
    }

    setCorrectThumbnailIcon() {
        if (!this.contentDisplayingService.showAdditionContent()) {
            this.changeThumbnailIcons('ratechapp');
            // if (this.contentDisplayingService.raTechappSenetReplacementText()) {
            //     document.querySelector('title').innerHTML = this.contentDisplayingService.raTechappSenetReplacementText();
            // }
        }
    }

    changeThumbnailIcon(selector, icon, domain) {
        const defaultIcon = document.querySelector(selector).getAttribute('href');
        const newIcon = defaultIcon.split('/icons')[0] + `/domains/${domain}/icons/${icon}`;
        document.querySelector(selector).setAttribute('href', newIcon);
    }

    changeThumbnailIcons(domain) {
        this.changeThumbnailIcon('link[rel="icon"]', 'icon-72.png', domain);
        this.changeThumbnailIcon('link[rel="apple-touch-icon"][sizes="180x180"]', 'icon-180.png', domain);
        this.changeThumbnailIcon('link[rel="apple-touch-icon"][sizes="120x120"]', 'icon-120.png', domain);
    }
}

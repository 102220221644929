import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {LoaderService} from "../../service/loader.service";
import {PaginationDataModel, SetPaginationDataModel} from "../../models/scrollPagination/paginationData.model";
import {PaginationData} from "../../models/scrollPagination/paginationData";
import {PaginationScrollService} from "../../service/pageScroll/paginationScroll.service";
import {PopupComponent} from "../popup/popup.component";
import {SenetproTransactionsModel, TRANSACTIONS_LIST_TYPE} from "../senetpro-transactions/senetpro-transactions.model";
import {PerksService} from "../../service/perks.service";
import {AccountService} from "../../service/account.service";
import {POPUP_MORE_IMAGES_TYPE} from "./popup-more-images.config";
import {NFT_POPUP_TYPE} from "../../pages/investments-nft-popup/investments-nft-popup.config";

@Component({
    selector: 'app-popup-more-images',
    templateUrl: './popup-more-images.component.html',
    styleUrls: ['./popup-more-images.component.scss']
})
export class PopupMoreImagesComponent implements OnInit, OnChanges {
    @Input() popupInfo;
    @Input() type;
    @Output() close: EventEmitter<boolean> = new EventEmitter();
    @Output() libraryImageCheckboxChange: EventEmitter<any> = new EventEmitter();

    imagesData: PaginationDataModel;
    POPUP_MORE_IMAGES_TYPE = POPUP_MORE_IMAGES_TYPE;

    showNFTPopup = false;
    NFTPopupData;

    showLibraryBigImagePopup = false;
    libraryBigImagePopupData;
    constructor(private loaderService: LoaderService,
                private perksService: PerksService,
                private accountService: AccountService,
                private paginationScrollService: PaginationScrollService) {}

    ngOnInit() {
        document.body.addEventListener( 'click', ( event ) => {
            if ((event.target as HTMLElement).classList.contains('js-images-item')) {
                const id = (<HTMLElement>event.target).getAttribute('data-id');
                const slideInfo = this.imagesData.items.find(el => el.uuid === id);

                if (slideInfo) {
                    this.onShowNFTPopup(slideInfo);
                }
            }
        } );
    }

    ngOnChanges(changes) {
        if (changes.popupInfo.currentValue.show) {
            this.imagesData = new PaginationData('images', '.js-images-item-list', '.js-images-parent', this.type === POPUP_MORE_IMAGES_TYPE.nft ? 'nftImages' : 'aiImages');

            this.getPrizesInfo();
        } else {
            if (this.imagesData) {
                this.paginationScrollService.resetPagination(this.imagesData);
            }

        }
    }

    getPrizesInfo() {
        if (this.type === POPUP_MORE_IMAGES_TYPE.nft) {
            this.accountService.getPageInfo(1, 1, 1, false, false, true).subscribe(data => {
                  this.setNFTInfo(data);
              },
              () => { this.loaderService.hideAndShowTryAgain(); },
              () => { this.loaderService.hide(); });
        } else {
            this.accountService.getPageInfo(1, 1, 1, false, true, false).subscribe(data => {
                  this.setImagesInfo(data);
              },
              () => { this.loaderService.hideAndShowTryAgain(); },
              () => { this.loaderService.hide(); });
        }

    }

    setNFTInfo(data: {nftImages, nftLimit: number, nftTotal: number }): void {
        this.setTransactionsList(data.nftImages, data.nftLimit, data.nftTotal);
    }

    setImagesInfo(data: {aiImages, aiLimit: number, aiTotal: number }): void {
        this.setTransactionsList(data.aiImages, data.aiLimit, data.aiTotal);
    }

    setTransactionsList(history: [SenetproTransactionsModel?], limit: number, total: number): void {
        this.paginationScrollService.resetPagination(this.imagesData);
        document.querySelector('.js-images-parent').scrollTop = 0;
        const prizesDataSettings: SetPaginationDataModel = {
            itemsList: history,
            scrollLimit: limit,
            total: total
        };
        this.initScrolledData(this.imagesData, prizesDataSettings);
    }

    initScrolledData(paginationDataName: PaginationDataModel, settings: SetPaginationDataModel) {
        paginationDataName = this.paginationScrollService.initScrolledData(paginationDataName, settings);
    }

    ///////---SCROLLING PAGINATION FUNCTIONAL---///////
    // DETECTING SCROLL
    scroll(e, settings: PaginationDataModel) {
        e.stopImmediatePropagation();
        e.stopPropagation();

        this.paginationScrollService.onScroll(e, settings, this.loadNewData.bind(this), true);
    }

    // REQUESTS ON ADDING NEW DATA
    loadNewData(page: number, step = 'end', settings: PaginationDataModel): void {
        if (settings.name ===  'images') {
            if (this.type === POPUP_MORE_IMAGES_TYPE.nft) {
                this.accountService.getPageInfo(1, 1, page, false, false, true).subscribe(data => {
                      this.paginationScrollService.dataAddingLogic(page, step, data, settings, true)
                  },
                  () => { this.loaderService.hideAndShowTryAgain() },
                  () => { this.loaderService.hide() }
                );
            } else {
                this.accountService.getPageInfo(1, page, 1, false, true, false).subscribe(data => {
                      this.paginationScrollService.dataAddingLogic(page, step, data, settings, true)
                  },
                  () => { this.loaderService.hideAndShowTryAgain() },
                  () => { this.loaderService.hide() }
                );
            }
        }
    }

    closePopup() {
        this.close.emit(true);
    }

    onShowNFTPopup(slide) {
        console.log('gggg');
        console.log(slide);
        document.querySelector('.login__forms').classList.add('blur');
        this.showNFTPopup = true;
        this.NFTPopupData = {
            type: NFT_POPUP_TYPE.info,
            data: {
                element: slide
            }
        }
    }

    onPopupNFTClose(needReload) {
        this.showNFTPopup = false;
        document.querySelector('.login__forms').classList.remove('blur');
        if (needReload) {
            //this.getData();
        }
    }

    onShowLibraryBigImagePopup(slide) {
        // document.body.classList.add('hide-menu');
        // document.querySelector('.login__forms').classList.add('blur');
        this.showLibraryBigImagePopup = true;
        this.libraryBigImagePopupData = {
            data: slide
        }
    }

    onPopupLibraryBigImageClose() {
        this.showLibraryBigImagePopup = false;
        // document.querySelector('.login__forms').classList.remove('blur');
    }

    onLibraryImageCheckboxChange($event, slide) {
        this.libraryImageCheckboxChange.emit({event: $event, item: slide})
    }
}

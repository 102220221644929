import {DOMAIN_RATECHAPP} from "./domain_settings";
import {DOMAIN_DEMO_NAME1} from "./demo_settings";

export const DOMAINS_LESS_CONTENT_LIST = [
  DOMAIN_RATECHAPP,
  DOMAIN_DEMO_NAME1
];

export const LOGO_LESS_CONTENT_REPLACEMENT = {
  [DOMAIN_RATECHAPP]: 'raTechapp',
  [DOMAIN_DEMO_NAME1]: 'raTechapp'
};

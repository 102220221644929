import {Injectable} from '@angular/core';
import {APP_VERSION_CONFIG} from "../../config/api";
import {HttpClient} from "@angular/common/http";
import {PopupComponent} from "../../shared/popup/popup.component";
import {ActivatedRoute, Router} from "@angular/router";
import {LanguageService} from "../../modules/language/language.service";


@Injectable({
    providedIn: 'root'
})
export class AppVersionService {
    private config = APP_VERSION_CONFIG;
    private versionPopupShow = false;

    constructor(private http: HttpClient,
                private router: Router,
                private languageService: LanguageService,
                private activatedRoute: ActivatedRoute,
                private popupComponent: PopupComponent) {

    }

    setVersionCount(count) {
        localStorage.setItem('versionCount', count);
    }

    getVersionCount(){
        if(localStorage.getItem('versionCount')){
            return parseInt(localStorage.getItem('versionCount'));
        }
        return 0;
    }

    checkVersion(){
        console.log("checkVersion");

        this.getVersion().subscribe((data) => {
            var version = this.getMeta('app-version');
            if(version != data.result){
                var versionCount:number = this.getVersionCount();
                ++versionCount;
                this.setVersionCount(versionCount);
                this.showUpgradePopup();
            } else {
                this.setVersionCount(0);
            }
        });


        let $this = this;
        setTimeout(function(){
            $this.checkVersion();
        },5*60*1000);
    }

    showUpgradePopup() {
        if(!this.versionPopupShow){
            this.versionPopupShow = true;

            if(false){
                const text = this.languageService.getString('messagesData', 'askToRestartApp');
                this.popupComponent.showPopupAgreement({
                    text: text,
                    closeBtn: false,
                    showConfirmButton: false
                }, () => {
                });
            }else{
                const text = this.languageService.getString('messagesData', 'appVersionIsOld');
                this.popupComponent.showPopupAgreement({
                    text: text,
                    closeBtn: false,
                    confirmButtonText: this.languageService.getString('upgrade')
                }, () => {
                    this.upgradeApp();
                });
            }
        }

    }

    getVersion() {
        return this.http.post<any>(this.config.version, {});
    }

    getMeta(metaName) {
        const metas = document.getElementsByTagName('meta');

        for (let i = 0; i < metas.length; i++) {
            if (metas[i].getAttribute('name') === metaName) {
                return metas[i].getAttribute('content');
            }
        }

        return '';
    }

    upgradeApp(){
        let time = + new Date();
        window.location.href = window.location.protocol+'//'+this.getDomain(window.location.href,true)+'?hash='+time;
    }

    getDomain(url, subdomain) {
        subdomain = subdomain || false;

        url = url.replace(/(https?:\/\/)?(www.)?/i, '');

        if (!subdomain) {
            url = url.split('.');

            url = url.slice(url.length - 2).join('.');
        }

        if (url.indexOf('/') !== -1) {
            return url.split('/')[0];
        }

        return url;
    }
}

var _a;
import { DOMAIN_RATECHAPP } from "./domain_settings";
import { DOMAIN_DEMO_NAME1 } from "./demo_settings";
export var DOMAINS_LESS_CONTENT_LIST = [
    DOMAIN_RATECHAPP,
    DOMAIN_DEMO_NAME1
];
export var LOGO_LESS_CONTENT_REPLACEMENT = (_a = {},
    _a[DOMAIN_RATECHAPP] = 'raTechapp',
    _a[DOMAIN_DEMO_NAME1] = 'raTechapp',
    _a);

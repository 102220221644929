import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {LoaderService} from "../../service/loader.service";
import {PopupComponent} from "../../shared/popup/popup.component";
import {NFT_POPUP_PAYMENT_TYPE, NFT_POPUP_TYPE} from "./investments-nft-popup.config";
import {CRYPTO_AMOUNT_MASK, PROJECT_CONFIG} from "../../config/project";
import {CashappPayService} from "../../service/cashapp-pay.service";
import {LanguageService} from "../../modules/language/language.service";
import {CurrencyService} from "../../modules/currency/currency.service";

// declare var Square: any;

@Component({
    selector: 'app-investments-nft-popup',
    templateUrl: './investments-nft-popup.component.html',
    styleUrls: ['./investments-nft-popup.component.scss']
})
export class InvestmentsNftPopupComponent implements OnInit {
    @Input() popupData;
    @Input() popupType;

    @Output() close = new EventEmitter();
    @Output() emitDepositConfirm = new EventEmitter();

    POPUP_TYPES = NFT_POPUP_TYPE;
    POPUP_PAYMENT_TYPES = NFT_POPUP_PAYMENT_TYPE;
    CRYPTO_AMOUNT_MASK = CRYPTO_AMOUNT_MASK;

    selectedImage = false;
    showTermsAndConditions = false;
    showCashApp = false;

    @ViewChild('imageInput', {static: false}) imageInput;
    @ViewChild('termsAccept', {static: false}) termsAccept: ElementRef;

    constructor(private loaderService: LoaderService,
                private languageService: LanguageService,
                private cdr: ChangeDetectorRef,
                private currencyService: CurrencyService,
                private cashappPayService: CashappPayService,
                private popupComponent: PopupComponent,) {

    }

    ngAfterViewChecked(){
        this.cdr.detectChanges();
    }

    ngOnInit() {
        this.cashappPayService.paymentResult.subscribe(tokenResult => {
            console.log(tokenResult);
            if (tokenResult) {
                if (tokenResult.status === 'OK') {
                    console.log(`Payment token is ${tokenResult.token}`);

                    const body = {
                        token: tokenResult.token
                    };
                    // $.post("https://api.dev.eboxenterprises.com/test/square.php?tt=1", body, (data, status) => {
                    //   console.log(data);
                    // });
                    this.onDepositConfirm(tokenResult.token);

                    // alert('request is here');
                    // console.log(body)
                } else {
                    console.error(tokenResult);
                    const message = this.languageService.getString('messagesData', 'paymentIsCanceled');
                    this.popupComponent.showPopupError({text: message});
                    this.closePopup(false);
                }
                this.cashappPayService.paymentResult.next(null);
            }
        })
    }

    closePopup(reload = false) {
        this.close.emit(reload);
        document.body.classList.remove('hide-menu');
        this.selectedImage = false;
        this.showTermsAndConditions = false;
        this.showCashApp = false;
        this.cashappPayService.destroyCashApp();
    }

    async ngOnChanges() {
        this.showCashApp = false;
        if (this.popupData) {
            document.body.classList.add('hide-menu');

            setTimeout(() => {
                document.body.querySelectorAll('.snake-btn').forEach(el => {
                    el.classList.add('is-notouched');
                });

                this.imageUploading();
            });

            if (this.popupData.payment === this.POPUP_PAYMENT_TYPES.cashApp) {
                if (!this.cashappPayService.isCashAppPayScripExist()) {
                    await this.cashappPayService.createCashAppPayButton(this.popupData.cashappInfo, this.popupData.element.amount);
                }
            }
        }
    }

    imageUploading() {
        if (document.querySelector('input[type="file"]')) {
            const $this = this;
            document.querySelector('input[type="file"]').addEventListener('change', function() {
                if (this.files && this.files[0]) {
                    var img = document.querySelector('.js-mint-image');

                    (img as HTMLImageElement).src = URL.createObjectURL(this.files[0]); // set src to blob url
                    $this.selectedImage = true;
                }
            });
        }
    }

    checkConfirm(data) {
        try {
            if (data.status === true) {
                this.popupComponent.showPopupSuccess({text: data.message});
                this.closePopup();
            } else if (data.status === false) {
                this.popupComponent.showPopupError({text: data.message});
            } else {
                this.loaderService.hideAndShowTryAgain();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    onDepositConfirm(token?) {
        if (this.popupData.payment === this.POPUP_PAYMENT_TYPES.cashApp) {
            this.emitDepositConfirm.emit({
                amount: this.popupData.element.amount,
                token
            });
        } else {
            this.emitDepositConfirm.emit({
                amount: this.popupData.element.amount
            });
        }
    }

    async onShowTermsAndConditions() {
        this.showTermsAndConditions = true;

        // if (this.popupData.payment === this.POPUP_PAYMENT_TYPES.cashApp) {
        //     await this.cashappPayService.showCashAppPayButton(this.popupData.element.amount);
        // }
    }

    checkTermsAgreeUncheck() {
        if (this.termsAccept) {
            return !this.termsAccept.nativeElement.checked;
        }
        return true;

    }

    async onShowCashApp() {
        this.showTermsAndConditions = false;
        this.showCashApp = true;

        if (this.popupData.payment === this.POPUP_PAYMENT_TYPES.cashApp) {
            await this.cashappPayService.showCashAppPayButton(this.popupData.element.amount);
        }
    }

    formatCurrency(amount) {
        return this.currencyService.formatCurrency(amount);
    }
}

<div class="investments-popup investments-popup--pay"
     [class.is-blurred]="showNFTPopup || showLibraryBigImagePopup"
     [class.is-active]="popupInfo.show">
    <div class="investments-popup__inner  js-images-parent" *ngIf="popupInfo.show">
        <button class="investments-popup__close" (click)="closePopup()"></button>
<!--        <div class="investments-popup__header investments-popup__header&#45;&#45;centered">Money has been deposited into the terminal</div>-->
<!--        <div class="investments-popup__content">-->
            <div class="dropdown" style="padding-bottom: 0">
<!--                <div class="dropdown__head" style="margin-bottom: 0">-->
<!--                    <div class="dropdown__title c-accent" style="text-transform: uppercase; text-align: center">-->
<!--                        TRANSACTIONS-->
<!--                    </div>-->
<!--                </div>-->
                <div class="dropdown__body dropdown__body--prizes" #scrolledEl (scroll)="scroll($event, imagesData)">
                    <div class="library library--grid" *ngIf="type === POPUP_MORE_IMAGES_TYPE.nft">
                        <button [attr.data-id]="slide.uuid"
                                *ngFor="let slide of imagesData.items"
                                class="slide library__slide js-images-item js-images-item-list">
                            <img class="slide library__slide-img" src="{{ slide.image_link }}" alt="" width="100%">
                            <!--                            <img class="slide library__slide-img" src="../../../assets/partners/snekapek/icons/icon-512.png" alt="" width="100%">-->
                            <p class="library__text">{{'ownership' | languageTranslate}} {{slide.ownership | number: '1.0-2'}}%</p>
                        </button>
                    </div>

                    <div class="library library--grid" *ngIf="type === POPUP_MORE_IMAGES_TYPE.image">
                        <button *ngFor="let slide of imagesData.items; let i = index"
                             class="slide library__slide js-library-slide js-images-item-list">
                            <img class="slide library__slide-img"
                                 (click)="onShowLibraryBigImagePopup(slide)"
                                 src="{{ slide.image_link ? slide.image_link : slide.image_full }}"
                                 style="pointer-events: auto"
                                 alt=""
                                 width="100%">
                            <div class="nft-terms-agreement">
                                <input [id]="'mintBrushPopup'+i"
                                       (change)="onLibraryImageCheckboxChange($event, slide)"
                                       [checked]="!+slide.privacy_status"
                                       class="nft-terms-agreement__input checkbox-primary" type="checkbox">
                                <label class="nft-terms-agreement__label" [for]="'mintBrushPopup'+i" style="cursor: pointer">
                                </label>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
<!--        </div>-->

    </div>
</div>

<app-investments-nft-popup  *ngIf="showNFTPopup && NFTPopupData"
                            [popupData]="NFTPopupData.data"
                            [popupType]="NFTPopupData.type"
                            (close)="onPopupNFTClose($event)"></app-investments-nft-popup>

<app-popup-big-image  *ngIf="showLibraryBigImagePopup && libraryBigImagePopupData"
                      [popupData]="libraryBigImagePopupData.data"
                      (close)="onPopupLibraryBigImageClose()"></app-popup-big-image>

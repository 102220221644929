import {
    Component,
    OnInit, Injectable
} from '@angular/core';
import {PopupComponent} from "../../shared/popup/popup.component";
import {LoaderService} from "../../service/loader.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {RouterPaths} from "../../config/router-paths.model";
import {ActivatedRoute, Router} from "@angular/router";
import {LanguageService} from "../../modules/language/language.service";
import {AuthenticationService} from "../../modules/auth/_services/authentication.service";
import {ContentDisplayingService} from "../../service/content-displaying.service";
import {PartnerService} from "../../service/partner.service";

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
})
@Injectable({providedIn: 'root'})
export class ResetPasswordComponent implements OnInit {
    passwordForm: FormGroup;
    RouterPaths = RouterPaths;

    constructor(
      private formBuilder: FormBuilder,
      private loaderService: LoaderService,
      private router: Router,
      private languageService: LanguageService,
      private activatedRoute: ActivatedRoute,
      private contentDisplayingService: ContentDisplayingService,
      private authenticationService: AuthenticationService,
      private partnerService: PartnerService,
      private popupComponent: PopupComponent) {

        this.passwordFormInit();

        this.popupComponent.closePopup();
        document.body.classList.remove('hide-menu');
    }

    ngOnInit() {
        this.loaderService.hide();
        this.focusFirstInput();
    }

    passwordFormInit(){
        this.passwordForm = this.formBuilder.group({
            password: [null, Validators.required],
            passwordRepeat: [null, Validators.required]
        });
    }

    onSubmit() {
        const code = this.activatedRoute.snapshot.queryParams.code;
        const email = this.activatedRoute.snapshot.queryParams.email;
        const password = this.passwordForm.controls.password.value;
        const passwordRepeat = this.passwordForm.controls.passwordRepeat.value;

        if (password !== passwordRepeat) {
            this.popupComponent.showPopupError({text: this.languageService.getString('messagesData', 'passwordNotMatch')});
            return;
        }

        if (code && email && password) {
            this.authenticationService.restorePassword(code, email, password).subscribe(data => {
                try {
                    if (data.status) {
                        this.popupComponent.showPopupSuccess({text: data.message});
                        this.popupComponent.showPopupSuccessReturnEvent({text: data.message}, () => {
                            this.goBackToLogin();
                        });
                    } else {
                        this.popupComponent.showPopupError({text: data.message});
                    }
                } catch (e) {
                    this.loaderService.hideAndShowTryAgain();
                }
            }, () => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    getPartnerLogo() {
        return this.partnerService.getPartnerLogo() ? this.partnerService.getPartnerLogo() : '../assets/images/logo.png';
    }

    focusFirstInput() {
        setTimeout(() => {
            document.querySelector('input').focus();
        });
    }

    goBackToLogin() {
        this.router.navigate(['./']);
    }
}

import {Injectable} from '@angular/core';
import {LoaderService} from "./loader.service";
import {CHECKOUT_CONFIG} from "../config/api";
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject} from "rxjs";
import {LanguageService} from "../modules/language/language.service";

declare var Square: any;

@Injectable({
    providedIn: 'root'
})
export class CashappPayService {
    payments;
    req;
    options = {
        redirectURL: window.location.href,
        referenceId: 'my-distinct-reference-id',
    };
    buttonOptions = {
        shape: 'semiround',
        width: 'full',
    };
    scriptId = 'cashAppScript';

    paymentResult = new BehaviorSubject(null);
    cashAppPay;
    constructor(public loaderService: LoaderService,
                private languageService: LanguageService,
                private http: HttpClient) {}

    async createCashAppPayButton(cashappInfo, amount) {
        const script = document.createElement('script');
        script.src = cashappInfo.mode === 'sandbox' ? "https://sandbox.web.squarecdn.com/v1/square.js" : "https://web.squarecdn.com/v1/square.js";
        script.setAttribute('id', this.scriptId);
        script.onload = async () => {
            this.payments = Square.payments(cashappInfo.appId, cashappInfo.locationId);
            this.req = this.payments.paymentRequest({
                countryCode: 'US',
                currencyCode: 'USD',
                total: {
                    amount: String(amount),
                    label: this.languageService.getString('total'),
                },
            });
            await this.showCashAppPayButton(amount);
        }
        document.body.appendChild(script);
    }

    async showCashAppPayButton(amount) {
        if (this.payments) {
            this.req = this.payments.paymentRequest({
                countryCode: 'US',
                currencyCode: 'USD',
                total: {
                    amount: String(amount),
                    label: this.languageService.getString('total'),
                },
            });

            if (!this.cashAppPay) {
              this.cashAppPay = await this.payments.cashAppPay(this.req, this.options);
              this.cashAppPay.addEventListener('ontokenization', (event) => {
                const { tokenResult } = event.detail;
                this.paymentResult.next(tokenResult);
                this.destroyCashApp();
                // if (tokenResult.status === 'OK') {
                //     cashAppPay.destroy();
                // }
              });
              await this.cashAppPay.attach('#cash-app-pay', this.buttonOptions);
            }
        }
    }

    isCashAppPayScripExist() {
        return document.querySelector(`#${this.scriptId}`);
    }

    destroyCashApp() {
        if (this.cashAppPay) {
            this.cashAppPay.destroy();
            this.cashAppPay = null;
        }
    }
}

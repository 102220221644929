import {Injectable} from '@angular/core';
import {PAYMENTS_TYPES} from "../config/payment-types.config";

@Injectable({
    providedIn: 'root'
})
export class PosSettingsService {
    PAYMENTS_TYPES = PAYMENTS_TYPES;

    storage = sessionStorage;
    checkOutPageVisibilityKey = 'isCheckOutVisible';
    redeemsVisibilityKey = 'isRedeemsVisible';
    supportVisibilityKey = 'isSupportVisible';
    payments;
    redeems;

    constructor() {}

    setRedeemsVisibility(redeems) {
        let state;
        if (!redeems) {
            state = 1;
        }

        if (redeems) {
            this.redeems = redeems;

            if (redeems.find(item => +item.status === 1)) {
                state = 1;
            } else {
                state = 0;
            }
        }
        this.storage.setItem(this.redeemsVisibilityKey, state);
    }

    getRedeemsVisibility(): boolean {
        return !!+this.storage.getItem(this.redeemsVisibilityKey);
    }

    removeRedeemsVisibility(): void {
        this.storage.removeItem(this.redeemsVisibilityKey);
    }

    setCheckOutPageVisibility(payments) {
        this.payments = payments;
        let state;
        if (!payments) {
            state = 1;
        }

        if (payments) {
            if (payments.find(item => +item.status === 1)) {
                state = 1;
            } else {
                state = 0;
            }
        }
        this.storage.setItem(this.checkOutPageVisibilityKey, state);
    }

    getCheckOutPageVisibility(): boolean {
        return !!+this.storage.getItem(this.checkOutPageVisibilityKey);
    }

    removeCheckOutPageVisibility(): void {
        this.storage.removeItem(this.checkOutPageVisibilityKey);
    }

    isCardsVisible() {
        if (this.payments) {
            if (this.payments.find(el => el.id === this.PAYMENTS_TYPES.card)) {
              return !!+this.payments.find(el => el.id === this.PAYMENTS_TYPES.card).status;
            }
            return false;
        }
        return true;
    }

    isCashAppVisible() {
        if (this.payments) {
            if (this.payments.find(el => el.id === this.PAYMENTS_TYPES.cashApp)) {
              return !!+this.payments.find(el => el.id === this.PAYMENTS_TYPES.cashApp).status;
            }
            return false;
        }
        return true;
    }

    setSupportVisible(val) {
        if ((+val === 1) || (+val === 0)) {
            this.storage.setItem(this.supportVisibilityKey, val);
        } else {
            this.storage.setItem(this.supportVisibilityKey, '0');
        }
    }

    getSupportVisibility(): boolean {
        return !!+this.storage.getItem(this.supportVisibilityKey);
    }
}

import { DOMAINS_LESS_CONTENT_LIST, LOGO_LESS_CONTENT_REPLACEMENT } from "../config/content-displaying.config";
import * as i0 from "@angular/core";
import * as i1 from "./partner.service";
var ContentDisplayingService = /** @class */ (function () {
    function ContentDisplayingService(partnerService) {
        this.partnerService = partnerService;
        this.domainsLessContent = DOMAINS_LESS_CONTENT_LIST;
        this.logoTextReplacement = LOGO_LESS_CONTENT_REPLACEMENT;
    }
    ContentDisplayingService.prototype.detectDomain = function () {
        var host = window.location.hostname;
        // const host = 'app.ra-techapp.com';
        var parts = host.split('.');
        var sndleveldomain = parts.slice(-2).join('.');
        return sndleveldomain;
    };
    ContentDisplayingService.prototype.showAdditionContent = function () {
        return !!this.partnerService.getContentType();
        // return !this.domainsLessContent.includes(this.detectDomain());
    };
    ContentDisplayingService.prototype.raTechappSenetReplacementText = function () {
        return this.logoTextReplacement[this.detectDomain()] ? this.logoTextReplacement[this.detectDomain()] : this.logoTextReplacement[this.domainsLessContent[0]];
    };
    ContentDisplayingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ContentDisplayingService_Factory() { return new ContentDisplayingService(i0.ɵɵinject(i1.PartnerService)); }, token: ContentDisplayingService, providedIn: "root" });
    return ContentDisplayingService;
}());
export { ContentDisplayingService };

import { NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./loader.service";
var LoginService = /** @class */ (function () {
    function LoginService(ngZone, route, loaderService, router) {
        this.ngZone = ngZone;
        this.route = route;
        this.loaderService = loaderService;
        this.router = router;
        this.loginChanged = new BehaviorSubject(null);
    }
    LoginService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginService_Factory() { return new LoginService(i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i1.ActivatedRoute), i0.ɵɵinject(i2.LoaderService), i0.ɵɵinject(i1.Router)); }, token: LoginService, providedIn: "root" });
    return LoginService;
}());
export { LoginService };

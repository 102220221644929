import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {WalletService} from "../../service/wallet.service";
import {LoaderService} from "../../service/loader.service";
import {PopupComponent} from "../popup/popup.component";
import {CODE_FOR_GENERATE_POPUP} from "../../config/project";

@Component({
    selector: 'app-popup-generate',
    templateUrl: './popup-generate.component.html',
    styleUrls: ['./popup-generate.component.scss']
})
export class PopupGenerateComponent implements OnInit {
    @Input() popupInfo: any = {show: false, code: null, canBeClosed: true};

    form: FormGroup;
    constructor(private formBuilder: FormBuilder,
                private loaderService: LoaderService,
                private popupComponent: PopupComponent,
                private walletService: WalletService) {}

    ngOnInit() {
        document.body.classList.add('hide-menu');
        document.body.querySelector('.login__forms').classList.add('is-blurred');
        document.body.querySelector('.app-wrapper').classList.add('is-blurred');

        this.form = this.formBuilder.group({
            password: [null, Validators.required],
            passwordRepeat: [null, Validators.required]
        });
    }

    ngOnDestroy() {
        document.body.querySelector('.app-wrapper').classList.remove('is-blurred');
        if (document.querySelectorAll('.investments-popup.is-active').length <= 1) {
            document.body.classList.remove('hide-menu');
            document.body.querySelector('.login__forms').classList.remove('is-blurred');
        }
    }

    closePopupSuccess() {}

    closePopup() {}

    isGeneratePopup() {
        return String(this.popupInfo.code) === String(CODE_FOR_GENERATE_POPUP);
    }

    sendForm() {
        const password = this.form.controls.password.value;
        const passwordRepeat = this.form.controls.passwordRepeat.value;


        this.onGeneratePassword(password);
    }

    onGeneratePassword(password) {
        this.walletService.generate(password).subscribe(data => {
            this.setRequestResult(data);
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        })
    }

    onSendPassword(password) {
        this.walletService.cryptoSendPassword(password).subscribe(data => {
            this.setRequestResult(data);
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        })
    }

    setRequestResult(data) {
        try {
            if (data.status) {
                this.popupComponent.showPopupSuccess({text: data.message});
                this.closePopupSuccess();
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

}

import {Component, OnInit} from '@angular/core';
import {PopupComponent} from "../../shared/popup/popup.component";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {LoaderService} from "../../service/loader.service";
import {Router} from "@angular/router";
import {CommissionService} from "../../service/commission/commission.service";
import {CommissionHistoryItemModel} from "../../models/commission/commissionHistoryItemModel";
import {COMMISSION_TYPE} from "./commission.config";
import {DateService} from "../../service/date.service";
import {PaginationDataModel, SetPaginationDataModel} from "../../models/scrollPagination/paginationData.model";
import {PaginationData} from "../../models/scrollPagination/paginationData";
import {PaginationScrollService} from "../../service/pageScroll/paginationScroll.service";
import {CRYPTO_AMOUNT_MASK, PROJECT_MESSAGES} from "../../config/project";
import {formatNumber} from "@angular/common";
import {PhoneMaskService} from "../../service/phoneMask.service";
import {LanguageService} from "../../modules/language/language.service";
import {CurrencyService} from "../../modules/currency/currency.service";
import {ContentDisplayingService} from "../../service/content-displaying.service";
import {PosSettingsService} from "../../service/pos-settings.service";

@Component({
    selector: 'app-commission',
    templateUrl: './commission.component.html',
    styleUrls: ['./commission.component.scss'],
})
export class CommissionComponent implements OnInit {
    formRefer: FormGroup;
    form: FormGroup;

    phoneMask = this.phoneMaskService.setPhoneMask();

    balance = 0;
    availableBalance = 0;
    commission = '';
    site: string;
    promoCode: string;

    commissionHistory: Array<CommissionHistoryItemModel> = [];

    commissionDataResponseKeys = {
        site: 'site',
        balance: 'balance',
        promoCode: 'promoCode',
        history: 'history',
        commission: 'commission',
        availableBalance: 'availableBalance',
        methods: 'methods',
    };

    COMMISSION_TYPE = COMMISSION_TYPE;

    methods = [];
    moreThenAvailablePayout = false;

    partnerDomain = '';
    commissionData: PaginationDataModel = new PaginationData('commission', '.js-commission-item', '.login__content', 'history');

    isInitial = true;
    scrollSubscribing;

    entriesVal = 0;
    redeemedVal = 0;
    balanceTypes = [];
    selectedMethod;
    constructor(private popupComponent: PopupComponent,
                private router: Router,
                private loaderService: LoaderService,
                private commissionService: CommissionService,
                private dateService: DateService,
                private languageService: LanguageService,
                private currencyService: CurrencyService,
                private posSettingsService: PosSettingsService,
                // private partnerService: PartnerService,
                private phoneMaskService: PhoneMaskService,
                private contentDisplayingService: ContentDisplayingService,
                private paginationScrollService: PaginationScrollService,
                private formBuilder: FormBuilder) {
    }


    ngOnInit() {
        this.formRefer = this.formBuilder.group({
            firstLastName: [null, Validators.required],
            phone: [null, Validators.required],
        });

        this.form = this.formBuilder.group({
            type: [null, Validators.required],
            amount: [null, Validators.required]
        });

        this.getData(true);
    }

    ngOnDestroy() {
      if (this.scrollSubscribing) {
        this.scrollSubscribing.unsubscribe();
      }
    }

    getData(update = false) {
        this.loaderService.show();
        this.commissionService.getCommissionData('', 1, 1).subscribe(data => {
            this.setData(data, update);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        })
    }

    setData(data, update) {
        try {
            if(data.status){
                this.promoCode = data[this.commissionDataResponseKeys.promoCode];
                this.site = data[this.commissionDataResponseKeys.site];
                this.balance = data[this.commissionDataResponseKeys.balance];
                this.availableBalance = data[this.commissionDataResponseKeys.availableBalance];
                this.commission = data[this.commissionDataResponseKeys.commission];
                this.commissionHistory = data[this.commissionDataResponseKeys.history];
                this.partnerDomain = data.site;

                this.entriesVal = data.entries;
                this.redeemedVal = data.spend;
                this.posSettingsService.setSupportVisible(data.support);

                if (!this.balanceTypes.length) {
                    this.balanceTypes = data.settles;
                    if (this.balanceTypes && this.balanceTypes.length) {
                        this.selectedMethod = this.balanceTypes[0];
                        this.form.controls.type.setValue(this.selectedMethod);
                    } else {
                        this.form.controls.type.setValue(null);
                    }
                }

                if (!update) {
                    this.paginationScrollService.resetPagination(this.commissionData);
                }

                setTimeout(() => {
                    const commissionDataSettings: SetPaginationDataModel = {
                        itemsList: data.history,
                        scrollLimit: data.historyLimit,
                        total: data.historyTotal
                    };

                    this.initScrolledData(this.commissionData, commissionDataSettings);

                    if (this.isInitial) {
                        setTimeout(() => {
                            this.scrollSubscribing = this.paginationScrollService.onContentScroll.subscribe(data => {
                                if (data) {
                                    this.scroll(data, this.commissionData);
                                }
                            });
                            this.isInitial = false;
                        });
                    }
                });
            }else{
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    onSubmit() {
        const phone = this.formRefer.controls.phone.value;
        const firstLastName = this.formRefer.controls.firstLastName.value;

        if (!phone || !firstLastName) {
            return;
        }

        this.commissionService.sendRefer(firstLastName, phone).subscribe(data => {
            this.displaySubmitResult(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        })
    }

    displaySubmitResult(data) {
        try {
            if(data.status){
                this.popupComponent.showPopupSuccess({text: data.message});
                this.formRefer.reset();
            }else{
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }


    onSubmitPayout() {
        const amount = this.form.controls.amount.value;

        if (!amount) {
            this.popupComponent.showPopupError({text: PROJECT_MESSAGES.notAllFieldsAreFilled});
        } else {
            const text = `${this.languageService.getString('confirmSpending')} $${formatNumber(amount, 'en', CRYPTO_AMOUNT_MASK)}.00`;
            this.popupComponent.showPopupConfirmCancel({text}, (status) => {
                if (status) {
                    this.sendPayout(amount);
                }
            });
        }
    }

    sendPayout(amount) {
        const type = this.form.controls.type.value ? this.form.controls.type.value.key : null;
        this.commissionService.sendPayout(amount, type).subscribe(data => {
            this.displaySubmitPayoutResult(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        })
    }

    displaySubmitPayoutResult(data) {
        try {
            if(data.status){
                this.popupComponent.showPopupSuccess({text: data.message});
                this.resetFormPayout();
                this.getData();
            }else{
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    resetFormPayout() {
        this.form.controls.amount.setValue(null);
        this.moreThenAvailablePayout = false;
    }

    getDate(date) {
        return this.dateService.getDateSafariSupport(date);
    }

    getAmount(amount, type, baseType) {
        return type === baseType ? amount : 0;
    }


    onInputAmount() {
        let avaliable = typeof this.availableBalance === 'number' ? this.availableBalance : Number.parseFloat(this.availableBalance);
        const entered = Number.parseFloat(this.form.controls.amount.value);

        if (avaliable < entered) {
            if (avaliable < 0) {
                avaliable = 0;
            }


            this.form.controls.amount.setValue(String(avaliable));
            this.moreThenAvailablePayout = true;
        } else {
            this.moreThenAvailablePayout = false;
        }
    }

    ///////---SCROLLING PAGINATION FUNCTIONAL---///////

    initScrolledData(paginationDataName: PaginationDataModel, settings: SetPaginationDataModel) {
        paginationDataName = this.paginationScrollService.initScrolledData(paginationDataName, settings);
    }

    // DETECTING SCROLL
    scroll(e, settings) {
        e.stopImmediatePropagation();
        e.stopPropagation();

        this.paginationScrollService.onScroll(e, settings, this.loadNewData.bind(this), true);
    }

    // REQUESTS ON ADDING NEW DATA
    loadNewData(page, step = 'end', settings):any {
        const type = settings.name ===  'commission' ? 'ledger' : 'disputs';
        const pageLedger = settings.name ===  'commission' ? page : '';
        const pageDisputs = settings.name ===  'commission' ? '' : page;

        this.commissionService.getCommissionData(type, pageLedger, pageDisputs).subscribe(data => {
            this.paginationScrollService.dataAddingLogic(page, step, data, settings, true);
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            // this.loaderService.hide();
        });

    }

    isAvailableBalance() {
        if (this.availableBalance < 0) {
            return false;
        } else {
            return true;
        }
    }

    formatCurrency(amount) {
        return this.currencyService.formatCurrency(amount);
    }

    showAdditionalContent() {
        return this.contentDisplayingService.showAdditionContent();
    }
}

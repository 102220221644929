import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {PopupComponent} from "../../shared/popup/popup.component";
import {LoaderService} from "../../service/loader.service";
import {SupportService} from "../../service/support/support.service";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
    selector: 'app-support',
    templateUrl: './support.component.html',
    styleUrls: ['./support.component.scss'],
})
export class SupportComponent implements OnInit {
    frameUrl;
    constructor(private loaderService: LoaderService,
                private route: ActivatedRoute,
                private router: Router,
                public sanitizer: DomSanitizer,
                private supportService: SupportService,
                private popupComponent: PopupComponent) {

    }

    ngOnInit() {
        this.getChatLink();

    }

    getChatLink() {
        // let djd = {session: "03cd76967c804947b206757249d068e3",
        //     sessionLink: "https://chat.dev.eboxenterprises.com/auth/03cd76967c804947b206757249d068e3",
        //     status: true};
        // this.getChatLinkResult(djd);
        this.supportService.getSession().subscribe(data => {
            this.getChatLinkResult(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    getChatLinkResult(data) {
        try {
            if (data.status) {
                this.frameUrl = this.getLink(data.sessionLink);
            } else if (data.status === false) {
                this.popupComponent.showPopupError({text: data.message});
            } else {
                this.loaderService.hideAndShowTryAgain();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }

    }

    getBackLink() {
        // return RouterPaths.rewards.main;
    }

    getLink(url){
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}

<div class="investments-popup investments-popup--pay" [class.is-active]="popupInfo.show">
    <div class="investments-popup__inner  js-prizes-parent" *ngIf="popupInfo.show">
        <button class="investments-popup__close"
                *ngIf="popupInfo.canBeClosed"
                (click)="closePopup()"></button>
        <div class="investments-popup__scrolled" id="scrolled-wrapper">
            <div class="investments-popup__header investments-popup__header--centered"
                 *ngIf="isGeneratePopup()">
<!--                Please enter your password to generate crypto wallet. Crypto wallet is 100% Free.-->
                {{'generateWalletData' | languageTranslate: [{$innerVariable: 'titleToGenerateWallet'}]}}
            </div>

            <div class="investments-popup__header investments-popup__header--centered"
                 *ngIf="!isGeneratePopup()">
<!--                Please enter your password to send crypto-->
                {{'generateWalletData' | languageTranslate: [{$innerVariable: 'titleToSendCrypto'}]}}
            </div>

            <form [formGroup]="form" (ngSubmit)="sendForm()">
                <div class="form-simple__item">
                    <input formControlName="password"
                           placeholder="{{'formData' | languageTranslate: [{$innerVariable: 'password'}]}}"
                           [type]="'text'"
                           class="form-simple__input"
                           autocomplete="off">
                </div>

                <div class="form-simple__item">
                    <input formControlName="passwordRepeat"
                           placeholder="{{'retype' | languageTranslate}} {{'formData' | languageTranslate: [{$innerVariable: 'password'}]}}"
                           [type]="'text'"
                           class="form-simple__input"
                           *ngIf="isGeneratePopup()"
                           autocomplete="off">
                </div>

                <div class="form-simple__btns">
                    <button class="button button--reverse"
                            *ngIf="isGeneratePopup()"
                            [disabled]="form.invalid || form.controls.password.value !== form.controls.passwordRepeat.value">
                        {{'generate' | languageTranslate}}
                    </button>

                    <button class="button button--reverse"
                            *ngIf="!isGeneratePopup()"
                            [disabled]="form.controls.password.invalid">
                        {{'formData' | languageTranslate: [{$innerVariable: 'send'}]}}
                    </button>
                </div>
            </form>

        </div>
    </div>
</div>

import { CHANNELS_CONFIG } from "../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./loader.service";
var ChannelsService = /** @class */ (function () {
    function ChannelsService(http, loaderService) {
        this.http = http;
        this.loaderService = loaderService;
        this.config = CHANNELS_CONFIG;
    }
    ChannelsService.prototype.getPageInfo = function () {
        this.loaderService.show();
        return this.http.post(this.config.getChannelsList, {});
    };
    ChannelsService.prototype.onCallMe = function () {
        this.loaderService.show();
        return this.http.post(this.config.onCallMe, {});
    };
    ChannelsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ChannelsService_Factory() { return new ChannelsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: ChannelsService, providedIn: "root" });
    return ChannelsService;
}());
export { ChannelsService };

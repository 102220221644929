import {Component, Injectable, OnInit} from '@angular/core';
import {LoaderService} from "../../service/loader.service";
import {PartnerService} from "../../service/partner.service";

@Component({
    selector: 'app-partner',
    templateUrl: './partner.component.html'
})
@Injectable({providedIn: 'root'})
export class PartnerComponent implements OnInit {

    constructor(private loaderService: LoaderService,
                private partnerService: PartnerService) {
    }

    ngOnInit() {
        this.loaderService.show();
        this.partnerService.detectPartner();
    }
}

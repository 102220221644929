import {Inject, Injectable, NgZone} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {COMMISSION_CONFIG} from "../../config/api";
import {LoaderService} from "../loader.service";

@Injectable({
    providedIn: 'root'
})
export class CommissionService {
    private config = COMMISSION_CONFIG;

    constructor(private http: HttpClient, public loaderService: LoaderService) {
    }

    getCommissionData(type, page, pageDisputs) {
        // this.showLoader();
        return this.http.post<any>(this.config.data, {type, page, pageDisputs});
    }

    sendRefer(firstLastName, phone) {
        this.loaderService.show();
        return this.http.post<any>(this.config.refer, {firstLastName, phone});
    }

    sendPayout(amount, type) {
        this.loaderService.show();
        return this.http.post<any>(this.config.payout, {amount, type});
    }
}

import {ComponentFactory, ComponentFactoryResolver, Injectable, ViewChild, ViewContainerRef} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {LoaderService} from "./loader.service";
import {PopupComponent} from "../shared/popup/popup.component";
import {BehaviorSubject, Observable} from "rxjs";
import {AccountService} from "./account.service";
import {PopupVerifyPhoneComponent} from "../shared/popup-verify-phone/popup-verify-phone.component";


@Injectable({
    providedIn: 'root'
})
export class PhoneVerifyService {
    showModal = new BehaviorSubject({show: false});
    repeatFunction = () => {};

    constructor(private http: HttpClient,
                private popupComponent: PopupComponent,
                private accountService: AccountService,
                private resolver: ComponentFactoryResolver,
                public loaderService: LoaderService) {}

    resetRepeatFunction() {
        this.repeatFunction = () => {};
    }

    showGeneratePopup(container) {
        this.accountService.onRequestVerifyPhone().subscribe((data) => {
            try {
                if (data.status) {
                    container.clear();
                    const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(PopupVerifyPhoneComponent);
                    const popup = container.createComponent(factory);
                    popup.instance.popupInfo.show = true;
                    popup.instance.closePopupSuccess = () => {
                        container.clear();
                        this.repeatFunction();
                        this.resetPopup();
                    };
                    popup.instance.closePopup = () => {
                        container.clear();
                        this.resetPopup();
                    };
                } else {
                    this.popupComponent.showPopupError({text: data.message});
                }
            } catch (e) {
                this.loaderService.hideAndShowTryAgain();
            }
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    showPopup() {
        this.showModal.next({show: true});
    }

    resetPopup() {
        this.showModal.next({show: false});
    }
}

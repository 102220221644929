import { CHECKOUT_CONFIG } from "../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./loader.service";
var CheckoutService = /** @class */ (function () {
    function CheckoutService(http, loaderService) {
        this.http = http;
        this.loaderService = loaderService;
        this.config = CHECKOUT_CONFIG;
    }
    CheckoutService.prototype.getPageInfo = function (only_balance) {
        if (only_balance === void 0) { only_balance = false; }
        this.loaderService.show();
        return this.http.post(this.config.getPageInfo, { only_balance: only_balance });
    };
    // getCartInfo(shortName, amount) {
    //     this.loaderService.show();
    //     return this.http.post<any>(this.config.getCartInfo, {shortName, amount});
    // }
    CheckoutService.prototype.buyWithCard = function (aiStyleId, amount, aiTypeImageGeneration, keywords, id) {
        this.loaderService.show();
        return this.http.post(this.config.buyWithCard, { aiStyleId: aiStyleId, amount: amount, aiTypeImageGeneration: aiTypeImageGeneration, keywords: keywords, id: id });
    };
    CheckoutService.prototype.buyWithCashApp = function (aiStyleId, amount, aiTypeImageGeneration, keywords, token) {
        this.loaderService.show();
        return this.http.post(this.config.buyWithCashApp, { aiStyleId: aiStyleId, amount: amount, aiTypeImageGeneration: aiTypeImageGeneration, keywords: keywords, token: token });
    };
    CheckoutService.prototype.getGenerateInfo = function () {
        this.loaderService.show();
        return this.http.post(this.config.getGenerateInfo, {});
    };
    CheckoutService.prototype.generateOne = function (keywords) {
        this.loaderService.show();
        return this.http.post(this.config.generateOne, { keywords: keywords });
    };
    CheckoutService.prototype.generateAll = function (keywords) {
        this.loaderService.show();
        return this.http.post(this.config.generateAll, { keywords: keywords });
    };
    CheckoutService.prototype.generateText = function () {
        //this.loaderService.show();
        return this.http.post(this.config.generateText, {});
    };
    CheckoutService.prototype.getMintInfo = function (page) {
        this.loaderService.show();
        return this.http.post(this.config.getMintInfo, { page: page });
    };
    CheckoutService.prototype.onMintImages = function (images) {
        this.loaderService.show();
        return this.http.post(this.config.onMintImages, { images: images });
    };
    CheckoutService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CheckoutService_Factory() { return new CheckoutService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: CheckoutService, providedIn: "root" });
    return CheckoutService;
}());
export { CheckoutService };

import { SPINNER_GAME_CONFIG } from "../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SpinnerService = /** @class */ (function () {
    function SpinnerService(http) {
        this.http = http;
        this.config = SPINNER_GAME_CONFIG;
    }
    SpinnerService.prototype.init = function () {
        // this.showLoader();
        var post = this.http.post(this.config.init, {});
        return post;
        // return timer(1500).pipe(
        //   mergeMap(() => this.http.post<any>(this.config.init, {}))
        // );
    };
    SpinnerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SpinnerService_Factory() { return new SpinnerService(i0.ɵɵinject(i1.HttpClient)); }, token: SpinnerService, providedIn: "root" });
    return SpinnerService;
}());
export { SpinnerService };

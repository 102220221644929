/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./popup-verify-phone.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../popup-change-user-info/popup-change-user-info.component.ngfactory";
import * as i3 from "../popup-change-user-info/popup-change-user-info.component";
import * as i4 from "../../service/loader.service";
import * as i5 from "../../service/account.service";
import * as i6 from "../../service/phoneMask.service";
import * as i7 from "../popup/popup.component";
import * as i8 from "../../modules/language/language.service";
import * as i9 from "@angular/forms";
import * as i10 from "./popup-verify-phone.component";
var styles_PopupVerifyPhoneComponent = [i0.styles];
var RenderType_PopupVerifyPhoneComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PopupVerifyPhoneComponent, data: {} });
export { RenderType_PopupVerifyPhoneComponent as RenderType_PopupVerifyPhoneComponent };
export function View_PopupVerifyPhoneComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-popup-change-user-info", [], null, [[null, "onVerified"], [null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onVerified" === en)) {
        var pd_0 = (_co.onVerify() !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.closePopup() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_PopupChangeUserInfoComponent_0, i2.RenderType_PopupChangeUserInfoComponent)), i1.ɵdid(1, 638976, null, 0, i3.PopupChangeUserInfoComponent, [i4.LoaderService, i5.AccountService, i6.PhoneMaskService, i7.PopupComponent, i8.LanguageService, i9.FormBuilder], { popupInfo: [0, "popupInfo"] }, { close: "close", onVerified: "onVerified" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.popupChangeUserInfo; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PopupVerifyPhoneComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-popup-verify-phone", [], null, null, null, View_PopupVerifyPhoneComponent_0, RenderType_PopupVerifyPhoneComponent)), i1.ɵdid(1, 245760, null, 0, i10.PopupVerifyPhoneComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PopupVerifyPhoneComponentNgFactory = i1.ɵccf("app-popup-verify-phone", i10.PopupVerifyPhoneComponent, View_PopupVerifyPhoneComponent_Host_0, { popupInfo: "popupInfo" }, {}, []);
export { PopupVerifyPhoneComponentNgFactory as PopupVerifyPhoneComponentNgFactory };

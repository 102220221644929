<div class="login__forms">
    <div class="login__tab">

        <div style="margin-bottom: 2rem">
            <app-balance-heading [title]="'Support'"></app-balance-heading>
        </div>

        <div class="chat-frame" *ngIf="frameUrl">
            <iframe [src]="frameUrl" frameborder="0"></iframe>
        </div>
    </div>
</div>


export const SENETPRO_REDEEM_METHODS = {
  games: 'games',
  senet: 'senet',
  mascot: 'mascot',
  river: 'river',
  goldenDragon: 'golden_dragon',
  topUpBalance: 'topUpBalance',
  sendToFriend: 'friend',
  giftCardVisa: 'reloadly_visa',
  giftCardAmazon: 'reloadly_amazon',
  paypal: 'paypal_payout',
  venmo: 'venmo_payout',
  card: 'stripe_debitcard',
  bankAccount: 'stripe_bank_account',
  cash: 'cash_payout',
  paynote: 'paynote',
  check: 'check_payout',
  chime: 'chime_ach',
  cashapp: 'cashapp_ach',
};

export const DEBIT_CARD_STATUS = {
  default: 1,
  pending: 2,
  error: 3,
  selected: 4,
  errorCard: 5,
};

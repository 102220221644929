<div class="spinner-wrapper">
    <div class="spinner-wrapper__inner">
        <div class="spinner">
            <div class="segments" [class.is-game-finish]="spinningFinish" [class.is-game-started]="spinning" [ngStyle]="{transform: 'rotate(' + transformValue + 'deg) translate(-50%, -50%)' }">
                <div #segment [attr.data-segment-position]="1" class="segment">
                    <div class="segment__inner"></div>
                    <div class="segment__middle"></div>
                    <div class="segment__end"></div>
                </div>
                <div #segment [attr.data-segment-position]="2" class="segment">
                    <div class="segment__inner"></div>
                    <div class="segment__middle"></div>
                    <div class="segment__end"></div>
                </div>
                <div #segment [attr.data-segment-position]="3" class="segment is-winning">
                    <div class="segment__inner"></div>
                    <div class="segment__middle"></div>
                    <div class="segment__end"></div>
                </div>
                <div #segment [attr.data-segment-position]="4" class="segment">
                    <div class="segment__inner"></div>
                    <div class="segment__middle"></div>
                    <div class="segment__end"></div>
                </div>
                <div #segment [attr.data-segment-position]="5" class="segment">
                    <div class="segment__inner"></div>
                    <div class="segment__middle"></div>
                    <div class="segment__end"></div>
                </div>
                <div #segment [attr.data-segment-position]="6" class="segment">
                    <div class="segment__inner"></div>
                    <div class="segment__middle"></div>
                    <div class="segment__end"></div>
                </div>

                <div #segment [attr.data-segment-position]="7" class="segment">
                    <div class="segment__inner"></div>
                    <div class="segment__middle"></div>
                    <div class="segment__end"></div>
                </div>
                <div #segment [attr.data-segment-position]="8" class="segment">
                    <div class="segment__inner"></div>
                    <div class="segment__middle"></div>
                    <div class="segment__end"></div>
                </div>
                <div #segment [attr.data-segment-position]="9" class="segment">
                    <div class="segment__inner"></div>
                    <div class="segment__middle"></div>
                    <div class="segment__end"></div>
                </div>
                <div #segment [attr.data-segment-position]="10" class="segment">
                    <div class="segment__inner"></div>
                    <div class="segment__middle"></div>
                    <div class="segment__end"></div>
                </div>
                <div #segment [attr.data-segment-position]="11" class="segment">
                    <div class="segment__inner"></div>
                    <div class="segment__middle"></div>
                    <div class="segment__end"></div>
                </div>
                <div #segment [attr.data-segment-position]="12" class="segment">
                    <div class="segment__inner"></div>
                    <div class="segment__middle"></div>
                    <div class="segment__end"></div>
                </div>
            </div>

            <div style="position:relative;padding-bottom: 101%;">
                <img style="position: absolute" [class.is-game-finish]="spinningFinish" src="../../../../assets/images/spinner/wheel_4.png" [ngStyle]="{transform: 'rotate(' + transformValue + 'deg)'}" class="spinner__wheel" alt="">

            </div>
            <div class="spinner__arrow">
                <img src="../../../../assets/images/spinner/game-arrow.png" class="spinner__arrow-img" alt="">
                <div class="spinner__arrow-inner"></div>
            </div>

            <button [disabled]="!data" class="spinner-btn" [class.is-disabled]="spinning" (click)="getSpinResult()"></button>
        </div>
        <img src="../../../assets/images/spinner/cents.png" alt="" class="spinner-coins">
    </div>
    <button class="spinner-question-btn"
            (click)="showInfo()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M10,0A10,10,0,1,0,20,10,10,10,0,0,0,10,0Zm0,16a1,1,0,1,1,1-1A1,1,0,0,1,10,16Zm1.87-5.54a1.69,1.69,0,0,0-.87,1.4V12a1,1,0,0,1-2,0v-.14a3.7,3.7,0,0,1,1.8-3.09,1.5,1.5,0,0,0,.64-1.69,1.45,1.45,0,0,0-1-1,1.53,1.53,0,0,0-1.34.26A1.5,1.5,0,0,0,8.5,7.5a1,1,0,0,1-2,0,3.5,3.5,0,1,1,5.37,3Z"/>
        </svg>
    </button>
   <div class="spinner-loader-wrapper">
       <div class="loader">{{'loading' | languageTranslate}}...</div>
   </div>
</div>


<audio #audio style="visibility: hidden; position: absolute"></audio>

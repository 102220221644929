import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {LoaderService} from "../../service/loader.service";
import {DEBIT_CARD_STATUS, SENETPRO_REDEEM_METHODS} from "../../pages/perks/perks.config";
import {PopupComponent} from "../popup/popup.component";
import {CardsService} from "../../service/cards.service";
import {formatCurrency} from "@angular/common";
import {PROJECT_MESSAGES} from "../../config/project";
import {PrizesSenetproMethodsModel} from "../../pages/perks/prizes-senetpro-methods.model";
import {ChannelsService} from "../../service/channels.service";
import {DateService} from "../../service/date.service";
import {POPUP_CHANNELS_TYPE} from "./popup-channels.config";
import {POPUP_DEBIT_CARD_TYPE} from "../popup-debit-card/popup-debit-card.config";
import {LanguageService} from "../../modules/language/language.service";
import {PhoneVerifyService} from "../../service/phoneVerify.service";
import {CurrencyService} from "../../modules/currency/currency.service";

@Component({
    selector: 'app-popup-channels',
    templateUrl: './popup-channels.component.html',
    styleUrls: ['./popup-channels.component.scss']
})
export class PopupChannelsComponent implements OnInit, OnChanges {
    @Input() popupInfo;
    @Output() close: EventEmitter<boolean> = new EventEmitter();

    DEBIT_CARD_POPUP_STEPS = {main: 1, additionInfo: 2};
    SENETPRO_REDEEM_METHODS = SENETPRO_REDEEM_METHODS;
    POPUP_CHANNELS_TYPE = POPUP_CHANNELS_TYPE;

    popupCheckInfo: any = {show: false, message: ''};
    popupBankInfo: any = {show: false, message: '', type: '', name: '', totalDebit: 0};
    checkMessage;
    minimumWithdrawalMessage;

    popupDebitCardInfo: any = {show: false};
    popupConfirmInfo: any = {show: false, totalDebit: 0};

    paypalVerified = { handle: '', status: false };
    venmoVerified = { handle: '', status: false };
    paynoteInfo = { handle: '', paynoteTitle: '' };
    debitCardSelectedName = '';

    availableMethods: PrizesSenetproMethodsModel[] = [];
    intervalTimer = null;
    descriptionMethodNotAvailable = '';
    editCardBtnId = 'editPopupBtn';
    isCardEditing = false;
    cashMessage;
    cashTickets;
    isCashPopupShown = false;
    selectedChannel = null;

    popupInnerDescription = '';
    constructor(private loaderService: LoaderService,
                private popupComponent: PopupComponent,
                private languageService: LanguageService,
                private phoneVerifyService: PhoneVerifyService,
                private dateService: DateService,
                private currencyService: CurrencyService,
                private channelsService: ChannelsService,
                private cardsService: CardsService) {}

    ngOnInit() {
      this.setEditCardEventHandler();
    }

    ngOnChanges(changes) {
        if (changes.popupInfo.currentValue.show) {
          if (changes.popupInfo.currentValue.type === this.POPUP_CHANNELS_TYPE.sell) {
            this.getPageInfo();
          } else {
            const methods = [
              {
                "shortName": "topUpBalance",
                "name": this.languageService.getStringFirstCharCapital('topUpBalanceTitle'),
                "description": this.languageService.getStringFirstCharCapital('topUpBalanceDescription')
              }
            ];

            this.setMethods(methods);
            setTimeout(() => {
              document.body.classList.add('hide-menu');
            });
          }

        } else {
            this.isCardEditing = false;
            this.availableMethods = [];
        }
    }

    getPageInfo() {
      this.phoneVerifyService.repeatFunction = this.getPageInfoRequest.bind(this);
      this.getPageInfoRequest()
    }

    getPageInfoRequest() {
      this.channelsService.getPageInfo().subscribe(data => {
          this.setChannelsList(data);
        },
        () => { this.loaderService.hideAndShowTryAgain(); },
        () => { this.loaderService.hide(); });
    }

    setChannelsList(data) {
      try {
        if (data.status) {
          document.body.classList.add('hide-menu');
          this.cashMessage = data.cashMessage && data.cashMessage.trim() ? data.cashMessage : this.languageService.getString('cashMessage');
          this.cashTickets = data.cashTickets;
          this.checkMessage = data.checkMessage;
          this.minimumWithdrawalMessage = data.minimumWithdrawalMessage;
          this.descriptionMethodNotAvailable = data.descriptionMethodNotAvailable;
          this.setMethods(data.methods)
        } else {
          this.popupComponent.showPopupError({text: data.message});
        }
      } catch (e) {
        this.loaderService.hideAndShowTryAgain();
      }
    }

    setMethods(methods: PrizesSenetproMethodsModel[]): void {
      this.paynoteInfo.paynoteTitle = '';
      document.body.classList.add('hide-menu');
      this.availableMethods = methods;
      this.availableMethods.forEach(method => {
        if (method.disableTime && method.disable && this.dateService.isDisableTimeActual(method.disableTime)) {
          if (this.intervalTimer === null) {
            this.setChannelDescription();
            this.intervalTimer = setInterval(() => {
              this.setChannelDescription();
            }, 1000);
          }
        } else if (method.disableTime && method.disable && !this.dateService.isDisableTimeActual(method.disableTime)) {
          method.descriptionDisable = this.getChannelComment(method);
          method.disable = false;
          method.disableTime = "";
        }

        if (method.shortName === SENETPRO_REDEEM_METHODS.paynote) {
          this.paynoteInfo.paynoteTitle = method.name;
        }
      });

    }

    setChannelDescription() {
      this.availableMethods.forEach((item) => {
        if (item.disableTime && item.disable && this.dateService.isDisableTimeActual(item.disableTime)) {
          item.descriptionDisable = this.getChannelComment(item);
        }
      });
    }

    getChannelComment(method) {
      if (method.disableTime) {
        const sub = this.dateService.getDisableTimeDist(method.disableTime);

        if (this.dateService.isDisableTimeActual(method.disableTime)) {
          const timeValue = ' <span>' + this.dateService.msToTime(sub) + '</span> ';
          return this.descriptionMethodNotAvailable.replace('{time}', timeValue);
        } else {
          clearInterval(this.intervalTimer);
          this.intervalTimer = null;
          method.disable = false;
          return method.description;
        }
      }
    }

    onChannelClick(method, needLoader = true) {
      const type: string = method.shortName;
      this.popupInnerDescription = method.text;
      switch (type) {
        case SENETPRO_REDEEM_METHODS.paypal:
          this.phoneVerifyService.repeatFunction = this.getPaypalInfo.bind(this, type);
          this.getPaypalInfo(type);
          break;
        case SENETPRO_REDEEM_METHODS.venmo:
          this.phoneVerifyService.repeatFunction = this.getVenmoInfo.bind(this, type);
          this.getVenmoInfo(type);
          break;
        case SENETPRO_REDEEM_METHODS.card:
          this.phoneVerifyService.repeatFunction = this.getDebitCardInfo.bind(this, type, method);
          this.getDebitCardInfo(type, method);
          break;
        // case SENETPRO_REDEEM_METHODS.cashapp:
        //   this.phoneVerifyService.repeatFunction = this.getCashAppInfo.bind(this, type, method);
        //   this.getCashAppInfo(type, method);
        //   break;
        case SENETPRO_REDEEM_METHODS.bankAccount:
          this.phoneVerifyService.repeatFunction = this.getBankAccountInfo.bind(this, type, method);
          this.getBankAccountInfo(type, method);
          break;
        case SENETPRO_REDEEM_METHODS.cash:
          this.showCashPopup();
          break;
        case SENETPRO_REDEEM_METHODS.chime:
        case SENETPRO_REDEEM_METHODS.paynote:
        case SENETPRO_REDEEM_METHODS.cashapp:
          this.phoneVerifyService.repeatFunction = this.showBankWithdrawPopup.bind(this, type, method.name.toUpperCase(), method.text);
          this.showBankWithdrawPopup(type, method.name.toUpperCase(), method.text);
          break;
        case SENETPRO_REDEEM_METHODS.check:
          this.showCheckPopup();
          break;
        default:
          this.showPopupConfirm(type, method.name.toUpperCase());
      }
    }

    showBankWithdrawPopup(type, name, message) {
      const maxAmountAvailable = this.popupInfo.availableBalance;
      this.popupBankInfo.show = true;
      this.popupBankInfo.type = type;
      this.popupBankInfo.name = name;
      this.popupBankInfo.message = message;
      this.popupBankInfo.totalDebit = maxAmountAvailable;
    }

    getVenmoInfo(type) {
      this.cardsService.getVenmoInfo().subscribe(data => {
        this.setAdditionInfo(data, type);
      }, () => { this.loaderService.hideAndShowTryAgain();
      }, () => { this.loaderService.hide(); });
    }

    getPaypalInfo(type) {
      this.cardsService.getPaypalInfo().subscribe(data => {
        this.setAdditionInfo(data, type);
      }, (error) => {
        this.loaderService.hideAndShowTryAgain();
      }, () => {
        this.loaderService.hide();
      });
    }

    getDebitCardInfo(type, method) {
      this.cardsService.getDebitCardInfo().subscribe(data => {
        this.setAdditionInfo(data, type, method);
      }, () => {
        this.loaderService.hideAndShowTryAgain();
      }, () => {
        this.loaderService.hide();
      });
    }

    getCashAppInfo(type, method) {
      this.cardsService.getCashAppInfo().subscribe(data => {
        this.setAdditionInfo(data, type, method);
      }, () => {
        this.loaderService.hideAndShowTryAgain();
      }, () => {
        this.loaderService.hide();
      });
    }

    getBankAccountInfo(type, method) {
      this.cardsService.getBankAccountInfo().subscribe(data => {
        this.setAdditionInfo(data, type, method);
      }, () => {
        this.loaderService.hideAndShowTryAgain();
      }, () => {
        this.loaderService.hide();
      });
    }

    getPaynoteInfo(type) {
      this.cardsService.getPaynoteInfo().subscribe(data => {
        this.setAdditionInfo(data, type);
      }, () => { this.loaderService.hideAndShowTryAgain();
      }, () => { this.loaderService.hide(); });
    }

    setAdditionInfo(data, type, method?): void {
      try {
        switch (type) {
          case SENETPRO_REDEEM_METHODS.paypal:
            this.setPaypalStatus(data);
            this.showPopupConfirm(type);
            break;
          case SENETPRO_REDEEM_METHODS.venmo:
            this.setVenmoStatus(data);
            this.showPopupConfirm(type);
            break;
          case SENETPRO_REDEEM_METHODS.card:
          case SENETPRO_REDEEM_METHODS.cashapp:
          case SENETPRO_REDEEM_METHODS.bankAccount:
            this.setDebitCardStatus(data, method);
            break;
          case SENETPRO_REDEEM_METHODS.paynote:
            this.paynoteInfo.handle = data.email;
            this.showPopupConfirm(type);
            break;
          case SENETPRO_REDEEM_METHODS.check:
            this.paynoteInfo.handle = data.email;
            this.showPopupConfirm(type);
            break;
        }
      } catch (e) {
        this.loaderService.hideAndShowTryAgain();
      }
    }

    setPaypalStatus(data) {
      this.paypalVerified.status = data.status;
      this.paypalVerified.handle = data.email;
    }

    setVenmoStatus(data) {
      this.venmoVerified.status = data.status;
      this.venmoVerified.handle = data.phone;
    }

    setDebitCardStatus(data, method): void {
      try {
        if (data.status) {
          this.selectedChannel = method.shortName;
          this.debitCardSelectedName = '';
          const debitCardStatus = data.result.status;
          const isBankAccount = method.shortName === this.SENETPRO_REDEEM_METHODS.bankAccount;
          const isCashApp = method.shortName === this.SENETPRO_REDEEM_METHODS.cashapp;
          const name = isBankAccount || isCashApp ? data.result.name : data.result.cardName;
          const debitCardName = name ? name.toUpperCase() : '';

          const errorText = `<b class="accent-link-popup">
                                ${isBankAccount ? this.languageService.getStringFirstCharCapital('paymentMethods', 'bankAccount') 
                                                : isCashApp ? this.languageService.getStringFirstCharCapital('paymentMethods', 'cashApp') 
                                                : this.languageService.getStringFirstCharCapital('paymentMethods', 'debitCard')} ${this.languageService.getStringFirstCharCapital('setupFailed')}. ${this.languageService.getStringFirstCharCapital('error')}:
                             </b> ` + String(data.result.errorTxt);

          switch (debitCardStatus) {
            case DEBIT_CARD_STATUS.default:
              if (isBankAccount) {
                this.showDebitCardPopup(POPUP_DEBIT_CARD_TYPE.bankAccount, true);
              } else if (isCashApp) {
                this.showDebitCardPopup(POPUP_DEBIT_CARD_TYPE.cashApp, true);
              } else {
                this.showDebitCardPopup(this.DEBIT_CARD_POPUP_STEPS.main, true);
              }
              break;
            case DEBIT_CARD_STATUS.pending:
              this.popupComponent.showPopupError({text: data.result.pendingTxt});
              break;
            case DEBIT_CARD_STATUS.error:
              this.showPopupAgreement(errorText, 'CORRECT', this.showDebitCardPopup.bind(this, this.DEBIT_CARD_POPUP_STEPS.additionInfo, false, method.shortName));
              break;
            case DEBIT_CARD_STATUS.errorCard:
              if (isBankAccount) {
                this.showPopupAgreement(errorText, 'CORRECT', this.showDebitCardPopup.bind(this, POPUP_DEBIT_CARD_TYPE.bankAccount));
              } else if (isCashApp) {
                this.showPopupAgreement(errorText, 'CORRECT', this.showDebitCardPopup.bind(this, POPUP_DEBIT_CARD_TYPE.cashApp));
              } else {
                this.showPopupAgreement(errorText, 'CORRECT', this.showDebitCardPopup.bind(this, this.DEBIT_CARD_POPUP_STEPS.main));
              }
              break;
            case DEBIT_CARD_STATUS.selected:
              this.debitCardSelectedName = debitCardName;
              this.showPopupConfirm(method.shortName);
              break;
          }
        } else {
          this.popupComponent.showPopupError({text: data.message});
          this.selectedChannel = null;
        }
      } catch (e) {
        this.loaderService.hideAndShowTryAgain()
        this.selectedChannel = null;
      }
    }

  setEditCardEventHandler(): void {
    document.body.addEventListener( 'click', ( event ) => {
      if( event.target && event.target['id'] == this.editCardBtnId ) {
        // this.popupComponent.closePopup();
        // this.closePopupConfirm();
        const popupType = event.target ? (event.target as HTMLElement).getAttribute('popup-type') : null;
        if (popupType === SENETPRO_REDEEM_METHODS.bankAccount) {
          this.showDebitCardPopup(POPUP_DEBIT_CARD_TYPE.bankAccount);
        } else if (popupType === SENETPRO_REDEEM_METHODS.cashapp) {
          this.showDebitCardPopup(POPUP_DEBIT_CARD_TYPE.cashApp);
        } else {
          this.showDebitCardPopup(this.DEBIT_CARD_POPUP_STEPS.main);
        }
        this.isCardEditing = true;
      }
    });
  }

    showPopupAgreement(text: string, confirmButtonText: string, successCallback: () => void, allowOutsideClick?): void {
      this.popupComponent.showPopupAgreement(
        {text: text, confirmButtonText: confirmButtonText, allowOutsideClick: allowOutsideClick},
        (status) => {
          if (status) { successCallback(); }
        });
    }

    showDebitCardPopup(step: number, backList = false, paymentType?): void {
      document.body.classList.add('hide-menu');
      this.popupDebitCardInfo = {
        show: true,
        type: step,
        backList,
        paymentType: paymentType,
        shortName: this.selectedChannel,
      };
      this.isCardEditing = true;
    }

    closeDebitCardPopup() {
      document.body.classList.add('hide-menu');
      this.popupDebitCardInfo = {
        show: false,
        shortName: null
      }
      this.isCardEditing = false;
    }

    closeDebitCardPopupBack(e) {
      document.body.classList.add('hide-menu');
      const isBankAccount = this.popupDebitCardInfo.type === POPUP_DEBIT_CARD_TYPE.bankAccount;
      const isCashApp = this.popupDebitCardInfo.type === POPUP_DEBIT_CARD_TYPE.cashApp;
      const isBackList = this.popupDebitCardInfo.backList;
      const paymentType = this.popupDebitCardInfo.paymentType;

      this.popupDebitCardInfo = {
        show: false,
        shortName: null
      };
      if (this.isCardEditing) {
        this.isCardEditing = false;
        if (!isBackList) {
          if (paymentType) {
            this.onChannelClick({shortName: paymentType}, false);
          } else {
            this.onChannelClick({shortName: isBankAccount ? SENETPRO_REDEEM_METHODS.bankAccount : isCashApp ? SENETPRO_REDEEM_METHODS.cashapp : SENETPRO_REDEEM_METHODS.card}, false);
          }
        }
      }
    }

    showPopupConfirm(type, name = '') {
      document.body.classList.add('hide-menu');
      const handle = type === this.SENETPRO_REDEEM_METHODS.card || type === this.SENETPRO_REDEEM_METHODS.cashapp || type === this.SENETPRO_REDEEM_METHODS.bankAccount ? this.debitCardSelectedName :
        type === this.SENETPRO_REDEEM_METHODS.paypal ? this.paypalVerified.handle :
          type === this.SENETPRO_REDEEM_METHODS.venmo ? this.venmoVerified.handle :
            type === this.SENETPRO_REDEEM_METHODS.paynote ? this.paynoteInfo.handle :
              name ? name : type;

      const maxAmountAvailable = this.popupInfo.availableBalance;

      if (type === this.SENETPRO_REDEEM_METHODS.giftCardVisa || type === this.SENETPRO_REDEEM_METHODS.giftCardAmazon) {
        const giftCardType = type === this.SENETPRO_REDEEM_METHODS.giftCardVisa ? 'visa' : 'amazon';
        this.phoneVerifyService.repeatFunction = this.getGiftCardInfoRequest.bind(this, giftCardType, type, maxAmountAvailable);
        this.getGiftCardInfoRequest(giftCardType, type, maxAmountAvailable);
      } else {
        this.popupConfirmInfo = {
          show: true,
          totalDebit: maxAmountAvailable,
          handle: handle,
          type: type,
          text: this.popupInnerDescription,
          paypalAddress: this.paypalVerified.status,
          venmoAddress: this.venmoVerified.status,
          paynoteTitle: this.paynoteInfo.paynoteTitle
        };
      }
      this.scrollToTopOfPopup(false);
    }

    getGiftCardInfoRequest(giftCardType, type, maxAmountAvailable) {
      this.cardsService.getGiftCardInfo(giftCardType).subscribe((data) => {
        try {
          if (data.status) {
            this.popupConfirmInfo = {
              show: true,
              totalDebit: maxAmountAvailable,
              handle: data.email,
              type: type,
              giftDescription: data.message,
              giftAmountType: data.denominationType,
              giftAmountArray: data.fixedDenominations
            };
          } else {
            this.popupComponent.showPopupError({text: data.message});
            document.body.classList.remove('hide-menu');
          }
        } catch (e) {
          this.loaderService.hideAndShowTryAgain();
          document.body.classList.remove('hide-menu');
        }
      }, () => {
        this.loaderService.hideAndShowTryAgain();
        document.body.classList.remove('hide-menu');
      }, () => {
        this.loaderService.hide();
      })
    }

    closePopupConfirm(full = false) {
      this.popupConfirmInfo = {
        show: false, totalDebit: 0
      };

      if (full) {
        document.body.classList.remove('hide-menu');
        this.closePopup(full);
      } else {
        this.scrollToTopOfPopup(false);
      }
    }

    submitPopupConfirm(info) {
      if (info.type === this.SENETPRO_REDEEM_METHODS.paypal) {
        this.onSubmitPaypalForm(info.amount, this.popupConfirmInfo.handle);
      } else if (info.type === this.SENETPRO_REDEEM_METHODS.venmo) {
        this.onSubmitVenmoForm(info.amount, this.popupConfirmInfo.handle);
      } else if ((info.type === this.SENETPRO_REDEEM_METHODS.card) || (info.type === this.SENETPRO_REDEEM_METHODS.bankAccount) || (info.type === this.SENETPRO_REDEEM_METHODS.cashapp)) {
        this.onSubmitDebitCardForm(info.amount, this.popupConfirmInfo.handle, info.type);
      } else if (info.type === this.SENETPRO_REDEEM_METHODS.giftCardVisa) {
        this.onSubmitGiftCardVisaForm(info.amount, this.popupConfirmInfo.handle, info.qty);
      } else if (info.type === this.SENETPRO_REDEEM_METHODS.giftCardAmazon) {
        this.onSubmitGiftCardAmazonForm(info.amount, this.popupConfirmInfo.handle, info.qty);
      } else if (info.type === this.SENETPRO_REDEEM_METHODS.paynote) {
        this.onSubmitPaynoteForm(info.amount, this.popupConfirmInfo.handle);
      } else if (info.type === this.SENETPRO_REDEEM_METHODS.sendToFriend) {
        // this.getPrizesInfo(true);
      } else {
        this.onSubmitWithdrawForm(info.amount, this.popupConfirmInfo.handle, this.popupConfirmInfo.type);
      }
    }

    onSubmitPaypalForm(amount, handle) {
      if (amount && handle) {
        const text = `${this.languageService.getStringFirstCharCapital('transfersData', 'youAreAboutToRedeem')} 
                      ${this.currencyService.formatCurrency(amount)} 
                      ${this.languageService.getStringFirstCharCapital('transfersData', 'toYourPayPalAccount')} 
                      ${handle}. 
                      ${this.languageService.getStringFirstCharCapital('pleaseConfirm')}`;
        this.showPopupConfirmCancel(text, this.submitPaypalCreatePayout.bind(this, handle, amount));
      } else {
        const message = this.languageService.getStringFirstCharCapital('messagesData', 'askToFillAllFormFields');
        this.popupComponent.showPopupError({text: message});
      }
    }

    submitPaypalCreatePayout(handle, amount):void {
      this.phoneVerifyService.repeatFunction = this.submitPaypalCreatePayoutRequest.bind(this, handle, amount);
      this.submitPaypalCreatePayoutRequest(handle, amount)
    }

    submitPaypalCreatePayoutRequest(handle, amount) {
      this.cardsService.redeemViaVenmoPaypal(handle, amount).subscribe(data => {
        this.displaySubmitPayoutResult(data);
      }, (error) => {
        this.loaderService.hideAndShowTryAgain();
      }, () => {
        this.loaderService.hide();
      });
    }

    onSubmitVenmoForm(amount, handle: string): void {
      if (amount && handle) {
        const text = `${this.languageService.getStringFirstCharCapital('transfersData', 'youAreAboutToRedeem')} 
                      ${this.currencyService.formatCurrency(amount)} 
                      ${this.languageService.getStringFirstCharCapital('transfersData', 'toYourVenmoTag')} 
                      ${handle}. 
                      ${this.languageService.getStringFirstCharCapital('pleaseConfirm')}`;
        // this.showPopupConfirmCancel(text, this.submitRedeemViaVenmoTag.bind(this, amount, handle));
        this.popupComponent.showPopupConfirmCancel({text}, (status) => {
          if (status) {
            this.submitRedeemViaVenmoTag(amount, handle)
          }
        });
      } else {
        this.popupComponent.showPopupError({text: PROJECT_MESSAGES.notAllFieldsAreFilled});
      }
    }
    submitRedeemViaVenmoTag(amount, handle): void {
      this.phoneVerifyService.repeatFunction = this.submitRedeemViaVenmoTagRequest.bind(this, amount, handle);
      this.submitRedeemViaVenmoTagRequest(amount, handle)
    }

    submitRedeemViaVenmoTagRequest(amount, handle) {
      this.cardsService.redeemViaVenmoTag(amount, handle).subscribe(data => {
          this.displaySubmitPayoutResult(data);
        },
        () => { this.loaderService.hideAndShowTryAgain() },
        () => { this.loaderService.hide(); }
      );
    }

  onSubmitPaynoteForm(amount, handle: string): void {
    if (amount && handle) {
      const text = `${this.languageService.getStringFirstCharCapital('transfersData', 'youAreAboutToRedeem')} 
                    ${this.currencyService.formatCurrency(amount)} 
                    ${this.languageService.getStringWithVariables([{email: this.paynoteInfo.paynoteTitle}], 'transfersData', 'toYourEmail')} ${handle}. 
                    ${this.languageService.getStringFirstCharCapital('pleaseConfirm')}`;
      this.showPopupConfirmCancel(text, this.submitRedeemViaPaynote.bind(this, amount, handle));
    } else {
      const message = this.languageService.getString('messagesData', 'notAllFieldsAreFilled');
      this.popupComponent.showPopupError({text: message});
    }
  }
  submitRedeemViaPaynote(amount, handle): void {
    this.phoneVerifyService.repeatFunction = this.submitRedeemViaPaynoteRequest.bind(this, handle, amount);
    this.submitRedeemViaPaynoteRequest(amount, handle);
  }

    submitRedeemViaPaynoteRequest(amount, handle) {
      this.cardsService.redeemViaPaynote(amount, handle).subscribe(data => {
          this.displaySubmitPayoutResult(data);
        },
        () => { this.loaderService.hideAndShowTryAgain() },
        () => { this.loaderService.hide(); }
      );
    }

    onSubmitDebitCardForm(amount, handle: string, type): void {
      const isDebitCard = type === this.SENETPRO_REDEEM_METHODS.card;
      const isBankAccount = type === this.SENETPRO_REDEEM_METHODS.bankAccount;
      const isCashApp = type === this.SENETPRO_REDEEM_METHODS.cashapp;

      const debitCardText = `${this.languageService.getStringFirstCharCapital('transfersData', 'youAreAboutToRedeem')} 
                             ${this.currencyService.formatCurrency(amount)} 
                             ${this.languageService.getStringFirstCharCapital('transfersData', 'toYourDebitCard')} ${handle}. 
                             ${this.languageService.getStringFirstCharCapital('pleaseConfirm')}`;
      const bankAccountText = `${this.languageService.getStringFirstCharCapital('transfersData', 'youAreAboutToRedeem')} 
                              ${this.currencyService.formatCurrency(amount)} 
                              ${this.languageService.getStringFirstCharCapital('transfersData', 'toYourBankAccount')} ${handle}. 
                              ${this.languageService.getStringFirstCharCapital('pleaseConfirm')}. 
                              ${this.languageService.getStringFirstCharCapital('transfersData', 'redeemBankAccountAdditionalText')}`;
      const cashAppText = `${this.languageService.getStringFirstCharCapital('transfersData', 'youAreAboutToRedeem')} 
                           ${this.currencyService.formatCurrency(amount)} 
                           ${this.languageService.getStringFirstCharCapital('transfersData', 'toYourCashAppAccount')} ${handle}. 
                           ${this.languageService.getStringFirstCharCapital('pleaseConfirm')}. 
                           ${this.languageService.getStringFirstCharCapital('transfersData', 'redeemCashAppAccountAdditionalText')}`;

      if (amount && handle) {
        const text = isDebitCard ? debitCardText : isBankAccount ? bankAccountText : cashAppText;
        if (isDebitCard) {
          this.showPopupConfirmCancel(text, this.submitRedeemViaDebitCard.bind(this, amount));
        } else if (type === this.SENETPRO_REDEEM_METHODS.cashapp)  {
          this.showPopupConfirmCancel(text, this.submitRedeemViaCashApp.bind(this, amount));
        } else if (type === this.SENETPRO_REDEEM_METHODS.bankAccount)  {
          this.showPopupConfirmCancel(text, this.submitRedeemViaBankAccount.bind(this, amount));
        }
      } else {
        const message = this.languageService.getString('messagesData', 'askToFillAllFormFields');
        this.popupComponent.showPopupError({text: message});
      }
    }
    submitRedeemViaDebitCard(amount): void {
      this.phoneVerifyService.repeatFunction = this.submitRedeemViaDebitCardRequest.bind(this, amount);
      this.submitRedeemViaDebitCardRequest(amount);
    }

    submitRedeemViaDebitCardRequest(amount) {
      this.cardsService.redeemViaDebitCard(amount).subscribe(data => {
          this.displaySubmitPayoutResult(data);
        },
        () => { this.loaderService.hideAndShowTryAgain() },
        () => { this.loaderService.hide() }
      );
    }

    submitRedeemViaBankAccount(amount): void {
      this.phoneVerifyService.repeatFunction = this.submitRedeemViaBankAccountRequest.bind(this, amount);
      this.submitRedeemViaBankAccountRequest(amount);
    }

    submitRedeemViaBankAccountRequest(amount) {
      this.cardsService.redeemViaBankAccount(amount).subscribe(data => {
          this.displaySubmitPayoutResult(data);
        },
        () => { this.loaderService.hideAndShowTryAgain() },
        () => { this.loaderService.hide() }
      );
    }
    submitRedeemViaCashApp(amount): void {
      this.phoneVerifyService.repeatFunction = this.submitRedeemViaCashAppRequest.bind(this, amount);
      this.submitRedeemViaCashAppRequest(amount)
    }
    submitRedeemViaCashAppRequest(amount) {
      this.cardsService.redeemViaCashApp(amount).subscribe(data => {
          this.displaySubmitPayoutResult(data);
        },
        () => { this.loaderService.hideAndShowTryAgain() },
        () => { this.loaderService.hide() }
      );
    }

    onSubmitGiftCardVisaForm(amount, handle, qty): void {
      if (amount && handle && qty) {
        const text = `${this.languageService.getStringFirstCharCapital('transfersData', 'youAreAboutToRedeem')} 
                      ${this.currencyService.formatCurrency(amount*qty)} 
                      ${this.languageService.getStringWithVariables([{email: handle}], 'transfersData', 'toVisaGiftCard')}. 
                      ${this.languageService.getStringFirstCharCapital('pleaseConfirm')}`;
        this.showPopupConfirmCancel(text, this.submitRedeemViaVisaGiftCard.bind(this, amount, qty));
      } else {
        const message = this.languageService.getString('messagesData', 'askToFillAllFormFields');
        this.popupComponent.showPopupError({text: message});
      }
    }
    submitRedeemViaVisaGiftCard(amount, qty): void {
      this.phoneVerifyService.repeatFunction = this.submitRedeemViaVisaGiftCardRequest.bind(this, amount, qty);
      this.submitRedeemViaVisaGiftCardRequest(amount, qty)
    }

    submitRedeemViaVisaGiftCardRequest(amount, qty) {
      this.cardsService.redeemViaVisaGiftCard(amount, qty).subscribe(data => {
          this.displaySubmitPayoutResult(data);
        },
        () => { this.loaderService.hideAndShowTryAgain() },
        () => { this.loaderService.hide() }
      );
    }

    onSubmitGiftCardAmazonForm(amount, handle, qty) {
      if (amount && handle && qty) {
        const text = `${this.languageService.getStringFirstCharCapital('transfersData', 'youAreAboutToRedeem')} 
                      ${this.currencyService.formatCurrency(amount*qty)} 
                      ${this.languageService.getStringWithVariables([{email: handle}], 'transfersData', 'toAmazonGiftCard')}. 
                      ${this.languageService.getStringFirstCharCapital('pleaseConfirm')}`;
        this.showPopupConfirmCancel(text, this.submitRedeemViaAmazonGiftCard.bind(this, amount, qty));
      } else {
        const message = this.languageService.getString('messagesData', 'askToFillAllFormFields');
        this.popupComponent.showPopupError({text: message});
      }
    }
    submitRedeemViaAmazonGiftCard(amount, qty) {
      this.phoneVerifyService.repeatFunction = this.submitRedeemViaAmazonGiftCardRequest.bind(this, amount, qty);
      this.submitRedeemViaAmazonGiftCardRequest(amount, qty)
    }
    submitRedeemViaAmazonGiftCardRequest(amount, qty) {
      this.cardsService.redeemViaAmazonGiftCard(amount, qty).subscribe(data => {
          this.displaySubmitPayoutResult(data);
        },
        () => { this.loaderService.hideAndShowTryAgain() },
        () => { this.loaderService.hide() }
      );
    }

    onSubmitWithdrawForm(amount, handle: string, shortName: string): void {
      if (amount && handle && shortName) {
        const text = `${this.languageService.getStringFirstCharCapital('transfersData', 'youAreAboutToWithdraw')} 
                      ${this.currencyService.formatCurrency(amount)} 
                      ${this.languageService.getStringFirstCharCapital('to')} ${handle}. 
                      ${this.languageService.getStringFirstCharCapital('pleaseConfirm')}`;
        this.showPopupConfirmCancel(text, this.submitWithdrawForm.bind(this, shortName, amount));
      } else {
        const message = this.languageService.getString('messagesData', 'askToFillAllFormFields');
        this.popupComponent.showPopupError({text: message});
      }
    }
    submitWithdrawForm(shortName: string, amount): void {
      this.phoneVerifyService.repeatFunction = this.submitWithdrawFormRequest.bind(this, shortName, amount);
      this.submitWithdrawFormRequest(shortName, amount)
    }

    submitWithdrawFormRequest(shortName, amount) {
      this.cardsService.withdraw(shortName, amount).subscribe(data => {
          this.displaySubmitPayoutResult(data);
        },
        () => { this.loaderService.hideAndShowTryAgain() },
        () => { this.loaderService.hide() }
      );
    }

    showPopupConfirmCancel(text: string, successCallback: () => void): void {
        this.popupComponent.showPopupConfirmCancel({text}, (status) => {
            if (status) { successCallback(); }
        });
    }

    displaySubmitPayoutResult(data) {
        try {
            if (data.status) {
                this.popupComponent.showPopupSuccess({text: data.message});
                this.closePopupConfirm(true);
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    changeHandlePopupConfirm(info) {
      this.popupConfirmInfo.handle = info.handle;
      if (info.type === this.SENETPRO_REDEEM_METHODS.paypal) {
        this.paypalVerified.status = true;
        this.paypalVerified.handle = info.handle;
      } else if (info.type === this.SENETPRO_REDEEM_METHODS.venmo) {
        this.venmoVerified.status = true;
        this.venmoVerified.handle = info.handle;
      } else if (info.type === this.SENETPRO_REDEEM_METHODS.paynote) {
        this.paynoteInfo.handle = info.handle;
      }
    }

    scrollToTopOfPopup(smooth = true) {
      setTimeout(() => {
        document.querySelector('#scrolled-wrapper').scroll({
          top: 0, left: 0, behavior: smooth ? 'smooth' : 'auto'
        });
      }, smooth ? 500 : 0);
    }

    closePopup(updateBalance = false) {
      this.close.emit(updateBalance);
      this.popupConfirmInfo.show = false;
      this.popupDebitCardInfo.show = false;
    }

    isDisableTimeActual(disableTime) {
      return this.dateService.isDisableTimeActual(disableTime)
    }

  showCashPopup() {
    this.isCashPopupShown = true;
  }

  closeCashPopup() {
      this.isCashPopupShown = false;
  }

  onCallMe() {
    this.phoneVerifyService.repeatFunction = this.callMeRequest.bind(this);
    this.callMeRequest();
  }

  callMeRequest() {
    this.channelsService.onCallMe().subscribe(data => {
      try {
        if (data.status) {
          this.popupComponent.showPopupSuccess({text: data.message});
          this.closeCashPopup();
        } else {
          this.popupComponent.showPopupError({text: data.message});
        }
      } catch (e) {
        this.loaderService.hideAndShowTryAgain();
      }
    }, () => {
      this.loaderService.hideAndShowTryAgain();
    }, () => {
      this.loaderService.hide();
    });
  }

  showCheckPopup() {
    const maxAmountAvailable = this.popupInfo.availableBalance;
    document.body.classList.add('hide-menu');
    this.popupCheckInfo.show = true;
    this.popupCheckInfo.message = this.popupInnerDescription;
    this.popupCheckInfo.totalDebit = maxAmountAvailable;
  }

  closeCheckPopup() {
    //document.body.classList.add('hide-menu');
    this.popupCheckInfo = {
      show: false,
      message: ''
    };
  }

  closeBankPopup() {
    //document.body.classList.add('hide-menu');
    this.popupBankInfo = {
      show: false,
      message: ''
    };
  }
}

<div class="investments-popup investments-popup--pay" [class.is-active]="popupInfo && popupInfo.show">
    <div class="investments-popup__inner" *ngIf="popupInfo && popupInfo.show">
        <button class="investments-popup__close" (click)="closePopup()"></button>
<!--        <div class="investments-popup__header investments-popup__header&#45;&#45;centered">Money has been deposited into the terminal</div>-->
        <div class="investments-popup__content" id="scrolled-wrapper">
            <div class="investments-popup__title">
                <p>
                    {{popupInfo.title}}
                </p>
            </div>
            <ng-container *ngIf="popupInfo.type === SHOP_POPUP_TYPE.activateBuy">
                <form [formGroup]="formActivateBuy" (ngSubmit)="sendForm()">
                    <div class="form-simple__item">
                        <input formControlName="code"
                               mask="0000 0000 0000 0000"
                               placeholder=" {{'formData' | languageTranslate: [{$innerVariable: 'code'}]}}"
                               [type]="'tel'"
                               class="form-simple__input"
                               autocomplete="off">
                    </div>

                    <div class="form-simple__btns">
                        <button class="button button--reverse"
                                [disabled]="formActivateBuy.invalid">
                            {{'formData' | languageTranslate: [{$innerVariable: 'send'}]}}
                        </button>
                    </div>
                </form>
            </ng-container>

            <ng-container *ngIf="popupInfo.type === SHOP_POPUP_TYPE.sellCrypto">
                <ng-container  *ngIf="isSellCryptoAmountStep()">

                    <div class="transaction-report">
                        <div class="transaction-report__body">
                            <div class="transaction-report__row">
                                <span>{{('balance' | languageTranslate).toUpperCase()}}</span> <span>{{popupInfo.btc | number: CRYPTO_AMOUNT_MASK}}</span>
                            </div>
                            <div class="transaction-report__row">
                                <span>{{('price' | languageTranslate).toUpperCase()}} </span> <span>{{formatCurrency(popupInfo.rate)}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="sell-crypto-addition" [innerHTML]="'sellCryptoAdditionInfo' | languageTranslate">
<!--                        To sell your bitcoin to us, please first transfer any-->
<!--                        bitcoin amount to your wallet associated with <a href="https://promo.senetpro.com">SENETPRO.COM</a>.-->
<!--                        Once the funs are received, you will be able to sell it and get sweeps entries.-->
                    </div>

                    <div class="popup-info-block">
                        <div class="qr-code">
                            <span>{{popupInfo.crypto}}</span>
                            <img src="{{popupInfo.qrCode}}" *ngIf="popupInfo.qrCode" alt="">
                            <button class="button button--copy" (click)="copyToBuffer(popupInfo.crypto)">
                                {{'copy' | languageTranslate}}
                            </button>
                        </div>
                    </div>
                </ng-container>

                <div class="transaction-report" *ngIf="isSellCryptoEstimateStep()">
                    <div class="transaction-report__body">
                        <div class="transaction-report__row">
                            <span style="min-width: 5rem; display: inline-block; text-transform: capitalize">
                                {{'bitcoin' | languageTranslate}}:</span> {{sellEstimateData.btc}} BTC
                        </div>
                        <div class="transaction-report__row">
                            <span style="min-width: 5rem; display: inline-block; text-transform: capitalize">
                                {{('sweeps' | languageTranslate)}}:</span> {{formatCurrency(sellEstimateData.sweeps)}}
                        </div>
                    </div>
                    <div class="transaction-report__result">
                        <div class="transaction-report__row">
                            <span style="min-width: 5rem; display: inline-block; text-transform: capitalize">
                                {{('total' | languageTranslate)}}:</span> {{formatCurrency(sellEstimateData.amount)}}
                        </div>
                    </div>
                </div>

                <form [formGroup]="formSellCrypto" (ngSubmit)="sendForm()">
                    <div class="form-simple__item" *ngIf="isSellCryptoAmountStep()">
                        <input mask="separator.0"
                               thousandSeparator=","
                               (input)="onInputAmount()"
                               formControlName="amount"
                               placeholder="{{'formData' | languageTranslate: [{$innerVariable: 'dollarAmount'}]}}"
                               [type]="'tel'"
                               class="form-simple__input"
                               autocomplete="off">
                        <div class="page__form-note" [class.is-active]="moreThenAvailablePayout">
                            {{('available' | languageTranslate)}} {{formatCurrency(popupInfo.availableBalance)}}
                        </div>
                    </div>

                    <div class="form-simple__item" *ngIf="isSellCryptoPasswordStep()">
                        <input formControlName="password"
                               placeholder="{{'formData' | languageTranslate: [{$innerVariable: 'password'}]}}"
                               [type]="'text'"
                               class="form-simple__input"
                               autocomplete="off">
                    </div>

                    <div class="form-simple__btns">
                        <button class="button button--reverse"
                                *ngIf="!isSellCryptoPasswordStep()"
                                [disabled]="!+formSellCrypto.controls.amount.value">
                            {{'continue' | languageTranslate}}
                        </button>

                        <button class="button button--reverse"
                                *ngIf="isSellCryptoPasswordStep()"
                                [disabled]="formSellCrypto.invalid">
                            {{'formData' | languageTranslate: [{$innerVariable: 'sell'}]}}
                        </button>
                    </div>
                </form>
            </ng-container>

            <ng-container *ngIf="popupInfo.type === SHOP_POPUP_TYPE.sellGiftCard">
                <form [formGroup]="formSellGiftCards" (ngSubmit)="sendForm()">
                    <div class="form-simple__item">
                        <input formControlName="code"
                               mask="A{4} A{4} A{4} A{4} A{4} A{4} A{4} A{4} A{4} A{4} A{4} A{4} A{4}"
                               placeholder="{{'formData' | languageTranslate: [{$innerVariable: 'code'}]}}"
                               [type]="'text'"
                               class="form-simple__input"
                               autocomplete="off">
                    </div>

                    <div class="form-simple__item">
                        <input mask="0*"
                               formControlName="pin"
                               placeholder="{{'formData' | languageTranslate: [{$innerVariable: 'pin'}]}}"
                               [type]="'text'"
                               class="form-simple__input"
                               autocomplete="off">
                    </div>

                    <div class="form-simple__item">
                        <input mask="separator.0"
                               thousandSeparator=","
                               formControlName="amount"
                               placeholder="{{'formData' | languageTranslate: [{$innerVariable: 'amount'}]}}"
                               [type]="'tel'"
                               class="form-simple__input"
                               autocomplete="off">
<!--                        <div class="page__form-note" [class.is-active]="moreThenAvailablePayout">-->
<!--                            Available {{popupInfo.availableBalance | currency : 'USD' : 'symbol'}}-->
<!--                        </div>-->
                    </div>

                    <div class="form-simple__btns">
                        <button class="button button--reverse"
                                [disabled]="!formSellGiftCards.controls.code.value || !+formSellGiftCards.controls.amount.value">
                            {{'formData' | languageTranslate: [{$innerVariable: 'send'}]}}
                        </button>
                    </div>
                </form>
            </ng-container>

            <ng-container *ngIf="popupInfo.type === SHOP_POPUP_TYPE.revealKeyPassword">
                <form [formGroup]="formRevealKeyPassword" (ngSubmit)="sendForm()">
                    <div class="form-simple__item">
                        <input formControlName="password"
                               placeholder="{{'formData' | languageTranslate: [{$innerVariable: 'password'}]}}"
                               [type]="'password'"
                               class="form-simple__input"
                               autocomplete="off">
                    </div>

                    <div class="form-simple__btns">
                        <button [disabled]="formRevealKeyPassword.invalid"
                                class="button button--reverse">
                            {{'ok' | languageTranslate}}
                        </button>
                    </div>
                </form>
            </ng-container>

            <ng-container *ngIf="popupInfo.type === SHOP_POPUP_TYPE.revealKey">
                <div class="popup-info-block">
                    <div class="popup-info-block__label">{{'cryptoAddress' | languageTranslate}}</div>
                    <div class="popup-info-block__value"><span>{{popupInfo.address}}</span></div>
                    <button class="button button--default button--small button--copy"
                            (click)="copyToBuffer(popupInfo.address)">{{'copy' | languageTranslate}}</button>
                </div>
                <div class="popup-info-block">
                    <div class="popup-info-block__label">{{'privateKey' | languageTranslate}}</div>
                    <div class="popup-info-block__value"><span>{{popupInfo.privateKey}}</span></div>
                    <button class="button button--default button--small button--copy"
                            (click)="copyToBuffer(popupInfo.privateKey)">{{'copy' | languageTranslate}}</button>
                </div>
                <div class="popup-info-block">
                    <div class="popup-info-block__label">{{'publicKey' | languageTranslate}}</div>
                    <div class="popup-info-block__value"><span>{{popupInfo.publicKey}}</span></div>
                    <button class="button button--default button--small button--copy"
                            (click)="copyToBuffer(popupInfo.publicKey)">{{'copy' | languageTranslate}}</button>
                </div>
                <div class="popup-info-block">
                    <div class="popup-info-block__label">{{'wif' | languageTranslate}}</div>
                    <div class="popup-info-block__value"><span>{{popupInfo.wif}}</span></div>
                    <button class="button button--default button--small button--copy"
                            (click)="copyToBuffer(popupInfo.wif)">
                        {{'copy' | languageTranslate}}
                    </button>
                </div>
                <form (ngSubmit)="closePopup()">
                    <div class="form-simple__btns">
                        <button class="button button--reverse">
                            {{'ok' | languageTranslate}}
                        </button>
                    </div>
                </form>
            </ng-container>

            <ng-container *ngIf="popupInfo.type === SHOP_POPUP_TYPE.receiveCrypto">
                <div class="popup-info-block">
                    <div class="popup-info-block__label">{{'cryptoAddress' | languageTranslate}}</div>
                    <div class="popup-info-block__value"><span>{{popupInfo.address}}</span></div>
                    <button class="button button--default button--small button--copy"
                            (click)="copyToBuffer(popupInfo.address)">{{'copy' | languageTranslate}}</button>
                </div>
                <form (ngSubmit)="closePopup()">
                    <div class="form-simple__btns">
                        <button class="button button--reverse">
                            {{'ok' | languageTranslate}}
                        </button>
                    </div>
                </form>
            </ng-container>

            <ng-container *ngIf="popupInfo.type === SHOP_POPUP_TYPE.sendCrypto">
                <div>
                    <div class="popup-info-block">
                        <div class="popup-info-block__label">{{'formData' | languageTranslate: [{$innerVariable: 'amount'}]}}</div>
                        <div class="popup-info-block__value">{{popupInfo.btc}} BTC</div>
                    </div>

                    <form [formGroup]="formSendCrypto" (ngSubmit)="sendForm()">

                        <div class="form-simple__item">
                            <input formControlName="address"
                                   placeholder="{{'cryptoAddress' | languageTranslate}}"
                                   [type]="'text'"
                                   class="form-simple__input"
                                   autocomplete="off">
                        </div>

                        <div class="form-simple__item">
                            <input (input)="onInputAmount()"
                                   mask="separator"
                                   thousandSeparator=","
                                   formControlName="amount"
                                   placeholder="{{'formData' | languageTranslate: [{$innerVariable: 'amount'}]}}"
                                   [type]="'tel'"
                                   class="form-simple__input"
                                   autocomplete="off">
                            <div class="page__form-note" [class.is-active]="moreThenAvailablePayout">
                                {{'available' | languageTranslate}} {{popupInfo.availableBalance}} BTC
                            </div>
                        </div>

                        <div class="form-simple__item">
                            <input formControlName="password"
                                   placeholder="{{'formData' | languageTranslate: [{$innerVariable: 'password'}]}}"
                                   [type]="'password'"
                                   class="form-simple__input"
                                   autocomplete="off">
                        </div>

                        <div class="form-simple__btns">
                            <button [disabled]="formSendCrypto.invalid || !+formSendCrypto.controls.amount.value"
                                    class="button button--reverse">
                                {{'redeem' | languageTranslate}}
                            </button>
                        </div>
                    </form>
                </div>
            </ng-container>
        </div>
    </div>
</div>

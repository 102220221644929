import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {LoaderService} from "../../service/loader.service";
import {LanguageService} from "../../modules/language/language.service";
import {NFT_POPUP_TYPE} from "../../pages/investments-nft-popup/investments-nft-popup.config";

@Component({
    selector: 'app-popup-big-image',
    templateUrl: './popup-big-image.component.html',
    styleUrls: ['./popup-big-image.component.scss']
})
export class PopupBigImageComponent implements OnInit {
    @Input() popupData;
    @Output() close = new EventEmitter();

    selectedImage = false;

    constructor(private loaderService: LoaderService,
                private languageService: LanguageService,
                private cdr: ChangeDetectorRef) {

    }

    ngAfterViewChecked(){
        this.cdr.detectChanges();
    }

    ngOnInit() {}

    closePopup(reload = false) {
        this.close.emit(reload);
        document.body.classList.remove('hide-menu');
        this.selectedImage = false;
    }

    async ngOnChanges() {
        if (this.popupData) {
            document.body.classList.add('hide-menu');
        }
    }
}

<div class=""
     *ngIf="(!isPopup && popupInfo.show) || isPopup"
     [class.is-active]="popupInfo.show && isPopup"
     [class.investments-popup.investments-popup--pay]="isPopup">
    <div class="investments-popup__inner"
         [class.investments-popup__inner]="isPopup">
        <button class="investments-popup__close"
                (click)="closePopup()"
                *ngIf="isPopup"></button>
        <div class="investments-popup__title">
            <ng-container *ngIf="popupInfo.type === PRIZES_METHODS.venmo && popupInfo.venmoAddress">
<!--                Withdraw to Venmo-->
                {{'withdrawData' | languageTranslate: [{$innerVariable: 'venmoTitle'}]}}
            </ng-container>

            <ng-container *ngIf="popupInfo.type === PRIZES_METHODS.paypal && popupInfo.paypalAddress">
<!--                Withdraw to PayPal-->
                {{'withdrawData' | languageTranslate: [{$innerVariable: 'payPalTitle'}]}}
            </ng-container>

            <ng-container *ngIf="popupInfo.type === PRIZES_METHODS.card">
<!--                Withdraw to your Debit Card-->
                {{'withdrawData' | languageTranslate: [{$innerVariable: 'debitCardTitle'}]}}
            </ng-container>

            <ng-container *ngIf="popupInfo.type === PRIZES_METHODS.cashapp">
<!--                Withdraw to Cash App Account-->
                {{'withdrawData' | languageTranslate: [{$innerVariable: 'cashAppAccountTitle'}]}}
            </ng-container>

            <ng-container *ngIf="popupInfo.type === PRIZES_METHODS.bankAccount">
<!--                Withdraw to your Bank Account-->
                {{'withdrawData' | languageTranslate: [{$innerVariable: 'bankAccountTitle'}]}}
            </ng-container>

            <ng-container *ngIf="popupInfo.type === PRIZES_METHODS.giftCardAmazon">
<!--                Get Amazon Gift Card-->
                {{'withdrawData' | languageTranslate: [{$innerVariable: 'amazonGiftCardTitle'}]}}
            </ng-container>

            <ng-container *ngIf="popupInfo.type === PRIZES_METHODS.giftCardVisa">
<!--                Get Visa Card-->
                {{'withdrawData' | languageTranslate: [{$innerVariable: 'visaCardTitle'}]}}
            </ng-container>

<!--            <ng-container *ngIf="popupInfo.type === PRIZES_METHODS.paynote">-->
<!--&lt;!&ndash;                Withdraw to {{popupInfo.paynoteTitle}}&ndash;&gt;-->
<!--                {{'withdrawData' | languageTranslate: [{$innerVariable: 'paynoteTitle', paynoteTitle: popupInfo.paynoteTitle}]}}-->
<!--            </ng-container>-->
        </div>
        <div class="investments-popup__scrolled">
            <ng-container *ngIf="popupInfo.type === PRIZES_METHODS.topUpBalance">
                <p style="text-align: center">
                    {{'withdrawData' | languageTranslate: [{$innerVariable: 'topUpTitle'}]}}
                </p>
            </ng-container>
            <div class="investments-popup__title-pay">

                <p *ngIf="popupInfo.type !== PRIZES_METHODS.paypal && popupInfo.type !== PRIZES_METHODS.venmo &&
                          popupInfo.type !== PRIZES_METHODS.card && !isOneOfGiftCards()">
                    {{popupInfo.popupTitle}}
                </p>

                <p *ngIf="popupInfo.type === PRIZES_METHODS.paypal && !popupInfo.paypalAddress">
<!--                    Please enter the email account registered with your PayPal. Only ONE PayPal email is allowed per active account.-->
                    {{'withdrawData' | languageTranslate: [{$innerVariable: 'askEnterPayPalEmail'}]}}
                </p>
                <p *ngIf="popupInfo.type === PRIZES_METHODS.paypal && popupInfo.paypalAddress">
<!--                    You opted to withdraw via PayPal. Your money will be sent via {{popupInfo.handle}}. If your PayPal email has changed, please contact support before transferring your funds or risk loosing it.-->
<!--                    {{'withdrawData' | languageTranslate: [{$innerVariable: 'askEnterPayPalEmail', handle: popupInfo.handle}]}}-->
                    {{popupInfo.text}}
                </p>

                <p *ngIf="popupInfo.type === PRIZES_METHODS.venmo && !popupInfo.venmoAddress">
<!--                    Please enter the Phone Number registered with your Venmo. Only ONE Venmo Phone Number is allowed per active account.-->
                    {{'withdrawData' | languageTranslate: [{$innerVariable: 'askEnterVenmoPhone'}]}}
                </p>
                <p *ngIf="popupInfo.type === PRIZES_METHODS.venmo && popupInfo.venmoAddress">
<!--                    {{'formData' | languageTranslate: [{$innerVariable: 'phone'}]}} {{popupInfo.handle | mask: phoneMask}} {{'withdrawData' | languageTranslate: [{$innerVariable: 'willAssociatedWithVenmo'}]}}-->
                    {{popupInfo.text}}
                </p>

                <p *ngIf="popupInfo.type === PRIZES_METHODS.card">
<!--                    You opted to withdraw directly to your debit card. Enter the amount and click Continue.-->
<!--                    {{'withdrawData' | languageTranslate: [{$innerVariable: 'optedToWithdrawDebitCard'}]}}-->
                    {{popupInfo.text}}
                </p>

<!--                <p *ngIf="popupInfo.type === PRIZES_METHODS.cashapp">-->
<!--&lt;!&ndash;                    You opted to withdraw to your Cash App Account. The money will be sent directly to your Cash App bank account. Enter the amount and click Continue.&ndash;&gt;-->
<!--                    {{'withdrawData' | languageTranslate: [{$innerVariable: 'optedToWithdrawCashApp'}]}}-->
<!--                </p>-->

                <p *ngIf="popupInfo.type === PRIZES_METHODS.bankAccount">
<!--                    You opted to withdraw to your Bank Account. The money will be sent directly to your Bank Account. Enter the amount and click Continue.-->
                    {{'withdrawData' | languageTranslate: [{$innerVariable: 'optedToWithdrawBankAccount'}]}}
                </p>

<!--                <p *ngIf="popupInfo.type === PRIZES_METHODS.paynote">-->
<!--&lt;!&ndash;                    You opted to withdraw to Paynote.&ndash;&gt;-->
<!--                    {{'withdrawData' | languageTranslate: [{$innerVariable: 'optedToWithdrawPaynote'}]}}-->
<!--                </p>-->

                <p *ngIf="isOneOfGiftCards()">
                    {{popupInfo.giftDescription}}
                </p>

                <div *ngIf="popupInfo.type === PRIZES_METHODS.card || popupInfo.type === PRIZES_METHODS.bankAccount || popupInfo.type === PRIZES_METHODS.cashapp">
                    <div class="c-accent"  style="opacity: 1; display: flex; justify-content: space-between; margin: 1rem 0">
                        <div>{{popupInfo.handle}}</div>
                        <button class="edit-card-btn" id="editPopupBtn" [attr.popup-type]="popupInfo.type">
                            {{'edit' | languageTranslate}}
                            <svg>
                                <use [attr.xlink:href]="'../../../../assets/images/sprite.svg#pencil'"></use>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

            <div class="page__form group-bordered-inner" *ngIf="popupInfo.type === PRIZES_METHODS.paypal && !popupInfo.paypalAddress">
                <app-verify-cashapp-tag [isPaypal]="true"  [verifyStatus]="popupInfo.paypalAddress" (onVerify)="setPaypalVerified($event)"></app-verify-cashapp-tag>
            </div>

            <div class="page__form group-bordered-inner" *ngIf="popupInfo.type === PRIZES_METHODS.venmo && !popupInfo.venmoAddress">
                <app-verify-cashapp-tag [isVenmo]="true" [isVenmoPhone]="true"  [verifyStatus]="popupInfo.venmoAddress" (onVerify)="setVenmoVerified($event)"></app-verify-cashapp-tag>
            </div>

            <form [formGroup]="form"
                  (ngSubmit)="onSubmitForm()"
                  *ngIf="(popupInfo.type === PRIZES_METHODS.paypal && popupInfo.paypalAddress) ||
                     (popupInfo.type === PRIZES_METHODS.venmo && popupInfo.venmoAddress) ||
                     (popupInfo.type !== PRIZES_METHODS.paypal && popupInfo.type !== PRIZES_METHODS.venmo)"
                  class="form-simple form-simple--shrink form-simple--primary">

                <div class="form-simple__item"
                     *ngIf="(popupInfo.type !== PRIZES_METHODS.venmo) &&
                            (popupInfo.type !== PRIZES_METHODS.card) &&
                            (popupInfo.type !== PRIZES_METHODS.bankAccount) &&
                            (popupInfo.type !== PRIZES_METHODS.cashapp) &&
                            (popupInfo.type !== PRIZES_METHODS.paynote)">
                    <input formControlName="handle"
                           placeholder="{{'formData' | languageTranslate: [{$innerVariable: 'handle'}]}}"
                           disabled="true"
                           [type]="'text'"
                           [value]="popupInfo.handle"
                           class="form-simple__input"
                           autocomplete="off">
                </div>

                <div class="form-simple__item"
                     *ngIf="popupInfo.type === PRIZES_METHODS.paynote">
                    <input formControlName="handle"
                           placeholder="{{'formData' | languageTranslate: [{$innerVariable: 'email'}]}}"
                           [type]="'text'"
                           required="true"
                           email="true"
                           [value]="popupInfo.handle"
                           class="form-simple__input"
                           autocomplete="off">
                </div>

                <div class="form-simple__item" *ngIf="popupInfo.type === PRIZES_METHODS.venmo && popupInfo.venmoAddress">
                    <input formControlName="phoneVenmo"
                           placeholder="{{'phoneNumber' | languageTranslate}}"
                           [mask]="phoneMask"
                           [disabled]="true"
                           [type]="'tel'"
                           class="form-simple__input"
                           autocomplete="off">
                </div>


<!--                <div class="form-simple__item" *ngIf="popupInfo.type === PRIZES_METHODS.games">-->
<!--                    <mat-form-field>-->
<!--                        <mat-select #methodsSelect-->
<!--                                    formControlName="handle"-->
<!--                                    [(value)]="selectedMethod" panelClass="my-select-panel-border-yellow">-->
<!--                            <mat-select-trigger>-->
<!--                                {{methodsSelect?.value?.name}}-->
<!--                            </mat-select-trigger>-->
<!--                            <mat-option *ngFor="let method of popupInfo.providers" [value]="method">-->
<!--                                {{method.name}}-->
<!--                            </mat-option>-->
<!--                        </mat-select>-->
<!--                    </mat-form-field>-->
<!--                </div>-->

                <div class="form-simple__item form-simple__item--amount"
                     *ngIf="(isOneOfGiftCards() && popupInfo.giftAmountType === 'range') ||
                     !isOneOfGiftCards()">
                    <input mask="separator.0"
                           thousandSeparator=","
                           (input)="onInputAmount()"
                           formControlName="amount"
                           placeholder="{{'formData' | languageTranslate: [{$innerVariable: 'amount'}]}}"
                           [type]="'tel'"
                           class="form-simple__input"
                           autocomplete="off">
                    <span>.00</span>
                    <div class="page__form-note" [class.is-active]="moreThenAvailablePayout">
                        {{'available' | languageTranslate}} {{formatCurrency(popupInfo.totalDebit)}}
                    </div>
                </div>

                <div>
                    <div class="amount-variants" *ngIf="isOneOfGiftCards() && popupInfo.giftAmountType === 'fixed' && popupInfo.giftAmountArray">
                        <button class="amount-variants__item"
                                type="button"
                                [class.is-active]="form.controls.amount.value === item"
                                (click)="selectAmount(item)"
                                *ngFor="let item of popupInfo.giftAmountArray">{{item}}</button>
                    </div>
                </div>


                <div class="form-simple__item"  *ngIf="isOneOfGiftCards()">
                    <input mask="separator.0"
                           (input)="onInputQuantity()"
                           formControlName="quantity"
                           placeholder="{{'formData' | languageTranslate: [{$innerVariable: 'quantity'}]}}"
                           [type]="'tel'"
                           class="form-simple__input"
                           autocomplete="off">
                    <div style="margin-top: 1rem">
                        {{'totalAmount' | languageTranslate}}: {{formatCurrency(totalAmount)}}
                    </div>
                    <div class="page__form-note page__form-note--qty" [class.is-active]="!isTotalAmountCorrect && +form.controls.quantity.value">
                        {{'totalAmountNotMoreThanAvailable' | languageTranslate}} {{formatCurrency(popupInfo.totalDebit)}}
                    </div>
                </div>

                <div class="form-simple__btns">
                    <button class="button"
                            [disabled]="+form.controls.amount.value <= 0 ||
                            (isOneOfGiftCards() && (!+form.controls.quantity.value || !isTotalAmountCorrect)) ||
                            ((popupInfo.type === PRIZES_METHODS.paynote) && (form.controls.handle.invalid || form.controls.amount.invalid))">
<!--                        Transfer-->
                        {{'continue' | languageTranslate}}
<!--                        <svg><rect></rect></svg>-->
                    </button>
                </div>
            </form>
<!--            <button class=""-->
<!--                    (click)="closePopup()"-->
<!--                    *ngIf="!isPopup">Back</button>-->
            <button class="button button--reverse"
                    style="width: 100%; margin-top: 1rem"
                    type="button"
                    *ngIf="!isPopup"
                    (click)="closePopup()">
                {{'btnBack' | languageTranslate}}
            </button>
        </div>
    </div>
</div>

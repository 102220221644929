import {Inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {LoaderService} from "../loader.service";
import {USER_BALANCE_CONFIG} from "../../config/api";

@Injectable({
  providedIn: 'root'
})
export class UserBalanceService {
  private config = USER_BALANCE_CONFIG;

  constructor(private http: HttpClient, public loaderService: LoaderService) {
  }

  // getBalance() {
  //   this.loaderService.show();
  //   return this.http.post<any>(this.config.balance, {});
  // }

  increaseBalance(amount, gameProviderId) {
    this.loaderService.show();
    return this.http.post<any>(this.config.increaseBalance, {amount, gameProviderId});
  }
}

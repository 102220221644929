<div class="nft-library" *ngIf="nftData" [class.is-blurred]="showNFTPopup">
    <div class="wallet" [class.wallet--flex]="!nftData.wallet" style="margin-bottom: 1rem;">

<!--        <button class="button button&#45;&#45;default button&#45;&#45;middle"-->
<!--                *ngIf="!nftData.wallet"-->
<!--                (click)="generateWallet()">Generate</button>-->

        <div class="wallet__info" *ngIf="nftData.wallet">
            <img [src]="nftData.qrCode" alt="" *ngIf="nftData.qrCode" class="wallet__qr-code">

            <div class="wallet__content">
                <div class="wallet__address">{{nftData.wallet}}</div>

                <div class="wallet__row">
                    <!--                                    <button class="button button&#45;&#45;default button&#45;&#45;small"-->
                    <!--                                            (click)="openMintPopup()">Mint Nft</button>-->
                    <button class="button button--default button--small"
                            (click)="copyToBuffer()">
                        {{'copy' | languageTranslate}}
                    </button>
                </div>
            </div>
        </div>
    </div>
<!--    <div class="dropdown__text gl-subtitle">-->
<!--        {{nftData.nftDescription}}-->
<!--    </div>-->
    <div class="dropdown__body" *ngIf="nftData.library.length">
        <div class="library library--grid">
            <button [attr.data-id]="slide.uuid"
                    *ngFor="let slide of nftData.library"
                    class="slide library__slide js-library-slide">
                <img class="slide library__slide-img" src="{{ slide.image_link }}" alt="" width="100%">
                <!--                            <img class="slide library__slide-img" src="../../../assets/partners/snekapek/icons/icon-512.png" alt="" width="100%">-->
                <p class="library__text">{{'ownership' | languageTranslate}} {{slide.ownership | number: '1.0-2'}}%</p>
            </button>
        </div>
    </div>
</div>

<app-investments-nft-popup  *ngIf="showNFTPopup && NFTPopupData"
                            [popupData]="NFTPopupData.data"
                            [popupType]="NFTPopupData.type"
                            (close)="onPopupNFTClose($event)"></app-investments-nft-popup>




/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./get-rewards.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../../modules/language/language-translate.pipe";
import * as i5 from "../../modules/language/language.service";
import * as i6 from "./get-rewards.component";
var styles_GetRewardsComponent = [i0.styles];
var RenderType_GetRewardsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GetRewardsComponent, data: {} });
export { RenderType_GetRewardsComponent as RenderType_GetRewardsComponent };
export function View_GetRewardsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "get-rewards"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "get-rewards__row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "a", [["class", "get-rewards__img"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", ""], ["src", "../../../assets/images/get-rewards.png"], ["style", "max-width: 100%"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 7, "div", [["class", "get-rewards__col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "get-rewards__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵpid(0, i4.LanguageTranslatePipe, [i5.LanguageService]), (_l()(), i1.ɵeld(9, 0, null, null, 3, "a", [["class", "button get-rewards__btn"], ["type", "button"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(11, null, [" ", " "])), i1.ɵpid(0, i4.LanguageTranslatePipe, [i5.LanguageService])], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.RouterPaths.commission; _ck(_v, 3, 0, currVal_2); var currVal_6 = _co.RouterPaths.commission; _ck(_v, 10, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("getRewardsInviteText")); _ck(_v, 7, 0, currVal_3); var currVal_4 = i1.ɵnov(_v, 10).target; var currVal_5 = i1.ɵnov(_v, 10).href; _ck(_v, 9, 0, currVal_4, currVal_5); var currVal_7 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("getRewardsStartBtn")); _ck(_v, 11, 0, currVal_7); }); }
export function View_GetRewardsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-get-rewards", [], null, null, null, View_GetRewardsComponent_0, RenderType_GetRewardsComponent)), i1.ɵdid(1, 114688, null, 0, i6.GetRewardsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GetRewardsComponentNgFactory = i1.ɵccf("app-get-rewards", i6.GetRewardsComponent, View_GetRewardsComponent_Host_0, {}, { emitClick: "onClick" }, []);
export { GetRewardsComponentNgFactory as GetRewardsComponentNgFactory };

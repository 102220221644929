import * as i0 from "@angular/core";
var ReferralBonusService = /** @class */ (function () {
    function ReferralBonusService() {
        this.storageReferralBonusStatusKey = 'referralBonusStatus';
    }
    ReferralBonusService.prototype.setReferralBonusStatus = function (status) {
        sessionStorage.setItem(this.storageReferralBonusStatusKey, status);
    };
    ReferralBonusService.prototype.getReferralBonusStatus = function () {
        return JSON.parse(sessionStorage.getItem(this.storageReferralBonusStatusKey));
    };
    ReferralBonusService.prototype.resetReferralBonusStatus = function () {
        sessionStorage.removeItem(this.storageReferralBonusStatusKey);
    };
    ReferralBonusService.prototype.showReferralPage = function () {
        return !!+this.getReferralBonusStatus();
    };
    ReferralBonusService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ReferralBonusService_Factory() { return new ReferralBonusService(); }, token: ReferralBonusService, providedIn: "root" });
    return ReferralBonusService;
}());
export { ReferralBonusService };

import {Component, OnInit} from '@angular/core';
import {slideToLeft} from "../../animations/router.animations";
import {getTermsAndConditions} from "./terms-and-conditions.text";
import {TERMS_AND_CONDITIONS_CONTENT_TYPES} from "./terms-and-conditions.config";
import {TermsAndConditionsService} from '../../service/terms-and-conditions/terms-and-conditions.service';
import {LoaderService} from '../../service/loader.service';
import {AuthenticationService} from "../../modules/auth/_services/authentication.service";
import {Router} from "@angular/router";
import {RouterPaths} from "../../config/router-paths.model";

@Component({
    selector: 'app-terms-and-conditions',
    templateUrl: './terms-and-conditions.component.html',
    styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {
    content = {
        title:'',
        subtitle:'',
        parts:[],
    };
    TERMS_AND_CONDITIONS_CONTENT_TYPES = TERMS_AND_CONDITIONS_CONTENT_TYPES;

    constructor(private termsAndConditionsService: TermsAndConditionsService,
                private router: Router,
                private authenticationService: AuthenticationService,
                private loaderService: LoaderService) {}


    ngOnInit() {
        this.termsAndConditionsService.getPageInfo().subscribe(data => {
            try {
                if (data.status) {
                    const name = data.businessName;
                    const domain = data.domain;
                    this.content = getTermsAndConditions(name, domain);
                }
            } catch (e) {
                this.loaderService.hideAndShowTryAgain();
            }
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    isUserLogged() {
        return this.authenticationService.isUserLogged();
    }

    goBack() {
        if (this.isUserLogged()) {
            this.router.navigate([RouterPaths.shop]);
        } else {
            this.router.navigate(['/']);
        }
    }
}

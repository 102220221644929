import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {LoaderService} from "../loader.service";
import {GAMES_PROVIDER_CONFIG} from "../../config/api";
import {PopupComponent} from "../../shared/popup/popup.component";


@Injectable({
    providedIn: 'root'
})
export class GamesProviderService {
    private config = GAMES_PROVIDER_CONFIG;

    constructor(private popupComponent: PopupComponent,
                private http: HttpClient,
                public loaderService: LoaderService) {
    }

    getSenetSession() {
        this.loaderService.show();
        let post = this.http.post<any>(this.config.getSenetSession, {});
        return post;
    }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {LoaderService} from "../../service/loader.service";
import {PopupComponent} from "../popup/popup.component";
import {PhoneMaskService} from "../../service/phoneMask.service";
import {CardsService} from "../../service/cards.service";
import {LanguageService} from "../../modules/language/language.service";

@Component({
    selector: 'app-verify-cashapp-tag',
    templateUrl: './verify-cashapp-tag.component.html',
    styleUrls: ['./verify-cashapp-tag.component.scss']
})
export class VerifyCashappTagComponent implements OnInit {
    @Input() isPaypal = false;
    @Input() isVenmo = false;
    @Input() isVenmoPhone = false;
    @Input() set verifyStatus(val:boolean) {
        this.cashappVerified = val;
    };
    @Output('onVerify')
    onVerify: EventEmitter<any> = new EventEmitter();
    cashappForm: FormGroup;
    paypalForm: FormGroup;
    venmoForm: FormGroup;
    cashappVerified = false;
    phoneMask;
    constructor(private formBuilder: FormBuilder,
                private loaderService: LoaderService,
                private popupComponent: PopupComponent,
                private languageService: LanguageService,
                private phoneMaskService: PhoneMaskService,
                private cardsService: CardsService) {}

    ngOnInit() {
        this.cashappForm = this.formBuilder.group({
            address: [null, Validators.required],
            retype: [null, Validators.required],
        });
        this.paypalForm = this.formBuilder.group({
            email: [null, Validators.required],
            retype: [null, Validators.required],
        });
        this.venmoForm = this.formBuilder.group({
            address: [null, Validators.required],
            retype: [null, Validators.required],
        });
        this.phoneMask = this.phoneMaskService.setPhoneMask();
    }

    onSubmitCashappForm() {
        // const address = this.cashappForm.controls.address.value;
        // const retype = this.cashappForm.controls.retype.value;
        //
        // this.popupComponent.showPopupConfirmCancel({text: `The CashApp address ${address} will be now associated with your account and will be used to send out redeems as well as to process deposits. If you would like to change it in the future, please contact support.`}, (status) => {
        //     if (status) {
        //         this.settingsService.verifyCashapp(address, retype).subscribe(data => {
        //             this.setSubmitFormResult(data);
        //         }, () => {
        //             this.loaderService.hideAndShowTryAgain();
        //         }, () => {
        //             this.loaderService.hide();
        //         })
        //     }
        // });
    }

    onSubmitVenmoForm() {
        const address = this.venmoForm.controls.address.value;
        const retype = this.venmoForm.controls.retype.value;
        const text = this.languageService.getStringWithVariables([{address: address}], 'venmoIsVerifiedText');

        this.popupComponent.showPopupConfirmCancel({text: text}, (status) => {
            if (status) {
                this.cardsService.verifyVenmo(address, retype).subscribe(data => {
                    this.setSubmitFormResult(data);
                }, () => {
                    this.loaderService.hideAndShowTryAgain();
                }, () => {
                    this.loaderService.hide();
                })
            }
        });
    }

    onSubmitPaypalForm() {
        const email = this.paypalForm.controls.email.value;
        const retype = this.paypalForm.controls.retype.value;
        const text = this.languageService.getStringWithVariables([{email: email}], 'emailIsVerifiedText')

        this.popupComponent.showPopupConfirmCancel({text: text}, (status) => {
            if (status) {
                this.cardsService.verifyPaypal(email, retype).subscribe(data => {
                    this.setSubmitFormResult(data);
                }, () => {
                    this.loaderService.hideAndShowTryAgain();
                }, () => {
                    this.loaderService.hide();
                })
            }
        });
    }

    setSubmitFormResult(data) {
        try {
            if (data.status) {
                this.popupComponent.showPopupSuccess({text: data.message});
                this.setCashappVerified();
            } else {
                this.popupComponent.showPopupError({text: data.message, confirmButtonText: this.languageService.getString('ok')});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    setCashappVerified() {
        this.cashappVerified = true;
        if (this.isPaypal) {
            this.onVerify.emit(this.paypalForm.controls.email.value);
        } else if (this.isVenmo) {
            this.onVerify.emit(this.venmoForm.controls.address.value);
        } else {
            this.onVerify.emit(this.cashappForm.controls.address.value);
        }
    }
}

import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ScrollToService {

    constructor() {
    }

    scrollToEnd(wrapper) {
        const list = document.querySelector(wrapper);
        setTimeout(() => {
            if (list) {
                const scrollTo = list.scrollHeight;
                list.scroll({
                    top: scrollTo,
                    left: 0,
                    behavior: 'smooth'
                });
            }
        }, 500)
    }
}

import { GAMES_PROVIDER_CONFIG } from "../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "../../shared/popup/popup.component";
import * as i2 from "@angular/common/http";
import * as i3 from "../loader.service";
var GamesProviderService = /** @class */ (function () {
    function GamesProviderService(popupComponent, http, loaderService) {
        this.popupComponent = popupComponent;
        this.http = http;
        this.loaderService = loaderService;
        this.config = GAMES_PROVIDER_CONFIG;
    }
    GamesProviderService.prototype.getSenetSession = function () {
        this.loaderService.show();
        var post = this.http.post(this.config.getSenetSession, {});
        return post;
    };
    GamesProviderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GamesProviderService_Factory() { return new GamesProviderService(i0.ɵɵinject(i1.PopupComponent), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.LoaderService)); }, token: GamesProviderService, providedIn: "root" });
    return GamesProviderService;
}());
export { GamesProviderService };

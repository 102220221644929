<div class="login__forms" [class.is-not-logged]="!isUserLogged()">
    <div class="login__tab login__tab--no-rewards-block">
        <button class="button" (click)="goBack()">Go Back</button>

        <app-balance-heading [title]="content.title"></app-balance-heading>

        <div class="terms-and-conditions">
            <div class="terms-and-conditions__inner">
                <h1 class="terms-and-conditions__title">{{content.title}}</h1>
                <h2 class="terms-and-conditions__subtitle">{{content.subtitle}}</h2>

                <div class="terms-and-conditions__block" *ngFor="let part of content.parts">
                    <h1 class="terms-and-conditions__title">{{part.title}}</h1>

                    <ng-template ngFor let-paragraph [ngForOf]="part.paragraphs">

                        <div *ngIf="paragraph.type === TERMS_AND_CONDITIONS_CONTENT_TYPES.innerBlock" class="terms-and-conditions__inner-block">
                            <h3 [innerHTML]="paragraph.title"></h3>

                            <p *ngFor="let item of paragraph.text" [innerHTML]="item"></p>
                        </div>

                        <p *ngIf="!paragraph.type" [innerHTML]="paragraph"></p>

                        <ul *ngIf="paragraph.type === TERMS_AND_CONDITIONS_CONTENT_TYPES.unorderedList">
                            <li *ngFor="let item of paragraph.items">{{item}}</li>
                        </ul>

                        <ol *ngIf="paragraph.type === TERMS_AND_CONDITIONS_CONTENT_TYPES.orderedList">
                            <li *ngFor="let item of paragraph.items">{{item}}</li>
                        </ol>

                        <div *ngIf="paragraph.type === TERMS_AND_CONDITIONS_CONTENT_TYPES.footer" class="terms-and-conditions__footer">
                            <p *ngFor="let item of paragraph.items" [innerHTML]="item"></p>
                        </div>

                        <ng-container *ngIf="paragraph.type === TERMS_AND_CONDITIONS_CONTENT_TYPES.parts">
                            <h5>{{paragraph.subtitle}}</h5>
                            <p *ngFor="let item of paragraph.items" [innerHTML]="item"></p>
                            <ng-container *ngIf="paragraph.list">
                                <ul *ngIf="paragraph.list">
                                    <li *ngFor="let listItem of paragraph.list">{{listItem}}</li>
                                </ul>
                            </ng-container>

                            <ng-container *ngIf="paragraph.listOrdered">
                                <ol *ngIf="paragraph.listOrdered">
                                    <li *ngFor="let listOrderedItem of paragraph.listOrdered">{{listOrderedItem}}</li>
                                </ol>
                            </ng-container>

                        </ng-container>
                    </ng-template>

                </div>
            </div>
        </div>
    </div>
</div>

<div class="login page-wrapper">
    <div class="login__inner" [class.has-back]="true">
        <div class="login__col login__top" style="padding-top: 1rem">
            <div class="login__header">
                <img class="login__logo" *ngIf="getPartnerLogo()" src="{{getPartnerLogo()}}" alt="">
            </div>

            <div class="login__title-wrapper">
                <div class="login__title">
                    <span class="login__title--inner">
                        {{('resetPasswordTitle' | languageTranslate)}}
                    </span>
                </div>
            </div>
        </div>

        <div class="login__col">
            <div class="login__forms">
                <div class="text-center login-tab">
                    <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()" class="login__form">
                        <div class="login__item">
                            <input formControlName="password"
                                   placeholder="{{'formData' | languageTranslate: [{$innerVariable: 'password'}]}}"
                                   [type]="'text'"
                                   class="login-input js-phone-input"
                                   autocomplete="off">
                        </div>

                        <div class="login__item">
                            <input formControlName="passwordRepeat"
                                   placeholder="{{'retype' | languageTranslate}} {{'formData' | languageTranslate: [{$innerVariable: 'password'}]}}"
                                   [type]="'text'"
                                   class="login-input js-phone-input"
                                   autocomplete="off">
                        </div>

                        <div class="form-btn-submit">
                            <button id="login_btn"
                                    class="button snake-btn is-notouched"
                                    [disabled]="passwordForm.invalid || (passwordForm.controls.password.value !== passwordForm.controls.passwordRepeat.value)">
                                {{'save' | languageTranslate}}
                                <svg><rect></rect></svg>
                            </button>
                            <button (click)="goBackToLogin()"
                                    style="margin-top: 1.5rem;"
                                    type="button"
                                    class="button snake-btn is-notouched">
                                {{'btnGoHome' | languageTranslate}}
                                <svg><rect></rect></svg>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

<!--        <div class="login__disclaimer">-->
<!--            {{'authFooterInfo' | languageTranslate}}-->
<!--        </div>-->

        <svg class="login__decor-bottom" width="324" height="12" viewBox="0 0 324 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="317.999" cy="6.00002" rx="5.99998" ry="5.99998" transform="rotate(-180 317.999 6.00002)" />
            <circle cx="284.999" cy="5.99996" r="5.99998" transform="rotate(-180 284.999 5.99996)" />
            <circle cx="251.999" cy="5.99996" r="5.99998" transform="rotate(-180 251.999 5.99996)" />
            <circle cx="6.00002" cy="5.99996" r="5.99998" transform="rotate(-180 6.00002 5.99996)"/>
            <line x1="227.999" y1="7.50003" x2="62.999" y2="7.50002" stroke-width="3"/>
        </svg>
    </div>
</div>

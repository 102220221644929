import {Injectable} from '@angular/core';
import {PRIZES_CONFIG, WITHDRAW_CHECK_CONFIG} from "../config/api";
import {HttpClient} from "@angular/common/http";
import {LoaderService} from "./loader.service";
import {RequestService} from "./request.service";

@Injectable({
    providedIn: 'root'
})
export class CardsService extends RequestService {
    private config = PRIZES_CONFIG;
    private configCheck = WITHDRAW_CHECK_CONFIG;

    constructor(http: HttpClient, loaderService: LoaderService) {
      super(http, loaderService);
    }


    addDebitCard(card, month,year, cvv, name, firstName, lastName, billingAddress, billingZip, city, stateId) {
        return this.request(this.config.addDebitCard, {card, month, year, cvv, name, firstName, lastName, billingAddress, billingZip, city, stateId});
    }

    addAdvancedDebitCard(card, month,year, cvv, name, firstName, lastName, billingAddress, billingZip, city, stateId) {
        return this.request(this.config.addAdvancedDebitCard, {card, month, year, cvv, name, firstName, lastName, billingAddress, billingZip, city, stateId});
    }

    addBankAccount(routingNumber, accountNumber) {
        return this.request(this.config.addBankAccount, {routingNumber, accountNumber});
    }

    addCashAppAccount(routingNumber, accountNumber) {
        return this.request(this.config.addCashAppAccount, {routingNumber, accountNumber});
    }

    removeDebitCard(id) {
        return this.request(this.config.removeDebitCard, {id});
    }

    getDebitCardInfo(needLoader = true) {
        return this.request(this.config.getDebitCardInfo, {}, needLoader);
    }

    getBankAccountInfo(needLoader = true) {
        return this.request(this.config.getBankAccountInfo, {}, needLoader);
    }

    getCashAppInfo(needLoader = true) {
        return this.request(this.config.getCashAppInfo, {}, needLoader);
    }

    getDebitCardAdditionInfo() {
        return this.request(this.config.getDebitCardAdditionInfo, {});
    }

    addDebitCardAdditionInfo(firstName, lastName, address, address2, city, zip, stateId, dob, lastFour, shortName) {
        return this.request(this.config.addDebitCardAdditionInfo, {firstName, lastName, address, address2, city, zip, stateId, dob, lastFour, shortName});
    }


    getGiftCardInfo(type) {
        return this.request(this.config.getGiftCardInfo, {type});
    }

    redeemViaVisaGiftCard(amount, qty) {
        return this.request(this.config.redeemViaVisaGiftCard, {amount, qty});
    }

    redeemViaAmazonGiftCard(amount, qty) {
        return this.request(this.config.redeemViaAmazonGiftCard, {amount, qty});
    }

    redeemViaDebitCard(amount) {
        return this.request(this.config.redeemViaDebitCard, {amount});
    }

    redeemViaBankAccount(amount) {
        return this.request(this.config.redeemViaBankAccount, {amount});
    }

    redeemViaCashApp(amount) {
        return this.request(this.config.redeemViaCashApp, {amount});
    }

    withdraw(shortname,amount) {
        return this.request(this.config.withdraw, {shortname,amount});
    }


    getPaypalInfo() {
        return this.request(this.config.getPaypalInfo, {});
    }

    verifyPaypal(email, retype) {
      return this.request(this.config.verifyPaypal, {email, retype});
    }

    redeemViaVenmoPaypal(email, amount) {
      return this.request(this.config.redeemViaVenmoPaypal, {email, amount});
    }


    getVenmoInfo() {
        return this.request(this.config.getVenmoInfo, {});
    }

    redeemViaVenmoTag(amount, handle: string) {
        return this.request(this.config.redeemViaVenmoTag, {amount, handle});
    }

    verifyVenmo(address, retype) {
        return this.request(this.config.verifyVenmo, {address, retype});
    }

    getDebitCards() {
        return this.request(this.config.getDebitCards, {});
    }

    getBankAccounts() {
        return this.request(this.config.getBankAccounts, {});
    }

    getCashAppAccounts() {
        return this.request(this.config.getCashAppAccounts, {});
    }

    changeActiveCard(uuid) {
        return this.request(this.config.changeActiveCard, {uuid});
    }

    changeActiveBankAccount(uuid) {
        return this.request(this.config.changeActiveBankAccount, {uuid});
    }

    changeActiveCashAppAccount(uuid) {
        return this.request(this.config.changeActiveCashAppAccount, {uuid});
    }

    removeAdvancedDebitCard(uuid) {
        return this.request(this.config.removeAdvancedDebitCard, {uuid});
    }

    removeBankAccount(uuid) {
        return this.request(this.config.removeBankAccount, {uuid});
    }

    removeCashAppAccount(uuid) {
        return this.request(this.config.removeCashAppAccount, {uuid});
    }

    getPaynoteInfo() {
        return this.request(this.config.getPaynoteInfo, {});
    }

    redeemViaPaynote(amount, handle: string) {
        return this.request(this.config.redeemViaPaynote, {amount, handle});
    }

    verifyCard(amount, id) {
        return this.request(this.config.verifyCard, {amount, id});
    }

    getCheckAddress(amount) {
        return this.request(this.configCheck.getAddress, {amount});
    }

    getCheckTotal(amount) {
        return this.request(this.configCheck.getTotal, {amount});
    }

    redeemViaCheck(amount, address) {
        return this.request(this.configCheck.onConfirm, {amount, address});
    }

    getRoutingList(shortName, value?) {
        if (value) {
          return this.request(this.config.getRoutingList, {number: value}, false);
        } else {
          return this.request(this.config.getRoutingList, {method: shortName});
        }
    }

    getSavedInstitutions(method) {
        return this.request(this.config.getSavedInstitutions, {method});
    }

    sendLegalData(firstName, lastName) {
        return this.request(this.config.sendLegalData, {firstName, lastName});
    }

    redeemViaPaynoteDirect(amount, routingNumberId, accountNumber) {
        return this.request(this.config.redeemViaPaynoteDirect, {amount, routingNumberId, accountNumber});
    }
}

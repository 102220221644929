import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {USER_CONTACT_CONFIG} from "../config/api";
import {LoaderService} from "./loader.service";

@Injectable({
    providedIn: 'root'
})
export class ContactInfoService {
    private config = USER_CONTACT_CONFIG;

    constructor(private http: HttpClient, public loaderService: LoaderService) {
    }

    createUser(first_name, last_name, email, promo_code, password) {
        this.loaderService.show();
        return this.http.post<any>(this.config.create, {first_name, last_name, email, promo_code, password});
    }

    saveUserContacts(first_name, last_name, email) {
        this.loaderService.show();
        return this.http.post<any>(this.config.save, {first_name, last_name, email});
    }
}

<div class="page__form">
    <form [formGroup]="contactInfo" (ngSubmit)="onSubmit()" class="form-simple form-simple--primary">
        <div class="form-simple__item">
            <input formControlName="first_name"
                   placeholder="{{'formData' | languageTranslate: [{$innerVariable: 'firstName'}]}}*"
                   [type]="'text'"
                   (change)="onInput('first_name', $event)"
                   class="form-simple__input"
                   autocomplete="off">
        </div>

        <div class="form-simple__item">
            <input formControlName="last_name"
                   placeholder="{{'formData' | languageTranslate: [{$innerVariable: 'lastName'}]}}*"
                   [type]="'text'"
                   (change)="onInput('last_name', $event)"
                   class="form-simple__input"
                   autocomplete="off">
        </div>

        <div class="form-simple__item">
            <input formControlName="email"
                   placeholder="{{'formData' | languageTranslate: [{$innerVariable: 'email'}]}}*"
                   [type]="'text'"
                   (input)="onInput('email', $event)"
                   email="true"
                   class="form-simple__input"
                   [class.input-bordered-mascot]="isMascot"
                   autocomplete="off">
        </div>

        <div class="form-simple__item" *ngIf="!isUserInfoUpdate">
            <input formControlName="promo"
                   placeholder="{{'formData' | languageTranslate: [{$innerVariable: 'promoCode'}]}}"
                   [type]="'text'"
                   email="true"
                   class="form-simple__input"
                   autocomplete="off">
        </div>

        <div class="form-simple__item">
            <input formControlName="password"
                   placeholder="{{'formData' | languageTranslate: [{$innerVariable: 'password'}]}}*"
                   [type]="'password'"
                   class="form-simple__input"
                   autocomplete="off">
        </div>

        <div class="form-simple__item">
            <input formControlName="passwordRepeat"
                   placeholder="{{'retype' | languageTranslate}} {{'formData' | languageTranslate: [{$innerVariable: 'password'}]}}*"
                   [type]="'password'"
                   class="form-simple__input"
                   autocomplete="off">
        </div>

        <div class="pin-block">
            <div class="pin-block__title">
                {{'authCreatePinTitle' | languageTranslate}}
            </div>
            <div class="login__items" formArrayName="digits">
                <input (keydown.enter)="onSubmit()"
                       *ngFor="let field of contactInfo.get('digits')['controls']; let i = index"
                       #inputs [maxLength]="1" [formControlName]="i"
                       (keydown)="check(i, field, $event)"
                       mask="0"
                       class="login-input-small" type="tel">
            </div>
        </div>


        <div class="form-simple__btns">
            <button
                    id="btnRegisterContactInfoSave"
                    class="button snake-btn is-notouched"
                    [disabled]="!contactInfo.controls.first_name.value ||
                                !contactInfo.controls.last_name.value ||
                                !contactInfo.controls.email.value ||
                                contactInfo.controls.password.invalid ||
                                contactInfo.controls.digits.invalid ||
                                (contactInfo.controls.password.value !== contactInfo.controls.passwordRepeat.value)">
                <ng-container *ngIf="!isUserInfoUpdate">
                    {{'register' | languageTranslate}}
                </ng-container>
                <ng-container *ngIf="isUserInfoUpdate">
                    {{'save' | languageTranslate}}
                </ng-container>
                <svg><rect></rect></svg>
            </button>
        </div>
        <div class="form-simple__btns" *ngIf="isUserInfoUpdate">
            <button class="button snake-btn is-notouched"
                    (click)="goBack()">
                {{'btnBack' | languageTranslate}}
                <svg><rect></rect></svg>
            </button>
        </div>
    </form>
</div>

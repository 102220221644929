export const AUTH_CODES = {
    registerNewUser: 402,
    showPassword: 410,
    showSMSPin: 411,
    showAuthPin: 412,
    phoneNoVerified: 413,
    updateUserInfo: 414,
    addCreditCard: 415,
    addLegalInfo: 416
};


import {Injectable} from '@angular/core';
import {LoaderService} from "./loader.service";
import {iif} from "rxjs";


@Injectable({
    providedIn: 'root'
})
export class PointService {
    storagePointKey = 'point';
    storagePointAccountsKey = 'pointAccounts';

    constructor(public loaderService: LoaderService) {
    }

    savePointAccounts(accounts) {
        sessionStorage.setItem(this.storagePointAccountsKey, JSON.stringify(accounts));
    }

    getPointAccounts() {
        return JSON.parse(sessionStorage.getItem(this.storagePointAccountsKey));
    }

    savePointInfo(info) {
        sessionStorage.setItem(this.storagePointKey, JSON.stringify(info));
    }

    getPointInfo(key?) {
        if (key) {
            const pointInfo = JSON.parse(sessionStorage.getItem(this.storagePointKey));
            return pointInfo[key];
        }
        return sessionStorage.getItem(this.storagePointKey);
    }

    resetPointsInfo() {
        sessionStorage.removeItem(this.storagePointKey);
        sessionStorage.removeItem(this.storagePointAccountsKey);
    }
}

import {Injectable} from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class SessionStorageService {
    private storage = sessionStorage;

    constructor() {}

    cleanStorage() {
        // this.removePromo();
        // this.removeCashAppTag();
        // this.removeLoginStep();
        // this.removeAppLink();
        // this.removeCashappFrom();
        // this.removeCashappLink();
    }

    getUserToken(){
        return JSON.parse(this.storage.getItem('currentUser')).token;
    }
}

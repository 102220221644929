<app-popup-wrapper [show]="popupInfo.show" (onClose)="closePopup()">
    <div class="investments-popup__scrolled" id="scrolled-wrapper">
        <div class="js-popup-subtitle investments-popup__header--centered">
            <ng-container *ngIf="!showLegalForm">
                {{popupInfo.message}}
            </ng-container>
            <ng-container *ngIf="showLegalForm">
                {{'legalShortFormTitle' | languageTranslate}}
            </ng-container>
        </div>

        <form [formGroup]="form" class="form-simple" *ngIf="!showLegalForm">
            <p style="margin-bottom: .5rem">{{'enter' | languageTranslate | titlecase}} {{popupInfo.name}}
                <span class="c-accent">{{'formData' | languageTranslate: [{$innerVariable: 'routingNumber'}]}}</span>
            </p>
            <div class="form-simple__item">
                <input formControlName="routingNumber"
                       mask="000 000 000 000"
                       maxlength="15"
                       placeholder="{{'formData' | languageTranslate: [{$innerVariable: 'routingNumber'}]}}"
                       [type]="'text'"
                       (input)="onRoutingNumberInput($event)"
                       (change)="onRoutingNumberChange()"
                       class="form-simple__input input-routing"
                       autocomplete="off">
                <ul class="input-select-list">
                    <li class="input-select-list__item"
                        (click)="selectRoutingNumber(item)"
                        *ngFor="let item of routingList">
                        {{item.number | mask: '000 000 000 000'}}
                    </li>
                </ul>
            </div>

            <div style="margin-bottom: 1.5rem; margin-top: -.5rem">
                <p class="c-accent" style="display: flex; align-items: center" *ngIf="selectedRoutingNumber">
                    <img style="display: block; margin-right: 0.5rem; height: 1em;" src="../../../assets/images/checked-i.svg" alt="">
                    {{selectedRoutingNumber?.institution_name | uppercase}}
                </p>
<!--                <p *ngIf="!selectedRoutingNumber">{{'error' | languageTranslate | uppercase}}: {{'checkYourNumber' | languageTranslate}}</p>-->
            </div>

            <p style="margin-bottom: .5rem">{{'enter' | languageTranslate | titlecase}} {{popupInfo.name}}
                <span class="c-accent">{{'formData' | languageTranslate: [{$innerVariable: 'accountNumber'}]}}</span>
            </p>
            <div class="form-simple__item">
                <input formControlName="accountNumber"
                       mask="000 000 000 000 000"
                       minlength="10"
                       maxlength="20"
                       placeholder="{{'enter' | languageTranslate}} {{'formData' | languageTranslate: [{$innerVariable: 'accountNumber'}]}}"
                       [type]="'text'"
                       class="form-simple__input"
                       autocomplete="off">
            </div>
            <div class="form-simple__item">
                <input formControlName="retype_accountNumber"
                       mask="000 000 000 000 000"
                       minlength="10"
                       maxlength="20"
                       placeholder="{{'reEnter' | languageTranslate}} {{'formData' | languageTranslate: [{$innerVariable: 'accountNumber'}]}}"
                       [type]="'text'"
                       class="form-simple__input"
                       autocomplete="off">
            </div>

            <div class="form-simple__item form-simple__item--amount">
                <input mask="separator.0"
                       thousandSeparator=","
                       formControlName="amount"
                       (input)="onInputAmount()"
                       placeholder="{{'formData' | languageTranslate: [{$innerVariable: 'amount'}]}}"
                       [type]="'tel'"
                       class="form-simple__input"
                       autocomplete="off">
                <span>.00</span>
                <div class="page__form-note" [class.is-active]="moreThenAvailablePayout">
                    {{'available' | languageTranslate}} {{formatCurrency(popupInfo.totalDebit)}}
                </div>
            </div>

            <div class="form-simple__btns">
                <div class="text-center form-btn-submit page__form-btns" style="margin-bottom: 1rem">
                    <button class="button"
                            type="button"
                            (click)="onContinue()"
                            [disabled]="isContinuePaymentButtonDisabled()">
                        {{'continue' | languageTranslate}}
                        <svg><rect></rect></svg>
                    </button>
                </div>
                <div class="text-center form-btn-submit page__form-btns">
                    <button class="button"
                            type="button"
                            (click)="onBack()">
                        {{'btnBack' | languageTranslate}}
                        <svg><rect></rect></svg>
                    </button>
                </div>
            </div>
        </form>

        <form [formGroup]="formLegal" class="form-simple" *ngIf="showLegalForm">

            <div class="form-simple__item">
                <input formControlName="legalFirstName"
                       placeholder="{{'formData' | languageTranslate: [{$innerVariable: 'legalFirstName'}]}}"
                       [type]="'text'"
                       class="form-simple__input"
                       autocomplete="off">
            </div>

            <div class="form-simple__item">
                <input formControlName="legalLastName"
                       placeholder="{{'formData' | languageTranslate: [{$innerVariable: 'legalLastName'}]}}"
                       [type]="'text'"
                       class="form-simple__input"
                       autocomplete="off">
            </div>

            <div class="form-simple__btns">
                <div class="text-center form-btn-submit page__form-btns" style="margin-bottom: 1rem">
                    <button class="button"
                            type="button"
                            (click)="onContinueLegal()"
                            [disabled]="formLegal.invalid">
                        {{'continue' | languageTranslate}}
                        <svg><rect></rect></svg>
                    </button>
                </div>
            </div>
        </form>
    </div>
</app-popup-wrapper>



import {
    Component,
    OnInit, Injectable, QueryList, ViewChildren, ElementRef, HostListener
} from '@angular/core';
import {PopupComponent} from "../../shared/popup/popup.component";
import {LoaderService} from "../../service/loader.service";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {RouterPaths} from "../../config/router-paths.model";
import {ActivatedRoute, Router} from "@angular/router";
import {LoginService} from "../../service/login.service";
import {AppPromptService} from "../../service/app/appPrompt.service";
import {AuthenticationService} from "../../modules/auth/_services/authentication.service";
import {ERROR_CODE} from "../../config/error_code";
import {TIMERS_CONFIG} from "../../config/project";
import {AppVersionService} from "../../service/app-version/appVersion.service";
import {LanguageService} from "../../modules/language/language.service";
import {PhoneMaskService} from "../../service/phoneMask.service";
import {AUTH_CODES} from "../../config/auth_codes";
import {environment} from "../../../environments/environment";
import {ContentDisplayingService} from "../../service/content-displaying.service";
import {PartnerService} from "../../service/partner.service";

const AUTH_TYPE = {
    sms: 'smsPin',
    smsPinPassword: 'smsPinPassword',
    authPin: 'authPin',
    authPinPassword: 'authPinPassword',
    password: 'clintPassword'
}

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
@Injectable({providedIn: 'root'})
export class LoginComponent implements OnInit {
    pinForm: FormGroup;
    phoneForm: FormGroup;

    phoneFormActive = true;
    authCode = null;

    registrationPartActive = false;
    isRegistrationUpdate = false;
    userRegistrationUpdateData: any;
    phoneLastFour = ' ';
    numOfDigits = 4;

    pointId = null;

    phoneMask;

    riverPayAccountId = '';
    terminalConnectingPopup = false;

    showGamesSelection = false;

    menu = false;
    RouterPaths = RouterPaths;

    contactUsPopup = {show: false};

    loginType = 'email';
    authType = '';

    // for INSTALL BUTTON
    deferredPrompt: any;
    IOSInstallBtn = false;
    ios_popup_text;
    androidInstallBtn = false;

    @ViewChildren('inputs') inputs: QueryList<ElementRef>;

    @HostListener('window:beforeinstallprompt', ['$event'])
    onbeforeinstallprompt(e) {
        e.preventDefault();
        // store install avaliable event
        this.deferredPrompt = e;

        // show button with display:block;
        this.androidInstallBtn = true;
        this.appPromptService.deferredPrompt = this.deferredPrompt;
    }


    constructor(
      private formBuilder: FormBuilder,
      private fb: FormBuilder,
      private appVersionService: AppVersionService,
      private authenticationService: AuthenticationService,
      private loginService: LoginService,
      private loaderService: LoaderService,
      private phoneMaskService: PhoneMaskService,
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private auth: AuthenticationService,
      private languageService: LanguageService,
      private contentDisplayingService: ContentDisplayingService,
      private appPromptService: AppPromptService,
      private partnerService: PartnerService,
      private popupComponent: PopupComponent) {

        this.auth.logout();

        this.phoneFormInit();
        this.pinFormInit();

        this.popupComponent.closePopup();
        document.body.classList.remove('hide-menu');

        this.deferredPrompt = this.appPromptService.deferredPrompt;
    }

    ngOnInit() {
        this.phoneMask = this.phoneMaskService.setPhoneMask();
        this.setSavedUserInfo();
        this.activatedRoute.queryParams.subscribe(params => {
            if (params.phone) {
                this.changeLoginType('phone');
                this.phoneForm.controls.phone.setValue(params.phone);
            }
            if (params.email) {
                this.changeLoginType('email');
                this.phoneForm.controls.email.setValue(params.email);
            }
            if (params.email || params.phone) {
                this.onPhoneSubmit();
            } else {
                this.loaderService.hide();
            }
        });
    }

    phoneFormInit(){
        this.phoneForm = this.formBuilder.group({
            phone: [null, Validators.required],
            email: [null, Validators.required],
            remember_user: [false],
        });
    }

    pinFormInit(){
        this.pinForm = this.formBuilder.group({
            digits: this.formBuilder.array([]),
            password: [null, Validators.required],
        });
        for (let i = 0; i < this.numOfDigits; i++) {
            (this.pinForm.get('digits') as FormArray).push(this.formBuilder.control(null, Validators.required))
        }
    }

    setSavedUserInfo() {
        if (localStorage.remember_user && (localStorage.remember_user !== "false")) {
            this.phoneForm.controls.remember_user.setValue(true);
            if (localStorage.phone && (localStorage.phone !== 'null')) {
                this.phoneForm.controls.phone.setValue(localStorage.phone);
                this.phoneForm.controls.email.setValue(null);
            }
            if (localStorage.email && (localStorage.email !== 'null')) {
                this.loginType = 'email';
                this.phoneForm.controls.email.setValue(localStorage.email);
                this.phoneForm.controls.phone.setValue(null);
            }
        } else {
            this.phoneForm.controls.remember_user.setValue(false);
            this.phoneForm.controls.phone.setValue(null);
            this.phoneForm.controls.email.setValue(null);
        }
        this.focusFirstInput();
    }

    resendSms() {
        this.onPhoneSubmit();
    }

    onPhoneSubmit() {
        if ((this.isLoginTypePhone() && this.phoneForm.controls.phone.invalid) ||
            (this.isLoginTypeEmail() && this.phoneForm.controls.email.invalid)) {
            return;
        }

        this.riverPayAccountId = '';

        const phone = this.isLoginTypePhone() ? this.phoneForm.controls.phone.value : null;
        const email = this.isLoginTypeEmail() ? this.phoneForm.controls.email.value : null;
        const authHash = localStorage.getItem('authHash');
        // let country = this.phoneForm.controls.country.value;

        this.authenticationService.checkPhoneAndSendPin(phone, email, authHash).subscribe(data => {
            // if (environment.frontEndTest) {
            //     data.status = false;
            //     data.code = 414;
            //     data.firstName = "";
            //     data.email = "dkdk@kddk";
            // }
            try {
                if (!data.status) {
                    if (data.code) {
                        if (data.code == AUTH_CODES.registerNewUser) {
                            this.errorHandlerRegisterNewUser();
                        } else if (data.code == AUTH_CODES.showSMSPin) {
                            this.showAuthSecondStep(data.code);
                            this.showSMSLoginStep();
                        } else if (data.code == AUTH_CODES.showAuthPin) {
                            this.showAuthSecondStep(data.code);
                            this.showAuthLoginStep();
                        } else if (data.code == AUTH_CODES.showPassword) {
                            this.showAuthSecondStep(data.code);
                            this.showPasswordLoginStep();
                        } else if (data.code == AUTH_CODES.updateUserInfo) {
                            this.errorHandlerUpdateUserInfo(data);
                        }  else {
                            this.popupComponent.showPopupError({text: data.message});
                        }
                    } else {
                        this.popupComponent.showPopupError({text: data.message});
                    }
                }
            } catch (e) {
                this.loaderService.hideAndShowTryAgain();
            }
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            setTimeout(() => {
                this.loaderService.hide();
            }, 0);
        });
    }

    showAuthSecondStep(code) {
        this.rememberUser();
        this.authCode = code;
        this.phoneFormActive = false;
    }

    showSMSLoginStep() {
        let phone = this.isLoginTypePhone() ? this.phoneForm.controls.phone.value : null;
        this.phoneLastFour = phone.substr(-4);

        this.authType = AUTH_TYPE.sms;
        this.focusPinInputs();
    }

    showAuthLoginStep() {
        this.authType = AUTH_TYPE.authPin;
        this.focusPinInputs();
    }

    showPasswordLoginStep() {
        this.authType = AUTH_TYPE.password;
        this.focusFirstInput();
    }

    rememberUser() {
        const phone = this.isLoginTypePhone() ? this.phoneForm.controls.phone.value : null;
        const email = this.isLoginTypeEmail() ? this.phoneForm.controls.email.value : null;
        if (this.phoneForm.controls.remember_user.value) {
            localStorage.phone = phone;
            localStorage.email = email;
            localStorage.remember_user = this.phoneForm.controls.remember_user.value;
        } else {
            localStorage.phone = '';
            localStorage.email = '';
            localStorage.remember_user = "false";
        }
    }

    errorHandlerRegisterNewUser() {
        this.registrationPartActive = true;
        this.phoneFormActive = false;
        this.isRegistrationUpdate = false;
        this.userRegistrationUpdateData = null;
    }

    errorHandlerUpdateUserInfo(data) {
        this.isRegistrationUpdate = true;
        this.registrationPartActive = true;
        this.phoneFormActive = false;
        this.userRegistrationUpdateData = data;

    }

    onSubmit() {
        const phone = this.isLoginTypePhone() ? this.phoneForm.controls.phone.value : null;
        const email = this.isLoginTypeEmail() ? this.phoneForm.controls.email.value : null;
        const password = this.isSecondStepPassword() || this.isSecondStepAuthAuthPinPassword() || this.isSecondStepAuthSMSPinPassword() ? this.pinForm.controls.password.value : null;
        const code = this.authCode;
        const authHash = localStorage.getItem('authHash');

        let pin = this.pinForm.controls.digits.value;
        pin = this.isSecondStepAuthSMSPin() || this.isSecondStepAuthAuthPin() ? pin.join('') : null;

        this.authenticationService.login(email, phone, pin, password, code, authHash).subscribe(data => {
            this.checkLoginSubmit(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    checkLoginSubmit(data) {
        try {
            if (data.status) {
                // this.showRiverPayAccounts(data);
                // this.showContactsFormFirst = data.result.first_name == '' && data.result.last_name == '' && data.result.email == '';
                // this.showContactsFormFirst = true;
                this.redirectToRewards();
            } else {
                if (data.code) {
                    if (data.code == ERROR_CODE.blocked || data.code == ERROR_CODE.authOver) {
                        this.popupComponent.showPopupErrorReturnEvent({text: data.message, closePopupAfter: TIMERS_CONFIG.popupHideAfter}, () => {
                            this.logout();
                        })
                    } else {
                        this.popupComponent.showPopupError({text: data.message});
                    }
                } else {
                    this.popupComponent.showPopupError({text: data.message});
                }
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    redirectToRewards() {
        // if (this.showContactsFormFirst) {
        //     this.router.navigate(['./home/contacts']);
        // } else {
            this.router.navigate([RouterPaths.shop]);
        // }
    }

    isPhoneClassActive() {
        if (this.phoneFormActive) {
            return true;
        }
        return false;
    }

    isRegistrationClassActive() {
        if (this.registrationPartActive) {
            return true;
        }
        return false;
    }

    isPinClassActive() {
        if (!this.phoneFormActive) {
            return true;
        }
        return false;
    }

    check(index, field, event) {
        if (isNaN(parseInt(event.key, 10)) && event.key !== 'Backspace') {
            event.preventDefault();
        }

        if (field.value && event.key !== 'Backspace') {
            if (index < this.inputs.toArray().length - 1) {
                this.inputs.toArray()[index + 1].nativeElement.focus();
            }
        } else if (event.key === 'Backspace') {
            if (index > 0) {
                field.setValue(null)
                this.inputs.toArray()[index - 1].nativeElement.focus();
            } else {
                console.log('first field');
            }
        }
    }

    onRememberUser(e) {
        const checked = e.target.checked;
        this.phoneForm.controls.remember_user.setValue(!!checked);
    }

    onRegisterNewUser(data) {
        const phone = this.isLoginTypePhone() ? this.phoneForm.controls.phone.value : null;
        const firstName = data.firstName;
        const lastName = data.lastName;
        const email = data.email;
        const promo = data.promo;
        const password = data.password;
        const pin = data.pin;

        if (pin.length !== 4) {
            const errorMessage = this.languageService.getString('messagesData', 'errorIncorrectPin');
            this.popupComponent.showPopupError({text: errorMessage});
            return
        }

        if (!firstName || !lastName || !email || !password || !pin) {
            const errorMessage = this.languageService.getString('messagesData', 'notAllFieldsAreFilled');
            this.popupComponent.showPopupError({text: errorMessage});
            return
        }

        this.loaderService.show();

        if (!this.isRegistrationUpdate) {
            this.authenticationService.register(firstName, lastName, email, promo, password, phone, pin).subscribe(data => {
                this.loaderService.hide();

                try {
                    if (data.status) {
                        let riverPayAccounts = data.accounts;

                        if (this.phoneForm.controls.remember_user.value) {
                            localStorage.phone = phone;
                            localStorage.email = email;
                        } else {
                            localStorage.phone = '';
                            localStorage.email = '';
                        }
                        localStorage.remember_user = this.phoneForm.controls.remember_user.value;

                        this.authenticationService.setData(data.result);

                        if (riverPayAccounts.length) {
                            this.checkLoginSubmit(data);
                        } else {
                            this.popupComponent.showPopupError();
                        }
                    } else {
                        this.popupComponent.showPopupError({text: data.message});
                    }
                } catch (e) {
                    this.loaderService.hide();
                    this.popupComponent.showPopupError();
                }

            }, (error) => {
                this.loaderService.hide();
                this.popupComponent.showPopupError();
            });
        } else {
            this.authenticationService.addPassword(phone,firstName, lastName, email, password, pin).subscribe(data => {
                this.loaderService.hide();

                try {
                    if (data.status) {
                        if (this.phoneForm.controls.remember_user.value) {
                            localStorage.phone = phone;
                            localStorage.email = email;
                        } else {
                            localStorage.phone = '';
                            localStorage.email = '';
                        }
                        this.checkLoginSubmit(data);
                    } else {
                        this.popupComponent.showPopupError({text: data.message});
                    }
                } catch (e) {
                    this.loaderService.hide();
                    this.popupComponent.showPopupError();
                }

            }, (error) => {
                this.loaderService.hide();
                this.popupComponent.showPopupError();
            });
        }

    }


    setPhoneMask(country) {
        this.phoneMask = this.phoneMaskService.setPhoneMask();
    }

    onCountryChange(e) {
        const value = e.target.value;
        this.setPhoneMask(value);
    }

    // getPartner() {
    //     this.currentPartner = this.PARTNERS[this.partnerService.currentPartner];
    // }

    getPartnerName() {
        // return '';
        return this.partnerService.getBusinessName() ? this.partnerService.getBusinessName() : 'Senet';

        // if (this.isApp) {
        //     return this.terminalService.getTitle();
        // } else {
        //     return this.partnerService.getName();
        // }

        //return this.currentPartner.name;
    }

    getPartnerLogo() {
        return this.partnerService.getPartnerLogo();
        // return this.partnerService.getPartnerLogo() ? this.partnerService.getPartnerLogo() : '../assets/images/logo.png';
    }

    // Detects if device is in standalone mode
    isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator['standalone']);

    isIos() {
        let userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test(userAgent);
    }

    install() {
        if (this.isIos() && !this.isInStandaloneMode()) {
            let userAgent = window.navigator.userAgent.toLowerCase();
            this.IOSInstallBtn = true;
            let device = this.languageService.getString('installData', 'device');

            if (/iphone/.test(userAgent)) {
                device = 'IPhone';
            } else if (/ipad/.test(userAgent)) {
                device = 'IPad';
            }
            // this.ios_popup_text = `Install this webapp on your ${device}: `;
            this.ios_popup_text = this.languageService.getStringWithVariables( [{'device': device}], 'installData', 'installThisWebapp');
        } else {
            this.deferredPrompt.prompt();
            this.deferredPrompt.userChoice.then(choiceResult => {
                if (choiceResult.outcome === 'accepted') {
                    // user accept the prompt
                    // lets hidden button
                    //this.androidInstallBtn = false;
                    //this.appInstalled = true;
                    //document.location.reload();

                    //this.appInstalled = true;

                } else {
                    console.log('User dismissed the prompt');
                }
                //this.deferredPrompt = null;
            });
        }
    }

    focusPinInputs() {
        setTimeout(() => {
            this.inputs.toArray()[0].nativeElement.focus();
        }, 0);
    }

    focusFirstInput() {
        setTimeout(() => {
            document.querySelector('input').focus();
        });
    }

    goBackToLogin() {
        document.querySelector('.login__inner').scrollTo({top: 0, behavior: 'smooth'});
        this.phoneFormActive = true;
        this.registrationPartActive = false;
        this.isRegistrationUpdate = false;
        this.userRegistrationUpdateData = null;

        this.authType = '';
        this.authCode = null;
        this.pinForm.reset();
        this.resetPinInput();
    }

    resetPinInput() {
        (this.pinForm.get('digits') as FormArray).clear();
        for (let i = 0; i < this.numOfDigits; i++) {
            (this.pinForm.get('digits') as FormArray).push(this.formBuilder.control(null, Validators.required))
        }
    }

    logout() {
        console.log("login logout()");

        this.auth.logout();
        //this.router.navigate(['/']);
        this.goBackToLogin();
        this.showGamesSelection = false;
        this.popupComponent.closePopup();

        if (this.phoneForm) {
            this.phoneForm.controls.phone.setValue(null);
        }else{
            this.phoneFormInit();
        }

        this.resetPinInput();
        this.pointId = null;
    }

    upgradeApp() {
        this.appVersionService.upgradeApp();
    }

    openAboutSite() {
        this.menu = false;
        const link = this.partnerService.getDomainHome();
        window.open(link, '_blank');
    }

    contactUs() {
        this.menu = false;
        this.contactUsPopup.show = true;
    }

    hideContactUs() {
        this.contactUsPopup.show = false;
    }

    isLoginBtnDisabled() {
        if (this.isLoginTypeEmail()) {
            return this.phoneForm.controls.email.invalid;
        }
        if (this.isLoginTypePhone()) {
            return this.phoneForm.controls.phone.invalid;
        }
    }

    changeLoginType(type) {
        this.loginType = type;
    }

    isLoginTypePhone() {
        return this.loginType === 'phone';
    }

    isLoginTypeEmail() {
        return this.loginType === 'email';
    }

    isSecondStepAuthSMSPin() {
        return this.authType === AUTH_TYPE.sms;
    }

    isSecondStepAuthSMSPinPassword() {
        return this.authType === AUTH_TYPE.smsPinPassword;
    }

    isSecondStepAuthAuthPin() {
        return this.authType === AUTH_TYPE.authPin;
    }

    isSecondStepAuthAuthPinPassword() {
        return this.authType === AUTH_TYPE.authPinPassword;
    }

    isSecondStepPassword() {
        return this.authType === AUTH_TYPE.password;
    }

    onForgotPassword() {
        const phone = this.isLoginTypePhone() ? this.phoneForm.controls.phone.value : null;
        const email = this.isLoginTypeEmail() ? this.phoneForm.controls.email.value : null;

        this.authenticationService.forgotPassword(email, phone).subscribe(data => {
            try {
                if (data.status) {
                    this.goBackToLogin();
                    this.popupComponent.showPopupSuccess({text: data.message});
                } else {
                    this.popupComponent.showPopupError({text: data.message});
                }
            } catch (e) {
                this.loaderService.hideAndShowTryAgain();
            }

        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    toggleInputType() {
        if (this.isSecondStepAuthAuthPin() || this.isSecondStepAuthAuthPinPassword()) {
            this.authType = this.isSecondStepAuthAuthPin() ? AUTH_TYPE.authPinPassword : AUTH_TYPE.authPin;
        } else if (this.isSecondStepAuthSMSPin() || this.isSecondStepAuthSMSPinPassword()) {
            this.authType = this.isSecondStepAuthSMSPin() ? AUTH_TYPE.smsPinPassword : AUTH_TYPE.sms;
        }
        // this.authType = this.isSecondStepAuthAuthPin() ? AUTH_TYPE.authPinPassword : this.isSecondStepAuthAuthPinPassword() ? AUTH_TYPE.authPin :
        //                 this.isSecondStepAuthSMSPin() ? AUTH_TYPE.smsPinPassword : AUTH_TYPE.sms;
        if (this.isSecondStepAuthAuthPinPassword() || this.isSecondStepAuthSMSPinPassword()) {
            this.focusFirstInput();
        } else {
            this.focusPinInputs();
        }
    }
}

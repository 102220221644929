<div class="investments-popup investments-popup--pay" [class.is-active]="popupInfo.show" [class.is-blurred]="isCashPopupShown || popupCheckInfo.show || popupBankInfo.show">
    <div class="investments-popup__inner  js-prizes-parent" *ngIf="popupInfo.show">
        <button class="investments-popup__close" (click)="closePopup()"></button>
<!--        <div class="investments-popup__header investments-popup__header&#45;&#45;centered">Money has been deposited into the terminal</div>-->
        <div class="investments-popup__scrolled" id="scrolled-wrapper">
            <div class="methods-list" *ngIf="!popupConfirmInfo.show && !popupDebitCardInfo.show">
                <div class="js-popup-subtitle investments-popup__header--centered" style="margin-bottom: 1rem;font-size: 0.8rem;">
                    {{minimumWithdrawalMessage}}
                </div>

                <div class="methods-list__item methods-list__header">
                    <div class="methods-list__left">
<!--                        Channel-->
                        {{'channelTitle' | languageTranslate}}
                    </div>
                    <div class="methods-list__right">
<!--                        Comments-->
                        {{'channelCommentsTitle' | languageTranslate}}
                    </div>
                </div>
                <div class="methods-list__item" *ngFor="let method of availableMethods">
                    <button class="methods-list__btn methods-list__left"
                            [class.is-limited]="method.limits"
                            [disabled]="method.disable && isDisableTimeActual(method.disableTime)"
                            (click)="onChannelClick(method)">{{method.name}}</button>
                    <div class="methods-list__description methods-list__right" *ngIf="!method.disable || !isDisableTimeActual(method.disableTime)" [innerHTML]="method.description"></div>
                    <div class="methods-list__description methods-list__right" *ngIf="method.disable && isDisableTimeActual(method.disableTime)" [innerHTML]="method.descriptionDisable"></div>

                    <!--                        <div class="methods-list__description methods-list__right" *ngIf="method.disableTime" [innerHTML]="method.description + ' <span>' + method.leftTime + '</span>'"></div>-->
                </div>
            </div>

            <div [ngStyle]="{visibility: isCardEditing ? 'hidden' : 'visible', maxHeight: isCardEditing ? 0 : 'initial'}">
                <app-popup-prizes-senetpro-confirm [popupInfo]="popupConfirmInfo"
                                                   [isPopup]="false"
                                                   (changeHandle)="changeHandlePopupConfirm($event)"
                                                   (confirm)="submitPopupConfirm($event)"
                                                   (close)="closePopupConfirm()">
                </app-popup-prizes-senetpro-confirm>
            </div>


            <app-popup-debit-card [popupInfo]="popupDebitCardInfo"
                                  [isPopup]="false"
                                  (back)="closeDebitCardPopupBack($event)"
                                  (close)="closeDebitCardPopup()">
            </app-popup-debit-card>
        </div>
    </div>
</div>

<div class="investments-popup" [class.is-active]="isCashPopupShown" style="z-index: 9999">
    <div class="investments-popup__inner">
        <button class="investments-popup__close" (click)="closeCashPopup()"></button>
        <div class="investments-popup__scrolled">
            <div class="js-popup-subtitle investments-popup__header--centered">
                {{cashMessage}}
            </div>

            <div class="form-simple__btns"
                 style="display: flex; flex-direction: row; justify-content: center">
                <div class="text-center form-btn-submit page__form-btns"
                     [ngStyle]="{marginRight: cashTickets ? '.5rem' : 'auto',
                                marginBottom: cashTickets ? '1rem' : 0,
                                marginLeft: cashTickets ? '0' : 'auto'}"
                     style="width: calc(50% - .5rem); margin-left: auto; max-width: 10rem">
                    <button class="button"
                            (click)="closeCashPopup()"
                            style="min-width: unset; width: 100%">
<!--                        Back-->
                        {{'btnBack' | languageTranslate}}
                        <svg><rect></rect></svg>
                    </button>
                </div>
                <div class="text-center form-btn-submit page__form-btns"
                     style="margin-bottom: 1rem; width: calc(50% - .5rem); max-width: 10rem; margin-left: .5rem" *ngIf="cashTickets">
                    <button class="button"
                            (click)="onCallMe()"
                            style="min-width: unset; width: 100%">
<!--                        Call Me-->
                        {{'btnCallMe' | languageTranslate}}
                        <svg><rect></rect></svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<app-popup-withdraw-check [popupInfo]="popupCheckInfo"
                          (onSuccess)="closePopupConfirm(true)"
                          (onClose)="closeCheckPopup()">
</app-popup-withdraw-check>

<app-popup-withdraw-bank  *ngIf="popupBankInfo.show"
                          [popupInfo]="popupBankInfo"
                          (onSuccess)="closePopupConfirm(true)"
                          (onClose)="closeBankPopup()">
</app-popup-withdraw-bank>

import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";


@Injectable({
    providedIn: 'root'
})
export class FrontendTestSettingsService {
    isTestMode = environment.frontEndTest;

    isResetAllSettings = false;

    partnerAdditionInfo = true;
    partnerTypeDefault = false;
    clientOn = false;

    constructor() {
        if (this.isTestModeOn()) {
            console.log('Frontend Test mode is on'.toLocaleUpperCase());
        } else {
            console.log('Frontend Test mode is off'.toLocaleUpperCase());
        }
    }

    isTestModeOn() {
        if (this.isTestMode && !this.isResetAllSettings) {
            return true;
        }

        return false;
    }

    isHideForTesting() {
        if (this.isTestModeOn()) {
            return false;
        }
        return true;
    }

    isClient() {
        if (this.isTestModeOn()) {
            return this.isTestModeOn() && this.clientOn;
        }

        return false;
    }

    isPartnerAdditionInfo() {
        if (this.isTestModeOn()) {
            return this.partnerAdditionInfo;
        }

        return false;
    }

    isPartnerTypeDefault() {
        if (this.isTestModeOn()) {
            return this.partnerTypeDefault;
        }

        return false;
    }
    isPartnerTypeSingle() {
        if (this.isTestModeOn()) {
            return !this.partnerTypeDefault;
        }

        return false;
    }
}

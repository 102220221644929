<app-popup-wrapper [show]="popupInfo.show" (onClose)="closePopup($event)">
    <div class="investments-popup__scrolled" id="scrolled-wrapper">
        <div class="js-popup-subtitle investments-popup__header--centered">
<!--            Please check your online statement for small deposit ranging from $0.50 to $0.99.-->
            {{'verifyCardInfo' | languageTranslate}}
        </div>

        <form [formGroup]="form" (ngSubmit)="sendForm()" class="verify-form">
            <div class="verify-form__item">
                <label class="verify-form__label login__text">
                    {{'formData' | languageTranslate: [{$innerVariable: 'amount'}]}}
                </label>
                <input formControlName="amount"
                       [prefix]="'$'"
                       mask="separator.2"
                       placeholder=""
                       [type]="'text'"
                       class="verify-form__input login__text"
                       autocomplete="off">
            </div>

            <div class="form-simple__btns">
<!--                <button class="button button&#45;&#45;reverse"-->
<!--                        *ngIf="isGeneratePopup()"-->
<!--                        [disabled]="form.invalid || form.controls.password.value !== form.controls.passwordRepeat.value">-->
<!--                    Generate-->
<!--                </button>-->

<!--                <button class="button button&#45;&#45;reverse"-->
<!--                        *ngIf="!isGeneratePopup()"-->
<!--                        [disabled]="form.controls.password.invalid">-->
<!--                    Send-->
<!--                </button>-->
                <div class="text-center form-btn-submit page__form-btns" style="margin-bottom: 1rem">
                    <button class="button"
                            [disabled]="!+form.controls.amount.value">
                        {{'verify' | languageTranslate}}
                        <svg><rect></rect></svg>
                    </button>
                </div>
            </div>
        </form>

    </div>
</app-popup-wrapper>



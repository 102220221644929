import { TRANSACTIONS_CONFIG } from "../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../loader.service";
var TransactionsService = /** @class */ (function () {
    function TransactionsService(http, loaderService) {
        this.http = http;
        this.loaderService = loaderService;
        this.config = TRANSACTIONS_CONFIG;
    }
    TransactionsService.prototype.confirmation = function (paymentIntent) {
        // return timer(2500).pipe();
        return this.http.post(this.config.confirmation, { paymentIntent: paymentIntent });
    };
    TransactionsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TransactionsService_Factory() { return new TransactionsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: TransactionsService, providedIn: "root" });
    return TransactionsService;
}());
export { TransactionsService };

<app-popup-wrapper [show]="popupInfo.show" (onClose)="closePopup()">
    <div class="investments-popup__scrolled" id="scrolled-wrapper">
        <div class="js-popup-subtitle investments-popup__header--centered" *ngIf="currentStep === popupSteps.amount">
            {{popupInfo.message}}
        </div>
        <div class="js-popup-subtitle investments-popup__header--centered" *ngIf="currentStep === popupSteps.address">
            {{'askToEnterDestinationAddress' | languageTranslate}}
        </div>

        <div *ngIf="currentStep === popupSteps.total">
            <div class="check-pic">
                <div class="check-pic__row" style="align-items: flex-start">
                    <img [src]="getPartnerLogo()"
                         class="check-pic__logo"
                         alt="">
                    <div class="check-pic__date check-pic__col check-pic__col--left">{{date | date: 'MM/dd/yyyy'}}</div>
                </div>
                <div class="check-pic__row">
                    <div class="check-pic__col check-pic__col--first">{{'pay' | languageTranslate}}</div>
                    <div class="check-pic__col check-pic__col--second">{{formatCurrency(subtotal)}}</div>
                    <div class="check-pic__col check-pic__col--left" style="max-width: 5rem;">{{'voidAfter90Days' | languageTranslate}}</div>
                </div>
                <div class="check-pic__row">
                    <div class="check-pic__col check-pic__col--first check-pic__col--mb" style="max-width: 3rem;">{{'toTheOrderOf' | languageTranslate}}</div>
                    <div class="check-pic__col check-pic__col--second">{{firstName}} {{lastName}}</div>
                </div>
                <div class="check-pic__row" style="align-items: center; margin-bottom: .8rem">
                    <div class="check-pic__col" style="font-size: .8em; margin-right: .5rem">{{'memo' | languageTranslate}}: </div>
                    <div class="check-pic__col check-pic__col--second" style="font-size: .8em; margin-right: .5rem">{{address}}</div>
                    <div class="check-pic__col check-pic__col--left">SENET</div>
                </div>
                <div class="check-pic__row">
                    <div class="check-pic__num">A000000000C A123456789C</div>
                </div>
            </div>
            <div class="check-preview-text">{{'previewOnly' | languageTranslate}}</div>
        </div>

        <form [formGroup]="form" class="form-simple">
            <div class="form-simple__item form-simple__item--amount"
                 *ngIf="currentStep === popupSteps.amount">
                <input mask="separator.0"
                       thousandSeparator=","
                       (input)="onInputAmount()"
                       formControlName="amount"
                       placeholder="{{'formData' | languageTranslate: [{$innerVariable: 'amount'}]}}"
                       [type]="'tel'"
                       class="form-simple__input"
                       autocomplete="off">
                <span>.00</span>
                <div class="page__form-note" [class.is-active]="moreThenAvailablePayout">
                    {{'available' | languageTranslate}} {{formatCurrency(popupInfo.totalDebit)}}
                </div>
            </div>

            <div class="form-simple__item" *ngIf="currentStep === popupSteps.address">
                    <textarea formControlName="address"
                              placeholder="{{'formData' | languageTranslate: [{$innerVariable: 'address'}]}}"
                              class="form-simple__input"
                              name="" id="" cols="30" rows="5"></textarea>
            </div>

            <div class="transaction-report" *ngIf="currentStep === popupSteps.total">
                <div class="transaction-report__body">
                    <div class="transaction-report__row">
                        <span>{{'checkAmount' | languageTranslate}}:</span> {{formatCurrency(subtotal)}}
                    </div>
                    <div class="transaction-report__row">
                        <span>{{'fee' | languageTranslate}}:</span> {{formatCurrency(fee)}}
                    </div>
                </div>
                <div class="transaction-report__result">
                    <div class="transaction-report__row">
                        <span>{{'total' | languageTranslate}}:</span> {{formatCurrency(total)}}
                    </div>
                </div>
            </div>

            <div class="form-simple__btns">
                <div class="text-center form-btn-submit page__form-btns" style="margin-bottom: 1rem">
                    <button class="button"
                            type="button"
                            (click)="onContinue()"
                            [disabled]="!+form.controls.amount.value ||
                                        ((currentStep === popupSteps.address) && !form.controls.address.value)">
                        {{'continue' | languageTranslate}}
                        <svg><rect></rect></svg>
                    </button>
                </div>
                <div class="text-center form-btn-submit page__form-btns">
                    <button class="button"
                            type="button"
                            (click)="onBack()">
                        {{'btnBack' | languageTranslate}}
                        <svg><rect></rect></svg>
                    </button>
                </div>
            </div>
        </form>

    </div>
</app-popup-wrapper>



import {Injectable} from '@angular/core';
import {LanguageService} from "../modules/language/language.service";

@Injectable({
    providedIn: 'root'
})
export class DateService {
    days = [
        this.languageService.getString('weekData', 'sunday'),
        this.languageService.getString('weekData', 'monday'),
        this.languageService.getString('weekData', 'tuesday'),
        this.languageService.getString('weekData', 'wednesday'),
        this.languageService.getString('weekData', 'thursday'),
        this.languageService.getString('weekData', 'friday'),
        this.languageService.getString('weekData', 'saturday')
    ];

    constructor(private languageService: LanguageService) {
    }

    getWeekDayName(date?) {
        let d;
        if (date) {
            d = new Date(date);
        } else {
            d = new Date();
        }
        return  this.days[d.getDay()];
    }

    getDateSafariSupport(date) {
        if (date) {
            return date.replace(/\s/g, 'T');
        } else {
            return '';
        }
    }

    msToTime(duration: number): string {
        let seconds: string|number = parseInt(String((duration/1000)%60));
        let minutes: string|number = parseInt(String((duration/(1000*60))%60));
        let hours: string|number = parseInt(String((duration/(1000*60*60))%24));
        const days: string|number = parseInt(String(duration/(1000*60*60*24)));
        const daysTitle = days === 1 ? this.languageService.getString('weekData', 'day') : this.languageService.getString('weekData', 'days');
        hours = (hours < 10) ? "0" + String(hours) : hours;
        minutes = (minutes < 10) ? "0" + String(minutes) : minutes;
        seconds = (seconds < 10) ? "0" + String(seconds) : seconds;

        return days?(days + " " + daysTitle + " " + hours + ":" + minutes + ":" + seconds):(hours + ":" + minutes + ":" + seconds);
    }

    getDisableTimeDist(disableTime) {
        const now = new Date();
        const utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
        const disableDate = new Date(disableTime);

        const sub = disableDate.getTime() - utc.getTime();
        return sub;
    }

    isDisableTimeActual(disableTime) {
        const dist = this.getDisableTimeDist(disableTime);
        return dist > 0;
    }
}

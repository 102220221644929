import { OnInit } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "../../service/loader.service";
import * as i2 from "@angular/router";
import * as i3 from "../../modules/language/language.service";
import * as i4 from "../../service/content-displaying.service";
import * as i5 from "../../service/partner.service";
import * as i6 from "../../service/account.service";
import * as i7 from "../../shared/popup/popup.component";
var VerifyEmailComponent = /** @class */ (function () {
    function VerifyEmailComponent(loaderService, router, languageService, activatedRoute, contentDisplayingService, partnerService, accountService, popupComponent) {
        this.loaderService = loaderService;
        this.router = router;
        this.languageService = languageService;
        this.activatedRoute = activatedRoute;
        this.contentDisplayingService = contentDisplayingService;
        this.partnerService = partnerService;
        this.accountService = accountService;
        this.popupComponent = popupComponent;
        this.verifyResultMessage = '';
        this.popupComponent.closePopup();
        document.body.classList.remove('hide-menu');
    }
    VerifyEmailComponent.prototype.ngOnInit = function () {
        this.onVerify();
    };
    VerifyEmailComponent.prototype.onVerify = function () {
        var _this = this;
        var code = this.activatedRoute.snapshot.queryParams.code;
        var email = this.activatedRoute.snapshot.queryParams.email;
        if (code && email) {
            this.accountService.onVerifyEmail(code, email).subscribe(function (data) {
                try {
                    _this.verifyResultMessage = data.message;
                }
                catch (e) {
                    _this.loaderService.hideAndShowTryAgain();
                }
            }, function () {
                _this.loaderService.hideAndShowTryAgain();
            }, function () {
                _this.loaderService.hide();
            });
        }
        else {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    VerifyEmailComponent.prototype.getPartnerLogo = function () {
        return this.partnerService.getPartnerLogo() ? this.partnerService.getPartnerLogo() : '../assets/images/logo.png';
    };
    VerifyEmailComponent.prototype.goBackToLogin = function () {
        this.router.navigate(['./']);
    };
    VerifyEmailComponent.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VerifyEmailComponent_Factory() { return new VerifyEmailComponent(i0.ɵɵinject(i1.LoaderService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.LanguageService), i0.ɵɵinject(i2.ActivatedRoute), i0.ɵɵinject(i4.ContentDisplayingService), i0.ɵɵinject(i5.PartnerService), i0.ɵɵinject(i6.AccountService), i0.ɵɵinject(i7.PopupComponent)); }, token: VerifyEmailComponent, providedIn: "root" });
    return VerifyEmailComponent;
}());
export { VerifyEmailComponent };

import {Component, EventEmitter, Input, OnInit, Output,Injectable} from '@angular/core';
import {SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import Swal from "sweetalert2";
import {LanguageService} from "../../modules/language/language.service";

@Component({
    selector: 'app-popup',
    templateUrl: './popup.component.html',
    styleUrls: ['./popup.component.scss']
})

@Injectable({providedIn: 'root'})
export class PopupComponent implements OnInit {

    constructor(private languageService: LanguageService) {
    }

    ngOnInit() {

    }

    swalStyled = Swal.mixin({
        customClass: {
            container: 'popup',
            popup: 'popup__inner',
            image: 'popup__image',
            title: 'popup__title',
            content: 'popup__content',
            confirmButton: 'popup__btn popup__btn_confirm button',
            cancelButton: 'popup__btn popup__btn_cancel'},
    });

    showPopupInfo(message?) {
        this.swalStyled.fire({
            showClass: {
                popup: 'swal-agreement'
            },
            html: message && message.text ? message.text : this.languageService.getString('messagesData','successMessage'),
            showConfirmButton: false,
            cancelButtonText: '<svg class="close" width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
              '<path d="M25.5 24.1719L18.3281 17L25.5 9.82812C25.8668 9.46136 25.8668 8.86676 25.5 8.5C25.1332 8.13324 24.5386 8.13324 24.1719 8.5L17 15.6719L9.82813 8.5C9.46136 8.13324 8.86676 8.13324 8.5 8.5C8.13324 8.86676 8.13324 9.46136 8.5 9.82812L15.6719 17L8.5 24.1719C8.13324 24.5386 8.13324 25.1332 8.5 25.5C8.86676 25.8668 9.46136 25.8668 9.82813 25.5L17 18.3281L24.1719 25.5C24.5386 25.8668 25.1332 25.8668 25.5 25.5C25.8668 25.1332 25.8668 24.5386 25.5 24.1719Z"/>\n' +
              '</svg>',
            allowOutsideClick: true,
            showCancelButton: true,
        })
    }

    showPopupSuccess(message?) {
        this.swalStyled.fire({
            showClass: {
                popup: 'swal-success'
            },
            text: message && message.text ? message.text : this.languageService.getString('messagesData','successMessage'),
            confirmButtonText: this.languageService.getString('okThanks'),
        })
    }

    showPopupSuccessReturnEvent(message, handler) {
        let text = message.text ? message.text : this.languageService.getString('messagesData','errorMessage');
        let confirmButtonText = message.confirmButtonText ? message.confirmButtonText : this.languageService.getString('ok');
        let closeBtn = message.closeBtn === false ? message.closeBtn : true;

        this.swalStyled.fire({
            showClass: {
                popup: 'swal-success'
            },
            text: text,
            showConfirmButton: closeBtn,
            allowOutsideClick: closeBtn,
            confirmButtonText: confirmButtonText,
        }).then((result) => {
            if (result.isConfirmed) {
                return handler(true);
            }else{
                return handler(false);
            }
        });
    }

    showPopupConfirmCancel(options, handler, timer = null) {
        let text = options.text ? options.text : this.languageService.getString('messagesData','errorMessage');
        let style = options.style ? options.style : 'swal-confirm-cancel';
        let closeBtn = options.closeBtn === false ? options.closeBtn : true;
        let confirmButtonText = options.confirmButtonText ? options.confirmButtonText : this.languageService.getString('confirm');
        let cancelButtonText = options.cancelButtonText ? options.cancelButtonText : this.languageService.getString('cancel');

        this.swalStyled.fire({
            showClass: {
                popup: style,

            },
            html: text,
            allowOutsideClick: closeBtn,
            showCancelButton: true,
            cancelButtonText: cancelButtonText,
            showConfirmButton: true,
            confirmButtonText: confirmButtonText,
            timer: timer,
            customClass: {
                container: 'popup',
                popup: 'popup__inner',
                image: 'popup__image',
                title: 'popup__title',
                content: 'popup__content',
                confirmButton: 'popup__btn popup__btn_confirm button',
                input: 'login-input',
                cancelButton: 'popup__btn popup__btn_cancel button'
            }
        }).then((result) => {
            const dismissReason: string = result.dismiss && result.dismiss.toString();

            if (dismissReason === 'timer') {
                return handler('timerOut');
            }

            if (result.isConfirmed) {
                return handler(true);
            } else {
                return handler(false);
            }
        })
    }

    showPopupErrorReturnEvent(message, handler) {
        let text = message.text ? message.text : this.languageService.getString('messagesData','errorMessage');
        let confirmButtonText = message.confirmButtonText ? message.confirmButtonText : this.languageService.getString('ok');
        let closeBtn = message.closeBtn === false ? message.closeBtn : true;

        this.swalStyled.fire({
            text: text,
            showConfirmButton: closeBtn,
            allowOutsideClick: closeBtn,
            confirmButtonText: confirmButtonText,
        }).then((result) => {
            if (result.isConfirmed) {
                return handler(true);
            }else{
                return handler(false);
            }
        });
    }

    showPopupError(message?) {
        let text = message && message.text ? message.text : this.languageService.getString('messagesData','errorMessage');
        let confirmButtonText = message && message.confirmButtonText ? message.confirmButtonText : this.languageService.getString('ok');
        let closeBtn = message && (message.closeBtn === false) ? message.closeBtn : true;

        this.swalStyled.fire({
            // title: 'Error',
            // imageUrl: '../assets/images/popup/error-logo.png',
            //className: "swal-back",
            showClass: {
                popup: 'swal-error'
            },
            text: text,
            showConfirmButton: closeBtn,
            allowOutsideClick: closeBtn,
            confirmButtonText: confirmButtonText
        })
    }

    showPopupTryAgain() {
        this.swalStyled.fire({
            showClass: {
                popup: 'swal-error'
            },
            text:  this.languageService.getString('messagesData','errorMessage'),
            confirmButtonText: this.languageService.getString('ok')
        });
    }

    showPopupAgreement(options, handler, timer = null) {
        let text = options.text ? options.text : this.languageService.getString('messagesData','errorMessage');
        let style = options.style ? options.style : 'swal-agreement';
        let closeBtn = options.closeBtn === false ? options.closeBtn : true;
        let allowOutsideClick = options.allowOutsideClick;
        let showConfirmButton = options.showConfirmButton === false ? options.showConfirmButton : true;
        let confirmButtonText = options.confirmButtonText ? options.confirmButtonText : this.languageService.getString('confirm');
        let cancelButtonText = options.cancelButtonText ? options.cancelButtonText : '<svg class="close" width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
          '<path d="M25.5 24.1719L18.3281 17L25.5 9.82812C25.8668 9.46136 25.8668 8.86676 25.5 8.5C25.1332 8.13324 24.5386 8.13324 24.1719 8.5L17 15.6719L9.82813 8.5C9.46136 8.13324 8.86676 8.13324 8.5 8.5C8.13324 8.86676 8.13324 9.46136 8.5 9.82812L15.6719 17L8.5 24.1719C8.13324 24.5386 8.13324 25.1332 8.5 25.5C8.86676 25.8668 9.46136 25.8668 9.82813 25.5L17 18.3281L24.1719 25.5C24.5386 25.8668 25.1332 25.8668 25.5 25.5C25.8668 25.1332 25.8668 24.5386 25.5 24.1719Z"/>\n' +
          '</svg>';

        this.swalStyled.fire({
            showClass: {
                popup: style,

            },
            html: text,
            confirmButtonText: confirmButtonText,
            cancelButtonText: closeBtn ? cancelButtonText : false,
            allowOutsideClick: allowOutsideClick !== undefined ? closeBtn && allowOutsideClick : closeBtn,
            showCancelButton: true,
            showConfirmButton: showConfirmButton,
            timer: timer

        }).then((result) => {
            const dismissReason: string = result.dismiss && result.dismiss.toString();

            if (dismissReason === 'timer') {
                return handler('timerOut');
            }

            if (result.isConfirmed) {
                return handler(true);
            } else {
                return handler(false);
            }
        })
    }

    closePopup() {
        if (this) {
            this.swalStyled.close();
        }
    }

    showPopupVariants(options, handler) {
        let text = options.text ? options.text : this.languageService.getString('messagesData','askToSelectAccount');
        let style = options.style ? options.style : 'swal-agreement';
        let confirmButtonText = options.confirmButtonText ? options.confirmButtonText : this.languageService.getString('confirm');
        let accounts = options.accounts;
        let cancelButtonText = options.cancelButtonText ? options.cancelButtonText : '<svg class="close" width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
          '<path d="M25.5 24.1719L18.3281 17L25.5 9.82812C25.8668 9.46136 25.8668 8.86676 25.5 8.5C25.1332 8.13324 24.5386 8.13324 24.1719 8.5L17 15.6719L9.82813 8.5C9.46136 8.13324 8.86676 8.13324 8.5 8.5C8.13324 8.86676 8.13324 9.46136 8.5 9.82812L15.6719 17L8.5 24.1719C8.13324 24.5386 8.13324 25.1332 8.5 25.5C8.86676 25.8668 9.46136 25.8668 9.82813 25.5L17 18.3281L24.1719 25.5C24.5386 25.8668 25.1332 25.8668 25.5 25.5C25.8668 25.1332 25.8668 24.5386 25.5 24.1719Z"/>\n' +
          '</svg>';

        let $this = this;
        this.swalStyled.fire({
            showClass: {
                popup: style,

            },
            input: 'radio',
            inputOptions: accounts,
            html: text,
            confirmButtonText: confirmButtonText,
            cancelButtonText: cancelButtonText,
            showCancelButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            inputValidator: (value) => {
                if (!value) {
                    return this.languageService.getString('messagesData','askToChooseSomething');
                }else{
                    //return handler(value);
                    //return value;
                }
            }
        }).then((result) => {
            if (result.isConfirmed) {
                return handler(result.value);
            }else{
                return handler(false);
            }
        })
    }

}
